import LoadingOverlay from 'react-loading-overlay';
import React, { Component } from "react";
import { Link } from 'react-router-dom';

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyToken, keyUser } from '../../../config'

import DashboardSideBar from '../../../components/dashboard/dashboardSideBar'
import FormUser from './form'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam'
import ModalMedia from "../../../components/modal/ModalMedia";
import Toast from '../../../components/Toast'

import { scriptsHideAds, scriptsUnHideAds } from '../../../utils/scripts'

class EditUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyword: "",
            text: '',
            roles: ["admin", "editor", "author", "contributor"],
            loading: false,
            loadingImage: false,
            loading: false,
            selectedImages: [],
            selectedImagesModal: [],
            imageText: "",
            images: [],
            imagePagination: {
                total_found: 1,
                limit: 9,
                current_page: 1,
                total_page: 1
            },
            newFormData: {
                username: "",
                name: "",
                email: "",
                biografi: "",
                no_hp: "",
                alamat: "",
                npwp: "",
                ktp: "",
                twitter: "",
                instagram: "",
                youtube: "",
                password: "",
                is_active: "0",
                is_verified: "0",
                is_blocked: "0",
                roles: "",
            },
            errors: {},
            addImagePostModal: false,
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true,
            verified: false,
            caption: "",
        }
        this.onChangeForm = this.onChangeForm.bind(this);
        this.onChangeCheckboxForm = this.onChangeCheckboxForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getDetailUser = this.getDetailUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this);
        this.getImagePosts = this.getImagePosts.bind(this);
        this.addImagePost = this.addImagePost.bind(this);
        this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onChangeKeyword = this.onChangeKeyword.bind(this);
        this.setParameterErrors = this.setParameterErrors.bind(this);
        this.onChangeCaption = this.onChangeCaption.bind(this);
        this.isSSR = IsSSR();
        this.token = null;
        this.user = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
        if (!this.isSSR) this.user = getStorageItem(keyUser);
    }

    handleChange(value) {
        this.setState({ text: value })
    }

    onChangeKeyword(e) {
        const { name, value } = e.target
        this.setState({ keyword: value })
    }

    onChangeCaption(e) {
        const { name, value } = e.target
        this.setState({ caption: value })
    }

    handleFileUpload(e) {
        const file = e.target.files[0];
        if (file) {
            this.setState({ loading: true });
            const formData = new FormData();
            let arrayName = file.name.split('.');
            arrayName.pop();
            let nameImage = arrayName.join(' ');

            formData.append("media", file)
            formData.append("meta_tag", nameImage.substring(0, 80))
            formData.append("media_type", "image")
            formData.append("caption", this.state.caption)
            const route = `/media/create`;
            fetch(`${apiUrl}` + route, {
                method: 'POST',
                headers: new Headers({
                    'x-access-token': this.token
                }),
                body: formData
            })
                .then(res => res.json()).then(response => {
                    this.setState({ loading: false });
                    if (response.status && response.status === "success") {
                        let images = [];
                        images[0] = response.data.id;
                        this.addImagePost(images, response.data.url);
                    } else {
                        CheckError(response);
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    CheckError(err);
                });
        }
    }

    componentDidMount() {
        this.getRoles().then(r => console.log('getRoles reload'));
        if (!this.token) return this.props.history.push(`/auth`);
        setTimeout(() => this.getDetailUser())
        setTimeout(() => {
            scriptsHideAds(() => {
                console.log('[scriptsHideAds.js]')
            })
        }, 600)
    }

    componentWillUnmount() {
        scriptsUnHideAds(() => {
            console.log('[scriptsUnhideAds.js]')
        })
    }

    getDetailUser() {
        const { match } = this.props
        var id = match.params.id
        let route = `/user/${id}`;
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    this.setState({ loading: false });
                    let rolesArr = [];
                    response.data.roles.map((role) => {
                        rolesArr.push(role.name);
                    });
                    let roles = rolesArr.join(",");
                    if (response.data.media) {
                        let images = []
                        images[0] = response.data.media.id
                        this.addImagePost(images, response.data.media.url)
                    }
                    delete response.data.roles;
                    delete response.data.media;

                    let newFormData = response.data;
                    newFormData.roles = roles;
                    this.setState({
                        newFormData,
                        loading: false,
                    });
                } else {
                    this.setState({ loading: false })
                    CheckError(response);
                    this.props.history.push(`/admin/users`);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                CheckError(error);
                this.props.history.push(`/admin/users`);
            })
    }

    getIndexOf(a, v) {
        var l = a.length
        for (var k = 0; k < l; k++) {
            if (a[k].name == v) {
                return true
            }
        }
        return false
    }

    async getRoles() {
        this.setState({ loadingRole: true })

        const user = JSON.parse(this.user)
        var isAdmin = await this.getIndexOf(user.roles, 'admin')
        this.setState({ isAdmin: isAdmin })

        var isEditor = await this.getIndexOf(user.roles, 'editor')
        this.setState({ isEditor: isEditor })

        var isAuthor = await this.getIndexOf(user.roles, 'author')
        this.setState({ isAuthor: isAuthor })

        var isContributor = await this.getIndexOf(user.roles, 'contributor')
        this.setState({ isContributor: isContributor })

        if (isAdmin) this.setState({ isAs: 'admin' })
        else if (isEditor) this.setState({ isAs: 'editor' })
        else if (isAuthor) this.setState({ isAs: 'author' })
        else if (isContributor) this.setState({ isAs: 'contributor' })
        if (['admin', 'editor', 'author'].indexOf(this.state.isAs) > -1) {
            this.setState({verified: true});
        }

        this.setState({ loadingRole: false })
    }

    onChangeDisclimer(e) {
        const { name, value, checked } = e.target
        if (checked) {
            this.setState({ [name]: 1 });
        } else {
            this.setState({ [name]: 0 });
        }
    }

    toggleAddImagePostModal() {
        if (!this.state.addImagePostModal) {
            this.getImagePosts();
        }
        let selectedImages = this.state.selectedImages;
        this.setState({
            addImagePostModal: !this.state.addImagePostModal,
            selectedImagesModal: selectedImages
        });
    }

    addImagePost(images, text, caption = "") {
        this.setState({ selectedImages: images });
        this.setState({ imageText: text });
        this.setState({ caption: caption });
    }

    setSelectedImagesModal(selectedImagesModal) {
        this.setState({ selectedImagesModal });
    }

    onChangeForm(e) {
        const { name, value } = e.target
        let { newFormData } = this.state;
        newFormData[name] = value;
        this.setState({ newFormData });
    }

    onChangeCheckboxForm(e) {
        const { name, value, checked } = e.target
        let { newFormData } = this.state;

        if (checked) {
            newFormData[name] = "1";
        } else {
            newFormData[name] = "0";
        }
        this.setState({ newFormData });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { match } = this.props
        var id = match.params.id
        let newFormData = this.state.newFormData;
        newFormData.media_id = this.state.selectedImages.join(",");
        this.setState({
            loading: true, newFormData
        }, () => {
            const params = this.state.newFormData;
            const route = `/user/update/${id}`;
            Api.put(route, params, this.token)
                .then(response => {
                    if (response.status && response.status === "success") {
                        Toast(response.message, "success");
                        this.props.history.push('/admin/users');
                    } else {
                        this.setState({ loading: false })
                        CheckErrorWithParam(response, this.setParameterErrors);
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    CheckErrorWithParam(error, this.setParameterErrors);
                })
        })
    }

    setParameterErrors(data) {
        this.setState({ errors: data })
    }

    getImagePosts(page = 1, limit = 9) {
        this.setState({ loadingImage: true });
        const route = `/media/index?type=image&page=` + page + `&limit=` + limit + `&keyword=` + this.state.keyword;
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    this.setState({ images: response.data, imagePagination: response.pagination, loadingImage: false });
                } else { CheckError(response); }
            })
            .catch((err) => {
                this.setState({ loadingImage: false });
                CheckError(err)
            })
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    render() {
        const {
            isAs
        } = this.state
        return (
            <div>
                <div className="container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link to="/admin/dashboard" className="breadcrumbs__url">
                                Home
                    </Link>
                        </li>
                        <li className="breadcrumbs__item">
                            <Link to="/admin/user" className="breadcrumbs__url">
                                User
                    </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Edit
                    </li>
                    </ul>
                </div>
                <div className="main-container container" id="main-container">
                    <div className="blog__content mb-72">
                        <h1 className="page-title">User</h1>
                        <div className="row card-row">
                            <DashboardSideBar isAs={isAs} localUser={this.user} {...this.props} />
                            <div className="col-lg-9 col-12">
                                <div className="entry card card-input">
                                    <LoadingOverlay
                                        active={this.state.loading}
                                        spinner
                                        text='Please Wait...'
                                    >
                                        <ModalMedia
                                            type={"post"}
                                            images={this.state.images}
                                            selectedImagesModal={this.state.selectedImagesModal}
                                            selectedImages={this.state.selectedImages}
                                            pagination={this.state.imagePagination}
                                            addImagePostModal={this.state.addImagePostModal}
                                            toggleAddImagePostModal={this.toggleAddImagePostModal}
                                            loadingImage={this.state.loadingImage}
                                            addImagePost={this.addImagePost}
                                            refreshData={this.getImagePosts}
                                            setSelectedImagesModal={this.setSelectedImagesModal}
                                            keyword={this.state.keyword}
                                            onChangeKeyword={this.onChangeKeyword}
                                        />
                                        <FormUser
                                            errors={this.state.errors}
                                            newFormData={this.state.newFormData}
                                            verified={this.state.verified}
                                            loading={this.state.loading}
                                            roles={this.state.roles}
                                            selectedImages={this.state.selectedImages}
                                            imageText={this.state.imageText}
                                            caption={this.state.caption}
                                            handleSubmit={this.handleSubmit}
                                            onChangeForm={this.onChangeForm}
                                            toggleAddImagePostModal={this.toggleAddImagePostModal}
                                            onChangeCheckboxForm={this.onChangeCheckboxForm}
                                            onVerify={this.onVerify}
                                            onChangeCaption={this.onChangeCaption}
                                            handleFileUpload={this.handleFileUpload}
                                            btnSubmit="Save"
                                            disabledForm={true}
                                            reqPass={false}
                                            isAs={this.state.isAs} 
                                        />
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUser;

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyToken, keyUser } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import FormTag from './form'
import Toast from '../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListTag extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingContainer: false,
            loading: false,
            editTagStatus: false,
            editTagId: 0,
            tagData: {
                name: '',
                slug: '',
                description: ''
            },
            deleteTagModal: false,
            deleteTagData: {
                id: '',
                name: ''
            },
            errors: {},
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true
        }

        this.onChangeTag = this.onChangeTag.bind(this)
        this.setLoadingContainer = this.setLoadingContainer.bind(this)
        this.refreshTags = this.refreshTags.bind(this)
        this.showTagDetail = this.showTagDetail.bind(this)
        this.deleteTag = this.deleteTag.bind(this)
        this.refreshEditData = this.refreshEditData.bind(this)
        this.setParameterErrors = this.setParameterErrors.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-tag').off( "click", ".detail-tag-btn");
                $('#datatable-tag').off( "click", ".delete-tag-btn");
                $('#datatable-tag')
                    .DataTable()
                    .destroy()
                $('#datatable-tag tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    onChangeTag(e) {
        const { name, value } = e.target
        let { tagData } = this.state
        tagData[name] = value
        this.setState({ tagData })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    refreshEditData() {
        this.setState({
            editTagStatus: false,
            editTagId: 0,
            tagData: {
                name: '',
                slug: '',
                description: ''
            },
            errors: {}
        })
    }

    fetchData() {
        const user = JSON.parse(this.props.localUser)
        const {
            isAs
        } = this.props

        var _ = this
        const route = `/tag/index`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var tagDatatableDraw = 1
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    tagDatatableDraw = data.draw
                    var requestData = {}
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/tag?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id', orderable: false },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    render: function (data) {
                        return (
                            '<a href="javascript:void(0);" data-id="' +
                            data.id +
                            '" class="detail-tag-btn">' +
                            data.name +
                            '</a>'
                        )
                    }
                },
                { data: 'slug', name: 'slug', orderable: false },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
                            data.id +
                            '" class="detail-tag-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
                            data.id +
                            '" data-name="' +
                            data.name +
                            '" class="delete-tag-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        })
        $('#datatable-tag').on('click', '.detail-tag-btn', function () {
            var id = $(this).data('id')
            _.showTagDetail(id)
        })
        $('#datatable-tag').on('click', '.delete-tag-btn', function () {
            var id = $(this).data('id')
            var name = $(this).data('name')
            _.deleteTag(id, name)
        })
    }

    refreshTags() {
        $('#datatable-tag')
            .DataTable()
            .ajax.reload()
    }

    showTagDetail(id) {
        this.setLoadingContainer(true)
        this.setParameterErrors({})
        const route = `/tag/` + id
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.setState({
                        editTagStatus: true,
                        editTagId: response.data.id,
                        tagData: response.data
                    })
                    this.setLoadingContainer(false)
                } else { CheckError(response) }
            })
            .catch((err) => {
                this.setLoadingContainer(false)
                CheckError(err)
            })
    }

    deleteRowTable(id) {
        this.setState({ loading: true })
        const route = `/tag/delete/` + id
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.refreshTags()
                    Toast(response.message, 'success')
                    this.setState({ deleteTagModal: false })
                } else { CheckError(response) }
            })
            .catch((err) => {
                this.refreshTags()
                this.setState({ deleteTagModal: false })
                CheckError(err)
            })
    }

    toggleDeleteTagsModal() {
        this.setState({
            deleteTagModal: !this.state.deleteTagModal
        })
    }

    deleteTag(id, name) {
        console.log(id)
        this.setState({
            deleteTagData: {
                id: id,
                name: name
            },
            deleteTagModal: !this.state.deleteTagModal
        })
    }

    setLoadingContainer(status) {
        this.setState({ loadingContainer: status })
    }

    render() {
        const { loadingContainer } = this.state
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <Modal
                            isOpen={this.state.deleteTagModal}
                            toggle={this.toggleDeleteTagsModal.bind(this)}
                            className={this.props.className}
                        >
                            <ModalHeader
                                toggle={this.toggleDeleteTagsModal.bind(this)}
                            >
                                Konfirmasi
                            </ModalHeader>
                            <ModalBody>
                                Apakah ingin menghapus tag{' '}
                                {this.state.deleteTagData.name}?
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.deleteRowTable.bind(
                                        this,
                                        this.state.deleteTagData.id
                                    )}
                                >
                                    Hapus
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDeleteTagsModal.bind(
                                        this
                                    )}
                                >
                                    Batalkan
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <FormTag
                            setLoadingContainer={this.setLoadingContainer}
                            onChangeTag={this.onChangeTag}
                            refreshTags={this.refreshTags}
                            setParameterErrors={this.setParameterErrors}
                            refreshEditData={this.refreshEditData}
                            editTagStatus={this.state.editTagStatus}
                            editTagId={this.state.editTagId}
                            tagData={this.state.tagData}
                            errors={this.state.errors}
                            isAs={this.props.isAs}
                        />
                        <div className="divider" />
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Tag List</h4>
                                    <table
                                        id="datatable-tag"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Tag Name</th>
                                                <th>Slug</th>
                                                <th>Start date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ListTag

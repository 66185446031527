import Toast from '../../components/Toast'

import { keyToken, keyUser } from '../../config'
import { removeStorageItem } from '../../helpers/storage'

const checkError = async (response) => {
    if (response.status && response.status === "failed") {
        if (response.message == "Fail to Authentication." || response.message == "No token provided.") {
            let msg = 'Token Expired, Please login again!'
            Toast(msg);
            await removeStorageItem(keyToken);
            await removeStorageItem(keyUser);
            setTimeout(() => {
                window.location.reload()
            }, 600);
        } else {
            Toast(response.message);
        }
    } else {
        Toast('Error internet connection');
    }
};

export default checkError
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { numberFormat } from "./NumberFormat";
import Loading from "./Loading";
class Covid19 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      local: { confirmed: {}, deaths: {}, recovered: {} },
      global: { confirmed: 0, deaths: 0, recovered: 0 },
      loadingLocal: true,
      loadingGlobal: true,
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loadingLocal: true,
      loadingGlobal: true,
    });
    fetch(`https://api-covid.ivoox.id/data-kasus`)
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        console.log('Covid19 response:', resJson);
        this.setState({
          local: { confirmed: { value: resJson?.cases ?? 0 }, deaths: { value: resJson?.deaths ?? 0 }, recovered: { value: resJson?.recovered ?? 0 } },
          loadingLocal: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingLocal: false,
        });
      });
    fetch(`https://api-covid.ivoox.id/data-global`)
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        this.setState({
          global: resJson,
          loadingGlobal: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingGlobal: false,
        });
      });
  }
  render() {
    const { local, global, loadingLocal, loadingGlobal } = this.state;

    return (
      <div className="content covid-19-ivoox">
        <div className="title_covid">Update Terbaru virus covid-19</div>
        <div className="counter-container">
          <div className="counter-box">
            <div className="flex">
              <div className="box">
                <div className="title">Indonesia</div>
              </div>
            </div>
            {loadingLocal && <Loading />}
            {!loadingLocal && local.confirmed && (
              <div className="flex">
                <div className="box">
                  <div className="counter-label">Positif</div>
                  <div className="counter-value text-secondary">
                    {numberFormat(local.confirmed.value)}
                  </div>
                </div>

                <div className="box">
                  <div className="counter-label">Meninggal</div>
                  <div className="counter-value text-danger">
                    {numberFormat(local.deaths.value)}
                  </div>
                </div>

                <div className="box">
                  <div className="counter-label">Sembuh</div>
                  <div className="counter-value text-success">
                    {numberFormat(local.recovered.value)}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="counter-box-separator"></div>

          <div className="counter-box">
            <div className="flex">
              <div className="box">
                <div className="title">Dunia</div>
              </div>
            </div>
            {loadingGlobal && <Loading />}

            {!loadingGlobal && global.cases && (
              <div className="flex">
                <div className="box">
                  <div className="counter-label">Positif</div>
                  <div className="counter-value text-secondary">
                    {numberFormat(global.cases)}
                  </div>
                </div>

                <div className="box">
                  <div className="counter-label">Meninggal</div>
                  <div className="counter-value text-danger">
                    {numberFormat(global.deaths)}
                  </div>
                </div>

                <div className="box">
                  <div className="counter-label">Sembuh</div>
                  <div className="counter-value text-success">
                    {numberFormat(global.recovered)}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="button">
            <a
              class="btn btn-color"
              href="https://covid-19.ivoox.id"
              target="_blank"
              rel="">
              See More
            </a>
          </div>
        </div>



      </div>
    );
  }
}

export default Covid19;

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { IsSSR } from '../../helpers/isSSR';

class AlamatRedaksi extends Component {
  constructor(props) {
    super(props);
    this.isSSR = IsSSR();
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container">
          <ul className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to="/" className="breadcrumbs__url">
                Home
              </Link>
            </li>
            <li className="breadcrumbs__item breadcrumbs__item--current">
              Alamat Redaksi
            </li>
          </ul>
        </div>

        <div className="main-container container" id="main-container">
          <h1 className="page-title">ALAMAT REDAKSI</h1>
          <div className="row">
            <div className="col-lg-8 mb-72">
              <div className="content-box">
                <p>
                  Jl. Batu Ceper IV, No.6C Gambir, Jakarta Pusat, Jakarta 10120
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlamatRedaksi;

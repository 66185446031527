import React, { Component } from 'react'
import AsyncPaginate from 'react-select-async-paginate';
import DatePicker from "react-datepicker";
import { apiUrl, sitekeyReaptcha } from '../../../../config'
import moment from 'moment'

import CheckError from '../../../../utils/admin/checkError'

class SearchReportPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.onChangeDate = this.onChangeDate.bind(this)
        this.getUsers = this.getUsers.bind(this)
        this.setValueUser = this.setValueUser.bind(this)
        this.getCategories = this.getCategories.bind(this)
        this.setValueCategroy = this.setValueCategroy.bind(this)
        this.onDeleteDate = this.onDeleteDate.bind(this)
    }

    onDeleteDate() {
        this.props.onChangeInput("createdDate", "");
        let e = {
            target: {
                "name": "createdAtMonth",
                "value": ""
            }
        }
        this.props.onChangeSearch(e);
        e = {
            target: {
                "name": "createdAtYear",
                "value": ""
            }
        }
        this.props.onChangeSearch(e);
    }

    onChangeDate(createdDate) {
        this.props.onChangeInput("createdDate", createdDate);
        console.log(createdDate);
        let e = {
            target: {
                "name": "createdAtMonth",
                "value": moment(createdDate).format("M")
            }
        }
        this.props.onChangeSearch(e);
        e = {
            target: {
                "name": "createdAtYear",
                "value": moment(createdDate).format("YYYY")
            }
        }
        this.props.onChangeSearch(e);
    }

    async getUsers(search, loadedOptions, { page }) {
        const route = `/user/index?page=${page}&limit=10&keyword=${search}`;
        const response = await fetch(`${apiUrl}` + route, { headers: { 'x-access-token': this.props.token } });
        const responseJSON = await response.json();
        let options = [];
        let hasMore = false;
        if (responseJSON.status && responseJSON.status === "success") {
            options = await responseJSON.data.map((users) => {
                let usersResponse = {
                    value: users.id,
                    label: users.name + "(" + users.username + ")",
                };
                return usersResponse;
            });
            if (page === 1) {
                options.unshift({
                    value: "",
                    label: "All User"
                })
            }
            if (responseJSON.pagination.total_page > responseJSON.pagination.current_page) {
                hasMore = true;
                this.setState({ pageTag: this.state.pageTag + 1 });
            } else {
                hasMore = false;
            }
        } else {
            CheckError(responseJSON)
        }
        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    setValueUser(value) {
        let author = value;
        this.props.onChangeInput("author", author);
        let e = {
            target: {
                "name": "user_id",
                "value": author.value
            }
        }
        this.props.onChangeSearch(e);
        let lable = {
            target: {
                "name": "user_name",
                "value": author.label
            }
        }
        this.props.onChangeSearch(lable);
    }

    async getCategories(search, loadedOptions, { page }) {
        const route = `/category/index?page=${page}&limit=10&keyword=${search}`;
        const response = await fetch(`${apiUrl}` + route, { headers: { 'x-access-token': this.props.token } });
        const responseJSON = await response.json();
        let options = [];
        let hasMore = false;
        if (responseJSON.status && responseJSON.status === "success") {
            options = await responseJSON.data.map((category) => {
                let categoryResponse = {
                    value: category.id,
                    label: category.name,
                };
                return categoryResponse;
            });
            if (page === 1) {
                options.unshift({
                    value: "",
                    label: "All Category"
                })
            }
            if (responseJSON.pagination.total_page > responseJSON.pagination.current_page) {
                hasMore = true;
                this.setState({ pageTag: this.state.pageTag + 1 });
            } else {
                hasMore = false;
            }
        } else {
            CheckError(responseJSON)
        }
        return {
            options: options,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    }

    setValueCategroy(value) {
        let category = value;
        this.props.onChangeInput("category", category);
        console.log(category);
        let e = {
            target: {
                "name": "category_id",
                "value": category.value
            }
        }
        this.props.onChangeSearch(e);
        let lable = {
            target: {
                "name": "category_name",
                "value": category.label
            }
        }
        this.props.onChangeSearch(lable);
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-3 col-12">
                    <div className="form-group">
                        <label htmlFor="title">
                            Post Title
                        </label>
                        <input id="title" type="text" placeholder="Post Title" name="title" value={this.props.searchData.title} onChange={this.props.onChangeSearch} />
                    </div>
                </div>
                {['admin', 'editor'].indexOf(this.props.isAs) > -1 && (
                    <div className="col-lg-3 col-12">
                        <div className="form-group">
                            <label htmlFor="author">
                                Author
                            </label>
                            <AsyncPaginate
                                id="author"
                                className="basic-author-select"
                                value={this.props.author}
                                loadOptions={this.getUsers}
                                onChange={this.setValueUser}
                                classNamePrefix="select"
                                placeholder="-- Pilih Author --"
                                additional={{
                                    page: 1,
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-3 col-12">
                    <div className="form-group">
                        <label htmlFor="typeList">
                            Status
                        </label>
                        <select name="typeList" id="typeList" onChange={this.props.onChangeSearch} value={this.props.searchData.typeList}>
                            <option value="all">All</option>
                            <option value="3">Published</option>
                            <option value="2">Pending</option>
                            <option value="1">Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-12">
                    <div className="form-group month-post-list">
                        <label htmlFor="createdDate">
                            All Dates
                        </label>
                        {this.props.createdDate && (
                            <i class="fa fa-remove" onClick={() => this.onDeleteDate()} style={{ cursor: "pointer", fontSize: "21px", color: "red", position: "absolute", zIndex: "5", top: "37px", right: "23px" }}></i>
                        )}
                        <DatePicker
                            id="createdDate"
                            selected={this.props.createdDate}
                            dateFormat="MMM yyyy"
                            onChange={this.onChangeDate}
                            showMonthYearPicker
                        />
                    </div>
                </div>
                {this.props.title !== 'Nitizen Jurnalism' && (
                    <div className="col-lg-3 col-12">
                        <div className="form-group">
                            <label htmlFor="category">
                                Category
                            </label>
                            <AsyncPaginate
                                id="category"
                                className="basic-author-select"
                                value={this.props.category}
                                loadOptions={this.getCategories}
                                onChange={this.setValueCategroy}
                                classNamePrefix="select"
                                placeholder="-- Pilih Kategori --"
                                additional={{
                                    page: 1,
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-3 col-12">
                    <div className="form-group">
                        <label htmlFor="limit">
                            Items per page
                        </label>
                        <select name="limit" id="limit" value={this.props.searchData.limit} onChange={this.props.onChangeSearch}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="1000">1000</option>
                            <option value="All">All</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-12 text-right">
                    <div className="form-group">
                        <button
                            style={{ marginTop: "27px" }}
                            className="btn btn-md btn-color btn-button"
                            onClick={() => this.props.refreshPosts()}
                        >
                            Filter{this.state.test}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchReportPost

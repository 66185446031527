import React, { Component } from 'react'

import Toast from './Toast'

import Api from '../services/api'

class NewsletterFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscribeEmail: ''
    }
    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onSubmitSubscribe(e) {
    e.preventDefault()
    this.setState({ submittedSubscribe: true })
    const { subscribeEmail } = this.state
    if (subscribeEmail) {
      const route = `/subcriber/create`
      let params = {}
      params.email = subscribeEmail
      Api.post(route, params, null)
        .then((response) => {
          if (response.status && response.status === 'success') {
            Toast(response.message, 'success')
          }
        })
        .catch((error) => {
          if (error.status && error.status === 'failed') {
            Toast(error.message)
          } else {
            Toast('Error internet connection')
          }
        })
    }
  }

  onChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render() {
    const { subscribeEmail } = this.state
    return (
      <div className="newsletter-wide__form">
        <form className="mc4wp-form" name="formSubscribe" onSubmit={this.onSubmitSubscribe}>
          <div className="mc4wp-form-fields">
            <div className="form-group">
              <input type="email" name="subscribeEmail" placeholder="Your email" value={subscribeEmail} onChange={this.onChange} />
            </div>
            <div className="form-group">
              <input type="submit" className="btn btn-lg btn-color btn-email" style={{ borderTopLeftRadius: 'none !important' }} value="Sign Up" />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default NewsletterFooter

import React, { Component } from "react";
import { Link } from "react-router-dom";
class Loading extends Component {
  render() {
    return (
      <div className="loading">
        {/* <img src={virusImage} alt="Loading..." className="rotating" /> */}
        <p>Memuat...</p>
      </div>
    );
  }
}

export default Loading;

import React, { Component } from "react";
import Reaptcha from 'reaptcha'

import { sitekeyReaptcha } from '../../../config'

class UserForm extends Component {
    render() {
        let roles = this.props.roles.map((role) => {
            return (
                <option key={role} value={role} >{role}</option>
            )
        });

        let imageContainer;
        if (this.props.selectedImages.length > 1) {
            imageContainer = (
                <div style={{ paddingBottom: "10px" }}>
                    {this.props.selectedImages.length} Images
                </div>
            );
        } else if (this.props.selectedImages.length === 1) {
            imageContainer = (
                <div style={{ paddingBottom: "10px" }}>
                    <div
                        style={{
                            height: "150px", width: "100%", backgroundImage: `url(${this.props.imageText}`,
                            backgroundRepeat: "no-repeat", backgroundSize: "cover"
                        }}
                    ></div>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="col-12">
                    <h4 className="heading-title">Add New User</h4>
                    <form onSubmit={this.props.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="username">Username <span className="text-red">*</span></label>
                            <input type="text" placeholder="Username"
                                id="username" name="username" required value={this.props.newFormData.username}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                                disabled={this.props.disabledForm}
                            />
                            {this.props.errors.username && this.props.errors.username.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.username.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="media_id">
                                Image
                            </label>
                            {imageContainer}
                            <div>
                                <div className="form-group">
                                    <label htmlFor="caption">Caption(Untuk Upload)</label>
                                    <input type="text" placeholder="Caption" name="caption" value={this.props.caption} onChange={this.props.onChangeCaption} 
                                        style={{marginBottom : "0px"}}
                                    />
                                    {this.props.errors.caption && this.props.errors.caption.message && (
                                        <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                            <small style={{ color: 'white' }}>{this.props.errors.caption.message}</small>
                                        </div>
                                    )}
                                </div>
                                <label className="btn btn btn-md btn-color btn-file" onClick={this.props.toggleAddImagePostModal} style={{ marginRight: "10px" }}>
                                    Browse
                                    </label>
                                <label className="btn btn btn-md btn-color btn-file">
                                    Upload
                                    <input type="file" onChange={this.props.handleFileUpload} />
                                </label>
                            </div>
                            <p>
                                The image must be a file of type: jpg,
                                jpeg, png, ico
                            </p>
                            {this.props.errors.media_id && this.props.errors.media_id.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.media_id.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Name <span className="text-red">*</span></label>
                            <input type="text" placeholder="Name"
                                id="name" name="name" required value={this.props.newFormData.name}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                            />
                            {this.props.errors.name && this.props.errors.name.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.name.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email <span className="text-red">*</span></label>
                            <input type="email" placeholder="Email"
                                id="email" name="email" required value={this.props.newFormData.email}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                                disabled={this.props.disabledForm}
                            />
                            {this.props.errors.email && this.props.errors.email.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.email.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password <span className="text-red">*</span></label>
                            <input type="password" placeholder="password"
                                id="password" name="password" value={this.props.newFormData.password}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                                required={this.props.reqPass}
                            />
                            {this.props.errors.password && this.props.errors.password.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.password.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="roles">Role <span className="text-red">*</span></label>
                            <select className="nomargin" name="roles" value={this.props.newFormData.roles} onChange={this.props.onChangeForm}>
                                <option value="" disabled>-- Pilih Role --</option>
                                {roles}
                            </select>
                            {this.props.errors.roles && this.props.errors.roles.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.roles.message}</small>
                                </div>
                            )}
                            <hr />
                        </div>
                        <div className="form-group">
                            <label htmlFor="no_hp">No Telepon</label>
                            <input type="number" placeholder="No Telepon"
                                id="no_hp" name="no_hp" value={this.props.newFormData.no_hp}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                            />
                            {this.props.errors.no_hp && this.props.errors.no_hp.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.no_hp.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="alamat">Alamat </label>
                            <textarea id="alamat" name="alamat" rows="7" value={this.props.newFormData.alamat} onChange={this.props.onChangeForm} />
                            {this.props.errors.alamat && this.props.errors.alamat.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.alamat.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="biografi">Biografi Singkat </label>
                            <textarea id="biografi" name="biografi" rows="7" value={this.props.newFormData.biografi} onChange={this.props.onChangeForm} />
                            {this.props.errors.biografi && this.props.errors.biografi.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.biografi.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="facebook">Facebook</label>
                            <input type="text" placeholder="Facebook"
                                id="facebook" name="facebook" value={this.props.newFormData.facebook}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                            />
                            {this.props.errors.facebook && this.props.errors.facebook.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.facebook.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="instagram">Instagram</label>
                            <input type="text" placeholder="Instagram"
                                id="instagram" name="instagram" value={this.props.newFormData.instagram}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                            />
                            {this.props.errors.instagram && this.props.errors.instagram.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.instagram.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="twitter">Twitter</label>
                            <input type="text" placeholder="Twitter"
                                id="twitter" name="twitter" value={this.props.newFormData.twitter}
                                onChange={this.props.onChangeForm} style={{ marginBottom: "0px" }}
                            />
                            {this.props.errors.twitter && this.props.errors.twitter.message && (
                                <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                    <small style={{ color: 'white' }}>{this.props.errors.twitter.message}</small>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-6">
                                    <input type="checkbox" className="checkbox-input"
                                        onChange={this.props.onChangeCheckboxForm}
                                        name="is_active" id="is_active" value="1"
                                        checked={this.props.newFormData.is_active === "1" ? true : false}
                                    />
                                    <label className="checkbox-input" htmlFor="is_active">
                                        Is Active
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="checkbox" className="checkbox-input"
                                        onChange={this.props.onChangeCheckboxForm} name="is_blocked"
                                        id="is_blocked" value="1"
                                        checked={this.props.newFormData.is_blocked === "1" ? true : false}
                                    />
                                    <label className="checkbox-input" htmlFor="is_blocked">
                                        Is Blocked
                                    </label>
                                </div>
                                <div className="col-6">
                                    <input type="checkbox" className="checkbox-input"
                                        onChange={this.props.onChangeCheckboxForm} name="is_verified"
                                        id="is_verified" value="1"
                                        checked={this.props.newFormData.is_verified === "1" ? true : false}
                                    />
                                    <label className="checkbox-input" htmlFor="is_verified">
                                        Is Verified
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            { (['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                <Reaptcha
                                    sitekey={sitekeyReaptcha}
                                    onVerify={this.onVerify}
                                />
                            }
                        </div>
                        <div className="form-group">
                            <button
                                disabled={
                                    !this.props.verified ||
                                    this.props.loading
                                }
                                type="submit"
                                className="btn btn-md btn-color btn-button"
                            >{this.props.btnSubmit}</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default UserForm;
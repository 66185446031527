import React, { Component } from 'react'

import { sosialMedia } from "../config";

class SocialMedia extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<aside className="widget widget-socials">
				<h4 className="widget-title">Let's hang out on social</h4>
				<div className="socials socials--wide socials--large">
					<div className="row row-16">
						<div className="col">
							<a className="social social-facebook" href={`${sosialMedia[0].link}`} title="facebook" target="_blank" aria-label="facebook">
								<i className="ui-facebook"></i>
								<span className="social__text">Facebook</span>
							</a>
							<a className="social social-twitter" href={`${sosialMedia[1].link}`} title="twitter" target="_blank" aria-label="twitter">
								<i className="ui-twitter"></i>
								<span className="social__text">Twitter</span>
							</a>
						</div>
						<div className="col">
							<a className="social social-google-plus" href={`${sosialMedia[2].link}`} title="google" target="_blank" aria-label="google">
								<i className="ui-google"></i>
								<span className="social__text">Google+</span>
							</a>
							<a className="social social-instagram" href={`${sosialMedia[3].link}`} title="instagram" target="_blank" aria-label="instagram" style={{padding : '0px 5px'}}>
								<i className="ui-instagram"></i>
								<span className="social__text">Instagram</span>
							</a>
						</div>
					</div>
				</div>
			</aside>
		)
	}
}

export default SocialMedia

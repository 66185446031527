import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Reaptcha from 'reaptcha';

import { getStorageItem } from '../../../helpers/storage';
import { IsSSR } from '../../../helpers/isSSR';

import { apiUrl, keyToken, keyUser, sitekeyReaptcha } from '../../../config';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam';
import ModalMedia from '../../../components/modal/ModalMedia';
import Toast from '../../../components/Toast';

class FormCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      keywordIcon: '',
      loading: false,
      categories: [],
      file: null,
      selectedImagesModal: [],
      selectedIconsModal: [],
      images: [],
      icons: [],
      imagePagination: {
        total_found: 1,
        limit: 9,
        current_page: 1,
        total_page: 1
      },
      iconPagination: {
        total_found: 1,
        limit: 9,
        current_page: 1,
        total_page: 1
      },
      errors: {},
      verified: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this);
    this.getImagePosts = this.getImagePosts.bind(this);
    this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.onChangeKeyword = this.onChangeKeyword.bind(this);

    this.toggleAddIconPostModal = this.toggleAddIconPostModal.bind(this);
    this.getIconPosts = this.getIconPosts.bind(this);
    this.setSelectedIconsModal = this.setSelectedIconsModal.bind(this);
    this.handleIconUpload = this.handleIconUpload.bind(this);
    this.onChangeKeywordIcon = this.onChangeKeywordIcon.bind(this);
    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);
  }

  componentDidMount() {
    if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
      this.setState({ verified: true });
    }
    setTimeout(() => this.getParentCategory());
  }

  onChangeKeyword(e) {
    const { name, value } = e.target;
    this.setState({ keyword: value });
  }

  getParentCategory() {
    this.setState({ loading: true });
    const route = `/category/all`;
    Api.get(route, this.token)
      .then((response) => {
        if (response.status && response.status === 'success') {
          this.setState({ categories: response.data, loading: false });
        } else {
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        CheckError(err);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.setLoadingContainer(true);
    this.props.setParameterErrors({});
    if (this.props.editCategoryStatus) {
      this.doUpdateCategory();
    } else {
      this.doCreateCategory();
    }
  }

  doCreateCategory() {
    const params = {
      name: this.props.categoryData.name,
      slug: this.props.categoryData.slug,
      order_id: this.props.categoryData.order_id,
      description: this.props.categoryData.description,
      is_focus: this.props.categoryData.is_focus,
      is_hide: this.props.categoryData.is_hide,
      parent_id: this.props.categoryData.parent_id,
      media_id: this.props.selectedImages.join(','),
      icon_id: this.props.selectedIcons.join(',')
    };
    const route = `/category/create`;
    Api.post(route, params, this.token)
      .then((response) => {
        this.props.setLoadingContainer(false);
        if (response.status && response.status === 'success') {
          this.props.refreshCategories();
          this.props.refreshEditData();
          Toast(response.message, 'success');
        } else {
          CheckErrorWithParam(response, this.props.setParameterErrors);
        }
      })
      .catch((err) => {
        this.props.setLoadingContainer(false);
        CheckErrorWithParam(err, this.props.setParameterErrors);
      });
  }

  doUpdateCategory() {
    const params = {
      name: this.props.categoryData.name,
      slug: this.props.categoryData.slug,
      order_id: this.props.categoryData.order_id,
      description: this.props.categoryData.description,
      parent_id: this.props.categoryData.parent_id,
      is_focus: this.props.categoryData.is_focus,
      is_hide: this.props.categoryData.is_hide,
      media_id: this.props.selectedImages.join(','),
      icon_id: this.props.selectedIcons.join(',')
    };
    const route = `/category/update/` + this.props.editCategoryId;
    Api.put(route, params, this.token)
      .then((response) => {
        this.props.setLoadingContainer(false);
        if (response.status && response.status === 'success') {
          this.props.refreshCategories();
          this.props.refreshEditData();
          Toast(response.message, 'success');
        } else {
          CheckErrorWithParam(response, this.props.setParameterErrors);
        }
      })
      .catch((err) => {
        this.props.setLoadingContainer(false);
        CheckErrorWithParam(err, this.props.setParameterErrors);
      });
  }

  cancel(e) {
    e.preventDefault();
    this.props.refreshEditData();
  }

  handleFileUpload(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', 'image');
      formData.append('caption', this.props.caption);
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            let images = [];
            images[0] = response.data.id;
            this.props.addImagePost(images, response.data.url);
          } else {
            CheckErrorWithParam(response, this.props.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.props.setParameterErrors);
        });
    }
  }

  toggleAddImagePostModal() {
    if (!this.state.addImagePostModal) {
      this.getImagePosts();
    }
    let selectedImages = this.props.selectedImages;
    this.setState({
      addImagePostModal: !this.state.addImagePostModal,
      selectedImagesModal: selectedImages
    });
  }

  setSelectedImagesModal(selectedImagesModal) {
    this.setState({ selectedImagesModal });
  }

  getImagePosts(page = 1, limit = 9) {
    this.setState({ loadingImage: true });
    const route =
      `/media/index?type=image&page=` +
      page +
      `&limit=` +
      limit +
      `&keyword=` +
      this.state.keyword;
    Api.get(route, this.token)
      .then((response) => {
        if (response.status && response.status === 'success') {
          this.setState({
            images: response.data,
            imagePagination: response.pagination,
            loadingImage: false
          });
        } else {
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loadingImage: false });
        CheckError(err);
      });
  }

  handleIconUpload(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', 'image');
      formData.append('caption', this.props.captionIcon);
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            let icons = [];
            icons[0] = response.data.id;
            this.props.addIconPost(icons, response.data.url);
          } else {
            CheckErrorWithParam(response, this.props.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.props.setParameterErrors);
        });
    }
  }

  toggleAddIconPostModal() {
    if (!this.state.addIconPostModal) {
      this.getIconPosts();
    }
    let selectedIcons = this.props.selectedIcons;
    this.setState({
      addIconPostModal: !this.state.addIconPostModal,
      selectedIconsModal: selectedIcons
    });
  }

  setSelectedIconsModal(selectedIconsModal) {
    this.setState({ selectedIconsModal });
  }

  getIconPosts(page = 1, limit = 9) {
    this.setState({ loadingImage: true });
    const route =
      `/media/index?type=image&page=` +
      page +
      `&limit=` +
      limit +
      `&keyword=` +
      this.state.keywordIcon;
    Api.get(route, this.token)
      .then((response) => {
        if (response.status && response.status === 'success') {
          this.setState({
            icons: response.data,
            iconPagination: response.pagination,
            loadingImage: false
          });
        } else {
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loadingImage: false });
        CheckError(err);
      });
  }

  onChangeKeywordIcon(e) {
    const { name, value } = e.target;
    this.setState({ keywordIcon: value });
  }

  onVerify = (recaptchaResponse) => {
    this.setState({
      verified: true
    });
  };

  render() {
    let parentCategories = this.state.categories.map((parent_category_name) => {
      return (
        <option key={parent_category_name.id} value={parent_category_name.id}>
          {parent_category_name.name}
        </option>
      );
    });
    parentCategories.push(<option key={null} value=""></option>);
    let headerContent;
    let buttonContent;
    if (this.props.editCategoryStatus) {
      headerContent = (
        <h4 className="heading-title">
          Edit Category Id {this.props.editCategoryId}
        </h4>
      );
      buttonContent = (
        <div className="form-group">
          <button
            key="category-btn-cancel"
            type="button"
            onClick={this.cancel}
            className="btn btn-md btn-button mr-3"
          >
            Cancel
          </button>
          <button
            disabled={!this.state.verified || this.state.loading}
            key="category-btn-edit"
            className="btn btn-md btn-color btn-button"
          >
            Save
          </button>
        </div>
      );
    } else {
      headerContent = <h4 className="heading-title">Add New Category</h4>;
      buttonContent = (
        <div className="form-group">
          <button
            disabled={!this.state.verified || this.state.loading}
            key="category-btn-add"
            className="btn btn-md btn-color btn-button"
          >
            Add Category
          </button>
        </div>
      );
    }
    let imageContainer;
    if (this.props.selectedImages.length > 1) {
      imageContainer = (
        <div style={{ paddingBottom: '10px' }}>
          {this.props.selectedImages.length} Images
        </div>
      );
    } else if (this.props.selectedImages.length === 1) {
      imageContainer = (
        <div style={{ paddingBottom: '10px' }}>
          <div
            style={{
              height: '150px',
              width: '100%',
              backgroundImage: `url(${this.props.imageText}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          ></div>
        </div>
      );
    }
    let iconContainer;
    if (this.props.selectedIcons.length > 1) {
      iconContainer = (
        <div style={{ paddingBottom: '10px' }}>
          {this.props.selectedIcons.length} Images
        </div>
      );
    } else if (this.props.selectedIcons.length === 1) {
      iconContainer = (
        <div style={{ paddingBottom: '10px' }}>
          <div
            style={{
              height: '150px',
              width: '100%',
              backgroundImage: `url(${this.props.iconText}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          ></div>
        </div>
      );
    }
    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <ModalMedia
          type={'post'}
          images={this.state.images}
          selectedImagesModal={this.state.selectedImagesModal}
          selectedImages={this.props.selectedImages}
          pagination={this.state.imagePagination}
          addImagePostModal={this.state.addImagePostModal}
          toggleAddImagePostModal={this.toggleAddImagePostModal}
          loadingImage={this.state.loadingImage}
          addImagePost={this.props.addImagePost}
          refreshData={this.getImagePosts}
          setSelectedImagesModal={this.setSelectedImagesModal}
          keyword={this.state.keyword}
          onChangeKeyword={this.onChangeKeyword}
        />
        <ModalMedia
          type={'post'}
          images={this.state.icons}
          selectedImagesModal={this.state.selectedIconsModal}
          selectedImages={this.props.selectedIcons}
          pagination={this.state.iconPagination}
          addImagePostModal={this.state.addIconPostModal}
          toggleAddImagePostModal={this.toggleAddIconPostModal}
          loadingImage={this.state.loadingImage}
          addImagePost={this.props.addIconPost}
          refreshData={this.getIconPosts}
          setSelectedImagesModal={this.setSelectedIconsModal}
          keyword={this.state.keywordIcon}
          onChangeKeyword={this.onChangeKeywordIcon}
        />
        <div className="row">
          <div className="col-12">
            {headerContent}
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">
                  Name <span className="text-red">*</span>
                </label>
                <input
                  name="name"
                  placeholder=""
                  id="name"
                  type="text"
                  onChange={this.props.onChangeCategory}
                  value={this.props.categoryData.name}
                />
                {this.props.errors.name && this.props.errors.name.message && (
                  <div
                    className="help-block"
                    style={{
                      backgroundColor: 'red',
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  >
                    <small style={{ color: 'white' }}>
                      {this.props.errors.name.message}
                    </small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="slug">Slug</label>
                <input
                  name="slug"
                  placeholder=""
                  id="slug"
                  type="text"
                  onChange={this.props.onChangeCategory}
                  value={this.props.categoryData.slug}
                />
                {this.props.errors.slug && this.props.errors.slug.message && (
                  <div
                    className="help-block"
                    style={{
                      backgroundColor: 'red',
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  >
                    <small style={{ color: 'white' }}>
                      {this.props.errors.slug.message}
                    </small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="order_id">Urutan ke</label>
                <input
                  name="order_id"
                  placeholder=""
                  id="order_id"
                  type="number"
                  onChange={this.props.onChangeCategory}
                  value={this.props.categoryData.order_id}
                />
                {this.props.errors.order_id &&
                  this.props.errors.order_id.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.order_id.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label htmlFor="name">Parent Category</label>
                <select
                  name="parent_id"
                  placeholder=""
                  id="parent_id"
                  onChange={this.props.onChangeCategory}
                  value={this.props.categoryData.parent_id || ''}
                >
                  {parentCategories}
                </select>
                {this.props.errors.parent_id &&
                  this.props.errors.parent_id.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.parent_id.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <div style={{ display: 'flex' }}>
                  <input
                    name="is_focus"
                    placeholder=""
                    id="is_focus"
                    type="checkbox"
                    // style={{ display: 'inline', width: '10px', height: '10px' }}
                    onChange={(e) => {
                      this.props.onChangeCategory({
                        target: {
                          value: e.target.checked ? 1 : 0,
                          name: e.target.name
                        }
                      });
                    }}
                    checked={this.props.categoryData.is_focus}
                  />
                  <label htmlFor="is_focus">Highlight</label>
                </div>
                {this.props.errors.is_focus &&
                  this.props.errors.is_focus.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.is_focus.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <div style={{ display: 'flex' }}>
                  <input
                    name="is_hide"
                    placeholder=""
                    id="is_hide"
                    type="checkbox"
                    // style={{ display: 'inline', width: '10px', height: '10px' }}
                    onChange={(e) => {
                      this.props.onChangeCategory({
                        target: {
                          value: e.target.checked ? 1 : 0,
                          name: e.target.name
                        }
                      });
                    }}
                    checked={this.props.categoryData.is_hide}
                  />
                  <label htmlFor="is_hide">Hide from nav bar</label>
                </div>
                {this.props.errors.is_hide &&
                  this.props.errors.is_hide.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.is_hide.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label htmlFor="media_id">Icon</label>
                {iconContainer}
                <div>
                  <div className="form-group">
                    <label htmlFor="captionIcon">
                      Caption Icon(Untuk Upload)
                    </label>
                    <input
                      type="text"
                      placeholder="Caption Icon"
                      name="captionIcon"
                      value={this.props.captionIcon}
                      onChange={this.props.onChangeCaptionIcon}
                      style={{ marginBottom: '0px' }}
                    />
                    {this.props.errors.caption &&
                      this.props.errors.caption.message && (
                        <div
                          className="help-block"
                          style={{
                            backgroundColor: 'red',
                            paddingLeft: 10,
                            paddingRight: 10
                          }}
                        >
                          <small style={{ color: 'white' }}>
                            {this.props.errors.caption.message}
                          </small>
                        </div>
                      )}
                  </div>

                  <label
                    className="btn btn btn-md btn-color btn-file"
                    onClick={this.toggleAddIconPostModal}
                    style={{ marginRight: '10px' }}
                  >
                    Browse
                  </label>
                  <label className="btn btn btn-md btn-color btn-file">
                    Upload
                    <input type="file" onChange={this.handleIconUpload} />
                  </label>
                </div>
                <p>The image must be a file of type: jpg, jpeg, png, ico</p>
                {this.props.errors.icon_id &&
                  this.props.errors.icon_id.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.icon_id.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label htmlFor="media_id">Image</label>
                {imageContainer}
                <div>
                  <div className="form-group">
                    <label htmlFor="captionIcon">
                      Caption Image Background(Untuk Upload)
                    </label>
                    <input
                      type="text"
                      placeholder="Caption Image Background"
                      name="caption"
                      value={this.props.caption}
                      onChange={this.props.onChangeCaption}
                      style={{ marginBottom: '0px' }}
                    />
                    {this.props.errors.caption &&
                      this.props.errors.caption.message && (
                        <div
                          className="help-block"
                          style={{
                            backgroundColor: 'red',
                            paddingLeft: 10,
                            paddingRight: 10
                          }}
                        >
                          <small style={{ color: 'white' }}>
                            {this.props.errors.caption.message}
                          </small>
                        </div>
                      )}
                  </div>
                  <label
                    className="btn btn btn-md btn-color btn-file"
                    onClick={this.toggleAddImagePostModal}
                    style={{ marginRight: '10px' }}
                  >
                    Browse
                  </label>
                  <label className="btn btn btn-md btn-color btn-file">
                    Upload
                    <input type="file" onChange={this.handleFileUpload} />
                  </label>
                </div>
                <p>The image must be a file of type: jpg, jpeg, png, ico</p>
                {this.props.errors.media_id &&
                  this.props.errors.media_id.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.media_id.message}
                      </small>
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label htmlFor="subtitle">Description</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  placeholder=""
                  name="description"
                  onChange={this.props.onChangeCategory}
                  value={this.props.categoryData.description || ''}
                ></textarea>
                {this.props.errors.description &&
                  this.props.errors.description.message && (
                    <div
                      className="help-block"
                      style={{
                        backgroundColor: 'red',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      <small style={{ color: 'white' }}>
                        {this.props.errors.description.message}
                      </small>
                    </div>
                  )}
              </div>
              <div style={{ marginBottom: 16 }}>
                {['admin', 'editor', 'author'].indexOf(this.props.isAs) <=
                  -1 && (
                  <Reaptcha
                    sitekey={sitekeyReaptcha}
                    onVerify={this.onVerify}
                  />
                )}
              </div>
              {buttonContent}
            </form>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default FormCategory;

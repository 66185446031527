import React, { Component } from 'react'
import { Button, Collapse } from 'reactstrap'

import Api from '../../services/api'

class CareerList extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.toggleAccordion = this.toggleAccordion.bind(this);
		this.toggleCustom = this.toggleCustom.bind(this);
		this.state = {
			collapse: false,
			accordion: [true, false, false],
			custom: [true, false],
			timeout: 300,
			loadingCareer: false,
			datasCareer: []
		}
	}

	componentDidMount() {
		this.fetchCareer()
	}

	fetchCareer() {
		this.setState({ loadingCareer: true }, () => {
			const params = `page=1&limit=10&keyword=`;
			const route = `/career_position/getCareer?${params}`;
			Api.get(route, null)
				.then(response => {
					this.setState({ datasCareer: response.data });

					if (response.data.length > 0) {
						var no = 0;
						var lists = [];
						response.data.map(data => {
							var val = false;
							if (no === 0) val = true;
							lists.push(val);
							no++
						});
						this.setState({ accordion: lists, custom: lists })
					}

					this.setState({ loadingCareer: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ loadingCareer: false })
				})
		})
	}

	toggle() {
		this.setState({ collapse: !this.state.collapse })
	}

	toggleAccordion(tab) {
		const prevState = this.state.accordion;
		const state = prevState.map((x, index) => (tab === index ? !x : false));

		this.setState({
			accordion: state
		})
	}

	toggleCustom(tab) {
		const prevState = this.state.custom;
		const state = prevState.map((x, index) => (tab === index ? !x : false));

		this.setState({
			custom: state
		})
	}

	render() {
		const { datasCareer, loadingCareer } = this.state;

		if (loadingCareer) return null;
		return (
			<div className="col-md-6">
				<div className="content-box">
					<div className="entry card card-career-list">
						<div id="exampleAccordion" data-children=".item">
							{datasCareer.map((dataCareer, dataCareerIndex) => (
								<div className="item" key={dataCareerIndex}>
									<Button
										className="career-collapse-toggle btn-career-position"
										color="link"
										onClick={() =>
											this.toggleCustom(dataCareerIndex)
										}
										aria-expanded={
											this.state.custom[dataCareerIndex]
										}
										aria-controls={`exampleAccordion${dataCareerIndex}`}
									>
										{dataCareer.name}
									</Button>
									<Collapse
										isOpen={
											this.state.custom[dataCareerIndex]
										}
										data-parent="#exampleAccordion"
										id={`exampleAccordion${dataCareerIndex}`}
									>
										<p>
											<strong>Job Description</strong>
										</p>
										<p
											dangerouslySetInnerHTML={{
												__html: dataCareer.details
											}}
										/>
									</Collapse>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default CareerList

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';

import { getStorageItem } from '../../../helpers/storage';
import { IsSSR } from '../../../helpers/isSSR';

import { apiUrl, keyToken, keyUser } from '../../../config';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import FormCategory from './form';
import Toast from '../../../components/Toast';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class ListCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingContainer: false,
      loading: false,
      editCategoryStatus: false,
      editCategoryId: 0,
      categoryData: {
        name: '',
        slug: '',
        description: '',
        media_id: '',
        parent_id: '',
        order_id: '',
        is_focus: 0,
        is_hide: 0
      },
      deleteCategoryModal: false,
      deleteCategoryData: {
        id: '',
        name: ''
      },
      errors: {},
      selectedImages: [],
      selectedIcons: [],
      imageText: '',
      iconText: '',
      isAdmin: false,
      isEditor: false,
      isAuthor: false,
      isContributor: false,
      isAs: '',
      loadingRole: true,
      caption: '',
      captionIcon: ''
    };

    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.setLoadingContainer = this.setLoadingContainer.bind(this);
    this.refreshCategories = this.refreshCategories.bind(this);
    this.showCategoryDetail = this.showCategoryDetail.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.refreshEditData = this.refreshEditData.bind(this);
    this.setParameterErrors = this.setParameterErrors.bind(this);
    this.addImagePost = this.addImagePost.bind(this);
    this.addIconPost = this.addIconPost.bind(this);
    this.onChangeCaption = this.onChangeCaption.bind(this);
    this.onChangeCaptionIcon = this.onChangeCaptionIcon.bind(this);
    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);
  }

  componentDidMount() {
    setTimeout(() => this.fetchData());
  }

  componentDidUpdate(prevProps, prevState) {
    window.onpopstate = (e) => {
      let params = new URLSearchParams(this.props.location.search);
      let paramsPrev = new URLSearchParams(prevProps.location.search);
      if (
        paramsPrev.get('page') !== params.get('page') ||
        paramsPrev.get('limit') !== params.get('limit') ||
        paramsPrev.get('keyword') !== params.get('keyword')
      ) {
        $('#datatable-category').off('click', '.detail-category-btn');
        $('#datatable-category').off('click', '.delete-category-btn');
        $('#datatable-category').DataTable().destroy();
        $('#datatable-category tbody').empty();
        setTimeout(() => this.fetchData());
      }
    };
  }

  onChangeCategory(e) {
    const { name, value } = e.target;
    let { categoryData } = this.state;
    categoryData[name] = value;
    this.setState({ categoryData });
  }

  onChangeCaption(e) {
    const { name, value } = e.target;
    this.setState({ caption: value });
  }

  onChangeCaptionIcon(e) {
    const { name, value } = e.target;
    this.setState({ captionIcon: value });
  }

  setParameterErrors(data) {
    this.setState({
      errors: data
    });
  }

  refreshEditData() {
    this.setState({
      editCategoryStatus: false,
      editCategoryId: 0,
      categoryData: {
        name: '',
        slug: '',
        order_id: '',
        description: '',
        parent: '',
        media_id: '',
        is_focus: 0,
        is_hide: 0
      },
      errors: {}
    });
    this.addImagePost([], '');
    this.addIconPost([], '');
  }

  refreshCategories() {
    $('#datatable-category').DataTable().ajax.reload();
  }

  fetchData() {
    const user = JSON.parse(this.props.localUser);
    const { isAs } = this.props;

    var _ = this;
    const route = `/category/index?sort_field=order_id&sort_by=ASC`;
    const url = `${apiUrl}${route}`;
    this.$el = $(this.el);
    var categoryDatatableDraw = 1;
    let searchParams = new URLSearchParams(_.props.location.search);
    var page =
      searchParams.get('page') && !isNaN(searchParams.get('page'))
        ? Number(searchParams.get('page'))
        : 1;
    var limit =
      searchParams.get('limit') && !isNaN(searchParams.get('limit'))
        ? Number(searchParams.get('limit'))
        : 5;
    var start = (page - 1) * limit ? (page - 1) * limit : 0;
    this.$el.DataTable({
      processing: true,
      scrollX: true,
      serverSide: true,
      order: [[0, 'desc']],
      pageLength: limit,
      displayStart: start,
      search: {
        search: searchParams.get('keyword') ? searchParams.get('keyword') : ''
      },
      lengthMenu: [
        [5, 10, 25, 50],
        [5, 10, 25, 50]
      ],
      searching: true,
      searchDelay: 1000,
      processing: true,
      ajax: {
        url: url,
        headers: {
          'x-access-token': _.token
        },
        data: function (data) {
          categoryDatatableDraw = data.draw;
          var requestData = {};
          requestData.page = data.start / data.length + 1;
          requestData.limit = data.length;
          requestData.keyword = data.search.value;
          if (['admin', 'editor'].indexOf(isAs) > -1) {
          } else {
            requestData.user_id = user.id;
          }
          if (data.draw !== 1) {
            _.props.history.push(
              `/admin/category?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`
            );
          }
          return requestData;
        },
        error: function (jqXHR, textStatus, errorThrown) {
          // Do something here
          if (jqXHR && jqXHR.responseJSON) {
            CheckError(jqXHR.responseJSON);
          } else {
            Toast('Error internet connection');
          }
        }
      },
      columns: [
        { data: 'id', name: 'id', orderable: false },
        {
          data: null,
          name: 'name',
          orderable: false,
          render: function (data) {
            return (
              '<a href="javascript:void(0);" data-id="' +
              data.id +
              '" class="detail-category-btn">' +
              data.name +
              '</a>'
            );
          }
        },
        { data: 'slug', name: 'slug', orderable: false },
        { data: 'order_id', name: 'order_id', orderable: false },
        { data: 'is_focus', name: 'is_focus', orderable: false },
        { data: 'is_hide', name: 'is_hide', orderable: false },
        { data: 'parent_id', name: 'parent_id', orderable: false },
        {
          data: 'createdAt',
          name: 'CreatedAt',
          orderable: false,
          render: function (data) {
            return moment(data).format('DD/MM/YYYY HH:mm');
          }
        },
        {
          data: null,
          className: 'center',
          searchable: false,
          orderable: false,
          render: function (data) {
            return (
              '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
              data.id +
              '" class="detail-category-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
              data.id +
              '" data-name="' +
              data.name +
              '" class="delete-category-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
            );
          }
        }
      ]
    });
    $('#datatable-category').on('click', '.detail-category-btn', function () {
      var id = $(this).data('id');
      _.showCategoryDetail(id);
    });
    $('#datatable-category').on('click', '.delete-category-btn', function () {
      var id = $(this).data('id');
      var name = $(this).data('name');
      _.deleteCategory(id, name);
    });
  }

  showCategoryDetail(id) {
    if (['admin', 'editor'].indexOf(this.props.isAs) > -1) {
      this.setLoadingContainer(true);
      this.setParameterErrors({});
      this.addImagePost([], '');
      this.addIconPost([], '');
      const route = `/category/` + id;
      Api.get(route, this.token)
        .then((response) => {
          if (response.status && response.status === 'success') {
            response.data.is_focus = Number(response.data.is_focus);
            response.data.is_hide = Number(response.data.is_hide);
            this.setState({
              editCategoryStatus: true,
              editCategoryId: response.data.id,
              categoryData: response.data
            });
            if (response.data.media) {
              let images = [];
              images[0] = response.data.media.id;
              this.addImagePost(images, response.data.media.url);
            }
            if (response.data.icon) {
              let icons = [];
              icons[0] = response.data.icon.id;
              this.addIconPost(icons, response.data.icon.url);
            }
            this.setLoadingContainer(false);
          } else {
            CheckError(response);
          }
        })
        .catch((err) => {
          this.setLoadingContainer(false);
          CheckError(err);
        });
    } else {
      Toast('You do not have access to this action');
    }
  }

  deleteRowTable(id) {
    if (['admin', 'editor'].indexOf(this.props.isAs) > -1) {
      this.setState({ loading: true });
      const route = `/category/delete/` + id;
      Api.delete(route, this.token)
        .then((response) => {
          if (response.status && response.status === 'success') {
            this.refreshCategories();
            Toast(response.message, 'success');
            this.setState({ deleteCategoryModal: false });
          } else {
            CheckError(response);
          }
        })
        .catch((err) => {
          this.refreshCategories();
          this.setState({ deleteCategoryModal: false });
          CheckError(err);
        });
    } else {
      Toast('You do not have access to this action');
    }
  }

  toggleDeleteCategoriesModal() {
    this.setState({
      deleteCategoryModal: !this.state.deleteCategoryModal
    });
  }

  deleteCategory(id, name) {
    this.setState({
      deleteCategoryData: {
        id: id,
        name: name
      },
      deleteCategoryModal: !this.state.deleteCategoryModal
    });
  }

  setLoadingContainer(status) {
    this.setState({ loadingContainer: status });
  }

  addImagePost(images, text, caption = '') {
    this.setState({ selectedImages: images });
    this.setState({ imageText: text });
    this.setState({ caption: caption });
  }

  addIconPost(icons, text, captionIcon = '') {
    this.setState({ selectedIcons: icons });
    this.setState({ iconText: text });
    this.setState({ captionIcon: captionIcon });
  }

  render() {
    const { loadingContainer } = this.state;
    const { isAs } = this.props;
    return (
      <div className="col-lg-9 col-12">
        <LoadingOverlay active={loadingContainer} spinner text="Please Wait...">
          <div className="entry card card-input">
            <Modal
              isOpen={this.state.deleteCategoryModal}
              toggle={this.toggleDeleteCategoriesModal.bind(this)}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleDeleteCategoriesModal.bind(this)}>
                Konfirmasi
              </ModalHeader>
              <ModalBody>
                Apakah ingin menghapus kategori{' '}
                {this.state.deleteCategoryData.name}?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={this.deleteRowTable.bind(
                    this,
                    this.state.deleteCategoryData.id
                  )}
                >
                  Hapus
                </Button>{' '}
                <Button
                  color="secondary"
                  onClick={this.toggleDeleteCategoriesModal.bind(this)}
                >
                  Batalkan
                </Button>
              </ModalFooter>
            </Modal>
            {['admin', 'editor'].indexOf(isAs) > -1 && (
              <FormCategory
                setLoadingContainer={this.setLoadingContainer}
                onChangeCategory={this.onChangeCategory}
                refreshCategories={this.refreshCategories}
                setParameterErrors={this.setParameterErrors}
                refreshEditData={this.refreshEditData}
                editCategoryStatus={this.state.editCategoryStatus}
                editCategoryId={this.state.editCategoryId}
                categoryData={this.state.categoryData}
                errors={this.state.errors}
                addImagePost={this.addImagePost}
                addIconPost={this.addIconPost}
                onChangeCaption={this.onChangeCaption}
                onChangeCaptionIcon={this.onChangeCaptionIcon}
                selectedImages={this.state.selectedImages}
                selectedIcons={this.state.selectedIcons}
                imageText={this.state.imageText}
                iconText={this.state.iconText}
                caption={this.state.caption}
                captionIcon={this.state.captionIcon}
                isAs={this.props.isAs}
              />
            )}

            <div className="divider" />
            <div className="row">
              <div className="col-12">
                <div className="mt-3">
                  <h4 className="heading-title">Category List</h4>
                  <table
                    id="datatable-category"
                    className="display table table-sm table-striped table-bordered"
                    width="100%"
                    ref={(el) => (this.el = el)}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Category Name</th>
                        <th>Slug</th>
                        <th>Order</th>
                        <th>Highlight</th>
                        <th>Hide Nav</th>
                        <th>Parent</th>
                        <th>Start date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default ListCategory;

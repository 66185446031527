import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

import Foto from '../../components/Foto'
import Video from '../../components/Video'
import EbookPost from '../../components/EbookPost'
import Polling from '../../components/Polling'

import { imagesUrl, baseUrlFront, assetsUrl } from '../../config'

import Api from '../../services/api'

import { IsSSR } from '../../helpers/isSSR'
import { scriptsSlide } from '../../utils/scripts'
import V2Foto from './../../components/V2Foto'
import Infografik from '../../components/Infografik'
import V2Vooxline from '../../components/V2Vooxline'

class Home extends Component {
  constructor(props) {
    super(props)
    console.log('Home props:', props)
    this.state = {
      datasTopTen: [],
      loadingTopTen: true,
      datasHeadline: [],
      datasHeadlineGroup: props.datasHeadlineGroup,
      loadingHeadline: props.loadingHeadline,
      datasBeritaUtama: [],
      datasEbook: null,
      loadingBeritaUtama: true,
      datasBeritaWawancara: [],
      loadingBeritaWawancara: true,
      datasBeritaLifestyle: [],
      loadingBeritaLifestyle: true,
      datasBeritaLiVoxpop: [],
      loadingBeritaVoxpop: true,
      datasByCategories: [],
      loadingByCategories: true,
      activeIndex: 0,
      datasIsFeature: [],
      loadingIsFeature: true,
      datasLastNewst: [],
      loadingLastNewst: true,
      loadingAdv: false,
      datasAdv: [],
      datasPolling: [],
      loadingPolling: true,
      datasBeritaFoto: [],
      datasBeritaVooxline: [],
      loadingBeritaFoto: true,
      datasInfografik: [],
      loadingInfografik: true,
      adModal: true
    }

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
    this.isSSR = IsSSR()
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0)
    this.fetchLastNewst()
    this.fetchHeadline()
    this.fetchIsFeature()
    this.fetchBeritaByCategory('berita-utama')
    this.loadByCategories()
    this.fetchAdv()
    this.fetchPolling()
    this.fetchEbook()
    this.fetchFoto()
    this.fetchVooxline()
    this.fetchInfografik()
  }

  bundleInternal() {
    scriptsSlide(() => {
      console.log('[scriptsSlide.js] in Home')
    })
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    const { datasBeritaUtama } = this.state
    if (this.animating) return
    const nextIndex = this.state.activeIndex === datasBeritaUtama.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    const { datasBeritaUtama } = this.state
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? datasBeritaUtama.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  loadByCategories() {
    console.log('loadByCategories loaded')
    const { datasByCategories } = this.state
    this.setState({ loadingByCategories: true }, () => {
      const params = `page=1&limit=1000&is_hide=0&keyword=`
      const route = `/category/getCategoriesByOrder?${params}`
      Api.get(route, null)
        .then(async (response) => {
          if (response.data.length > 0) {
            var index = 0
            const notAllowCategories = ['berita-utama', 'foto', 'video', 'vooxline']
            console.log('asd', response)
            await response.data.map((data, keyCategory) => {
              if (notAllowCategories.indexOf(data.slug) > -1) {
                console.log('Not Allow Categories for : ', data.slug)
              } else {
                const params_get_by_category = `page=1&limit=3&keyword=&type=article&state_id=3&schedule=0&category=${data.slug}`
                const route_get_by_category = `/post/get_by_category?${params_get_by_category}`
                Api.get(route_get_by_category, null)
                  .then(async (response_get_by_category) => {
                    if (response_get_by_category.data.length > 2) {
                      if (keyCategory % 2 == 0) {
                        var item = {}
                        item['datas'] = response_get_by_category.data
                        item['cloneType'] = 'event'
                        item['category'] = data

                        if (response_get_by_category.data.length > 0) {
                          const route = `/post/get_total_like_and_comment/${response_get_by_category.data[0].post.id}`
                          Api.get(route, null)
                            .then((responseLike) => {
                              item['datas'][0]['post']['manualCountLikeComment'] = responseLike.data
                            })
                            .catch((errorLike) => {
                              console.log('errorLike error:', errorLike)
                            })
                        }

                        datasByCategories[keyCategory] = item
                        this.setState({
                          datasByCategories: datasByCategories
                        })
                      } else {
                        var item = {}
                        item['datas'] = response_get_by_category.data
                        item['cloneType'] = 'odd'
                        item['category'] = data

                        if (response_get_by_category.data.length > 0) {
                          const route = `/post/get_total_like_and_comment/${response_get_by_category.data[0].post.id}`
                          Api.get(route, null)
                            .then((responseLike) => {
                              item['datas'][0]['post']['manualCountLikeComment'] = responseLike.data
                            })
                            .catch((errorLike) => {
                              console.log('errorLike error:', errorLike)
                            })
                        }

                        datasByCategories[keyCategory] = item
                        this.setState({
                          datasByCategories: datasByCategories
                        })
                      }
                      index += 1
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }
            })
          }

          this.setState({ loadingByCategories: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingByCategories: false })
        })
    })
  }

  fetchEbook() {
    this.fetchBeritaByCategory('ebook')
  }

  fetchFoto() {
    this.fetchBeritaByCategory('foto')
  }

  fetchVooxline() {
    this.fetchBeritaByCategory('vooxline')
  }

  fetchBeritaByCategory(cat) {
    var varLoadingName = null
    var varDataName = null
    var limit = 3
    var type = 'article'
    if (cat === 'berita-utama') {
      varLoadingName = 'loadingBeritaUtama'
      varDataName = 'datasBeritaUtama'
      limit = 10
    } else if (cat === 'wawancara') {
      varLoadingName = 'loadingBeritaWawancara'
      varDataName = 'datasBeritaWawancara'
    } else if (cat === 'lifestyle') {
      varLoadingName = 'loadingBeritaLifestyle'
      varDataName = 'datasBeritaLifestyle'
      limit = 10
    } else if (cat === 'voxpop') {
      varLoadingName = 'loadingBeritaVoxpop'
      varDataName = 'datasBeritaLiVoxpop'
    } else if (cat === 'video') {
      varLoadingName = 'loadingBeritaVideo'
      varDataName = 'datasBeritaVideo'
    } else if (cat === 'foto') {
      varLoadingName = 'loadingBeritaFoto'
      varDataName = 'datasBeritaFoto'
      limit = 9
    } else if (cat === 'vooxline') {
      varLoadingName = 'loadingBeritaVooxline'
      varDataName = 'datasBeritaVooxline'
      limit = 3
    } else if (cat === 'ebook') {
      varLoadingName = ''
      varDataName = 'datasEbook'
      type = 'ebook'
      limit = 3
    }

    this.setState({ [varLoadingName]: true }, () => {
      const params = `page=1&limit=${limit}&keyword=&${type}=article&state_id=3&schedule=0&category=${cat}`
      const route = `/post/get_by_category?${params}`
      Api.get(route, null)
        .then((response) => {
          if (cat === 'berita-utama') {
            if (response.data.length > 0) {
              var lists = []
              var no = 1
              response.data.map((dt) => {
                var item = {}
                item.src = dt.post.mediaable ? dt.post.mediaable.media.url : !this.isSSR && '/assets/'
                item.tag = dt.post.tagables ? (dt.post.tagables[0] ? dt.post.tagables[0].tag.slug : 'ivoox') : 'ivoox'
                item.url = dt.post.url
                item.altText = no
                item.caption = dt.post.description
                item.header = dt.post.title
                no += 1
                lists.push(item)
              })
              this.setState({ [varDataName]: lists })
              this.setState({ [varLoadingName]: false })
            }
          } else {
            this.setState({ [varDataName]: response.data })
            if (response.data.length > 0) this.renderCountLikeComment(response.data[0].post.id, varDataName)
            this.setState({ [varLoadingName]: false })
          }
        })
        .catch((error) => {
          console.error(error)
          this.setState({ [varLoadingName]: false })
        })
    })
  }

  fetchInfografik() {
    var limit = 9
    var type = 'article'
    const varLoadingName = 'loadingInfografik'
    const varDataName = 'datasInfografik'

    this.setState({ [varLoadingName]: true }, () => {
      const params = `page=1&limit=${limit}&keyword=&${type}=article&state_id=3&schedule=0&category=infografik`
      const route = `/post/get_by_category?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ [varDataName]: response.data })
          if (response.data.length > 0) this.renderCountLikeComment(response.data[0].post.id, varDataName)
          this.setState({ [varLoadingName]: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ [varLoadingName]: false })
        })
    })
  }

  fetchIsFeature() {
    console.log('fetchIsFeature loaded')
    this.setState({ loadingIsFeature: true }, () => {
      const params = `page=1&limit=2&keyword=&type=article&state_id=3&schedule=0&is_featured=1`
      const route = `/post/index?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasIsFeature: response.data })
          this.setState({ loadingIsFeature: false })
        })
        .catch((error) => {
          this.setState({ loadingIsFeature: false })
        })
    })
  }

  fetchHeadline() {
    console.log('fetchHeadline loaded')
    this.setState({ loadingHeadline: true }, () => {
      const params = `page=1&limit=30&keyword=&state_id=3&schedule=0`
      const route = `/post/is_headline?${params}`
      Api.get(route, null)
        .then((response) => {
          var datasGroup = this.groupArr(response.data, 10)
          this.setState({ datasHeadlineGroup: datasGroup })
          this.setState({ datasHeadline: response.data })
          this.setState({ loadingHeadline: false })

          setTimeout(() => {
            this.bundleInternal()
          }, 900)
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingHeadline: false })
        })
    })
  }

  fetchTopTen() {
    console.log('fetchTopTen loaded')
    this.setState({ loadingTopTen: true }, () => {
      const params = `page=1&limit=8&keyword=&state_id=3&schedule=0`
      const route = `/post/populer?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasTopTen: response.data })
          this.setState({ loadingTopTen: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingTopTen: false })
        })
    })
  }

  fetchLastNewst() {
    console.log('fetchLastNewst loaded')
    this.setState({ loadingLastNewst: true }, () => {
      // const params = `page=1&limit=10&keyword=&type=&state_id=3&schedule=0`;
      const params = `page=1&limit=10&keyword=&state_id=3&schedule=0`
      const route = `/post/index?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasLastNewst: response.data })
          this.setState({ loadingLastNewst: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingLastNewst: false })
        })
    })
  }

  groupArr(data, n) {
    var group = []
    for (var i = 0, j = 0; i < data.length; i++) {
      if (i >= n && i % n === 0) j++
      group[j] = group[j] || []
      group[j].push(data[i])
    }
    return group
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  renderCountLikeComment(ids, forObject) {
    var obj = this.state[forObject]
    const route = `/post/get_total_like_and_comment/${ids}`
    Api.get(route, null)
      .then((response) => {
        obj[0]['post']['manualCountLikeComment'] = response.data
        this.setState({ [forObject]: obj })
      })
      .catch((error) => {
        console.log('renderCountLikeComment error:', obj, error)
      })
  }

  fetchAdv() {
    this.setState({ loadingAdv: true }, () => {
      const params = `page=1&limit=10&keyword=`
      const route = `/ad/index?${params}`
      Api.get(route, null)
        .then((response) => {
          var lists = {}
          if (response.data.length > 0) {
            response.data.map((item) => {
              lists[item.position] = item
            })
          }
          this.setState({ datasAdv: lists })
          this.setState({ loadingAdv: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingAdv: false })
        })
    })
  }

  fetchPolling() {
    console.log('fetchPolling loaded')
    this.setState({ loadingPolling: true }, () => {
      const params = `page=1&limit=5&keyword=&schedule=0`
      const route = `/polling/index?${params}`
      Api.get(route, null)
        .then((response) => {
          let listsPolling = []
          if (response.data.length === 1) {
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[0])
          } else if (response.data.length === 2) {
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[1])
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[1])
          } else if (response.data.length === 3) {
            listsPolling.push(response.data[0])
            listsPolling.push(response.data[1])
            listsPolling.push(response.data[2])
            listsPolling.push(response.data[0])
          } else {
            listsPolling = response.data
          }
          this.setState({ datasPolling: listsPolling })
          this.setState({ loadingPolling: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingPolling: false })
        })
    })
  }

  render() {
    const {
      datasTopTen,
      loadingTopTen,
      datasHeadline,
      datasHeadlineGroup,
      loadingHeadline,
      datasBeritaUtama,
      loadingBeritaUtama,
      datasBeritaWawancara,
      loadingBeritaWawancara,
      datasBeritaLifestyle,
      loadingBeritaLifestyle,
      datasBeritaLiVoxpop,
      loadingBeritaVoxpop,
      datasBeritaVideo,
      loadingBeritaVideo,
      datasBeritaFoto,
      datasBeritaVooxline,
      loadingBeritaFoto,
      loadingBeritaVooxline,
      datasInfografik,
      loadingInfografik,
      datasByCategories,
      loadingByCategories,
      activeIndex,
      datasIsFeature,
      loadingIsFeature,
      datasLastNewst,
      loadingLastNewst,
      loadingAdv,
      datasAdv,
      datasPolling,
      loadingPolling
    } = this.state

    const slides = datasBeritaUtama.map((item) => {
      return (
        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.altText}>
          <div
            className="containerImg"
            style={{
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              marginBottom: 16
            }}
          >
            <div className="contentImg">
              <Link
                to={{
                  pathname: `/${item.url}`,
                  search: `?tag_from=${item.tag}`
                }}
              >
                <h2
                  style={{
                    color: 'white',
                    margin: 0,
                    padding: 0
                  }}
                >
                  {item.header}
                </h2>
              </Link>
              {/* <p style={{ color: 'whitesmoke' }}>
                {item.caption.length > 50
                  ? `${item.caption.substring(0, 50)}...`
                  : `${item.caption}`}
              </p> */}
            </div>
          </div>
        </CarouselItem>
      )
    })

    if (loadingHeadline) return null

    return (
      <div id="main-container" className={!this.isSSR && window.outerWidth < 480 ? 'main-container container' : ''}>
        <div className="row row-20">
          <div className="col-lg-6 order-lg-2">
            <LoadingOverlay active={loadingBeritaUtama} spinner text="Loading your content...">
              <section className="section mb-16">
                <div style={{ marginBottom: 16 }}>
                  <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
                    <CarouselIndicators items={datasBeritaUtama} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                  </Carousel>
                </div>

                <div className="row row-20 card-row ">
                  {datasIsFeature.map((dataBeritaUtama, dataBeritaUtamaIndex) => (
                    <div className="col-md-6 min-height-auto pinned-home" key={dataBeritaUtamaIndex}>
                      <article className="entry card">
                        <div className="entry__img-holder card__img-holder">
                          <Link
                            to={{
                              pathname: `/${dataBeritaUtama.url}`,
                              search: `?tag_from=${dataBeritaUtama.tagables[0] ? dataBeritaUtama.tagables[0].tag.slug : 'unknown'}`
                            }}
                          >
                            <div className="thumb-container thumb-70">
                              {/* <div className=""> */}
                              <img
                                className="logo__img entry__img lazyload"
                                // className=""
                                // src={`${dataBeritaUtama.mediaable && dataBeritaUtama.mediaable.media ? dataBeritaUtama.mediaable.media.url : '' }`}
                                alt=""
                                style={{
                                  backgroundImage:
                                    dataBeritaUtama.mediaable && dataBeritaUtama.mediaable.media
                                      ? `url(${dataBeritaUtama.mediaable.media.url})`
                                      : 'url(/static/media/logo-dark-beta.f32370e6.png)',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center'
                                }}
                              />
                            </div>
                          </Link>
                          <Link
                            to={{
                              pathname: `/category/${dataBeritaUtama.categoryables[0].category.slug}`
                            }}
                            className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                          >
                            {dataBeritaUtama.categoryables[0].category.name}
                          </Link>
                        </div>

                        <div className="entry__body card__body">
                          <div className="entry__header">
                            <Link
                              to={{
                                pathname: `/${dataBeritaUtama.url}`,
                                search: `?tag_from=${dataBeritaUtama.tagables[0] ? dataBeritaUtama.tagables[0].tag.slug : 'unknown'}`
                              }}
                            >
                              <h2
                                className="entry__title"
                                dangerouslySetInnerHTML={{
                                  __html: dataBeritaUtama.title
                                }}
                              />
                            </Link>
                            <ul className="entry__meta">
                              <li className="entry__meta-author">
                                <span>by</span>
                                {` `}
                                <Link
                                  to={{
                                    pathname: `/authorPost/${dataBeritaUtama.user.username}`
                                  }}
                                >
                                  {dataBeritaUtama.user.name}
                                </Link>
                              </li>
                              <li className="entry__meta-date">{moment(dataBeritaUtama.createdAt).format('LLLL')}</li>
                            </ul>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
                </div>
              </section>
            </LoadingOverlay>
          </div>

          {!this.isSSR && window.outerWidth < 480 ? null : (
            <aside className="col-lg-3 sidebar order-lg-1">
              <LoadingOverlay active={loadingHeadline} spinner text="Loading your content...">
                <aside className="widget widget-headlines">
                  <h4 className="widget-title">More Headlines</h4>
                  <div id="owl-headlines" className="owl-carousel owl-theme">
                    {datasHeadlineGroup.map((headline, headlineIndex) => (
                      <div className="owl-item" key={headlineIndex}>
                        <ul className="post-list-small post-list-small--1">
                          {headline.map((headlineItem, headlineItemIndex) => (
                            <li className="post-list-small__item" key={headlineItemIndex}>
                              <article className="post-list-small__entry clearfix">
                                <div className="post-list-small__body">
                                  <h3 className="post-list-small__entry-title">
                                    <Link
                                      to={{
                                        pathname: `/${headlineItem.url}`,
                                        search: `?tag_from=${headlineItem.tagables[0] ? headlineItem.tagables[0].tag.slug : 'unknown'}`
                                      }}
                                    >
                                      {headlineItem.title}
                                    </Link>
                                  </h3>
                                </div>
                              </article>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div className="owl-custom-nav text-center">
                    <button className="owl-custom-nav__btn owl-custom-nav__btn--prev" aria-label="previous slide">
                      <i className="ui-arrow-left" />
                      <span className="owl-custom-nav__label">Prev</span>
                    </button>
                    <button className="owl-custom-nav__btn owl-custom-nav__btn--next" aria-label="next slide">
                      <span className="owl-custom-nav__label">Next</span>
                      <i className="ui-arrow-right" />
                    </button>
                  </div>
                </aside>
              </LoadingOverlay>
            </aside>
          )}

          {!this.isSSR && window.outerWidth < 480 ? null : (
            <aside className="col-lg-3 sidebar order-lg-3">
              <LoadingOverlay active={loadingHeadline} spinner text="Loading your content...">
                <aside className="widget widget-popular-posts" style={{ padding: 0, border: 0 }}>
                  <div className="stockdio-home">
                    <iframe
                      id="IDRRates"
                      src="https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=FOREX&amp;symbols=USD/IDR;EUR/IDR;AUD/IDR;SGD/IDR;HKD/IDR;CNY/IDR;GBP/IDR&amp;includeLogo=false&amp;includeSymbol=false&amp;palette=Financial-Light&amp;title=IDR%20Exchange%20Rates&amp;onload=IDRRates&amp;displayTargetCurrency=false"
                      width="100%"
                      height="200"
                      frameBorder="0"
                      scrolling="no"
                    />
                  </div>

                  <div className="stockdio-home">
                    <iframe
                      id="st_0fe382e12add4358b22a5bf0eb4ba13c"
                      src="https://api.stockdio.com/visualization/financial/charts/v1/MarketOverview?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&includeEquities=false&includeIndices=false&includeCurrencies=false&palette=Financial-Light&onload=st_0fe382e12add4358b22a5bf0eb4ba13c"
                      width="100%"
                      height="200"
                      frameBorder="0"
                      scrolling="no"
                    />
                  </div>

                  {/* <div className="tabs">
                    <ul
                      className="tabs__list"
                      style={{ width: '100%' }}
                    >
                      <li
                        className="tabs__item tabs__item--active"
                        style={{ width: '50%' }}
                      >
                        <a href="#tab-1" className="tabs__url tabs__trigger">Top Gainer</a>
                      </li>
                      <li
                        className="tabs__item"
                        style={{ width: '50%' }}
                      >
                        <a href="#tab-2" className="tabs__url tabs__trigger">Top Looser</a>
                      </li>
                    </ul>
                  </div>

                  <div className="tabs__content tabs__content-trigger">
                    <div className="tabs__content-pane tabs__content-pane--active" id="tab-1">
                      <p>
                        <iframe
                          id='topGainers'
                          src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_gainers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Gainers&amp;intraday=false&amp;onload=topGainers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                          width='100%'
                          height='auto'
                          frameBorder='0'
                          scrolling='no'
                          style={{ height: 275, marginTop: 16, overflowY: 'scroll' }}
                        />
                      </p>
                    </div>
                    <div className="tabs__content-pane" id="tab-2">
                      <p>
                        <iframe
                          id='topLosers'
                          src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_losers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Losers&amp;intraday=false&amp;onload=topLosers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                          width='100%'
                          height='auto'
                          frameBorder='0'
                          scrolling='no'
                          style={{ height: 275, marginTop: 16, overflowY: 'scroll' }}
                        />
                      </p>
                    </div>
                  </div> */}

                  {/* <iframe
                    id='topGainers'
                    src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_gainers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Gainers&amp;intraday=false&amp;onload=topGainers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                    width='100%'
                    height='auto'
                    frameBorder='0'
                    scrolling='no'
                    style={{ height: 275, marginTop: 16, overflowY: 'scroll' }}
                  />
                  <iframe
                    id='topLosers'
                    src='https://api.stockdio.com/visualization/financial/charts/v1/QuoteBoard?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&amp;stockExchange=IDX&amp;presetList=top_losers&amp;limit=10&amp;palette=Financial-Light&amp;title=Top%20Losers&amp;intraday=false&amp;onload=topLosers&amp;includeLogo=false&amp;includeCompany=false&amp;allowSort=false&amp;includeChange=true&amp;includePercentChange=true&amp;fontSize=9'
                    width='100%'
                    height='auto'
                    frameBorder='0'
                    scrolling='no'
                    style={{ height: 275, marginTop: 16, overflowY: 'scroll' }}
                  /> */}
                  {datasAdv['RightKanal'] && (
                    <div style={{ marginTop: 16 }}>
                      {datasAdv['RightKanal'].type == 'media' && datasAdv['RightKanal'].media && datasAdv['RightKanal'].media.media_type === 'image' && (
                        <a target="_blank" href={datasAdv['RightKanal'].url !== '' ? datasAdv['RightKanal'].url : '#'}>
                          <img src={datasAdv['RightKanal'] ? datasAdv['RightKanal'].media.url : ''} style={{ width: '100%' }} />
                        </a>
                      )}
                    </div>
                  )}
                </aside>
              </LoadingOverlay>
            </aside>
          )}
        </div>
        {datasAdv['Popup'] && (
          <Modal
            size="xl"
            isOpen={this.state.adModal}
            toggle={() => {
              this.setState({ adModal: false })
            }}
            className="modal-image-custome"
            centered
          >
            <ModalHeader
              toggle={() => {
                this.setState({ adModal: false })
              }}
            ></ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <div style={{ marginTop: 16 }}>
                {datasAdv['Popup'].type == 'media' && datasAdv['Popup'].media && datasAdv['Popup'].media.media_type === 'image' && (
                  <a target="_blank" href={datasAdv['Popup'].url !== '' ? datasAdv['Popup'].url : '#'}>
                    <img src={datasAdv['Popup'] ? datasAdv['Popup'].media.url : ''} style={{ width: '100%' }} />
                  </a>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}

        {/*<hr />*/}
        <section className="section mb-24">
          <div className="title-wrap title-wrap--line title-wrap--pr d-none d-md-block">
            <span
              style={{
                display: 'inline-block',
                float: 'left',
                position: 'relative',
                top: '6px'
              }}
            >
              <Link to={{ pathname: `/latestNews` }}>
                <h3 className="section-title">latest news</h3>
              </Link>
            </span>
            <span
              style={{
                display: 'inline-block',
                float: 'right',
                right: '5px',
                top: '8px',
                position: 'relative'
              }}
            >
              <Link to={{ pathname: `/latestNews` }}>
                <h5>
                  <u>See All</u>
                </h5>
              </Link>
            </span>
          </div>
          <div className="title-wrap title-wrap--line d-block d-md-none" style={{ marginBottom: 16 }}>
            <div className="row">
              <div className="col-md-6" style={{ width: '50%' }}>
                <Link to={{ pathname: `/latestNews` }}>
                  <h3 className="section-title">latest news</h3>
                </Link>
              </div>
              <div
                className="col-md-6"
                style={{
                  textAlign: 'right',
                  width: '50%'
                }}
              >
                <Link to={{ pathname: `/latestNews` }}>
                  <h5>
                    <u>See All</u>
                  </h5>
                </Link>
              </div>
            </div>
          </div>
          <LoadingOverlay active={loadingLastNewst} spinner text="Loading your content...">
            <div className="d-block d-md-none">
              <div className="row row-20 card-row">
                <div className="col-md-6">
                  {datasLastNewst.map((dataLastNewst, dataLastNewstIndex) =>
                    dataLastNewstIndex === 0 ? (
                      <article className="entry thumb thumb--size-3-cus thumb--mb-20" key={dataLastNewstIndex} style={{ marginBottom: '0px' }}>
                        <div
                          className="entry__img-holder thumb__img-holder"
                          style={{
                            backgroundImage: dataLastNewst.mediaable !== null ? `url(${dataLastNewst.mediaable ? dataLastNewst.mediaable.media.url : ''})` : ''
                          }}
                        >
                          <div className="bottom-gradient" />
                          <div className="thumb-text-holder thumb-text-holder--2">
                            <ul className="entry__meta">
                              <li>
                                <Link
                                  to={{
                                    pathname: `/category/${
                                      dataLastNewst.categoryables
                                        ? dataLastNewst.categoryables[0]
                                          ? dataLastNewst.categoryables[0].category
                                            ? dataLastNewst.categoryables[0].category.slug
                                            : 'unknown'
                                          : 'unknown'
                                        : 'unknown'
                                    }`
                                  }}
                                  className="entry__meta-category--label"
                                >
                                  {dataLastNewst.categoryables
                                    ? dataLastNewst.categoryables[0]
                                      ? dataLastNewst.categoryables[0].category
                                        ? dataLastNewst.categoryables[0].category.name
                                        : 'unknown'
                                      : 'unknown'
                                    : 'unknown'}
                                </Link>
                              </li>
                            </ul>
                            <Link
                              to={{
                                pathname: `/${dataLastNewst.url}`,
                                search: `?tag_from=${
                                  dataLastNewst.tagables ? (dataLastNewst.tagables[0] ? dataLastNewst.tagables[0].tag : 'unknown'.slug) : 'unknown'
                                }`
                              }}
                            >
                              <h2
                                className="thumb-entry-title"
                                dangerouslySetInnerHTML={{
                                  __html: dataLastNewst.title
                                }}
                              />
                            </Link>
                          </div>
                          <Link
                            to={{
                              pathname: `/${dataLastNewst.url}`,
                              search: `?tag_from=${
                                dataLastNewst.tagables
                                  ? dataLastNewst.tagables[0]
                                    ? dataLastNewst.tagables[0].tag
                                      ? dataLastNewst.tagables[0].tag.slug
                                      : 'unknown'
                                    : 'unknown'
                                  : 'unknown'
                              }`
                            }}
                            className="thumb-url"
                          />
                        </div>
                      </article>
                    ) : null
                  )}
                </div>
                <div className="col-md-6">
                  <div className="row card-row">
                    {datasLastNewst.slice(0, 5).map((dataLastNewst, dataLastNewstIndex) =>
                      dataLastNewstIndex === 0 ? null : (
                        <div className="col-md-6" key={dataLastNewstIndex}>
                          <article
                            className="entry card thumb--size-3 card-hard-auto"
                            style={{
                              height: '100%'
                            }}
                          >
                            <div className="entry__img-holder card__img-holder">
                              <Link
                                to={{
                                  pathname: `/${dataLastNewst.url}`,
                                  search: `?tag_from=${
                                    dataLastNewst.tagables ? (dataLastNewst.tagables[0] ? dataLastNewst.tagables[0].tag : 'unknown'.slug) : 'unknown'
                                  }`
                                }}
                              >
                                <div className="thumb-container thumb-70">
                                  {/* <div className=""> */}
                                  <img
                                    className="logo__img entry__img lazyload"
                                    // className=""
                                    src={dataLastNewst.mediaable !== null ? `${dataLastNewst.mediaable.media.url}` : ''}
                                    alt=""
                                  />
                                </div>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/category/${
                                    dataLastNewst.categoryables
                                      ? dataLastNewst.categoryables[0]
                                        ? dataLastNewst.categoryables[0].category
                                          ? dataLastNewst.categoryables[0].category.slug
                                          : 'unknown'
                                        : 'unknown'
                                      : 'unknown'
                                  }`
                                }}
                                className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                              >
                                {dataLastNewst.categoryables
                                  ? dataLastNewst.categoryables[0]
                                    ? dataLastNewst.categoryables[0].category
                                      ? dataLastNewst.categoryables[0].category.name
                                      : 'unknown'
                                    : 'unknown'
                                  : 'unknown'}
                              </Link>
                            </div>

                            <div
                              className="entry__body card__body"
                              style={{
                                minHeight: 'auto'
                              }}
                            >
                              <div className="entry__header">
                                <Link
                                  to={{
                                    pathname: `/${dataLastNewst.url}`,
                                    search: `?tag_from=${
                                      dataLastNewst.tagables ? (dataLastNewst.tagables[0] ? dataLastNewst.tagables[0].tag : 'unknown'.slug) : 'unknown'
                                    }`
                                  }}
                                >
                                  <h2
                                    className="entry__title"
                                    dangerouslySetInnerHTML={{
                                      __html: dataLastNewst.title
                                    }}
                                  />
                                </Link>
                                <ul className="entry__meta">
                                  <li className="entry__meta-author">
                                    <span>by</span>
                                    <Link
                                      to={{
                                        pathname: `/authorPost/${dataLastNewst.user.username}`
                                      }}
                                    >
                                      {` `}
                                      {dataLastNewst.user.name}
                                    </Link>
                                  </li>
                                  <li className="entry__meta-date">{moment(dataLastNewst.createdAt).format('LLLL')}</li>
                                </ul>
                              </div>
                            </div>
                          </article>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div id="owl-posts" className="owl-carousel owl-theme owl-carousel--arrows-outside d-none d-md-block">
              {datasLastNewst.map((dataLastNewst, dataLastNewstIndex) => (
                <article className="entry card" key={dataLastNewstIndex}>
                  <div className="entry__img-holder card__img-holder">
                    <Link
                      to={{
                        pathname: `/${dataLastNewst.url}`,
                        search: `?tag_from=${dataLastNewst.tagables ? (dataLastNewst.tagables[0] ? dataLastNewst.tagables[0].tag : 'unknown'.slug) : 'unknown'}`
                      }}
                    >
                      <div className="thumb-container thumb-70">
                        {/* <div className=""> */}
                        <img
                          className="logo__img entry__img lazyload"
                          // className=""
                          src={`${dataLastNewst.mediaable ? dataLastNewst.mediaable.media.url : ''}`}
                          alt=""
                        />
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: `/category/${
                          dataLastNewst.categoryables
                            ? dataLastNewst.categoryables[0]
                              ? dataLastNewst.categoryables[0].category
                                ? dataLastNewst.categoryables[0].category.slug
                                : 'unknown'
                              : 'unknown'
                            : 'unknown'
                        }`
                      }}
                      className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
                    >
                      {dataLastNewst.categoryables
                        ? dataLastNewst.categoryables[0]
                          ? dataLastNewst.categoryables[0].category
                            ? dataLastNewst.categoryables[0].category.name
                            : 'unknown'
                          : 'unknown'
                        : 'unknown'}
                    </Link>
                  </div>
                  <div className="entry__body card__body" style={{ minHeight: '195px' }}>
                    <div className="entry__header">
                      <Link
                        to={{
                          pathname: `/${dataLastNewst.url}`,
                          search: `?tag_from=${
                            dataLastNewst.tagables
                              ? dataLastNewst.tagables[0]
                                ? dataLastNewst.tagables[0].tag
                                  ? dataLastNewst.tagables[0].tag.slug
                                  : 'unknown'
                                : 'unknown'
                              : 'unknown'
                          }`
                        }}
                      >
                        <h2
                          className="entry__title"
                          dangerouslySetInnerHTML={{
                            __html: dataLastNewst.title
                          }}
                        />
                      </Link>
                      <ul className="entry__meta">
                        <li className="entry__meta-author">
                          <span>by</span>
                          {` `}
                          <Link
                            to={{
                              pathname: `/authorPost/${dataLastNewst.user.username}`
                            }}
                          >
                            {dataLastNewst.user.name}
                          </Link>
                        </li>
                        <li className="entry__meta-date">{moment(dataLastNewst.createdAt).format('LLLL')}</li>
                      </ul>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </LoadingOverlay>
        </section>

        {window.outerWidth < 480 && datasAdv['InNewsRight'] && (
          <div>
            {datasAdv['InNewsRight'].type == 'media' && datasAdv['InNewsRight'].media && datasAdv['InNewsRight'].media.media_type === 'image' && (
              <a target="_blank" href={datasAdv['InNewsRight'].url !== '' ? datasAdv['InNewsRight'].url : '#'}>
                <img src={datasAdv['InNewsRight'] ? datasAdv['InNewsRight'].media.url : ''} style={{ width: '100%' }} />
              </a>
            )}
            {datasAdv['InNewsRight'].type == 'media' && datasAdv['InNewsRight'].media && datasAdv['InNewsRight'].media.media_type === 'video' && (
              <video width="100%" style={{ height: 105 }} controls={false} autoPlay loop>
                <source src={datasAdv['InNewsRight'].media.url} type={datasAdv['InNewsRight'].media.file_type} />
                Your browser does not support the video tag.
              </video>
            )}
            {datasAdv['InNewsRight'].type == 'adsense' && datasAdv['InNewsRight'].adsense && (
              <p
                dangerouslySetInnerHTML={{
                  __html: datasAdv['InNewsRight'].adsense
                }}
              ></p>
            )}
          </div>
        )}
        <hr />
        {/* <div className="col-md-10 offset-md-1 text-center" style={{ marginBottom: 16 }}>
					<a target="_blank" href="">
						<img
							src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
							style={{ width: '100%' }}
						/>
					</a>
				</div> */}
        {datasAdv['Holistick1'] && (
          <React.Fragment>
            <div className="col-md-10 offset-md-1 text-center" style={{ marginBottom: 16 }}>
              {datasAdv['Holistick1'].type == 'media' && datasAdv['Holistick1'].media && datasAdv['Holistick1'].media.media_type === 'image' && (
                <a target="_blank" href={datasAdv['Holistick1'].url}>
                  <img src={datasAdv['Holistick1'] ? datasAdv['Holistick1'].media.url : ''} style={{ width: '100%' }} />
                </a>
              )}
              {datasAdv['Holistick1'].type == 'media' && datasAdv['Holistick1'].media && datasAdv['Holistick1'].media.media_type === 'video' && (
                <video width="100%" style={{ height: 105 }} controls={false} autoPlay loop>
                  <source src={datasAdv['Holistick1'].media.url} type={datasAdv['Holistick1'].media.file_type} />
                  Your browser does not support the video tag.
                </video>
              )}
              {datasAdv['Holistick1'].type == 'adsense' && datasAdv['Holistick1'].adsense && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: datasAdv['Holistick1'].adsense
                  }}
                />
              )}
            </div>
            <hr />
          </React.Fragment>
        )}
        {datasBeritaVooxline.length > 0 && <V2Vooxline loading={loadingBeritaVooxline} datas={datasBeritaVooxline} />}
        {datasBeritaFoto.length > 0 && <V2Foto loading={loadingBeritaFoto} datas={datasBeritaFoto} />}
        <hr />

        {datasByCategories.length > 0
          ? datasByCategories.map((dataByCategories, dataByCategoriesIndex) =>
              dataByCategories.cloneType === 'odd' ? (
                <div key={dataByCategoriesIndex}>
                  <section className="section mb-24 category-section-post">
                    <div className="title-wrap title-wrap--line" style={{ marginBottom: 16 }}>
                      <div className="row">
                        <div className="col-md-6" style={{ width: '50%' }}>
                          <Link
                            to={{
                              pathname: `/category/${dataByCategories.category.slug}`
                            }}
                          >
                            <h3 className="section-title">{dataByCategories.category.name}</h3>
                          </Link>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            textAlign: 'right',
                            width: '50%'
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/category/${dataByCategories.category.slug}`
                            }}
                          >
                            <h5>
                              <u>See All</u>
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row row-20 card-row">
                      <div className="col-md-6">
                        {dataByCategories.datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                          dataByCategoriesParentIndex === 0 ? (
                            <article className="entry thumb thumb--size-3-cus thumb--mb-20" key={dataByCategoriesParentIndex} style={{ marginBottom: '0px' }}>
                              <div
                                className="entry__img-holder thumb__img-holder"
                                style={{
                                  backgroundImage:
                                    dataByCategoriesParent.post.mediaable !== null ? `url(${dataByCategoriesParent.post.mediaable.media.url})` : ''
                                }}
                              >
                                <div className="bottom-gradient" />
                                <div className="thumb-text-holder thumb-text-holder--2">
                                  <ul className="entry__meta">
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/category/${dataByCategories.category.slug}`
                                        }}
                                        className="entry__meta-category--label"
                                      >
                                        {dataByCategories.category.name}
                                      </Link>
                                    </li>
                                  </ul>
                                  <Link
                                    to={{
                                      pathname: `/${dataByCategoriesParent.post.url}`,
                                      search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                                    }}
                                  >
                                    <h2
                                      className="thumb-entry-title"
                                      dangerouslySetInnerHTML={{
                                        __html: dataByCategoriesParent.post.title
                                      }}
                                    />
                                  </Link>
                                </div>
                                <Link
                                  to={{
                                    pathname: `/${dataByCategoriesParent.post.url}`,
                                    search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                                  }}
                                  className="thumb-url"
                                />
                              </div>
                            </article>
                          ) : null
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="row card-row">
                          {dataByCategories.datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                            dataByCategoriesParentIndex === 0 ? null : (
                              <div className="col-md-6" key={dataByCategoriesParentIndex}>
                                <article
                                  className="entry card thumb--size-3 card-hard-auto"
                                  style={{
                                    height: '100%'
                                  }}
                                >
                                  <div className="entry__img-holder card__img-holder">
                                    <Link
                                      to={{
                                        pathname: `/${dataByCategoriesParent.post.url}`,
                                        search: `?tag_from=${
                                          dataByCategoriesParent.post.tagables
                                            ? dataByCategoriesParent.post.tagables[0]
                                              ? dataByCategoriesParent.post.tagables[0].tag
                                                ? dataByCategoriesParent.post.tagables[0].tag.slug
                                                : 'unknown'
                                              : 'unknown'
                                            : 'unknown'
                                        }`
                                      }}
                                    >
                                      {/* <div className="thumb-container thumb-70"> */}
                                      <div className="">
                                        <img
                                          // className="logo__img entry__img lazyload"
                                          className=""
                                          src={dataByCategoriesParent.post.mediaable !== null ? `${dataByCategoriesParent.post.mediaable.media.url}` : ''}
                                          alt=""
                                        />
                                      </div>
                                    </Link>
                                    <Link
                                      to={{
                                        pathname: `/category/${dataByCategories.category.slug}`
                                      }}
                                      className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                                    >
                                      {dataByCategories.category.name}
                                    </Link>
                                  </div>

                                  <div
                                    className="entry__body card__body"
                                    style={{
                                      minHeight: 'auto'
                                    }}
                                  >
                                    <div className="entry__header">
                                      <Link
                                        to={{
                                          pathname: `/${dataByCategoriesParent.post.url}`,
                                          search: `?tag_from=${
                                            dataByCategoriesParent.post.tagables
                                              ? dataByCategoriesParent.post.tagables[0]
                                                ? dataByCategoriesParent.post.tagables[0].tag
                                                  ? dataByCategoriesParent.post.tagables[0].tag.slug
                                                  : 'unknown'
                                                : 'unknown'
                                              : 'unknown'
                                          }`
                                        }}
                                      >
                                        <h2
                                          className="entry__title"
                                          dangerouslySetInnerHTML={{
                                            __html: dataByCategoriesParent.post.title
                                          }}
                                        />
                                      </Link>
                                      <ul className="entry__meta">
                                        <li className="entry__meta-author">
                                          <span>by</span>
                                          <Link
                                            to={{
                                              pathname: `/authorPost/${dataByCategoriesParent.post.user.username}`
                                            }}
                                          >
                                            {` `}
                                            {dataByCategoriesParent.post.user.name}
                                          </Link>
                                        </li>
                                        <li className="entry__meta-date">{moment(dataByCategoriesParent.post.createdAt).format('LLLL')}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <hr />
                  {dataByCategoriesIndex === 3 && (
                    <div style={{ marginBottom: 16 }}>
                      {/* <div style={{ marginTop: 16 }} className="col-md-10 offset-md-1">
												<a target="_blank" href="">
													<img
														src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
														style={{ width: '100%' }}
													/>
												</a>
											</div> */}
                      {datasAdv['MiddleBanner'] && (
                        <>
                          <div style={{ marginTop: 16 }} className="col-md-10 offset-md-1">
                            {datasAdv['MiddleBanner'].type == 'media' &&
                              datasAdv['MiddleBanner'].media &&
                              datasAdv['MiddleBanner'].media.media_type === 'image' && (
                                <a target="_blank" href={datasAdv['MiddleBanner'].url !== '' ? datasAdv['MiddleBanner'].url : '#'}>
                                  <img src={datasAdv['MiddleBanner'] ? datasAdv['MiddleBanner'].media.url : ''} style={{ width: '100%' }} />
                                </a>
                              )}
                            {datasAdv['MiddleBanner'].type == 'media' &&
                              datasAdv['MiddleBanner'].media &&
                              datasAdv['MiddleBanner'].media.media_type === 'video' && (
                                <video width="100%" style={{ height: 105 }} controls={false} autoPlay loop>
                                  <source src={datasAdv['MiddleBanner'].media.url} type={datasAdv['MiddleBanner'].media.file_type} />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            {datasAdv['MiddleBanner'].type == 'adsense' && datasAdv['MiddleBanner'].adsense && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: datasAdv['MiddleBanner'].adsense
                                }}
                              ></p>
                            )}
                          </div>
                          <hr />
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div key={dataByCategoriesIndex}>
                  <section className="section mb-24 category-section-post">
                    <div className="title-wrap title-wrap--line" style={{ marginBottom: 16 }}>
                      <div className="row">
                        <div className="col-md-6" style={{ width: '50%' }}>
                          <Link
                            to={{
                              pathname: `/category/${dataByCategories.category.slug}`
                            }}
                          >
                            <h3 className="section-title">{dataByCategories.category.name}</h3>
                          </Link>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            textAlign: 'right',
                            width: '50%'
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/category/${dataByCategories.category.slug}`
                            }}
                          >
                            <h5>
                              <u>See All</u>
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row row-20 card-row">
                      {!this.isSSR &&
                        window.outerWidth < 480 &&
                        dataByCategories.datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                          dataByCategoriesParentIndex === 0 ? (
                            <div className="col-md-6" key={dataByCategoriesParentIndex}>
                              <article className="entry thumb thumb--size-3 thumb--mb-20" key={dataByCategoriesParentIndex} style={{ marginBottom: '0px' }}>
                                <div
                                  className="entry__img-holder thumb__img-holder"
                                  style={{
                                    backgroundImage:
                                      dataByCategoriesParent.post.mediaable !== null ? `url(${dataByCategoriesParent.post.mediaable.media.url})` : ''
                                  }}
                                >
                                  <div className="bottom-gradient" />
                                  <div className="thumb-text-holder thumb-text-holder--2">
                                    <ul className="entry__meta">
                                      <li>
                                        <Link
                                          to={{
                                            pathname: `/category/${dataByCategories.category.slug}`
                                          }}
                                          className="entry__meta-category--label"
                                        >
                                          {dataByCategories.category.name}
                                        </Link>
                                      </li>
                                    </ul>
                                    <Link
                                      to={{
                                        pathname: `/${dataByCategoriesParent.post.url}`,
                                        search: `?tag_from=${
                                          dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : 'unknown'
                                        }`
                                      }}
                                    >
                                      <h2
                                        className="thumb-entry-title"
                                        dangerouslySetInnerHTML={{
                                          __html: dataByCategoriesParent.post.title
                                        }}
                                      />
                                    </Link>
                                  </div>
                                  <Link
                                    to={{
                                      pathname: `/${dataByCategoriesParent.post.url}`,
                                      search: `?tag_from=${
                                        dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : 'unknown'
                                      }`
                                    }}
                                    className="thumb-url"
                                  />
                                </div>
                              </article>
                            </div>
                          ) : null
                        )}

                      <div className="col-md-6">
                        <div className="row card-row">
                          {dataByCategories.datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                            dataByCategoriesParentIndex === 0 ? null : (
                              <div className="col-md-6" key={dataByCategoriesParentIndex}>
                                <article
                                  className="entry card thumb--size-3 card-hard-auto"
                                  style={{
                                    height: '100%'
                                  }}
                                >
                                  <div className="entry__img-holder card__img-holder">
                                    <Link
                                      to={{
                                        pathname: `/${dataByCategoriesParent.post.url}`,
                                        search: `?tag_from=${
                                          dataByCategoriesParent.post.tagables
                                            ? dataByCategoriesParent.post.tagables[0]
                                              ? dataByCategoriesParent.post.tagables[0].tag
                                                ? dataByCategoriesParent.post.tagables[0].tag.slug
                                                : 'unknown'
                                              : 'unknown'
                                            : 'unknown'
                                        }`
                                      }}
                                    >
                                      {/* <div className="thumb-container thumb-70"> */}
                                      <div className="">
                                        <img
                                          // className="logo__img entry__img lazyload"
                                          className=""
                                          src={dataByCategoriesParent.post.mediaable !== null ? `${dataByCategoriesParent.post.mediaable.media.url}` : ''}
                                          alt=""
                                        />
                                      </div>
                                    </Link>
                                    <Link
                                      to={{
                                        pathname: `/category/${dataByCategories.category.slug}`
                                      }}
                                      className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                                    >
                                      {dataByCategories.category.name}
                                    </Link>
                                  </div>

                                  <div
                                    className="entry__body card__body"
                                    style={{
                                      minHeight: 'auto'
                                    }}
                                  >
                                    <div className="entry__header">
                                      <Link
                                        to={{
                                          pathname: `/${dataByCategoriesParent.post.url}`,
                                          search: `?tag_from=${
                                            dataByCategoriesParent.post.tagables
                                              ? dataByCategoriesParent.post.tagables[0]
                                                ? dataByCategoriesParent.post.tagables[0].tag
                                                  ? dataByCategoriesParent.post.tagables[0].tag.slug
                                                  : 'unknown'
                                                : 'unknown'
                                              : 'unknown'
                                          }`
                                        }}
                                      >
                                        <h2
                                          className="entry__title"
                                          dangerouslySetInnerHTML={{
                                            __html: dataByCategoriesParent.post.title
                                          }}
                                        />
                                      </Link>
                                      <ul className="entry__meta">
                                        <li className="entry__meta-author">
                                          <span>by</span>
                                          <Link
                                            to={{
                                              pathname: `/authorPost/${dataByCategoriesParent.post.user.username}`
                                            }}
                                          >
                                            {` `}
                                            {dataByCategoriesParent.post.user.name}
                                          </Link>
                                        </li>
                                        <li className="entry__meta-date">{moment(dataByCategoriesParent.post.createdAt).format('LLLL')}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      {!this.isSSR &&
                        window.outerWidth > 480 &&
                        dataByCategories.datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                          dataByCategoriesParentIndex === 0 ? (
                            <div className="col-md-6" key={dataByCategoriesParentIndex}>
                              <article className="entry thumb thumb--size-3-cus thumb--mb-20" key={dataByCategoriesParentIndex} style={{ marginBottom: '0px' }}>
                                <div
                                  className="entry__img-holder thumb__img-holder"
                                  style={{
                                    backgroundImage:
                                      dataByCategoriesParent.post.mediaable !== null ? `url(${dataByCategoriesParent.post.mediaable.media.url})` : ''
                                  }}
                                >
                                  <div className="bottom-gradient" />
                                  <div className="thumb-text-holder thumb-text-holder--2">
                                    <ul className="entry__meta">
                                      <li>
                                        <Link
                                          to={{
                                            pathname: `/category/${dataByCategories.category.slug}`
                                          }}
                                          className="entry__meta-category--label"
                                        >
                                          {dataByCategories.category.name}
                                        </Link>
                                      </li>
                                    </ul>
                                    <h2 className="thumb-entry-title">
                                      <Link
                                        to={{
                                          pathname: `/${dataByCategoriesParent.post.url}`,
                                          search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                                        }}
                                      >
                                        {dataByCategoriesParent.post.title}
                                      </Link>
                                    </h2>
                                  </div>
                                  <Link
                                    to={{
                                      pathname: `/${dataByCategoriesParent.post.url}`,
                                      search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                                    }}
                                    className="thumb-url"
                                  />
                                </div>
                              </article>
                            </div>
                          ) : null
                        )}
                    </div>
                  </section>
                  <hr />
                </div>
              )
            )
          : null}
        {datasInfografik.length > 0 && <Infografik loading={loadingInfografik} datas={datasInfografik} />}
        <hr />
        {datasPolling.length > 0 && (
          <>
            <section className="section mb-24">
              <div className="title-wrap title-wrap--line">
                <div className="row">
                  <div className="col-md-6" style={{ width: '50%' }}>
                    <Link
                      to={{
                        pathname: `/polling/all`
                      }}
                    >
                      <h3 className="section-title">Polling</h3>
                    </Link>
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      textAlign: 'right',
                      width: '50%'
                    }}
                  >
                    <Link
                      to={{
                        pathname: `/polling/all`
                      }}
                    >
                      <h5>
                        <u>See All</u>
                      </h5>
                    </Link>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Polling loading={loadingPolling} datas={datasPolling} />
              </div>
            </section>
            <hr />
          </>
        )}

        <section className="section mb-24 category-section-post">
          <div className="title-wrap title-wrap--line">
            <div className="row">
              <div className="col-md-6" style={{ width: '50%' }}>
                <Link
                  to={{
                    pathname: `/category/ebook`
                  }}
                >
                  <h3 className="section-title">EBOOK</h3>
                </Link>
              </div>
              <div
                className="col-md-6"
                style={{
                  textAlign: 'right',
                  width: '50%'
                }}
              >
                <Link
                  to={{
                    pathname: `/category/ebook`
                  }}
                >
                  <h5>
                    <u>See All</u>
                  </h5>
                </Link>
              </div>
            </div>
          </div>
          {this.state.datasEbook && <EbookPost dataEbook={this.state.datasEbook} />}
        </section>
        {/* <div className="col-md-10 offset-md-1 text-center" style={{ marginBottom: 16 }}>
					<a target="_blank" href="">
						<img
							src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
							style={{ width: '100%' }}
						/>
					</a>
				</div> */}
        {datasAdv['Holistick2'] && (
          <div className="col-md-10 offset-md-1 text-center" style={{ marginBottom: 16 }}>
            {datasAdv['Holistick2'].type == 'media' && datasAdv['Holistick2'].media && datasAdv['Holistick2'].media.media_type === 'image' && (
              <a target="_blank" href={datasAdv['Holistick2'].url}>
                <img src={datasAdv['Holistick2'] ? datasAdv['Holistick2'].media.url : ''} style={{ width: '100%' }} />
              </a>
            )}
            {datasAdv['Holistick2'].type == 'media' && datasAdv['Holistick2'].media && datasAdv['Holistick2'].media.media_type === 'video' && (
              <video width="100%" style={{ height: 105 }} controls={false} autoPlay loop>
                <source src={datasAdv['Holistick2'].media.url} type={datasAdv['Holistick2'].media.file_type} />
                Your browser does not support the video tag.
              </video>
            )}
            {datasAdv['Holistick2'].type == 'adsense' && datasAdv['Holistick2'].adsense && (
              <p
                dangerouslySetInnerHTML={{
                  __html: datasAdv['Holistick2'].adsense
                }}
              />
            )}
          </div>
        )}

        {/* {<Foto loading={loadingBeritaFoto} datas={datasBeritaFoto} />} */}
        <hr />
        {<Video loading={loadingBeritaVideo} datas={datasBeritaVideo} />}
      </div>
    )
  }
}

export default withRouter(Home)

import { NotificationManager } from 'react-light-notifications';

function Toast(message = 'This is message', type = 'error') {
	if (type === 'error') {
		NotificationManager.error({
			message,
			timeOut: 2500,
			onClick: () => {}
		});
	}
	else if(type === 'success') {
		NotificationManager.success({
			message,
			timeOut: 2500,
			onClick: () => {}
		});
	}
}

export default Toast;

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import moment from 'moment';

import { getStorageItem } from '../../../helpers/storage';
import { IsSSR } from '../../../helpers/isSSR';

import { keyToken } from '../../../config';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam';
import Toast from '../../Toast';

class EditModalMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteMediaModal: false,
      errors: {}
    };
    this.setParameterErrors = this.setParameterErrors.bind(this);
    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);
  }

  setParameterErrors(data) {
    this.setState({
      errors: data
    });
  }

  toggleDeleteMediasModal() {
    this.setState({ deleteMediaModal: !this.state.deleteMediaModal });
  }

  deleteRowTable() {
    if (this.props.selectedMedia.id) {
      this.props.setLoading(true);
      const route = `/media/delete/` + this.props.selectedMedia.id;
      Api.delete(route, this.token)
        .then((response) => {
          if (response.status && response.status === 'success') {
            this.props.setLoading(false);
            this.props.setSelectedImage(null);
            this.props.refreshMedias();
            Toast(response.message, 'success');
          } else {
            CheckError(response);
          }
        })
        .catch((err) => {
          this.props.setLoading(false);
          this.props.setSelectedImage(null);
          this.refreshMedias();
          CheckError(err);
        });
    } else {
      Toast('Media tidak terbaca atau sudah terhapus');
    }
  }

  doUpdatemedia() {
    this.props.setLoading(true);
    this.setState({ loading: true });
    const params = {
      meta_tag: this.props.selectedMedia.meta_tag,
      caption: this.props.selectedMedia.caption
    };
    const route = `/media/update/` + this.props.selectedMedia.id;
    Api.put(route, params, this.token)
      .then((response) => {
        this.props.setLoading(false);
        if (response.status && response.status === 'success') {
          this.props.refreshMedias();
          Toast(response.message, 'success');
        } else {
          CheckErrorWithParam(response, this.setParameterErrors);
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.setLoading(false);
        CheckErrorWithParam(err, this.setParameterErrors);
      });
  }

  render() {
    let mediaContainer = null;
    if (this.props.selectedMedia.media_type === 'image') {
      mediaContainer = <img src={this.props.selectedMedia.url} />;
    } else {
      mediaContainer = (
        <div style={{ height: '100%', width: '100%', textAlign: 'center' }}>
          <i
            className="fa fa-file"
            aria-hidden="true"
            style={{ fontSize: '50px', marginTop: '15px', display: 'block' }}
          ></i>
          <span
            style={{
              display: 'inline-block',
              width: '100%',
              height: '68px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {this.props.selectedMedia.file_type}
          </span>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.deleteMediaModal}
          toggle={this.toggleDeleteMediasModal.bind(this)}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeleteMediasModal.bind(this)}>
            Konfirmasi
          </ModalHeader>
          <ModalBody>
            Apakah ingin menghapus media "{this.props.selectedMedia.meta_tag}"?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteRowTable.bind(this)}>
              Hapus
            </Button>{' '}
            <Button
              color="secondary"
              onClick={this.toggleDeleteMediasModal.bind(this)}
            >
              Batalkan
            </Button>
          </ModalFooter>
        </Modal>
        <div className="col-md-4 form-edit-media">
          <div className="row" style={{ wordBreak: 'break-all' }}>
            <div className="col-md-6">{mediaContainer}</div>
            <div className="col-md-6">
              <div>
                <b>
                  {this.props.selectedMedia.new_path_name
                    ? this.props.selectedMedia.new_path_name.replace(
                        'media_public/',
                        ''
                      )
                    : ''}
                </b>{' '}
              </div>
              <div>
                {moment(this.props.selectedMedia.createdAt).format(
                  'DD/MM/YYYY'
                )}
              </div>
              <div className="form-group" style={{ margin: '10px 0px 0px' }}>
                <span>
                  <a
                    href="javascript:void(0);"
                    onClick={() => this.doUpdatemedia()}
                    style={{ color: '#007bff', paddingRight: '5px' }}
                  >
                    Update
                  </a>
                  <span>|</span>
                  <a
                    href="javascript:void(0);"
                    onClick={() => this.toggleDeleteMediasModal()}
                    style={{ paddingLeft: '5px' }}
                  >
                    Delete
                  </a>
                </span>
              </div>
            </div>
          </div>
          <hr style={{ margin: '10px 0px' }} />
          <div>
            <div className="form-group">
              <label htmlFor="title">URL</label>
              <input
                type="text"
                disable="true"
                readOnly={true}
                name="url"
                placeholder=""
                style={{ backgroundColor: '#eeeeee' }}
                value={this.props.selectedMedia.url}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                required
                name="title"
                placeholder=""
                onChange={this.props.onChangeMetaTag}
                value={this.props.selectedMedia.meta_tag}
              />
              {this.state.errors.meta_tag &&
                this.state.errors.meta_tag.message && (
                  <div
                    className="help-block"
                    style={{
                      backgroundColor: 'red',
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  >
                    <small style={{ color: 'white' }}>
                      {this.state.errors.meta_tag.message.replace(
                        'meta tag',
                        'title'
                      )}
                    </small>
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="caption">Caption</label>
              {/* <input
                                type="text"
                                name="caption" placeholder=""
                                onChange={this.props.onChangeCaptionUpdate}
                                value={this.props.selectedMedia.caption ? this.props.selectedMedia.caption : ""}
                            /> */}
              <textarea
                id="caption"
                name="caption"
                rows="4"
                cols="50"
                onChange={this.props.onChangeCaptionUpdate}
              >
                {this.props.selectedMedia.caption
                  ? this.props.selectedMedia.caption
                  : ''}
              </textarea>
              <input
                type="text"
                name="caption"
                placeholder=""
                onChange={this.props.onChangeCaptionUpdate}
                value={
                  this.props.selectedMedia.caption
                    ? this.props.selectedMedia.caption
                    : ''
                }
              />
              {this.state.errors.caption &&
                this.state.errors.caption.message && (
                  <div
                    className="help-block"
                    style={{
                      backgroundColor: 'red',
                      paddingLeft: 10,
                      paddingRight: 10
                    }}
                  >
                    <small style={{ color: 'white' }}>
                      {this.state.errors.caption.message}
                    </small>
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="title">Uploaded By</label>
              <input
                type="text"
                disable="true"
                readOnly={true}
                name="url"
                placeholder=""
                style={{ backgroundColor: '#eeeeee' }}
                value={
                  this.props.selectedMedia.user
                    ? this.props.selectedMedia.user.name
                    : ''
                }
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditModalMedia;

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { numberFormat } from './NumberFormat'
import Loading from './Loading'
import moment from 'moment'

class EbookPost extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    console.log(this.props.dataEbook)
  }

  render() {
    const { dataEbook, loading } = this.props

    return (
      <div className="row row-20 card-row">
        {dataEbook.map((data, index) => (
          <div
            className="col-md-4"
            style={{
              margin: '0px 0px 10px 0px'
            }}
          >
            <article
              className="row"
              style={{
                height: '100%'
              }}
            >
              <div className="col-12">
                <div className="row-ebook">
                  <div class="entry__img-holder card__img-holder">
                    <Link
                      to={{
                        pathname: `/${data.post.url}`,
                        search: `?tag_from=${
                          data.post.tagables
                            ? data.post.tagables[0]
                              ? data.post.tagables[0].tag
                                ? data.post.tagables[0].tag.slug
                                : 'unknown'
                              : 'unknown'
                            : 'unknown'
                        }`
                      }}
                    >
                      <img
                        className="logo__img"
                        src={data.post.mediaable !== null ? `${data.post.mediaable.media.url}` : ''}
                        class="entry__img lazyload"
                        alt=""
                      />
                    </Link>
                    <Link
                      to={{
                        pathname: `/category/ebook`
                      }}
                      className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                    >
                      ebook
                    </Link>
                  </div>

                  <div class="ebook-footer">
                    <div class="entry__header">
                      <Link
                        to={{
                          pathname: `/${data.post.url}`,
                          search: `?tag_from=${
                            data.post.tagables
                              ? data.post.tagables[0]
                                ? data.post.tagables[0].tag
                                  ? data.post.tagables[0].tag.slug
                                  : 'unknown'
                                : 'unknown'
                              : 'unknown'
                          }`
                        }}
                      >
                        <h2 class="entry__title ebook_post_title_2">{data.post.title}</h2>
                        <p
                          className="ebook_desc"
                          dangerouslySetInnerHTML={{
                            __html: data.post.content.substring(0, 150) + '...'
                          }}
                        ></p>
                      </Link>
                      <ul class="entry__meta">
                        <li class="entry__meta-author">
                          <span>by</span>
                          <Link
                            to={{
                              pathname: `/authorPost/${data.post.user.username}`
                            }}
                          >
                            {` `}
                            {data.post.user.name}
                          </Link>
                        </li>
                        <li class="entry__meta-date">{moment(data.post.createdAt).format('LLLL')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>
    )
  }
}

export default EbookPost

import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'

import Api from '../../services/api'

import { IsSSR } from '../../helpers/isSSR'

class Polling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasSearch: [],
            loadingPostCategory: true,
            datasTopTen: [],
            loadingTopTen: true,
            subscribeEmail: '',
            submittedSubscribe: false,
            entities: {
                data: [],
                pagination: {
                    total_found: 1,
                    limit: 5,
                    current_page: 1,
                    total_page: 1
                }
            },
            first_page: 1,
            current_page: 1,
            offset: 4
        };
        this.isSSR = IsSSR();
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0);
        this.fetchTopTen();
		let searchParams = new URLSearchParams(this.props.location.search);
        let current_page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? searchParams.get('page') : 1; 
        this.setState(
            // { current_page: this.state.entities.pagination.current_page },
            { current_page: current_page },
            () => {
                this.fetchEntities()
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
		let params = new URLSearchParams(this.props.location.search);
		let paramsPrev = new URLSearchParams(prevProps.location.search);
        if (paramsPrev.get('page') !== params.get('page')) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: params.get('page') },
                () => {
                    window.scrollTo(0, 0);
                    this.fetchEntities()
                }
            )
        }
    }

    fetchEntities() {
        let searchParams = new URLSearchParams(this.props.location.search);
        this.setState({ loadingPostCategory: true }, () => {
            const params = `page=${this.state.current_page}&limit=${
                this.state.entities.pagination.limit
                }&keyword=&schedule=0`;
            const route = `/polling/index?${params}`;
            Api.get(route, null)
                .then(response => {
                    this.setState({ entities: response });
                    this.setState({ datasSearch: response.data });
                    this.setState({ loadingPostCategory: false })
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ loadingPostCategory: false })
                })
        })
    }

    changePage(pageNumber) {
        this.setState({ current_page: pageNumber }, () => {
            this.props.history.push(`/polling/all?page=${pageNumber}`);
            // window.scrollTo(0, 0);
            // this.fetchEntities()
        })
    }

    pagesNumbers() {
        let from =
            this.state.entities.pagination.current_page - this.state.offset;
        if (from < 1) {
            from = 1
        }
        let to = from + this.state.offset * 2;
        if (to >= this.state.entities.pagination.total_page) {
            to = this.state.entities.pagination.total_page
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
            pagesArray.push(page)
        }
        return pagesArray
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return (
                <span
                    key={page}
                    className={`pagination__page ${
                        page === this.state.entities.pagination.current_page
                            ? 'pagination__page--current'
                            : null
                        }`}
                    onClick={() => this.changePage(page)}
                >
                    {page}
                </span>
            )
        })
    }

    fetchTopTen() {
        this.setState({ loadingTopTen: true }, () => {
            const params = `page=1&limit=4&keyword=&schedule=0`;
            const route = `/post/populer?${params}`;
            Api.get(route, null)
                .then(response => {
                    this.setState({ datasTopTen: response.data });
                    this.setState({ loadingTopTen: false })
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ loadingTopTen: false })
                })
        })
    }

    render() {
        const {
            datasSearch,
            loadingPostCategory,
            datasTopTen,
            loadingTopTen
        } = this.state;

        return (
            <div className="main-container container pt-40" id="main-container">
                <div className="row">
                    <div className="col-lg-8 blog__content mb-72">
                        <h1 className="page-title tt-capitalize">
                            Polling Archive
						</h1>
                        {loadingPostCategory ? (
                            <div style={{ marginTop: 20 }}>Loading...</div>
                        ) : (
                                <div style={{ marginTop: 20 }}>
                                    {datasSearch.length > 0 ? (
                                        <div>
                                            {datasSearch.map(
                                                (dataSearch, indexDataSearch) => (
                                                    <article
                                                        className="entry card post-list"
                                                        key={indexDataSearch}
                                                    >
                                                        <div
                                                            className="entry__img-holder post-list__img-holder card__img-holder"
                                                            style={{
                                                                backgroundImage: dataSearch.mediaables
                                                                    ? `url(${`${
                                                                    dataSearch
                                                                        .mediaables[0]
                                                                        ? dataSearch
                                                                            .mediaables[0]
                                                                            .media
                                                                            .url
                                                                        : ''
                                                                    }`})`
                                                                    : ''
                                                            }}
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname: `/polling/${
                                                                        dataSearch.id
                                                                        }/${
                                                                        dataSearch.url
                                                                        }`
                                                                }}
                                                                className="thumb-url"
                                                            />
                                                            <img
                                                                style={{
                                                                    backgroundImage: dataSearch.mediaables
                                                                        ? `url(${`${
                                                                        dataSearch
                                                                            .mediaables[0]
                                                                            ? dataSearch
                                                                                .mediaables[0]
                                                                                .media
                                                                                .url
                                                                            : '/assets/img/favicon-ivoox.png'
                                                                        }`})`
                                                                        : '/assets/img/favicon-ivoox.png'
                                                                }}
                                                                alt=""
                                                                className="entry__img d-none"
                                                            />
                                                            <a
                                                                href="#"
                                                                className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
                                                            >
                                                                VOTER{' '}
                                                                {
                                                                    dataSearch
                                                                        .polling_choices
                                                                        .length
                                                                }
                                                            </a>
                                                        </div>
                                                        <div className="entry__body post-list__body card__body">
                                                            <div className="entry__header">
                                                                <h2 className="entry__title">
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/polling/${
                                                                                dataSearch.id
                                                                                }/${
                                                                                dataSearch.url
                                                                                }`
                                                                        }}
                                                                    >
                                                                        {
                                                                            dataSearch.title
                                                                        }
                                                                    </Link>
                                                                </h2>
                                                                <ul className="entry__meta">
                                                                    <li className="entry__meta-author">
                                                                        <span>
                                                                            by
                                                                    </span>
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/authorPost/${
                                                                                    dataSearch.user
                                                                                        ? dataSearch
                                                                                            .user
                                                                                            .username
                                                                                        : 'Unknown'
                                                                                    }`
                                                                            }}
                                                                        >
                                                                            {` `}{' '}
                                                                            {dataSearch.user
                                                                                ? dataSearch
                                                                                    .user
                                                                                    .name
                                                                                : 'Unknown'}
                                                                        </Link>
                                                                    </li>
                                                                    <li className="entry__meta-date">
                                                                        {moment(
                                                                            dataSearch.createdAt
                                                                        ).format(
                                                                            'MM/YY/DD'
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="entry__excerpt">
                                                                <p>
                                                                    {
                                                                        dataSearch.description
                                                                    }
                                                                </p>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/polling/${
                                                                            dataSearch.id
                                                                            }/${
                                                                            dataSearch.url
                                                                            }`
                                                                    }}
                                                                    className="btn btn-color"
                                                                >
                                                                    Vote Now
															</Link>
                                                            </div>
                                                        </div>
                                                    </article>
                                                )
                                            )}
                                            {this.state.entities.data &&
                                                this.state.entities.data.length >
                                                0 && (
                                                    <nav className="pagination">
                                                        <a
                                                            href="javascript:void(0);"
                                                            onClick={() =>
                                                                1 ===
                                                                    this.state.entities
                                                                        .pagination
                                                                        .current_page
                                                                    ? null
                                                                    : this.changePage(
                                                                        this.state
                                                                            .entities
                                                                            .pagination
                                                                            .current_page -
                                                                        1
                                                                    )
                                                            }
                                                            className="pagination__page pagination__icon pagination__page--next"
                                                        >
                                                            <i className="ui-arrow-left" />
                                                        </a>
                                                        {this.pageList()}
                                                        <a
                                                            href="javascript:void(0);"
                                                            onClick={() =>
                                                                this.state.entities
                                                                    .pagination
                                                                    .total_page ===
                                                                    this.state.entities
                                                                        .pagination
                                                                        .current_page
                                                                    ? null
                                                                    : this.changePage(
                                                                        this.state
                                                                            .entities
                                                                            .pagination
                                                                            .current_page +
                                                                        1
                                                                    )
                                                            }
                                                            className="pagination__page pagination__icon pagination__page--next"
                                                        >
                                                            <i className="ui-arrow-right" />
                                                        </a>
                                                    </nav>
                                                )}
                                        </div>
                                    ) : (
                                            <div style={{ marginTop: 10 }}>
                                                No results for your search
									</div>
                                        )}
                                </div>
                            )}
                    </div>
                    <aside className="col-lg-4 sidebar sidebar--right">
                        <LoadingOverlay
                            active={loadingTopTen}
                            spinner
                            text="Loading your content..."
                        >
                            <aside className="widget widget-popular-posts">
                                <h4 className="widget-title">Popular Posts</h4>
                                <ul className="post-list-small">
                                    {datasTopTen.length > 0 ? (
                                        datasTopTen.map(
                                            (topTen, topTenIndex) => (
                                                <li
                                                    className="post-list-small__item"
                                                    key={topTenIndex}
                                                >
                                                    <article className="post-list-small__entry clearfix">
                                                        <div className="post-list-small__img-holder">
                                                            <div className="thumb-container thumb-100">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${
                                                                            topTen.url
                                                                            }`,
                                                                        search:
                                                                            '?tag_from=ivoox-react'
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={`${
                                                                            topTen.mediaable !==
                                                                                null
                                                                                ? `${
                                                                                topTen
                                                                                    .mediaable
                                                                                    .media
                                                                                    .url
                                                                                }`
                                                                                : ''
                                                                            }`}
                                                                        alt=""
                                                                        className="post-list-small__img--rounded lazyload"
                                                                    />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="post-list-small__body">
                                                            <h3 className="post-list-small__entry-title">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${
                                                                            topTen.url
                                                                            }`,
                                                                        search:
                                                                            '?tag_from=ivoox-react'
                                                                    }}
                                                                >
                                                                    {
                                                                        topTen.title
                                                                    }
                                                                </Link>
                                                            </h3>
                                                            <ul className="entry__meta">
                                                                <li className="entry__meta-author">
                                                                    <span>
                                                                        by
                                                                    </span>
                                                                    <a href="#">
                                                                        {` `}{' '}
                                                                        {
                                                                            topTen
                                                                                .user
                                                                                .name
                                                                        }
                                                                    </a>
                                                                </li>
                                                                <li className="entry__meta-date">
                                                                    {moment(topTen.createdAt).format('MM/YY/DD')}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </article>
                                                </li>
                                            )
                                        )
                                    ) : (
                                            <div>Empty</div>
                                        )}
                                </ul>
                            </aside>
                        </LoadingOverlay>

                        <aside className="widget widget-socials">
                            <h4 className="widget-title">
                                Let's hang out on social
							</h4>
                            <div className="socials socials--wide socials--large">
                                <div className="row row-16">
                                    <div className="col">
                                        <a
                                            className="social social-facebook"
                                            href="https://www.facebook.com/ivooxidnews"
                                            title="facebook"
                                            target="_blank"
                                            aria-label="facebook"
                                        >
                                            <i className="ui-facebook" />
                                            <span className="social__text">
                                                Facebook
                                            </span>
                                        </a>
                                        <a
                                            className="social social-twitter"
                                            href="https://twitter.com/ivooxid"
                                            title="twitter"
                                            target="_blank"
                                            aria-label="twitter"
                                        >
                                            <i className="ui-twitter" />
                                            <span className="social__text">
                                                Twitter
                                            </span>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a
                                            className="social social-google-plus"
                                            href="https://plus.google.com/u/0/104998791569411648008"
                                            title="google"
                                            target="_blank"
                                            aria-label="google"
                                        >
                                            <i className="ui-google" />
                                            <span className="social__text">
                                                Google+
                                            </span>
                                        </a>
                                        <a
                                            className="social social-instagram"
                                            href="https://www.instagram.com/ivooxid/"
                                            title="instagram"
                                            target="_blank"
                                            aria-label="instagram"
                                            style={{padding : '0px 5px'}}
                                        >
                                            <i className="ui-instagram" />
                                            <span className="social__text">
                                                Instagram
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </aside>
                </div>
            </div>
        )
    }
}

export default Polling

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from "moment";

class PopularPosts extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { data, loading } = this.props;

		if (loading) return null;
		return (
			<aside className="widget widget-popular-posts">
				<h4 className="widget-title">Popular Posts</h4>
				<ul className="post-list-small">
					{data.map((topTen, topTenIndex) => (
						<li className="post-list-small__item" key={topTenIndex}>
							<article className="post-list-small__entry clearfix">
								<div className="post-list-small__img-holder">
									<div className="thumb-container thumb-100">
										<Link to={{ pathname: `/${topTen.url}`, search: `?tag_from=${topTen.tagables[0] ? topTen.tagables[0].tag.slug:'unknown'}` }}>
											<img
												src={topTen.mediaable !== null ? `${topTen.mediaable.media.url}` : ''}
												alt=""
												className="post-list-small__img--rounded lazyload" />
										</Link>
									</div>
								</div>
								<div className="post-list-small__body">
									<h3 className="post-list-small__entry-title">
										<Link to={{ pathname: `/${topTen.url}`, search: `?tag_from=${topTen.tagables[0] ? topTen.tagables[0].tag.slug:'unknown'}` }}>{topTen.title}</Link>
									</h3>
									<ul className="entry__meta">
										<li className="entry__meta-author">
											<span>by</span>
											<Link to={{ pathname: `/authorPost/${topTen.user.username}` }}>{` `} {topTen.user.name}</Link>
										</li>
										<li className="entry__meta-date">
											{moment(topTen.createdAt).format('LLLL')}
										</li>
									</ul>
								</div>
							</article>
						</li>
					))}
				</ul>
			</aside>
		)
	}
}

export default PopularPosts

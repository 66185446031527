import React, { Component } from 'react'
import CareerInput from './careerInput'
import CareerList from './careerList'

import { IsSSR } from '../../helpers/isSSR'

class CareerForm extends Component {
	constructor(props) {
		super(props);
		this.isSSR = IsSSR();
	}

	componentDidMount() {
		if (!this.isSSR) window.scrollTo(0, 0)
	}

	render() {
		return (
			<div className="main-container container" id="main-container">
				<h1 className="page-title">Career</h1>
				<div className="row">
					<CareerList />
					<CareerInput />
				</div>
			</div>
		)
	}
}

export default CareerForm
import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import {
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
} from 'reactstrap'

import Api from '../../services/api'

import Toast from '../../components/Toast'
import PopularPosts from '../../components/PopularPosts'
import SocialMedia from '../../components/SocialMedia'

import { IsSSR } from '../../helpers/isSSR'

class Test extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			datasIsFeatured: [],
			loadingIsFeatured: true,
			datasPostCategory: [],
			loadingPostCategory: true,
			datasTopTen: [],
			loadingTopTen: true,
			subscribeEmail: '',
			submittedSubscribe: false,
			entities: {
				data: [],
				pagination: {
					total_found: 1,
					limit: 6,
					current_page: 1,
					total_page: 1
				}
			},
			first_page: 1,
			current_page: 1,
			offset: 4,
			limit: 6
		};
		this.isSSR = IsSSR();

		this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
		this.onChange = this.onChange.bind(this);

		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this)
	}

	componentDidMount() {
		if (!this.isSSR) window.scrollTo(0, 0);
		this.fetchTopTen();
		this.fetchIsFeatured();

		this.setState(
			{ current_page: this.state.entities.pagination.current_page },
			() => {
				this.fetchEntities()
			}
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			window.scrollTo(0, 0);
			this.fetchTopTen();
			this.fetchIsFeatured();

			this.setState(
				{ current_page: this.state.entities.pagination.current_page },
				() => {
					this.fetchEntities()
				}
			)
		}
	}

	onExiting() {
		this.animating = true
	}

	onExited() {
		this.animating = false
	}

	next() {
		const { datasIsFeatured } = this.state;
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === datasIsFeatured.length - 1
				? 0
				: this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex })
	}

	previous() {
		const { datasIsFeatured } = this.state;
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0
				? datasIsFeatured.length - 1
				: this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex })
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex })
	}

	fetchIsFeatured() {
		const { match } = this.props;
		var slug = match.params.slug;

		this.setState({ datasIsFeatured: [] });
		this.setState({ loadingIsFeatured: true }, () => {
			var params = `page=${this.state.current_page}&limit=${
				this.state.entities.pagination.limit
				}&keyword=&type=&state_id=3&schedule=0&category=${slug}&is_featured=1`;
			if (['foto', 'video'].indexOf(slug) > -1) params = `page=${this.state.current_page}&limit=${this.state.entities.pagination.limit}&keyword=&type=${slug === 'foto' ? 'image' : 'video'}&schedule=0&category=${slug}&is_featured=1`;
			const route = `/post/get_by_category?${params}`;
			Api.get(route, null)
				.then(response => {
					if (response.data.length > 0) {
						var lists = [];
						var no = 1;
						response.data.map(dt => {
							var item = {};
							item.src = dt.post.mediaable.media.url;
							item.tag = dt.post.tagables && dt.post.tagables[0]
								? dt.post.tagables[0].tag.slug
								: 'ivoox';
							item.url = dt.post.url;
							item.altText = no;
							item.caption = dt.post.description;
							item.header = dt.post.title;
							no += 1;
							lists.push(item)
						});
						this.setState({ datasIsFeatured: lists })
					}
					this.setState({ loadingIsFeatured: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ loadingIsFeatured: false })
				})
		})
	}

	fetchEntities() {
		const { match } = this.props;
		var slug = match.params.slug;

		this.setState({ datasPostCategory: [] });
		this.setState({ loadingPostCategory: true }, () => {
			var params = `page=${this.state.current_page}&limit=${
				this.state.entities.pagination.limit
				}&keyword=&type=&state_id=3&schedule=0&category=${slug}&is_featured=0`;
			if (['foto', 'video'].indexOf(slug) > -1) params = `page=${this.state.current_page}&limit=${this.state.entities.pagination.limit}&keyword=&type=${slug === 'foto' ? 'image' : 'video'}&schedule=0&category=${slug}&is_featured=0`;
			const route = `/post/get_by_category?${params}`;
			Api.get(route, null)
				.then(response => {
					this.setState({ entities: response });
					this.setState({ datasPostCategory: response.data });
					if (response.data.length > 0) this.renderCountLikeComment(response.data[0].post.id);
					this.setState({ loadingPostCategory: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ loadingPostCategory: false })
				})
		})
	}

	renderCountLikeComment(ids) {
		var obj = this.state.datasPostCategory;
		const route = `/post/get_total_like_and_comment/${ids}`;
		Api.get(route, null)
			.then(response => {
				if (obj && obj[0] && obj[0]['post']) obj[0]['post']['manualCountLikeComment'] = response.data;
				this.setState({ 'datasPostCategory': obj })
			})
			.catch(error => {
				console.log('renderCountLikeComment error:', obj, error)
			})
	}

	changePage(pageNumber) {
		this.setState({ current_page: pageNumber }, () => {
			window.scrollTo(0, 0);
			this.fetchEntities()
		})
	}

	pagesNumbers() {
		let from =
			this.state.entities.pagination.current_page - this.state.offset;
		if (from < 1) {
			from = 1
		}
		let to = from + this.state.offset * 2;
		if (to >= this.state.entities.pagination.total_page) {
			to = this.state.entities.pagination.total_page
		}
		let pagesArray = [];
		for (let page = from; page <= to; page++) {
			pagesArray.push(page)
		}
		return pagesArray
	}

	pageList() {
		return this.pagesNumbers().map(page => {
			return (
				<span
					key={page}
					className={`pagination__page ${
						page === this.state.entities.pagination.current_page
							? 'pagination__page--current'
							: null
						}`}
					onClick={() => this.changePage(page)}
				>
					{page}
				</span>
			)
		})
	}

	onChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value })
	}

	onSubmitSubscribe(e) {
		e.preventDefault();
		this.setState({ submittedSubscribe: true });
		const { subscribeEmail } = this.state;
		if (subscribeEmail) {
			const route = `/subcriber/create`
			let params = {};
			params.email = subscribeEmail;
			Api.post(route, params, null)
				.then(response => {
					if (response.status && response.status === "success") {
						Toast(response.message, "success");
					}
				})
				.catch(error => {
					if (error.status && error.status === "failed") {
						Toast(error.message);
					} else {
						Toast('Error internet connection');
					}
				})
		}
	}

	fetchTopTen() {
		this.setState({ loadingTopTen: true }, () => {
			const params = `page=1&limit=4&keyword=&state_id=3&schedule=0`;
			const route = `/post/populer?${params}`;
			Api.get(route, null)
				.then(response => {
					this.setState({ datasTopTen: response.data });
					this.setState({ loadingTopTen: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ loadingTopTen: false })
				})
		})
	}

	render() {
		const {
			datasIsFeatured,
			loadingIsFeatured,
			datasPostCategory,
			loadingPostCategory,
			datasTopTen,
			loadingTopTen,
			subscribeEmail,
			submittedSubscribe,
			activeIndex
		} = this.state;
		const { match } = this.props;

		const slides = datasIsFeatured.map(item => {
			return (
				<CarouselItem
					onExiting={this.onExiting}
					onExited={this.onExited}
					key={item.altText}
				>
					<div className="containerImg" style={{
						backgroundImage: `url(${item.src})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						marginBottom: 16
					}}>
						<div className="contentImg">
							<Link
								to={{
									pathname: `/${item.url}`,
									search: `?tag_from=${item.tag}`
								}}
							>
								<h2
									style={{
										color: 'white',
										margin: 0,
										padding: 0
									}}
									dangerouslySetInnerHTML={{
										__html:
											item.header
									}}
								/>
							</Link>
							<p style={{ color: 'whitesmoke' }}>
								{item.caption.length > 50
									? `${item.caption.substring(0, 50)}...`
									: `${item.caption}`}
							</p>
						</div>
					</div>
				</CarouselItem>
			)
		});

		if (loadingIsFeatured) return <div>Loading...</div>;
		return (
			<div>
				{match.params.slug === 'pasar-modal' && (
					<div className="row nopadding-mob" style={{ backgroundColor: 'white', color: 'white' }}>
						<div className="col-md-12" style={{ padding: "0px", height: "35px" }}>
							<iframe id='st_ba9228bf28024911993b6ebf2fe4a791' frameBorder='0' scrolling='no' width='100%' height='100%' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&stockExchange=IDX&symbols=WIKA;WSKT;UNVR;UNTR;TBIG;TLKM;SSMS;SRIL;SMRA;SMGR;SILO;SCMA;PWON;PTPP;PTBA;MYRX;MPPA;PGAS;LSIP;LPPF&palette=Financial-Light&onload=st_ba9228bf28024911993b6ebf2fe4a791'></iframe>
						</div>
						{/* <div className="col-md-12">
							<iframe frameBorder='0' scrolling='no' width='100%' height='150' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&symbols=^NYA;^FTSE100;^IDX;^AEX&scroll=No&palette=Financial-Light&template=9d286d46-a7fd-41b5-85ec-f0260c80a19d&font=Roboto&motif=face&positiveColor=459A5A&negativeColor=FD3535'></iframe>
						</div> */}
						<div className="col-md-3 p-0-i stockdio-content" style={{ marginTop: '25px' }}>
							<iframe frameBorder='0' scrolling='no' width='100%' height='150' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&symbols=^NYA&scroll=No&palette=Financial-Light&template=9d286d46-a7fd-41b5-85ec-f0260c80a19d&font=Roboto&motif=face&positiveColor=459A5A&negativeColor=FD3535'></iframe>
						</div>
						<div className="col-md-3 p-0-i stockdio-content" style={{ marginTop: '25px' }}>
							<iframe frameBorder='0' scrolling='no' width='100%' height='150' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&symbols=^FTSE100&scroll=No&palette=Financial-Light&template=9d286d46-a7fd-41b5-85ec-f0260c80a19d&font=Roboto&motif=face&positiveColor=459A5A&negativeColor=FD3535'></iframe>
						</div>
						<div className="col-md-3 p-0-i stockdio-content" style={{ marginTop: '25px' }}>
							<iframe frameBorder='0' scrolling='no' width='100%' height='150' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&symbols=^IDX&scroll=No&palette=Financial-Light&template=9d286d46-a7fd-41b5-85ec-f0260c80a19d&font=Roboto&motif=face&positiveColor=459A5A&negativeColor=FD3535'></iframe>
						</div>
						<div className="col-md-3 p-0-i stockdio-content" style={{ marginTop: '25px' }}>
							<iframe frameBorder='0' scrolling='no' width='100%' height='150' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=31B5314D67A44ABE9C2B5B0BFAA168E7&symbols=^AEX&scroll=No&palette=Financial-Light&template=9d286d46-a7fd-41b5-85ec-f0260c80a19d&font=Roboto&motif=face&positiveColor=459A5A&negativeColor=FD3535'></iframe>
						</div>
					</div>
				)}
				<div className="container">
					<ul className="breadcrumbs">
						<li className="breadcrumbs__item">
							<Link to="/" className="breadcrumbs__url">
								Home
							</Link>
						</li>
						<li className="breadcrumbs__item breadcrumbs__item--current tt-capitalize">
							{match.params.slug.replace('-', ' ')}
						</li>
					</ul>
				</div>

				<div className="main-container container" id="main-container">
					<div className="row">
						<div className="col-lg-8 blog__content mb-72">
							<h1 className="page-title tt-capitalize">{match.params.slug.replace('-', ' ')}</h1>
							{loadingPostCategory ? <div>Loading...</div> :
								(
									<div>

										<div className="row card-row">
											<div className="col-md-12">
												<Carousel
													activeIndex={activeIndex}
													next={this.next}
													previous={this.previous}
												>
													<CarouselIndicators
														items={datasIsFeatured}
														activeIndex={activeIndex}
														onClickHandler={this.goToIndex}
													/>
													{slides}
													<CarouselControl
														direction="prev"
														directionText="Previous"
														onClickHandler={this.previous}
													/>
													<CarouselControl
														direction="next"
														directionText="Next"
														onClickHandler={this.next}
													/>
												</Carousel>
											</div>
										</div>
										{datasPostCategory.length > 0 ? (
											<div className="row card-row">
												{datasPostCategory.map((dataPostCategory, dataPostCategoryIndex) => (
													<div className="col-md-6 min-height-auto" key={dataPostCategoryIndex}>
														<article className="entry card">
															<div className="entry__img-holder card__img-holder">
																<Link
																	to={{
																		pathname: `/${
																			dataPostCategory.post
																				.url
																			}`, search: `?tag_from=${dataPostCategory.post.tagables ? dataPostCategory.post.tagables[0] ? dataPostCategory.post.tagables[0].tag ? dataPostCategory.post.tagables[0].tag.slug : 'unknown' : 'uknown' : 'unknown'}`
																	}}
																>
																	<div className="thumb-container thumb-70" 
																		style={{
																			backgroundImage: dataPostCategory.post.mediaable !== null ? `url(${`${dataPostCategory.post.mediaable.media.url}`})` : `url(/static/media/logo-dark-beta.f32370e6.png)`,
																			backgroundSize: "cover", 
																			backgroundRepeat: "no-repeat",
																			backgroundPosition: "center"
																		}}
																	>
																		{/* <img
																			src={dataPostCategory.post.mediaable !== null ? `${dataPostCategory.post.mediaable.media.url}` : ''}
																			className="entry__img lazyload"
																			alt=""
																		/> */}
																	</div>
																</Link>
																<Link
																	to={{
																		pathname: `/category/${
																			dataPostCategory.post.categoryables
																				? dataPostCategory.post
																					.categoryables[0]
																					? dataPostCategory.post
																						.categoryables[0]
																						.category
																						? dataPostCategory.post
																							.categoryables[0]
																							.category
																							.slug
																						: 'unknown'
																					: 'unknown'
																				: 'unknown'
																			}`
																	}}
																	className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
																>
																	{dataPostCategory.post.categoryables
																		? dataPostCategory.post
																			.categoryables[0]
																			? dataPostCategory.post
																				.categoryables[0]
																				.category
																				? dataPostCategory.post
																					.categoryables[0]
																					.category.name
																				: 'unknown'
																			: 'unknown'
																		: 'unknown'}
																</Link>
															</div>
															<div className="entry__body card__body">
																<div className="entry__header">
																	<Link
																		to={{
																			pathname: `/${
																				dataPostCategory.post
																					.url
																				}`, search: `?tag_from=${dataPostCategory.post.tagables ? dataPostCategory.post.tagables[0] ? dataPostCategory.post.tagables[0].tag ? dataPostCategory.post.tagables[0].tag.slug : 'unknown' : 'uknown' : 'unknown'}`
																		}}
																	>
																		<h2
																			className="entry__title"
																			dangerouslySetInnerHTML={{
																				__html:
																					dataPostCategory.post.title
																			}}
																		/>
																	</Link>
																	<ul className="entry__meta">
																		<li className="entry__meta-author">
																			<span>by</span>
																			<Link to={{
																				pathname: `/authorPost/${dataPostCategory
																					.post.user
																					? dataPostCategory
																						.post
																						.user
																						.username
																					: 'Unknown'}`
																			}}>
																				{` `}{' '}
																				{dataPostCategory
																					.post.user
																					? dataPostCategory
																						.post
																						.user
																						.name
																					: 'Unknown'}
																			</Link>
																		</li>
																		<li className="entry__meta-date">
																			{moment(
																				dataPostCategory
																					.post
																					.createdAt
																			).format(
																				'LLLL'
																			)}
																		</li>
																	</ul>
																</div>
															</div>
														</article>
													</div>
												))}
											</div>
										) : <div>No results for your search.</div>}

										{this.state.entities.data &&
											this.state.entities.data.length > 0 && (
												<nav className="pagination">
													<a
														href="javascript:void(0);"
														onClick={() =>
															1 ===
																this.state.entities
																	.pagination.current_page
																? null
																: this.changePage(
																	this.state
																		.entities
																		.pagination
																		.current_page -
																	1
																)
														}
														className="pagination__page pagination__icon pagination__page--next"
													>
														<i className="ui-arrow-left" />
													</a>
													{this.pageList()}
													<a
														href="javascript:void(0);"
														onClick={() =>
															this.state.entities
																.pagination
																.total_page ===
																this.state.entities
																	.pagination.current_page
																? null
																: this.changePage(
																	this.state
																		.entities
																		.pagination
																		.current_page +
																	1
																)
														}
														className="pagination__page pagination__icon pagination__page--next"
													>
														<i className="ui-arrow-right" />
													</a>
												</nav>
											)}
									</div>
								)
							}
						</div>
						<aside className="col-lg-4 sidebar sidebar--right">
							<PopularPosts loading={loadingTopTen} data={datasTopTen} />
							{submittedSubscribe && !subscribeEmail && (
								<div
									className="help-block"
									style={{
										backgroundColor: 'red',
										padding: 10,
										color: 'white'
									}}
								>
									Email is required
								</div>
							)}
							<aside className="widget widget_mc4wp_form_widget">
								<h4 className="widget-title">Newsletter</h4>
								<p className="newsletter__text">
									<i className="ui-email newsletter__icon" />
									Subscribe for our daily news
								</p>
								<form
									className="mc4wp-form"
									name="formSubscribe"
									onSubmit={this.onSubmitSubscribe}
								>
									<div className="mc4wp-form-fields">
										<div className="form-group">
											<input
												type="email"
												name="subscribeEmail"
												placeholder="Your email"
												value={subscribeEmail}
												onChange={this.onChange}
											/>
										</div>
										<div className="form-group">
											<input
												type="submit"
												className="btn btn-lg btn-color"
												value="Sign Up"
											/>
										</div>
									</div>
								</form>
							</aside>
							<SocialMedia />
						</aside>
					</div>
				</div>
			</div>
		)
	}
}

export default Test

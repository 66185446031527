import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { IsSSR } from '../../helpers/isSSR';

class SopPerlindunganJurnalis extends Component {
  constructor(props) {
    super(props);
    this.isSSR = IsSSR();
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container">
          <ul className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to="/" className="breadcrumbs__url">
                Home
              </Link>
            </li>
            <li className="breadcrumbs__item breadcrumbs__item--current">
              SOP Perlindungan Jurnalis
            </li>
          </ul>
        </div>

        <div className="main-container container" id="main-container">
          <h1 className="page-title">SOP PERLINDUNGAN JURNALIS</h1>
          <div className="row">
            <div className="col-lg-8 mb-72">
              <div className="content-box">
                <p>
                  <strong>KEMERDEKAAN&nbsp;</strong>menyatakan pikiran dan
                  pendapat merupakan hak asasi manusia yang tidak dapat
                  dihilangkan dan harus dihormati. Rakyat Indonesia telah
                  memilih dan berketetapan hati melindungi kemerdekaan
                  menyatakan pikiran dan pendapat itu dalam Undang Undang Dasar
                  1945. Ke merdekaan pers adalah salah satu wujud kedaulatan
                  rakyat dan bagian penting dari kemerdekaan menyatakan pikiran
                  dan pendapat.
                </p>

                <p>
                  Wartawan adalah pilar utama kemerdekaan pers. Oleh karena itu
                  dalam menjalankan tugas profesinya wartawan mutlak mendapat
                  perli ndungan hukum dari negara, masyarakat, dan perusahaan
                  pers. Untuk itu Standar Perlindungan Profesi Wartawan ini
                  dibuat:
                </p>

                <ol>
                  <li>
                    Perlindungan yang diatur dalam standar ini adalah
                    perlindungan hukum untuk wartawan yang menaati kode etik
                    jurnalistik dalam melaksanakan tugas jurnalistiknya memenuhi
                    hak masyarakat memperoleh informasi;
                  </li>
                  <li>
                    Dalam melaksanakan tugas jurnalistik, wartawan memperoleh
                    perlindungan hukum dari negara, masyarakat, dan perusahaan
                    pers. Tugas jurnalistik meliputi mencari, memperoleh,
                    memiliki, menyimpan, mengolah, dan menyampaikan informasi
                    melalui media massa;
                  </li>
                  <li>
                    Dalam menjalankan tugas jurnalistik, wartawan dil indungi
                    dari tindak kekerasan, pengambilan, penyitaan dan atau
                    perampasan alat alat kerja, serta tidak boleh dihambat atau
                    diintimidasi oleh pihak manapun;
                  </li>
                  <li>
                    Karya jurnalistik wartawan dilindungi dari segala bentuk
                    penyensoran;
                  </li>
                  <li>
                    Wartawan yang ditugaskan khusus di wilayah berbahaya dan
                    atau konflik wajib dilengkapi surat penugasan, peralatan
                    keselamatan yang memenuhi syarat, asuransi, serta
                    pengetahuan, keterampilan dari perusahaan pers yang
                    berkaitan dengan kepentingan penugasannya;
                  </li>
                  <li>
                    Dalam penugasan ju rnalistik di wilayah konflik bersenjata,
                    wartawan yang telah menunjukkan identitas sebagai wartawan
                    dan tidak menggunakan identitas pihak yang bertikai, wajib
                    diperlakukan sebagai pihak yang netral dan diberikan
                    perlindungan hukum sehingga dilarang diintim idasi,
                    disandera, disiksa, dianiaya, apalagi dibunuh;
                  </li>
                  <li>
                    Dalam perkara yang menyangkut karya jurnalistik, perusahaan
                    pers diwakili oleh penanggungjawabnya;
                  </li>
                  <li>
                    Dalam kesaksian perkara yang menyangkut karya jurnalistik,
                    penanggungjawabnya hanya dapat ditanya mengenai berita yang
                    telah dipublikasikan. Wartawan dapat menggunakan hak tolak
                    untuk melindungi sumber informasi;
                  </li>
                  <li>
                    Pemilik atau manajemen perusahaan pers dilarang memaksa
                    wartawan untuk membuat berita yang melanggar Kode Etik
                    Jurnalistik dan atau hukum yang berlaku.
                  </li>
                </ol>

                <p>Jakarta, 25 April 2008</p>

                <p>
                  Standar ini disetujui dan ditandatangani oleh sejumlah
                  organisasi pers, pimpinan perusahaan pers, tokoh pers, lembaga
                  terkait, ser ta Dewan Pers di Jakarta, 25 April 2008. Sebelum
                  disahkan, draft Standar Perlindungan Profesi Wartawan telah
                  dibahas melalui serangkaian diskusi yang digelar Dewan Pers.
                  Pembuatan Standar ini merupakan pelaksanaan fungsi Dewan Pers
                  menurut Pasal 15 ayat (f ) UU No.40/1999 tentang Pers yaitu
                  memfasilitasi organisasi organisasi pers dalam menyusun
                  peraturan peraturan di bidang pers dan meningkatkan kualitas
                  profesi wartawan
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SopPerlindunganJurnalis;

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';

import EditModalMedia from './partials/Edit'

const selectedImage = {
    display: 'block',
    border: '3px solid #CC0000',
    borderRadius: '7px',
    width: '100%',
    height: '100%'
};

const unselectedImage = {
    display: 'block',
    width: '100%',
    height: '100%'
};

class ModalMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameImage: "",
            meta_tag: "",
            media_type: "",
            file_type: "",
            first_page: 1,
            current_page: 1,
            offset: 4,
            loading: false,
        }

        this.handleSelected = this.handleSelected.bind(this);
        this.handleSubmitImage = this.handleSubmitImage.bind(this);
        this.onChangeCaptionUpdate = this.onChangeCaptionUpdate.bind(this);
        this.onChangeMetaTag = this.onChangeMetaTag.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setSelectedImage = this.setSelectedImage.bind(this);
    }

    pagesNumbers() {
        let from =
            this.props.pagination.current_page - this.state.offset
        if (from < 1) {
            from = 1
        }
        let to = from + this.state.offset * 2
        if (to >= this.props.pagination.total_page) {
            to = this.props.pagination.total_page
        }
        let pagesArray = []
        for (let page = from; page <= to; page++) {
            pagesArray.push(page)
        }
        return pagesArray
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return (
                <span
                    key={page}
                    style={{ cursor: "pointer" }}
                    className={`pagination__page ${
                        page === this.props.pagination.current_page
                            ? 'pagination__page--current'
                            : null
                        }`}
                    onClick={() => this.props.refreshData(page)}
                >
                    {page}
                </span>
            )
        })
    }

    handleSelected(id, nameImage, media_type, file_type, meta_tag, image) {
        if (this.props.type === "image") {
            var data = [...this.props.selectedImagesModal];
            if (data.includes(id)) {
                let index = data.indexOf(id);
                if (index > -1) {
                    data.splice(index, 1);
                    this.props.setSelectedImagesModal(data);
                }
            } else {
                data.push(id);
                this.props.setSelectedImagesModal(data);
            }
        } else {
            let data = [id];
            this.setState({ nameImage: nameImage, media_type, file_type, meta_tag, selectedImage: image })
            this.props.setSelectedImagesModal(data);
        }
    }

    handleSubmitImage() {
        let images = this.props.selectedImagesModal;
        let text = "";
        if (images.length > 1) {
            text = images.length + " Images";
        } else if (images.length === 1) {
            text = this.state.nameImage;
        }
        this.props.addImagePost(images, text, this.state.media_type, this.state.file_type, this.state.meta_tag);
        this.props.toggleAddImagePostModal();
    }

    onChangeMetaTag(e) {
        const { name, value } = e.target
        let { selectedImage } = this.state;
        selectedImage["meta_tag"] = value;
        this.setState({ selectedImage });
    }

    onChangeCaptionUpdate(e) {
        const { name, value } = e.target
        let { selectedImage } = this.state;
        selectedImage["caption"] = value;
        this.setState({ selectedImage });
    }

    setLoading(value) {
        this.setState({ loading: value })
    }

    setSelectedImage(value) {
        this.setState({ selectedImage: value })
    }

    render() {
        let images = this.props.images.map((image) => {
            if (image.media_type === "image") {
                return (
                    <div
                        key={"image-" + image.id}
                        className="col-md-4 col-6  ivoox-media-img"
                        style={{ height: "128px" }}
                        title={image.meta_tag}
                    >
                        <a
                            onClick={() => this.handleSelected(image.id, image.url, image.media_type, image.file_type, image.meta_tag, image)}
                            href="javascript:void(0);"
                            style={this.props.selectedImagesModal.includes(image.id) ? selectedImage : unselectedImage}
                        >
                            <div
                                style={{
                                    height: "100%", width: "100%", backgroundImage: `url(${image.url}`,
                                    backgroundRepeat: "no-repeat", backgroundSize: "cover"
                                }}
                            >
                            </div>
                        </a>
                    </div>
                )
            } else {
                return (
                    <div
                        key={"image-" + image.id}
                        className="col-md-4 col-6  ivoox-image-img"
                        style={{ height: "128px" }}
                        title={image.meta_tag}
                    >
                        <a
                            onClick={() => this.handleSelected(image.id, image.url, image.media_type, image.file_type, image.meta_tag, image)}
                            href="javascript:void(0);"
                            style={this.props.selectedImagesModal.includes(image.id) ? selectedImage : unselectedImage}
                        >
                            <div
                                style={{ height: "100%", width: "100%", textAlign: "center" }}
                            >
                                <i className="fa fa-file" aria-hidden="true" style={{ fontSize: "50px", marginTop: "15px", display: "block" }}></i>
                                <span style={{ display: "inline-block", width: "100%", height: "68px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    {image.file_type}
                                </span>
                            </div>
                        </a>
                    </div>
                );
            }
        });

        return (
            <Modal
                size="md" isOpen={this.props.addImagePostModal}
                toggle={this.props.toggleAddImagePostModal} className="modal-image-custome"
            >
                <ModalHeader toggle={this.props.toggleAddImagePostModal}>Images</ModalHeader>
                <ModalBody>
                    <LoadingOverlay
                        active={this.props.loadingImage}
                        spinner
                        text='Please Wait...'
                    >
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="col-lg-12 col-12 p-0">
                                            <input id="keyword" name="keyword" type="text" placeholder="Search media" className="nav__search-input" value={this.props.keyword} onChange={this.props.onChangeKeyword} />
                                            <button type="button" className="search-button btn btn-md btn-color btn-button" onClick={() => this.props.refreshData(1)}>
                                                <i className="ui-search nav__search-icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ivoox-media">
                                    {images}
                                </div>
                                {this.props.images &&
                                    this.props.images.length > 0 && (
                                        <nav className="pagination">
                                            <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                    1 ===
                                                        this.props.pagination.current_page
                                                        ? null
                                                        : this.props.refreshData(
                                                            this.props
                                                                .pagination
                                                                .current_page -
                                                            1
                                                        )
                                                }
                                                style={{ cursor: 1 === this.props.pagination.current_page ? 'not-allowed' : 'pointer' }}
                                                className="pagination__page pagination__icon pagination__page--next"
                                            >
                                                <i className="ui-arrow-left" />
                                            </a>
                                            {this.pageList()}
                                            <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                    this.props.pagination
                                                        .total_page ===
                                                        this.props.pagination.current_page
                                                        ? null
                                                        : this.props.refreshData(
                                                            this.props
                                                                .pagination
                                                                .current_page +
                                                            1
                                                        )
                                                }
                                                style={{
                                                    cursor: this.props.pagination
                                                        .total_page === this.props.pagination.current_page ? 'not-allowed' : 'pointer'
                                                }}
                                                className="pagination__page pagination__icon pagination__page--next"
                                            >
                                                <i className="ui-arrow-right" />
                                            </a>
                                        </nav>
                                    )
                                }
                            </div>
                            {this.state.selectedImage && (
                                <EditModalMedia
                                    selectedMedia={this.state.selectedImage}
                                    onChangeMetaTag={this.onChangeMetaTag}
                                    onChangeCaptionUpdate={this.onChangeCaptionUpdate}
                                    refreshMedias={this.props.refreshData}
                                    setLoading={this.setLoading}
                                    setSelectedImage={this.setSelectedImage}
                                />
                            )}
                        </div>
                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.props.toggleAddImagePostModal()}>Batalkan</Button>
                    <Button color="primary" onClick={() => this.handleSubmitImage()}>Select</Button>{' '}
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalMedia;
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Api from '../services/api'
import NewsletterFooter from './NewsletterFooter'

import { sosialMedia } from '../config'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datasJustForYou: [],
      loadingJustForYou: false
    }
  }

  componentDidMount() {
    this.fetchNavigation()
  }

  fetchNavigation() {
    this.setState({ loadingJustForYou: true }, () => {
      const params = `page=1&limit=5&keyword=`
      const route = `/tag/index?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasJustForYou: response.data })
          this.setState({ loadingJustForYou: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingJustForYou: false })
        })
    })
  }

  render() {
    const { datasJustForYou, loadingJustForYou } = this.state
    const { loading, datas } = this.props
    return (
      <footer
        className="footer footer--white"
        style={{
          marginBottom: datas['BottomLock'] ? (window.outerWidth < 480 ? 0 : 60) : 0
        }}
      >
        <div className="container">
          {datas['BottomBanner'] && (
            <React.Fragment>
              {datas['BottomBanner'].type == 'media' && datas['BottomBanner'].media && datas['BottomBanner'].media.media_type === 'image' && (
                <div className="col-md-10 offset-md-1 text-center">
                  <a href={datas['BottomBanner'].url !== '' ? datas['BottomBanner'].url : '#'}>
                    <img src={datas['BottomBanner'] ? datas['BottomBanner'].media.url : ''} style={{ width: '100%' }} />
                  </a>
                </div>
              )}
              {datas['BottomBanner'].type == 'media' && datas['BottomBanner'].media && datas['BottomBanner'].media.media_type === 'video' && (
                <div className="text-center">
                  <video width="100%" style={{ width: '100%' }} controls={false} autoPlay loop>
                    <source src={datas['BottomBanner'].media.url} type={datas['BottomBanner'].media.file_type} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              {datas['BottomBanner'].type == 'adsense' && datas['BottomBanner'].adsense && (
                <div className="text-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: datas['BottomBanner'].adsense
                    }}
                  ></p>
                </div>
              )}
            </React.Fragment>
          )}

          <hr />
          <div className="footer__widgets footer__widgets--short">
            <div className="row">
              <div className="col-md-4">
                <Link to="/">
                  <img
                    className="logo__img"
                    src={'/assets/img/logo-ivoox-4.png'}
                    // src={require('../../assets/img/logo-ivoox-2.png')}
                    style={{ width: '100%' }}
                  />
                </Link>
                {/* <div className="footer-address">
											<p>PT Media Nusantara Sakti</p>
											<p>The City Tower</p>
											<p>Jl. MH Thamrin No.81, LT12/1N</p>
											<p>Jakarta. 10310 Indonesia</p>
										</div> */}
              </div>

              <div className="col-md-5">
                <aside className="widget widget_nav_menu">
                  <h4 className="widget-title2">Useful Links</h4>
                  <div className="footer-link">
                    <div className="footer-link-2">
                      <div className="footer-link-child">
                        <Link to="/redaksi">Redaksi</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/contactUs">Contact</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/careerForm">Career</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/beriklan">Beriklan</Link>
                      </div>
                    </div>
                    <div className="footer-link-2">
                      <div className="footer-link-child">
                        <Link to="/privacyPolicy">Privacy Policy</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/disclaimer">Disclaimer</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/sop-perlindungan-jurnalis">SOP Perlindungan Jurnalis</Link>
                      </div>
                      <div className="footer-link-child">
                        <Link to="/pedoman-pemberitaan-media-siber">Pedoman Pemberitaan Media Siber</Link>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>

              {/* <div className="col-lg-3 col-md-6">
                  <aside className="widget widget_nav_menu">
                    <h4 className="widget-title">Tags</h4>
                    <ul>
                      {datasJustForYou.map((justForYou, i) => (
                        <li key={i}>
                          <Link to={`/tag/${justForYou.slug}`} className="tt-capitalize">
                            #{justForYou.slug}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div> */}
              <div className="col-md-3">
                <div className="col-12" style={({ marginBottom: '25px' }, { padding: '0px' })}>
                  <h4 className="widget-title2">Subscribe For Newsletter</h4>
                  <NewsletterFooter />
                </div>
              </div>
              <div className="col-md-12">
                <hr />
              </div>
              <div className="footer-bot">
                <div className="socials socials--large socials--rounded socials--grey text-center">
                  <a href="https://www.facebook.com/ivooxidnews" className="social social-facebook social--large social--rounded" aria-label="facebook">
                    <i className="ui-facebook" />
                  </a>
                  <a href="https://twitter.com/ivooxid" className="social social-twitter social--large social--rounded" aria-label="twitter">
                    <i className="ui-twitter" />
                  </a>
                  <a href="https://www.tiktok.com/@ivooxindonesia" className="social social-google-plus social--large social--rounded" aria-label="google+">
                    <img src="/assets/img/tiktok.png" style={{ width: 20 }} />
                  </a>
                  <a href="https://www.instagram.com/ivooxid/" className="social social-instagram social--large social--rounded" aria-label="instagram">
                    <i className="ui-instagram" />
                  </a>
                </div>
                <div className="row text-center">
                  <div className="col-6">
                    <a href="https://play.google.com/store/apps/details?id=com.ptmns.ivoox" target="_blank">
                      <img
                        src={'/assets/img/google-play-footer.png'}
                        // src={require('../../assets/img/google-play-footer.png')}
                        alt="IVoox for Android"
                      />
                    </a>
                  </div>
                  <div className="col-6">
                    <a href="https://itunes.apple.com/id/app/ivooxid/id1204539860?mt=8" target="_blank">
                      <img
                        src={'/assets/img/app-store-footer-new.png'}
                        // src={require('../../assets/img/app-store-footer-new.png')}
                        alt="IVoox for iOS "
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyUser, keyToken } from '../../../config'
import CheckError from '../../../utils/admin/checkError'
import Toast from '../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingContainer: false,
            loading: false,
            loadingRole: true
        }

        this.refreshRoles = this.refreshRoles.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    onChangeRole(e) {
        const { name, value } = e.target
        let { roleData } = this.state
        roleData[name] = value
        this.setState({ roleData })
    }

    fetchData() {
        const user = JSON.parse(this.props.localUser)
        const {
            isAs
        } = this.props

        var _ = this
        const route = `/role/index`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var roleDatatableDraw = 1
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    roleDatatableDraw = data.draw
                    var requestData = {}
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id', orderable: false },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    render: function (data) {
                        // var nameRole = (data.name) ? data.name.charAt(0).toUpperCase() + data.name.substring(1) : "";
                        return data.name;
                    }
                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
            ]
        })
    }

    refreshRoles() {
        $('#datatable-role')
            .DataTable()
            .ajax.reload()
    }

    render() {
        const { loadingContainer } = this.state
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Role List</h4>
                                    <table
                                        id="datatable-role"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Role Name</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ListRole

import { apiUrl } from '../config'

export default class Api {
	static headers(token) {
		if (token)
			return {
				Accept: 'application/json',
				'Content-Type': 'application/json;text/plain',
				'x-access-token': `${token}`
			};
		return {
			Accept: 'application/json',
			'Content-Type': 'application/json;text/plain',
		}
	}

	static get(route, token) {
		return this.fetchMethod(route, null, token, 'GET')
	}

	static post(route, params, token) {
		return this.fetchMethod(route, params, token, 'POST')
	}

	static put(route, params, token) {
		return this.fetchMethod(route, params, token, 'PUT')
	}

	static delete(route, token) {
		return this.fetchMethod(route, [], token, 'DELETE')
	}

	static fetchMethod(route, params, token, method) {
		const url = `${apiUrl}${route}`;
		let options = Object.assign(
			{ method: method },
			params ? { body: JSON.stringify(params) } : null
		);
		options.headers = Api.headers(token);

		return fetch(url, options)
			.then(response => {
				let responseJson = response.json();
				if (response.ok) return responseJson;
				return responseJson.then(error => {
					throw error
				})
			})
			.then(responseJson => responseJson)
	}
}

import React, { Component } from 'react'
import DashboardThreeColumn from './dashboardThreeColumn'
import DashboardTwoRow from './dashboardTwoRow'
import moment from 'moment'

import { apiUrl, keyToken } from '../../config'

import NotificationVerify from '../../utils/admin/notificationVerify'

import { IsSSR } from "../../helpers/isSSR";
import { getStorageItem } from "../../helpers/storage";

const $ = require('jquery');
$.DataTable = require('datatables.net');

class DashboardSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
		this.isSSR = IsSSR();
		this.token = null;
		if (!this.isSSR) this.token = getStorageItem(keyToken);
		this.refreshPosts = this.refreshPosts.bind(this)
	}

	componentDidMount() {
		setTimeout(() => this.fetch())
	}

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword') ||
                paramsPrev.get('sort_field') !== params.get('sort_field')||
                paramsPrev.get('sort_by') !== params.get('sort_by')
            ) {
                $('#datatable-post-dashboard').off( "click", ".detail-post-btn");
                $('#datatable-post-dashboard')
                    .DataTable()
                    .destroy()
                $('#datatable-post-dashboard tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

	fetch() {
		const { isAs, localUser } = this.props;
		const user = JSON.parse(localUser);
		let $this = this;
		const route = `/post/index`;
		const url = `${apiUrl}${route}`;
		this.$el = $(this.el);
		let postDatatableDraw = 1;
        let searchParams = new URLSearchParams(this.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        var sort_field = 0;
        var sort_by = searchParams.get('sort_by') && searchParams.get('sort_by') == 'ASC' ? 'asc' : 'desc';

        if (searchParams.get('sort_field') && searchParams.get('sort_field') == "state_id") {
            sort_field = 4;
        } else if (searchParams.get('sort_field') && searchParams.get('sort_field') == "createdAt") {
            sort_field = 5;
        } 
		this.$el.DataTable({
			processing: true,
			scrollX: true,
			serverSide: true,
            order: [[sort_field, sort_by]],
			lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
			searching: true,
			searchDelay: 1000,
			ajax: {
				url: url,
				headers: {
					'x-access-token': this.token
				},
				data: function(data) {
					postDatatableDraw = data.draw;
					let requestData = {};
					if (data.order && data.order[0] && data.order[0].column === 4) requestData.sort_field = "state_id";
					else if(data.order && data.order[0] && data.order[0].column === 5) requestData.sort_field = "createdAt";
					else requestData.sort_field = "id";
					if (data.order && data.order[0] && data.order[0].dir === "desc") requestData.sort_by = "DESC";
					else requestData.sort_by = "ASC";
					requestData.page = data.start / data.length + 1;
					requestData.limit = data.length;
					requestData.keyword = data.search.value;
					requestData.state_id = 'all';
					requestData.schedule = 0;
					if (['admin', 'editor'].indexOf(isAs) > -1) console.log('');
					else requestData.user_id = user.id;

                    if(data.draw !== 1) {
						$this.props.history.push(`/admin/dashboard?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}&sort_field=${requestData.sort_field}&sort_by=${requestData.sort_by}`);
                    }
					return requestData
				},
			},
			columns: [
				{
					data: null,
					name: 'id',
					render: function(data) {
						var url = "";
						if(['admin', 'editor', 'author'].indexOf($this.props.isAs) > -1) url = `/admin/post/${data.id}/Post` ;
						else url = `/admin/post/${data.id}/Nitizen Jurnalism`;
						return (
							'<a href="' + url + '" data-id="' + data.id + '" class="detail-post-btn">' + data.id + '</a>'
						)
					}
				},
				{
					data: null,
					name: 'title',
					orderable: false,
					width: '250px',
					render: function(data) {
						var url = "";
						if(['admin', 'editor', 'author'].indexOf($this.props.isAs) > -1) url = `/admin/post/${data.id}/Post` ;
						else url = `/admin/post/${data.id}/Nitizen Jurnalism`;
						return (
							'<a href="' + url + '" data-id="' + data.id + '" class="detail-post-btn">' + data.title + '</a>'
						)
					}
				},
				{
					data: null,
					name: 'name',
					orderable: false,
					searchable: false,
					render: function(data) {
						return data.user.name
					}
				},
				{
					data: null,
					name: 'categories',
					orderable: false,
					searchable: false,
					render: function(data) {
						let categories = '';
						let total = data.categoryables.length;
						data.categoryables.map(function(item, key) {
							categories += item.category.name;
							categories = key + 1 < total ? categories + ', ' : categories
						});
						return categories
					}
				},
				{
					data: null,
					name: 'status',
					render: function(data) {
						let state = (data.state && data.state.name) ? data.state.name : "";
						return state;
					}
				},
				{
					data: null,
					name: 'createdAt',
					render: function(data) {
						let createdDate = moment(data.createdAt).format('DD/MM/YYYY HH:mm');
						return createdDate
					}
				},
			]
		});
		$('#datatable-post-dashboard').on(
			'click',
			'.detail-post-btn',
			function(e) {
				e.preventDefault();
				let id = $(this).data('id');
				$this.showPostDetail(id)
			}
		)
	}

	refreshPosts() {
		$('#datatable-post-dasboard')
			.DataTable()
			.ajax.reload()
	}

	showPostDetail(id) {
		if(['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) this.props.history.push(`/admin/post/${id}/Post`) ;
		else this.props.history.push(`/admin/post/${id}/Nitizen Jurnalism`);
	}

	render() {
		const { isAs, localUser } = this.props;
		return (
			<div className="col-lg-9 col-12">
				<NotificationVerify isAs={isAs} localUser={localUser}>
					<div className="entry card pt-4 pb-5 p-3">
						<DashboardThreeColumn isAs={isAs} localUser={localUser} />
						<DashboardTwoRow isAs={isAs} localUser={localUser} />
						<table
							id="datatable-post-dashboard"
							className="display table table-sm table-striped table-bordered"
							width="100%"
							ref={el => (this.el = el)}
						>
							<thead>
							<tr>
								<th>ID</th>
								<th>Title</th>
								<th>Author</th>
								<th>Categories</th>
								<th>Status</th>
								<th>Date</th>
							</tr>
							</thead>
						</table>
					</div>
				</NotificationVerify>
			</div>
		)
	}
}

export default DashboardSection

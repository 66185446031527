import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import AdsForm from './form'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import Toast from '../../../components/Toast'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import { apiUrl, keyToken, keyUser } from '../../../config'

const $ = require('jquery');
$.DataTable = require('datatables.net');

class AdsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingContainer: false,
            loading: false,
            editFormStatus: false,
            editFormId: 0,
            formData: {
                name: '',
                position: '',
                type: '',
                media_id: '',
                adsense: '',
                url: ''
            },
            deleteCategoryModal: false,
            deleteCategoryData: {
                id: '',
                name: ''
            },
            errors: {},
            selectedImages: [],
            imageText: '',
            mediaType: '',
            file_type: '',
            meta_tag: '',
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true,
            caption: "",
        };
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);

        this.onChangeForm = this.onChangeForm.bind(this);
        this.setLoadingContainer = this.setLoadingContainer.bind(this);
        this.refresh = this.refresh.bind(this);
        this.showCategoryDetail = this.showCategoryDetail.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.refreshEditData = this.refreshEditData.bind(this);
        this.setParameterErrors = this.setParameterErrors.bind(this);
        this.addImagePost = this.addImagePost.bind(this)
        this.onChangeCaption = this.onChangeCaption.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-category').off("click", ".detail-category-btn");
                $('#datatable-category').off("click", ".delete-category-btn");
                $('#datatable-category')
                    .DataTable()
                    .destroy()
                $('#datatable-category tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => { }
    }

    onChangeForm(e) {
        const { name, value } = e.target;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({ formData })
    }

    onChangeCaption(e) {
        const { name, value } = e.target
        this.setState({ caption: value })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    refreshEditData() {
        this.setState({
            editFormStatus: false,
            editFormId: 0,
            formData: {
                name: '',
                position: '',
                type: '',
                media_id: ''
            },
            errors: {}
        });
        this.addImagePost([], '', '', '', '')
    }

    fetchData() {
        const { isAs, localUser } = this.props;
        const user = JSON.parse(localUser);

        let _ = this;
        const route = `ad/index`;
        const url = `${apiUrl}/${route}`;
        this.$el = $(this.el);
        let categoryDatatableDraw = 1;
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: { "search": searchParams.get('keyword') ? searchParams.get('keyword') : "" },
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': this.token
                },
                data: function (data) {
                    categoryDatatableDraw = data.draw;
                    let requestData = {};
                    requestData.page = data.start / data.length + 1;
                    requestData.limit = data.length;
                    requestData.keyword = data.search.value;
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    if (data.draw !== 1) {
                        _.props.history.push(`/admin/ads?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if (jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'Id', orderable: false },
                { data: 'name', name: 'Name', orderable: false },
                // { data: 'position', name: 'Position', orderable: false },
                {
                    data: 'position',
                    name: 'position',
                    orderable: false,
                    render: function (data) {
                        var value = data;
                        if (data === 'TopBanner2') value = 'TopBanner'
                        else if (data === 'TopBanner') value = 'Header'
                        return value
                    }
                },
                { data: 'type', name: 'Type', orderable: false },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
                            data.id +
                            '" class="detail-category-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);" title="Edit"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
                            data.id +
                            '" data-name="' +
                            data.name +
                            '" class="delete-category-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);" title="Delete"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        });
        $('#datatable-category').on(
            'click',
            '.detail-category-btn',
            function () {
                let id = $(this).data('id');
                _.showCategoryDetail(id)
            }
        );
        $('#datatable-category').on(
            'click',
            '.delete-category-btn',
            function () {
                let id = $(this).data('id');
                let name = $(this).data('name');
                _.deleteCategory(id, name)
            }
        )
    }

    refresh() {
        $('#datatable-category')
            .DataTable()
            .ajax.reload()
    }

    showCategoryDetail(id) {
        if (!this.isSSR) window.scrollTo(0, 0);
        this.setLoadingContainer(true);
        this.setParameterErrors({});
        this.refreshEditData();
        const route = `/ad/` + id;
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.setState({
                        editFormStatus: true,
                        editFormId: response.data.id,
                        formData: response.data
                    });
                    if (response.data.media) {
                        let images = [];
                        images[0] = response.data.media.id;
                        this.addImagePost(
                            images,
                            response.data.media.url,
                            response.data.media.media_type,
                            response.data.media.file_type,
                            response.data.media.meta_tag
                        )
                    }
                    this.setLoadingContainer(false)
                }
            })
            .catch(err => {
                Toast('Error internet connection');
                this.setLoadingContainer(false)
            })
    }

    deleteRowTable(id) {
        this.setState({ loading: true });
        const route = `/ad/delete/` + id;
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.refresh();
                    Toast(response.message, 'success');
                    this.setState({ deleteCategoryModal: false })
                }
            })
            .catch(() => {
                this.refresh();
                Toast('Error internet connection');
                this.setState({ deleteCategoryModal: false })
            })
    }

    toggleDeleteCategoriesModal() {
        this.setState({
            deleteCategoryModal: !this.state.deleteCategoryModal
        })
    }

    deleteCategory(id, name) {
        this.setState({
            deleteCategoryData: {
                id: id,
                name: name
            },
            deleteCategoryModal: !this.state.deleteCategoryModal
        })
    }

    setLoadingContainer(status) {
        this.setState({ loadingContainer: status })
    }

    addImagePost(images, text, mediaType, file_type, meta_tag, caption = "") {
        this.setState({ selectedImages: images });
        this.setState({
            imageText: text,
            mediaType: mediaType,
            file_type: file_type,
            meta_tag: meta_tag,
            caption: caption,
        })
    }

    render() {
        const { loadingContainer } = this.state;
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <Modal
                            isOpen={this.state.deleteCategoryModal}
                            toggle={this.toggleDeleteCategoriesModal.bind(this)}
                            className={this.props.className}
                        >
                            <ModalHeader
                                toggle={this.toggleDeleteCategoriesModal.bind(
                                    this
                                )}
                            >
                                Konfirmasi
							</ModalHeader>
                            <ModalBody>
                                Apakah ingin menghapus ads ini{' '}
                                {this.state.deleteCategoryData.name}?
							</ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.deleteRowTable.bind(
                                        this,
                                        this.state.deleteCategoryData.id
                                    )}
                                >
                                    Hapus
								</Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDeleteCategoriesModal.bind(
                                        this
                                    )}
                                >
                                    Batalkan
								</Button>
                            </ModalFooter>
                        </Modal>
                        <AdsForm
                            setLoadingContainer={this.setLoadingContainer}
                            onChangeForm={this.onChangeForm}
                            refresh={this.refresh}
                            setParameterErrors={this.setParameterErrors}
                            refreshEditData={this.refreshEditData}
                            onChangeCaption={this.onChangeCaption}
                            editFormStatus={this.state.editFormStatus}
                            editFormId={this.state.editFormId}
                            formData={this.state.formData}
                            errors={this.state.errors}
                            addImagePost={this.addImagePost}
                            selectedImages={this.state.selectedImages}
                            imageText={this.state.imageText}
                            mediaType={this.state.mediaType}
                            file_type={this.state.file_type}
                            meta_tag={this.state.meta_tag}
                            caption={this.state.caption}
                            isAs={this.props.isAs}
                        />
                        <div className="divider" />
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Ads List</h4>
                                    <table
                                        id="datatable-category"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Type</th>
                                                <th>CreatedAt</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default AdsList

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyToken, keyUser } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import FormComment from './form'
import Toast from '../../../components/Toast'


const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListComment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingContainer: false,
            loading: false,
            editCommentStatus: false,
            editCommentId: 0,
            commentData: {
                content: '',
                commentable_id: '',
                commentable_type: '',
            },
            deleteCommentModal: false,
            deleteCommentData: {
                id: ''
            },
            errors: {},
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            loadingRole: true
        }

        this.onChangeComment = this.onChangeComment.bind(this)
        this.setLoadingContainer = this.setLoadingContainer.bind(this)
        this.refreshComments = this.refreshComments.bind(this)
        this.showCommentDetail = this.showCommentDetail.bind(this)
        this.deleteComment = this.deleteComment.bind(this)
        this.refreshEditData = this.refreshEditData.bind(this)
        this.setParameterErrors = this.setParameterErrors.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-comment').off( "click", ".detail-comment-btn");
                $('#datatable-comment').off( "click", ".delete-comment-btn");
                $('#datatable-comment')
                    .DataTable()
                    .destroy()
                $('#datatable-comment tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    onChangeComment(e) {
        const { name, value } = e.target
        let { commentData } = this.state
        commentData[name] = value
        this.setState({ commentData })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    refreshEditData() {
        this.setState({
            editCommentStatus: false,
            editCommentId: 0,
            commentData: {
                content: '',
                commentable_id: '',
                commentable_type: '',
            },
            errors: {}
        })
    }

    fetchData() {
        const user = JSON.parse(this.props.localUser)
        const {
            isAs
        } = this.props

        var _ = this
        const route = `/comment`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var commentDatatableDraw = 1
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    commentDatatableDraw = data.draw
                    var requestData = {}
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/comments?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id', orderable: false },
                {
                    data: null,
                    name: 'user_id',
                    orderable: false,
                    render: function (data) {
                        var username = (data.user) ? data.user.username : "";
                        return username;
                    }
                },
                {
                    data: null,
                    name: 'content',
                    width: "40%",
                    orderable: false,
                    render: function (data) {
                        var content = data.content;
                        return data.content;
                    }
                },
                {
                    data: null,
                    name: 'commentable_id',
                    width: "40%",
                    orderable: false,
                    render: function (data) {
                        var title = "";
                        if (data.post && data.commentable_type == "App/Post") {
                            title = data.post.title;
                        } else if (data.polling && data.commentable_type == "App/Polling") {
                            title = data.polling.title;
                        } else if (data.comment && data.commentable_type == "App/Comment") {
                            title = "Commment to comment id " + data.comment.id;
                        }
                        return title;
                    }
                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        var disabled = "";
                        var detailBtn = "";
                        var deleteBtn = "";
                        if (['admin', 'editor'].indexOf(isAs) > -1) {
                            detailBtn = "detail-comment-btn";
                            deleteBtn = "delete-comment-btn";
                        } else {
                            disabled = "disabled";
                        }
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
                            data.id +
                            '" class="' + detailBtn + ' btn btn-sm btn-color btn-button w-100 ' + disabled + '" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
                            data.id +
                            '" class="' + deleteBtn + ' btn btn-sm btn-color btn-button w-100 ' + disabled + '" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        })
        $('#datatable-comment').on('click', '.detail-comment-btn', function () {
            var id = $(this).data('id')
            _.showCommentDetail(id)
        })
        $('#datatable-comment').on('click', '.delete-comment-btn', function () {
            var id = $(this).data('id')
            _.deleteComment(id)
        })
    }

    refreshComments() {
        $('#datatable-comment')
            .DataTable()
            .ajax.reload()
    }

    showCommentDetail(id) {
        this.setLoadingContainer(true)
        this.setParameterErrors({})
        const route = `/comment/` + id
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.setState({
                        editCommentStatus: true,
                        editCommentId: response.data.id,
                        commentData: response.data
                    })
                    this.setLoadingContainer(false)
                }
            })
            .catch(() => {
                Toast('Error internet connection')
                this.setLoadingContainer(false)
            })
    }

    deleteRowTable(id) {
        this.setState({ loading: true })
        const route = `/comment/delete/` + id
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.refreshComments()
                    Toast(response.message, 'success')
                    this.setState({ deleteCommentModal: false })
                }
            })
            .catch(() => {
                this.refreshComments()
                Toast('Error internet connection')
                this.setState({ deleteCommentModal: false })
            })
    }

    toggleDeleteCommentsModal() {
        this.setState({
            deleteCommentModal: !this.state.deleteCommentModal
        })
    }

    deleteComment(id) {
        console.log(id)
        this.setState({
            deleteCommentData: {
                id: id
            },
            deleteCommentModal: !this.state.deleteCommentModal
        })
    }

    setLoadingContainer(status) {
        this.setState({ loadingContainer: status })
    }

    render() {
        const { loadingContainer } = this.state
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <Modal
                            isOpen={this.state.deleteCommentModal}
                            toggle={this.toggleDeleteCommentsModal.bind(this)}
                            className={this.props.className}
                        >
                            <ModalHeader
                                toggle={this.toggleDeleteCommentsModal.bind(this)}
                            >
                                Konfirmasi
                            </ModalHeader>
                            <ModalBody>
                                Apakah ingin menghapus comment dengan ID{' '}
                                {this.state.deleteCommentData.id}?
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.deleteRowTable.bind(
                                        this,
                                        this.state.deleteCommentData.id
                                    )}
                                >
                                    Hapus
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDeleteCommentsModal.bind(
                                        this
                                    )}
                                >
                                    Batalkan
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <FormComment
                            setLoadingContainer={this.setLoadingContainer}
                            onChangeComment={this.onChangeComment}
                            refreshComments={this.refreshComments}
                            setParameterErrors={this.setParameterErrors}
                            refreshEditData={this.refreshEditData}
                            editCommentStatus={this.state.editCommentStatus}
                            editCommentId={this.state.editCommentId}
                            commentData={this.state.commentData}
                            errors={this.state.errors}
                            isAs={this.props.isAs} 
                        />
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Comment List</h4>
                                    <table
                                        id="datatable-comment"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Username</th>
                                                <th>Comment</th>
                                                <th>In Response To</th>
                                                <th>Submitted On</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ListComment

import React, { Component } from 'react'

class NotificationVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { isAs, localUser } = this.props;
		const user = JSON.parse(localUser);

		return (
			<div className="notification-verify-container">
				{ ['admin', 'editor', 'author'].indexOf(isAs) <= -1 &&
				user && user.is_verified === "0" && (
					<div style={{
                        position: "absolute",
                        zIndex: "10",
                        width: "100%",
                        height: "100%",
                        background: "#d2cfcfb5",
                        display: "inline-block",
                    }} >
                        <div style={{
                            top: "46%",
                            position: "relative",
                            textAlign: "center",
                        }}>
                            <h2 style={{
                                color: "#CC0000"
                            }}>Your account must be verified by admin!</h2>
                        </div>
                    </div>
				)}
				{this.props.children}
			</div>
		)
	}
}

export default NotificationVerify

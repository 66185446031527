import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: 'AIzaSyBP_Zrt2mH7K9zyd0EIOsslEsHGHyudE4Y',
  authDomain: 'ivooxid-apps.firebaseapp.com',
  databaseURL: 'https://ivooxid-apps.firebaseio.com',
  projectId: 'ivooxid-apps',
  storageBucket: 'ivooxid-apps.appspot.com',
  messagingSenderId: '108894608348',
  appId: '1:108894608348:web:944fd5a2842b69a17c0d19',
  measurementId: 'G-V6DMD8LCDV'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
export const provider = new GoogleAuthProvider();
export const auth = getAuth(firebaseApp);

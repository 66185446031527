import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Api from '../services/api'

class Trending extends Component {
	constructor(props) {
		super(props)
		this.state = {
			datasTrending: [],
			loadingTrending: true
		}
	}

	componentDidMount() {
		this.fetchData()
	}

	fetchData() {
		this.setState({ loadingTrending: true }, () => {
			const params = `page=1&limit=5&keyword=&type=&schedule=0`
			const route = `/post/index?${params}`
			Api.get(route, null)
				.then(response => {
					this.setState({ datasTrending: response.data })
					this.setState({ loadingTrending: false })
				})
				.catch(error => {
					console.error(error)
					this.setState({ loadingTrending: false })
				})
		})
	}

	render() {
		const { datasTrending, loadingTrending } = this.state

		if (loadingTrending) return null
		return (
			<div className={window.outerWidth < 480 ? 'container' : ''}>
				<div className="trending-now trending-now--1">
                    <span className="trending-now__label">
                        <i className="ui-flash" />
                        <span className="trending-now__text d-lg-inline-block d-none">
                            Newsflash
                        </span>
                    </span>
					<div className="newsticker">
						<ul className="newsticker__list">
							{datasTrending.map(
								(datasTrending, datasTrendingIndex) => (
									<li
										className="newsticker__item"
										key={datasTrendingIndex}
									>
										<Link
											to={{
												pathname: `/${datasTrending.url}`,
												search: '?tag_from=yahoo'
											}}
											className="newsticker__item-url"
										>
											{datasTrending.title}
										</Link>
									</li>
								)
							)}
						</ul>
					</div>
					<div className="newsticker-buttons">
						<button
							className="newsticker-button newsticker-button--prev"
							id="newsticker-button--prev"
							aria-label="next article"
						>
							<i className="ui-arrow-left" />
						</button>
						<button
							className="newsticker-button newsticker-button--next"
							id="newsticker-button--next"
							aria-label="previous article"
						>
							<i className="ui-arrow-right" />
						</button>
					</div>
				</div>
			</div>
		)
	}
}

export default Trending

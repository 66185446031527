import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { IsSSR } from '../../helpers/isSSR';

class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.isSSR = IsSSR();
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container">
          <ol className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to="/" className="breadcrumbs__url">
                Home
              </Link>
            </li>
            <li className="breadcrumbs__item breadcrumbs__item--current">
              Disclaimer
            </li>
          </ol>
        </div>

        <div className="main-container container" id="main-container">
          <h1 className="page-title">Disclaimer</h1>
          <div className="row">
            <div className="col-lg-8 mb-72">
              <div className="content-box">
                <p>
                  Seluruh layanan yang diberikan mengikuti aturan main yang
                  berlaku dan ditetapkan oleh ivooxid.
                </p>

                <p>
                  <strong>Pasal Sanggahan (Disclaimer):</strong>
                </p>

                <p>
                  ivooxid tidak bertanggung-jawab atas tidak tersampaikannya
                  data/informasi yang disampaikan oleh pembaca melalui berbagai
                  jenis saluran komunikasi (e-mail, sms, online form) karena
                  faktor kesalahan teknis yang tidak diduga-duga sebelumnya
                </p>

                <p>
                  ivooxid berhak untuk memuat , tidak memuat, mengedit, dan/atau
                  menghapus data/informasi yang disampaikan oleh pembaca.
                </p>

                <p>
                  Data dan/atau informasi yang tersedia di ivooxid hanya sebagai
                  rujukan/referensi belaka, dan tidak diharapkan untuk tujuan
                  perdagangan saham, transaksi keuangan/bisnis maupun transaksi
                  lainnya. Walau berbagai upaya telah dilakukan untuk
                  menampilkan data dan/atau informasi seakurat mungkin, ivooxid
                  dan semua mitra yang menyediakan data dan informasi, termasuk
                  para pengelola halaman konsultasi, tidak bertanggung jawab
                  atas segala kesalahan dan keterlambatan memperbarui data atau
                  informasi, atau segala kerugian yang timbul karena tindakan
                  yang berkaitan dengan penggunaan data/informasi yang disajikan
                  ivooxid.
                </p>

                <p>
                  <strong>Kebijakan Konten Dewasa</strong>
                </p>

                <p>
                  Kebijakan ini mengatur semua konten dewasa yang terbit di
                  ivooxid. Artikel yang mengandung konten dewasa adalah yang
                  memuat tentang organ-organ genital dan mengandung unsur
                  seksualitas. Untuk bisa mengakses konten dewasa ivooxid, maka
                  pembaca harus berusia 18 tahun ke atas dan mengikuti ketentuan
                  berikut ini :
                </p>

                <ul>
                  <li>1. Pembaca harus login untuk membuat akun di ivooxid.</li>
                  <li>
                    2. Setelah login, pembaca harus mengisi dan mengkonfirmasi
                    tanggal lahir. Data yang diberikan harus sebenarnya, ivooxid
                    tidak bertanggung jawab atas data yang tidak benar.
                  </li>
                  <li>
                    3. Sesi aktif login ivooxid berlaku untuk dua minggu. Saat
                    sesi aktif berakhir, user akan diminta login ivooxid kembali
                    untuk bisa membaca artikel konten dewasa.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Disclaimer;

import LoadingOverlay from 'react-loading-overlay'
import React, { Component } from 'react'
import Reaptcha from 'reaptcha'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Link } from 'react-router-dom'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import {
    apiUrl,
    assetsUrl,
    keyToken,
    keyUser,
    sitekeyReaptcha
} from '../../../config'

import DashboardSideBar from '../../../components/dashboard/dashboardSideBar'
import ReactQuillWithImage from '../../../components/textarea/ReactQuillWithMedia'
import ModalMedia from '../../../components/modal/ModalMedia'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam'
import Toast from '../../../components/Toast'

import { scriptsHideAds, scriptsUnHideAds } from '../../../utils/scripts'

class EditPollingForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyword: '',
            deleteModal: false,
            text: '',
            loading: false,
            loadingImage: false,
            min_answer: 2,
            max_answer: 35,
            total_answer: 2,
            loading: false,
            polling_answers: [],
            answer: [],
            selectedImages: [],
            selectedImagesModal: [],
            imageText: '',
            images: [],
            imagePagination: {
                total_found: 1,
                limit: 9,
                current_page: 1,
                total_page: 1
            },
            tags: [],
            newPollingData: {
                title: '',
                question: '',
                expired_at: '2020-02-22 00:00:00',
                state_id: 3,
                medias: '',
                is_featured: 0
            },
            errors: {},
            addImagePostModal: false,
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true,
            verified: false,
            caption: ''
        }
        this.onChangePost = this.onChangePost.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onChangeDisclimer = this.onChangeDisclimer.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.addAnswer = this.addAnswer.bind(this)
        this.removeAnswer = this.removeAnswer.bind(this)
        this.changeAnswer = this.changeAnswer.bind(this)
        this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this)
        this.getImagePosts = this.getImagePosts.bind(this)
        this.deleteData = this.deleteData.bind(this)
        this.deleteRowTable = this.deleteRowTable.bind(this)
        this.addImagePost = this.addImagePost.bind(this)
        this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.onChangeKeyword = this.onChangeKeyword.bind(this)
        this.setParameterErrors = this.setParameterErrors.bind(this)
        this.onChangeCaption = this.onChangeCaption.bind(this)
        this.isSSR = IsSSR()
        this.token = null
        this.user = null
        if (!this.isSSR) this.token = getStorageItem(keyToken)
        if (!this.isSSR) this.user = getStorageItem(keyUser)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0)
        if (!this.token) return this.props.history.push(`/auth`)
        this.getRoles().then(r => console.log('getRoles reload'))
        setTimeout(() => this.getDetailPolling())
        setTimeout(() => {
            scriptsHideAds(() => {
                console.log('[scriptsHideAds.js]')
            })
        }, 600)
    }

    componentWillUnmount() {
        scriptsUnHideAds(() => {
            console.log('[scriptsUnhideAds.js]')
        })
    }

    getIndexOf(a, v) {
        var l = a.length
        for (var k = 0; k < l; k++) {
            if (a[k].name == v) {
                return true
            }
        }
        return false
    }

    async getRoles() {
        this.setState({ loadingRole: true })

        const user = JSON.parse(this.user)
        var isAdmin = await this.getIndexOf(user.roles, 'admin')
        this.setState({ isAdmin: isAdmin })

        var isEditor = await this.getIndexOf(user.roles, 'editor')
        this.setState({ isEditor: isEditor })

        var isAuthor = await this.getIndexOf(user.roles, 'author')
        this.setState({ isAuthor: isAuthor })

        var isContributor = await this.getIndexOf(user.roles, 'contributor')
        this.setState({ isContributor: isContributor })

        if (isAdmin) this.setState({ isAs: 'admin' })
        else if (isEditor) this.setState({ isAs: 'editor' })
        else if (isAuthor) this.setState({ isAs: 'author' })
        else if (isContributor) this.setState({ isAs: 'contributor' })
        if (['admin', 'editor', 'author'].indexOf(this.state.isAs) > -1) {
            this.setState({ verified: true })
        }

        this.setState({ loadingRole: false })
    }

    onChangeKeyword(e) {
        const { name, value } = e.target
        this.setState({ keyword: value })
    }

    onChangeCaption(e) {
        const { name, value } = e.target
        this.setState({ caption: value })
    }

    handleFileUpload(e) {
        const file = e.target.files[0]
        if (file) {
            this.setState({ loading: true })
            const formData = new FormData()
            let arrayName = file.name.split('.')
            arrayName.pop()
            let nameImage = arrayName.join(' ')

            formData.append('media', file)
            formData.append('meta_tag', nameImage.substring(0, 80))
            formData.append('media_type', 'image')
            formData.append('caption', this.state.caption)
            const route = `/media/create`
            fetch(`${apiUrl}` + route, {
                method: 'POST',
                headers: new Headers({
                    'x-access-token': this.token
                }),
                body: formData
            })
                .then(res => res.json())
                .then(response => {
                    this.setState({ loading: false })
                    if (response.status && response.status === 'success') {
                        let images = []
                        images[0] = response.data.id
                        this.addImagePost(images, response.data.url)
                    } else {
                        CheckErrorWithParam(response, this.setParameterErrors)
                    }
                })
                .catch(err => {
                    this.setState({ loading: false })
                    CheckErrorWithParam(err, this.setParameterErrors)
                })
        }
    }

    deleteRowTable() {
        this.setState({ loading: true })
        const route = `/polling/delete/` + this.state.newPollingData.id
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    Toast(response.message, 'success')
                    this.props.history.push('/admin/polling')
                } else {
                    CheckError(response)
                }
            })
            .catch(err => {
                CheckError(err)
            })
    }

    toggleDeleteDataModal() {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }

    deleteData() {
        this.setState({
            deleteModal: !this.state.deleteModal
        })
    }

    setSelectedImagesModal(selectedImagesModal) {
        this.setState({ selectedImagesModal })
    }

    toggleAddImagePostModal() {
        if (!this.state.addImagePostModal) {
            this.getImagePosts()
        }
        let selectedImages = this.state.selectedImages
        this.setState({
            addImagePostModal: !this.state.addImagePostModal,
            selectedImagesModal: selectedImages
        })
    }

    addImagePost(images, text, caption = '') {
        this.setState({ selectedImages: images })
        this.setState({ imageText: text })
        this.setState({ caption: caption })
    }

    handleChange(value) {
        this.setState({ text: value })
    }

    getDetailPolling() {
        const { match } = this.props
        var id = match.params.id
        let route = `/polling/${id}`
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.setState({ loading: false })
                    let mediasArr = []
                    response.data.mediaables.map(mediaable => {
                        if (mediaable.media) {
                            mediasArr.push(mediaable.media.id)
                        }
                    })
                    let medias = mediasArr.join(',')
                    let imageText =
                        mediasArr.length > 0 && response.data.mediaables[0]
                            ? response.data.mediaables[0].media.url
                            : ''
                    delete response.data.state
                    delete response.data.user
                    delete response.data.categoryables
                    delete response.data.mediaables
                    delete response.data.mediaable
                    delete response.data.tagables
                    delete response.data.schedule
                    delete response.data.polling_choices

                    const polling_answers = response.data.polling_answers
                    const answer = polling_answers.map(answer_item => {
                        return answer_item.answer
                    })
                    delete response.data.polling_answers
                    const newPollingData = response.data
                    newPollingData.medias = medias
                    newPollingData.expired_at = '2020-02-22 00:00:00'
                    this.setState({
                        imageText,
                        newPollingData,
                        answer,
                        loading: false,
                        selectedImages: mediasArr,
                        text: newPollingData.question,
                        polling_answers: polling_answers,
                        total_answer: polling_answers.length
                    })
                } else {
                    this.setState({ loading: false })
                    CheckError(response)
                    this.props.history.push('/admin/polling')
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                CheckError(error)
                this.props.history.push('/admin/polling')
            })
    }

    addAnswer() {
        if (this.state.total_answer < this.state.max_answer) {
            const answer = this.state.answer
            answer.push('')
            this.setState({ total_answer: this.state.total_answer + 1, answer })
        }
    }

    removeAnswer() {
        if (this.state.total_answer > this.state.min_answer) {
            const answer = this.state.answer
            answer.pop()
            this.setState({ total_answer: this.state.total_answer - 1, answer })
        }
    }

    onChangeDisclimer(e) {
        const { name, value, checked } = e.target
        if (checked) {
            this.setState({ [name]: 1 })
        } else {
            this.setState({ [name]: 0 })
        }
    }

    onChangePost(e) {
        const { name, value } = e.target
        let { newPollingData } = this.state
        newPollingData[name] = value
        this.setState({ newPollingData })
    }

    getImagePosts(page = 1, limit = 9) {
        this.setState({ loadingImage: true })
        const route =
            `/media/index?type=image&page=` +
            page +
            `&limit=` +
            limit +
            `&keyword=` +
            this.state.keyword
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.setState({
                        images: response.data,
                        imagePagination: response.pagination,
                        loadingImage: false
                    })
                } else {
                    CheckError(response)
                }
            })
            .catch(err => {
                this.setState({ loadingImage: false })
                CheckError(err)
            })
    }

    handleSubmit(e) {
        e.preventDefault()
        if (this.state.total_answer < this.state.min_answer) {
            Toast(`Jumlah jawaban harus ${this.state.min_answer} atau lebih`)
        } else if (this.state.total_answer > this.state.max_answer) {
            Toast(`Jumlah jawaban harus ${this.state.max_answer} atau kurang`)
        } else {
            const { match } = this.props
            var id = match.params.id
            var state_id = 3
            if (['contributor'].indexOf(this.state.isAs) > -1) state_id = 2
            let newPollingData = this.state.newPollingData
            newPollingData.question = this.state.text
            newPollingData.state_id = state_id
            newPollingData.medias = this.state.selectedImages.join(',')
            this.setState(
                {
                    loading: true,
                    newPollingData
                },
                () => {
                    const params = newPollingData
                    const route = `/polling/update/${id}`
                    Api.put(route, params, this.token)
                        .then(response => {
                            if (
                                response.status &&
                                response.status === 'success'
                            ) {
                                this.checkPollingAnswer(response.data.id)
                                this.setState({ loading: false })
                                Toast(response.message, 'success')
                                this.props.history.push('/admin/polling')
                            } else {
                                this.setState({ loading: false })
                                CheckErrorWithParam(
                                    response,
                                    this.setParameterErrors
                                )
                            }
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            CheckErrorWithParam(error, this.setParameterErrors)
                        })
                }
            )
        }
    }

    checkPollingAnswer(polling_id) {
        let max =
            this.state.polling_answers.length > this.state.answer.length
                ? this.state.polling_answers.length
                : this.state.answer.length
        for (var i = 0; i < max; i++) {
            if (
                this.state.polling_answers[i] &&
                this.state.answer[i] &&
                this.state.polling_answers[i].answer != this.state.answer[i]
            ) {
                this.updatePollingAnswer(
                    this.state.polling_answers[i].id,
                    polling_id,
                    this.state.answer[i]
                )
            } else if (!this.state.polling_answers[i] && this.state.answer[i]) {
                this.savePollingAnswer(polling_id, i)
            } else if (this.state.polling_answers[i] && !this.state.answer[i]) {
                this.deletePollingAnswer(this.state.polling_answers[i].id, i)
            }
        }
    }

    savePollingAnswer(polling_id, index) {
        const params = {
            polling_id: polling_id,
            answer: this.state.answer[index]
        }
        const route = `/polling_answer/create`
        Api.post(route, params, this.token)
            .then(response => {
                if (response.status && response.status !== 'success') {
                    CheckError(response)
                }
            })
            .catch(error => {
                CheckError(error)
            })
    }

    deletePollingAnswer(id) {
        const route = `/polling_answer/delete/${id}`
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status != 'success') {
                    CheckError(response)
                }
            })
            .catch(error => {
                CheckError(error)
            })
    }

    updatePollingAnswer(id, polling_id, answer) {
        const params = {
            polling_id: polling_id,
            answer: answer
        }
        const route = `/polling_answer/update/${id}`
        Api.put(route, params, this.token)
            .then(response => {
                if (response.status && response.status != 'success') {
                    CheckError(response)
                }
            })
            .catch(error => {
                CheckError(error)
            })
    }

    changeAnswer(e) {
        const { value } = e.target
        const answer = this.state.answer
        answer[e.target.getAttribute('data-id')] = value
        this.setState({ answer })
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    render() {
        const { isAs, loadingRole } = this.state

        let answer = this.state.answer.map((answer_item, index) => {
            return (
                <div key={'answer-' + (index + 1)} className="form-group">
                    <label htmlFor={'answer-' + (index + 1)}>
                        {'Jawaban ' + (index + 1)}
                    </label>
                    <input
                        name={'answer-' + (index + 1)}
                        data-id={index}
                        placeholder=""
                        id={'answer-' + (index + 1)}
                        value={answer_item}
                        type="text"
                        onChange={this.changeAnswer.bind(this)}
                    />
                </div>
            )
        })
        let imageContainer
        if (this.state.selectedImages.length > 1) {
            imageContainer = (
                <div style={{ paddingBottom: '10px' }}>
                    {this.state.selectedImages.length} Images
                </div>
            )
        } else if (this.state.selectedImages.length === 1) {
            imageContainer = (
                <div style={{ paddingBottom: '10px' }}>
                    <div
                        style={{
                            height: '150px',
                            width: '100%',
                            backgroundImage: `url(${this.state.imageText}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    ></div>
                </div>
            )
        }

        if (loadingRole) return null
        return (
            <div>
                <div className="main-container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link
                                to="/admin/dashboard"
                                className="breadcrumbs__url"
                            >
                                Home
                            </Link>
                        </li>
                        <li className="breadcrumbs__item">
                            <Link
                                to="/admin/polling"
                                className="breadcrumbs__url"
                            >
                                Polling
                            </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Edit
                        </li>
                    </ul>
                </div>
                <div className="main-container" id="main-container">
                    <div className="blog__content mb-72">
                        <h1 className="page-title">Polling</h1>
                        <div className="row card-row">
                            <DashboardSideBar
                                isAs={isAs}
                                localUser={this.user}
                                {...this.props}
                            />
                            <div className="col-lg-9 col-12">
                                <div className="entry card card-input">
                                    <LoadingOverlay
                                        active={this.state.loading}
                                        spinner
                                        text="Please Wait..."
                                    >
                                        <Modal
                                            isOpen={this.state.deleteModal}
                                            toggle={this.toggleDeleteDataModal.bind(
                                                this
                                            )}
                                            className={this.props.className}
                                        >
                                            <ModalHeader
                                                toggle={this.toggleDeleteDataModal.bind(
                                                    this
                                                )}
                                            >
                                                Konfirmasi
                                            </ModalHeader>
                                            <ModalBody>
                                                Apakah ingin menghapus post ini?
                                                id :{' '}
                                                {this.state.newPollingData.id}
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    color="primary"
                                                    onClick={this.deleteRowTable.bind()}
                                                >
                                                    Hapus
                                                </Button>{' '}
                                                <Button
                                                    color="secondary"
                                                    onClick={this.toggleDeleteDataModal.bind(
                                                        this
                                                    )}
                                                >
                                                    Batalkan
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                        <ModalMedia
                                            type={'post'}
                                            images={this.state.images}
                                            selectedImagesModal={
                                                this.state.selectedImagesModal
                                            }
                                            selectedImages={
                                                this.state.selectedImages
                                            }
                                            pagination={
                                                this.state.imagePagination
                                            }
                                            addImagePostModal={
                                                this.state.addImagePostModal
                                            }
                                            toggleAddImagePostModal={
                                                this.toggleAddImagePostModal
                                            }
                                            loadingImage={
                                                this.state.loadingImage
                                            }
                                            addImagePost={this.addImagePost}
                                            refreshData={this.getImagePosts}
                                            setSelectedImagesModal={
                                                this.setSelectedImagesModal
                                            }
                                            keyword={this.state.keyword}
                                            onChangeKeyword={
                                                this.onChangeKeyword
                                            }
                                        />
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="heading-title">
                                                    Edit Polling
                                                </h4>
                                                <form
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <div className="form-group">
                                                        <Link
                                                            to={`/polling/${this.state.newPollingData.id}/${this.state.newPollingData.url}`}
                                                        >
                                                            <span className="btn btn-md btn-color mt-3 mb-3">
                                                                view
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="title">
                                                            Judul{' '}
                                                            <span className="text-red">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Tulis Judul di Sini"
                                                            name="title"
                                                            required
                                                            value={
                                                                this.state
                                                                    .newPollingData
                                                                    .title
                                                            }
                                                            onChange={
                                                                this
                                                                    .onChangePost
                                                            }
                                                            style={{
                                                                marginBottom:
                                                                    '0px'
                                                            }}
                                                        />
                                                        {this.state.errors
                                                            .title &&
                                                            this.state.errors
                                                                .title
                                                                .message && (
                                                                <div
                                                                    className="help-block"
                                                                    style={{
                                                                        backgroundColor:
                                                                            'red',
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10
                                                                    }}
                                                                >
                                                                    <small
                                                                        style={{
                                                                            color:
                                                                                'white'
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .errors
                                                                                .title
                                                                                .message
                                                                        }
                                                                    </small>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="media_id">
                                                            Image{' '}
                                                            <span className="text-red">
                                                                *
                                                            </span>
                                                        </label>
                                                        {imageContainer}
                                                        <div>
                                                            <div className="form-group">
                                                                <label htmlFor="caption">
                                                                    Caption(Untuk
                                                                    Upload)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Caption"
                                                                    name="caption"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .caption
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onChangeCaption
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            '0px'
                                                                    }}
                                                                />
                                                                {this.state
                                                                    .errors
                                                                    .caption &&
                                                                    this.state
                                                                        .errors
                                                                        .caption
                                                                        .message && (
                                                                        <div
                                                                            className="help-block"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    'red',
                                                                                paddingLeft: 10,
                                                                                paddingRight: 10
                                                                            }}
                                                                        >
                                                                            <small
                                                                                style={{
                                                                                    color:
                                                                                        'white'
                                                                                }}
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .errors
                                                                                        .caption
                                                                                        .message
                                                                                }
                                                                            </small>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <label
                                                                className="btn btn btn-md btn-color btn-file"
                                                                onClick={
                                                                    this
                                                                        .toggleAddImagePostModal
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '10px'
                                                                }}
                                                            >
                                                                Browse
                                                            </label>
                                                            <label className="btn btn btn-md btn-color btn-file">
                                                                Upload
                                                                <input
                                                                    type="file"
                                                                    onChange={
                                                                        this
                                                                            .handleFileUpload
                                                                    }
                                                                />
                                                            </label>
                                                        </div>
                                                        <p>
                                                            The image must be a
                                                            file of type: jpg,
                                                            jpeg, png, ico
                                                        </p>
                                                        {this.state.errors
                                                            .medias &&
                                                            this.state.errors
                                                                .medias
                                                                .message && (
                                                                <div
                                                                    className="help-block"
                                                                    style={{
                                                                        backgroundColor:
                                                                            'red',
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10
                                                                    }}
                                                                >
                                                                    <small
                                                                        style={{
                                                                            color:
                                                                                'white'
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .errors
                                                                                .medias
                                                                                .message
                                                                        }
                                                                    </small>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Kutipan</label>
                                                        <ReactQuillWithImage
                                                            name="question"
                                                            value={
                                                                this.state.text
                                                            }
                                                            handleChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            token={this.token}
                                                        />
                                                        {this.state.errors
                                                            .question &&
                                                            this.state.errors
                                                                .question
                                                                .message && (
                                                                <div
                                                                    className="help-block"
                                                                    style={{
                                                                        backgroundColor:
                                                                            'red',
                                                                        paddingLeft: 10,
                                                                        paddingRight: 10
                                                                    }}
                                                                >
                                                                    <small
                                                                        style={{
                                                                            color:
                                                                                'white'
                                                                        }}
                                                                    >
                                                                        {
                                                                            this
                                                                                .state
                                                                                .errors
                                                                                .question
                                                                                .message
                                                                        }
                                                                    </small>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div
                                                        className="mt-3"
                                                        id="exampleAccordion"
                                                        data-children=".item"
                                                    >
                                                        <div className="item">
                                                            {answer}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="mt-3"
                                                        id="exampleAccordion"
                                                        data-children=".item"
                                                        style={{
                                                            textAlign: 'right'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display:
                                                                    'inline-block'
                                                            }}
                                                        >
                                                            <button
                                                                className="btn btn-md btn-color btn-button"
                                                                onClick={() =>
                                                                    this.addAnswer()
                                                                }
                                                                type="button"
                                                                style={{
                                                                    borderRadius:
                                                                        '100%',
                                                                    marginRight:
                                                                        '10px',
                                                                    padding:
                                                                        '4px 15px'
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '25px'
                                                                    }}
                                                                >
                                                                    +
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="btn btn-md btn-color btn-button"
                                                                onClick={() =>
                                                                    this.removeAnswer()
                                                                }
                                                                type="button"
                                                                style={{
                                                                    borderRadius:
                                                                        '100%',
                                                                    padding:
                                                                        '4px 17px'
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize:
                                                                            '25px'
                                                                    }}
                                                                >
                                                                    -
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginBottom: 16
                                                        }}
                                                    >
                                                        {[
                                                            'admin',
                                                            'editor',
                                                            'author'
                                                        ].indexOf(
                                                            this.state.isAs
                                                        ) <= -1 && (
                                                            <Reaptcha
                                                                sitekey={
                                                                    sitekeyReaptcha
                                                                }
                                                                onVerify={
                                                                    this
                                                                        .onVerify
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            style={{
                                                                marginRight:
                                                                    '10px'
                                                            }}
                                                            onClick={() =>
                                                                this.deleteData()
                                                            }
                                                            className="btn btn-md btn-color btn-button"
                                                        >
                                                            Delete
                                                        </button>
                                                        <button
                                                            disabled={
                                                                !this.state
                                                                    .verified ||
                                                                this.state
                                                                    .loading
                                                            }
                                                            type="submit"
                                                            className="btn btn-md btn-color btn-button"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditPollingForm

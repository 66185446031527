import React, { Component } from 'react'
import moment from 'moment'

import { apiUrl } from '../../../../config'

import SearchPost from './search'
import CheckError from '../../../../utils/admin/checkError'
import NotificationVerify from '../../../../utils/admin/notificationVerify'
import Toast from '../../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListReportContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestData : {},
            searchData: {
                title: "",
                createdAtMonth: '',
                createdAtYear: '',
                limit: 5
            },
            createdDate: "",
            urlDownloadExcel: "",
        }
        this.refreshDatas = this.refreshDatas.bind(this)
        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show()
    }

    componentDidMount() {
        const user = JSON.parse(this.props.localUser);
        setTimeout(() => this.loadDatas(user.id))
    }

    onChangeInput(name, value) {
        this.setState({ [name] : value });
    }

    onChangeSearch(e) {
        const { name, value } = e.target
        let { searchData } = this.state;
        searchData[name] = value;
        if (name === "limit") {
            this.setState({ searchData });
            if(value !== 'All') {
                $('#datatable-report-contact').DataTable().page.len(value)
            } else {
                $('#datatable-report-contact').DataTable().page.len(-1)
            }
        } else {
            this.setState({ searchData });
        }
    }
    
    handleSubmit(e) {
        const {requestData} = this.state
        const route = '/report/contacts?sort_field=' + (requestData.sort_field ? requestData.sort_field : '') +
            '&sort_by=' + (requestData.sort_by ? requestData.sort_by : '') +
            '&page=' + (requestData.page ? requestData.page : '') +
            '&limit=' + (requestData.limit ? requestData.limit : '') +
            '&keyword=' + (requestData.keyword ? requestData.keyword : '') +
            '&createdAtMonth=' + (requestData.createdAtMonth ? requestData.createdAtMonth : '') +
            '&createdAtYear=' + (requestData.createdAtYear ? requestData.createdAtYear : '');
        const url = `${apiUrl}${route}`;
        this.setState({urlDownloadExcel : url})
        return true;
    }

    loadDatas(user_id) {
        const {
            isAs
        } = this.props

        var _ = this
        var forTitle = this.props.title
        const route = `/contact/index`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var datatableDraw = 1
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        var sort_field = 0;
        var sort_by = searchParams.get('sort_by') && searchParams.get('sort_by') == 'ASC' ? 'asc' : 'desc';
        
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[sort_field, sort_by]],
            pageLength: limit,
            displayStart: start,
            lengthChange: false,
            searching: false,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.props.token
                },
                data: function (data) {
                    var requestData = {}
                    datatableDraw = data.draw;

                    if (data.order && data.order[0] && data.order[0].dir === "desc") {
                        requestData.sort_by = "DESC";
                    } else {
                        requestData.sort_by = "ASC";
                    }

                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = _.state.searchData.title
                    requestData.createdAtMonth = _.state.searchData.createdAtMonth
                    requestData.createdAtYear = _.state.searchData.createdAtYear
                    _.setState({requestData});
                        
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                {
                    data: null,
                    name: 'id',
                    render: function (data) {
                        var id = data.id
                        return id;
                    }

                },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        var name = data.guest ? data.guest.name : '';
                        return name;
                    }
                },
                {
                    data: null,
                    name: 'email',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        var email = data.guest ? data.guest.email : '';
                        return email;
                    }
                },
                {
                    data: null,
                    name: 'phone',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        return data.phone;
                    }
                },
                {
                    data: null,
                    name: 'subject',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        return data.subject;
                    }
                },
                {
                    data: null,
                    name: 'message',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        var message = data.message ? data.message : '';
                        message = message.length <= 20 ? message : message.substring(0, 20) + '...';
                        return message;
                    }
                },
                {
                    data: null,
                    name: 'createdAt',
                    render: function (data) {
                        var createdDate = moment(data.createdAt ).format('DD/MM/YYYY HH:mm')
                        return createdDate
                    }
                }
            ]
        })
    }

    refreshDatas() {
        $('#datatable-report-contact')
            .DataTable()
            .ajax.reload()
    }

    render() {
        return (
            <div className="col-lg-9 col-12">
                <NotificationVerify isAs={this.props.isAs} localUser={this.props.localUser}>
                    <div className="entry card card-input">
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">
                                        {this.props.title} List
                                    </h4>
                                    <div>
                                        <SearchPost
                                            searchData={this.state.searchData}
                                            createdDate={this.state.createdDate}
                                            searchData={this.state.searchData}
                                            isAs={this.props.isAs}
                                            token={this.props.token}
                                            title={this.props.title}
                                            location={this.props.location}
                                            onChangeSearch={this.onChangeSearch}
                                            refreshDatas={this.refreshDatas}
                                            onChangeInput={this.onChangeInput}
                                        />
                                    </div>
                                    <table
                                        id="datatable-report-contact"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 text-right">
                                <div className="form-group">
                                    <form action={this.state.urlDownloadExcel} method="post" onSubmit={this.handleSubmit}> 
                                        <input type="hidden" name="x-access-token" value={this.props.token} />
                                        <button
                                            style={{ marginTop: "27px" }}
                                            className="btn btn-md btn-color btn-button"
                                        >
                                            Download Excel
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </NotificationVerify>
            </div>
        )
    }
}

export default ListReportContact

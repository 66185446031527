import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import Reaptcha from 'reaptcha'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { keyToken, keyUser, sitekeyReaptcha } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkErrorWithParam'
import Toast from '../../../components/Toast'

class FormComment extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            verified: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() { 
        if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
            this.setState({verified: true});
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.setLoadingContainer(true)
        this.props.setParameterErrors({})
        if (this.props.editCommentStatus) {
            this.doUpdateComment()
        } else {
            this.doCreateComment()
        }
    }

    doCreateComment() {
        const params = {
            content: this.props.commentData.content,
            commentable_id: this.props.commentData.commentable_id,
            commentable_type: this.props.commentData.commentable_type
        }
        const route = `/comment/create`
        Api.post(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false)
                if (response.status && response.status === 'success') {
                    this.props.refreshComments()
                    this.props.refreshEditData()
                    Toast(response.message, 'success')
                } else {
                    CheckError(response, this.props.setParameterErrors)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false)
                CheckError(err, this.props.setParameterErrors)
            })
    }

    doUpdateComment() {
        const params = {
            content: this.props.commentData.content,
            commentable_id: this.props.commentData.commentable_id,
            commentable_type: this.props.commentData.commentable_type
        }
        const route = `/comment/update/` + this.props.editCommentId
        Api.put(route, params, this.token)
            .then(response => {
                console.log()
                this.props.setLoadingContainer(false)
                if (response.status && response.status === 'success') {
                    this.props.refreshComments()
                    this.props.refreshEditData()
                    Toast(response.message, 'success')
                } else {
                    CheckError(response, this.props.setParameterErrors)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false)
                CheckError(err, this.props.setParameterErrors)
            })
    }

    cancel(e) {
        e.preventDefault()
        this.props.refreshEditData()
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    render() {
        let headerContent = null
        let buttonContent = null
        if (this.props.editCommentStatus) {
            headerContent = (
                <h4 className="heading-title">
                    Edit Comment Id {this.props.editCommentId}
                </h4>
            )
            buttonContent = (
                <div className="form-group">
                    <button
                        key="comment-btn-cancel"
                        type="button"
                        onClick={this.cancel}
                        className="btn btn-md btn-button mr-3"
                    >
                        Cancel
                    </button>
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="comment-btn-edit"
                        className="btn btn-md btn-color btn-button"
                    >
                        Save
                    </button>
                </div>
            )
        } else {
            headerContent = <h4 className="heading-title">Add New Comment</h4>
            buttonContent = (
                <div className="form-group">
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="comment-btn-add"
                        className="btn btn-md btn-color btn-button"
                    >
                        Add Comment
                    </button>
                </div>
            )
        }
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text="Please Wait..."
            >
                {this.props.editCommentStatus && (
                    <div>
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {headerContent}
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">
                                            Content Comment{' '}
                                            <span className="text-red">*</span>
                                        </label>
                                        <textarea
                                            name="content"
                                            placeholder=""
                                            id="content"
                                            type="text"
                                            onChange={
                                                this.props.onChangeComment
                                            }
                                            rows="7"
                                            value={
                                                this.props.commentData.content
                                            }
                                        ></textarea>
                                        {this.props.errors.content &&
                                            this.props.errors.content
                                                .message && (
                                                <div
                                                    className="help-block"
                                                    style={{
                                                        backgroundColor: 'red',
                                                        paddingLeft: 10,
                                                        paddingRight: 10
                                                    }}
                                                >
                                                    <small
                                                        style={{
                                                            color: 'white'
                                                        }}
                                                    >
                                                        {
                                                            this.props.errors
                                                                .content.message
                                                        }
                                                    </small>
                                                </div>
                                            )}
                                    </div>
                                    <div style={{ marginBottom: 16 }}>
                                        { (['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                            <Reaptcha
                                                sitekey={sitekeyReaptcha}
                                                onVerify={this.onVerify}
                                            />
                                        }
                                    </div>
                                    {buttonContent}
                                </form>
                            </div>
                        </div>
                        <div className="divider" />
                    </div>
                )}
            </LoadingOverlay>
        )
    }
}

export default FormComment
import React, { Component } from 'react';
import moment from 'moment';

class JadwalImsak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasImsak: [],
      date: moment().format('dddd, dd MMMM YYYY'),
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true }, () => {
      fetch('https://equran.id/api/v2/imsakiyah/jadwal', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ provinsi: 'DKI Jakarta', kabKota: 'Kota Jakarta' }),
      })
        .then((res) => res.json())
        .then((json) => {
          console.log('Jadwal Imsak');
          var date = moment().format('YYYY-MM-DD');
          console.log(json.data.data[date]);
          this.setState({ datasImsak: json.data.data[date] });
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const { date, datasImsak, loading } = this.state;

    if (loading) return null;
    return (
      <div className='heading-imsak-wrapper'>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='heading-imsak'>Jadwal Imsyak Jakarta dan Sekitarnya</h3>
            <h6>{datasImsak.tanggal}</h6>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='jadwal-imsak'>
              <div className='row'>
                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Imsyak</h5>
                    <p className='text-imsak'>{datasImsak.imsak}</p>
                  </div>
                </div>

                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Subuh</h5>
                    <p className='text-imsak'>{datasImsak.subuh}</p>
                  </div>
                </div>

                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Dzuhur</h5>
                    <p className='text-imsak'>{datasImsak.dzuhur}</p>
                  </div>
                </div>

                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Ashar</h5>
                    <p className='text-imsak'>{datasImsak.ashar}</p>
                  </div>
                </div>

                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Maghrib</h5>
                    <p className='text-imsak'>{datasImsak.maghrib}</p>
                  </div>
                </div>

                <div className='col-md-2 col-4 list-imsak'>
                  <div className='card-body-imsak'>
                    <h5 className='title-imsak'>Isya</h5>
                    <p className='text-imsak'>{datasImsak.isya}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default JadwalImsak;

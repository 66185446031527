import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SidebarDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {report_submenu : false};
		this.openReport = this.openReport.bind(this)

	}

	openReport(e) {
		e.preventDefault();
		console.log(this.state.report_submenu);
		this.setState({report_submenu : !this.state.report_submenu})
	}

	render() {
		const { isAs, localUser } = this.props;
		const user = JSON.parse(localUser);

		return (
			<div className="col-lg-3 col-12">
				<div className="entry card pb-5 p-3">
					<article>
						<div className="entry__body card__body nopadding">
							<div className="entry__header dashboard-menu">
								<div className="dashboard-profile text-center">
									<img
										src={'/assets/img/logo-ivoox.png'}
									/>
									<Link
										to="/admin/profile"
										className="btn btn-sm btn-dark mb-1 mt-3"
									>
										<span>Edit Profile</span>
									</Link>
								</div>
								<p style={{ fontSize: 12 }}>
									Hai, <b>{user ? user.name : 'ivoox user'}</b> as <b>{isAs}</b>
								</p>
								<hr />
								<ul>
									{[
										'admin',
										'editor',
										'author',
										'contributor'
									].indexOf(isAs) > -1 && (
											<li>
												<Link
													to="/admin/dashboard"
													className="nav__dropdown"
												>
													Dashboard
											</Link>
											</li>
										)}

									{['admin', 'editor', 'author'].indexOf(
										isAs
									) > -1 && (
											<li>
												<Link
													to="/admin/post"
													className="nav__dropdown"
												>
													Post
											</Link>
											</li>
										)}

									{[
										'admin',
										'editor',
										'author',
										'contributor'
									].indexOf(isAs) > -1 && (
											<li>
												<Link
													to="/admin/media"
													className="nav__dropdown"
												>
													Media
											</Link>
											</li>
										)}

									{['admin', 'editor', 'author'].indexOf(
										isAs
									) > -1 && (
											<li>
												<Link
													to="/admin/category"
													className="nav__dropdown"
												>
													Category
											</Link>
											</li>
										)}

									{['admin', 'editor', 'author'].indexOf(
										isAs
									) > -1 && (
											<li>
												<Link
													to="/admin/tag"
													className="nav__dropdown"
												>
													Tag
											</Link>
											</li>
										)}

									{['admin', 'editor', 'contributor'].indexOf(
										isAs
									) > -1 && (
											<li>
												<Link
													to="/admin/polling"
													className="nav__dropdown"
												>
													Polling
											</Link>
											</li>
										)}

									{['admin', 'editor'].indexOf(isAs) > -1 && (
										<li>
											<Link
												to="/admin/ads"
												className="nav__dropdown"
											>
												Ads
											</Link>
										</li>
									)}

									{[
										'admin',
										'editor',
										'contributor'
									].indexOf(isAs) > -1 && (
											<li>
												<Link
													to="/admin/nitizenJurnalism"
													className="nav__dropdown"
												>
													Nitizen Jurnalism
											</Link>
											</li>
										)}

									{[
										'admin',
										'editor'
									].indexOf(isAs) > -1 && (
											<li>
												<Link
													to="/admin/users"
													className="nav__dropdown"
												>
													Users {/* (X) */}
												</Link>
											</li>
										)}

									{['admin', 'editor'].indexOf(isAs) > -1 && (
										<li>
											<Link
												to="/admin/roles"
												className="nav__dropdown"
											>
												Roles
											</Link>
										</li>
									)}

									{['admin', 'editor'].indexOf(isAs) > -1 && (
										<li>
											<Link
												to="/admin/careerPosition"
												className="nav__dropdown"
											>
												Career Position
											</Link>
										</li>
									)}

									{[
										'admin',
										'editor',
										'author',
										'contributor'
									].indexOf(isAs) > -1 && (
											<li>
												<Link
													to="/admin/comments"
													className="nav__dropdown"
												>
													Comments
											</Link>
											</li>
										)}

									{['admin'].indexOf(isAs) > -1 && (
										<li>
											<Link
												to="/admin/activityLogs"
												className="nav__dropdown"
											>
												Activity Logs {/* (X) */}
											</Link>
										</li>
									)}

									{['admin'].indexOf(isAs) > -1 && (
										<li class="" onClick={this.openReport}>
											<a href="javascipt:void()" className=""> 
												<span class="nav-label">Report</span>
											</a>
											<button
												style={{
													top: "0",
													right: "5px",
													backgroundColor: "transparent",
													border: "none",
													color: "#54555E",
													fontSize: "12px",
													padding: "0",
													width: "46px",
													height: "46px",
													outline: "none",
													position : 'relative',
													transform: this.state.report_submenu ? "rotate(180deg)" : "rotate(0deg)",
													textAlign: "center"}}
											>
												<i className="ui-arrow-down" />
											</button>
											<ul 
												className="sidenav__menu-dropdown" 
												style={{background : 'white', display: this.state.report_submenu ? 'block' : 'none'}}
											>
												<li style={{padding: "0px"}}>
													<Link
														to={`/admin/report/posts`}
														className="sidenav__menu-url"
													>
														Post
													</Link>
												</li>
												<li style={{padding: "0px"}}>
													<Link
														to={`/admin/report/contacts`}
														className="sidenav__menu-url"
													>
														Contact
													</Link>
												</li>
												<li style={{padding: "0px"}}>
													<Link
														to={`/admin/report/careers`}
														className="sidenav__menu-url"
													>
														Career
													</Link>
												</li>
											</ul>
										</li>
									)}
								</ul>
							</div>
						</div>
					</article>
				</div>
			</div>
		)
	}
}

export default SidebarDashboard

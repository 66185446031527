import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Reaptcha from 'reaptcha'

import Toast from '../../components/Toast'
import { sitekeyReaptcha } from '../../config'

import { IsSSR } from '../../helpers/isSSR'

class Beriklan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fBName: '',
			fBDesc: '',
			submittedBeriklan: false,
			loadingSubmit: false,
			verified: false
		};

		this.onSubmitBeriklan = this.onSubmitBeriklan.bind(this);
		this.onChange = this.onChange.bind(this);
		this.isSSR = IsSSR();
	}

	componentDidMount() {
		if (!this.isSSR) window.scrollTo(0, 0)
	}

	onChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value })
	}

	onVerify = recaptchaResponse => {
		this.setState({
			verified: true
		})
	};

	onSubmitBeriklan(e) {
		e.preventDefault();

		this.setState({ submittedBeriklan: true });
		const { fBName, fBDesc } = this.state;
		if (fBName !== '' && fBDesc !== '') this.onSave()
	}

	onSave() {
		const { fBName, fBDesc } = this.state;
		Toast('Thanks', 'success')
	}

	render() {
		const { fBName, fBDesc, submittedBeriklan, loadingSubmit } = this.state;
		return (
			<div>
				<div className="container">
					<ul className="breadcrumbs">
						<li className="breadcrumbs__item">
							<Link to="/" className="breadcrumbs__url">
								Home
							</Link>
						</li>
						<li className="breadcrumbs__item breadcrumbs__item--current">
							Beriklan
						</li>
					</ul>
				</div>

				<div className="main-container container" id="main-container">
					<div className="blog__content mb-72">
						<h1 className="page-title">Beriklan</h1>
						<p>
							Untuk konsultasi tentang pemasangan banner dan
							berita di ivoox.id.
						</p>
						<p>
							silahkan hubungi ke email:{' '}
							<a
								href="mailto:Marketing@ivoox.id?Subject=Beriklan"
								target="_top"
							>
								Marketing@ivoox.id
							</a>
						</p>
					</div>
					<div>
						<form
							className="contact-form mt-30 mb-30"
							name="formContactUs"
							onSubmit={this.onSubmitBeriklan}
						>
							<div
								className="contact-name"
								style={{ marginBottom: 10 }}
							>
								<label htmlFor="name">
									Name{' '}
									<abbr title="required" className="required">
										*
									</abbr>
								</label>
								<input
									name="fBName"
									id="fBName"
									type="text"
									value={fBName}
									onChange={this.onChange}
									required="required"
								/>
							</div>
							<div
								className="contact-message"
								style={{ marginBottom: 10 }}
							>
								<label htmlFor="message">
									Message{' '}
									<abbr title="required" className="required">
										*
									</abbr>
								</label>
								<textarea
									id="fBDesc"
									name="fBDesc"
									rows="7"
									value={fBDesc}
									onChange={this.onChange}
									required="required"
								/>
							</div>
							<div style={{ marginBottom: 16 }}>
								<Reaptcha
									sitekey={sitekeyReaptcha}
									onVerify={this.onVerify}
								/>
							</div>
							<input
								type="submit"
								disabled={!this.state.verified}
								className="btn btn-lg btn-color btn-button"
								value="Submit"
								id="submit-message"
							/>
							<div id="msg" className="message" />
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Beriklan

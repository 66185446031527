import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getStorageItem, removeStorageItem } from '../helpers/storage'
import { IsSSR } from '../helpers/isSSR'

import { keyToken } from '../config'

class TopHedaer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.isSSR = IsSSR();
		this.token = null;
		if (!this.isSSR) this.token = getStorageItem(keyToken);
		this.doLogout = this.doLogout.bind(this);
	}

	doLogout() {
		if (!this.isSSR) removeStorageItem(keyToken).then(r => console.log('remove token'));
		this.refreshPage()
	}

	refreshPage() {
		if (!this.isSSR) window.location.reload()
	}

	render() {
		const { loading, datas } = this.props;

		if (loading) return null;
		return (
			<div className="container topbar-ivoox-container">
				<div className="flex-parent">
					<div className="flex-child" id="topbar-ivoox">
						{/* <div className="ads-top">
							<a
								href=""
							>
								<img
									src="https://ivooxs3.s3.ap-southeast-1.amazonaws.com/media_public/1566290306843_upload_36d2468c950001049e98aab00d5fd920.png"
									style={{ width: '100%' }}
								/>
							</a>
						</div> */}

						{window.outerWidth > 480 &&
							datas &&
							datas['TopLock'] && (
								<div className="ads-top">
									{datas['TopLock'].type == 'media' &&
										datas['TopLock'].media &&
										datas['TopLock'].media.media_type ===
										'image' && (
											<a
												href={
													datas['TopLock'].url !==
														''
														? datas['TopLock']
															.url
														: '#'
												}
											>
												<img
													src={
														datas['TopLock']
															? datas[
																'TopLock'
															].media.url
															: ''
													}
													style={{ width: '100%' }}
												/>
											</a>
										)}
									{datas['TopLock'].type == 'media' &&
										datas['TopLock'].media &&
										datas['TopLock'].media.media_type ===
										'video' && (
											<video
												width="100%"
												controls={false}
												autoPlay
												loop
											>
												<source
													src={
														datas['TopLock']
															.media.url
													}
													type={
														datas['TopLock']
															.media.file_type
													}
												/>
												Your browser does not support
												the video tag.
									</video>
										)}
									{datas['TopLock'].type == 'adsense' &&
										datas['TopLock'].adsense && (
											<p
												dangerouslySetInnerHTML={{
													__html:
														datas['TopLock']
															.adsense
												}}
											/>
										)}
								</div>
							)}

						<div className="row d-flex">
							<div className="col-6" id="topbar-date">
								<p style={{ fontSize: 12 }}>
									{moment().format('LL')}
								</p>
							</div>
							<div className="col-6">
								<div className="row">
									<div className="socials top-bar-socmed socials--nobase socials--dark justify-content-end col-8">
										<a
											className="social social-facebook"
											href="https://www.facebook.com/ivooxidnews"
											target="_blank"
											aria-label="facebook"
										>
											<i className="ui-facebook" />
										</a>
										<a
											className="social social-twitter"
											href="https://twitter.com/ivooxid"
											target="_blank"
											aria-label="twitter"
										>
											<i className="ui-twitter" />
										</a>
										<a
											className="social social-google-plus"
											href="https://plus.google.com/u/0/104998791569411648008"
											target="_blank"
											aria-label="google"
										>
											<i className="ui-google" />
										</a>
										<a
											className="social social-instagram"
											href="https://www.instagram.com/ivooxid/"
											target="_blank"
											aria-label="instagram"
										>
											<i className="ui-instagram" />
										</a>
									</div>
									<div className="col-4 AccountLogOut text-right" style={{ paddingLeft: "0px" }}>
										{this.token !== null ? (
											<Link to="/admin/dashboard">
												<span style={{ fontSize: 12 }}>
													MY DASHBOARD
                                                </span>
											</Link>
										) : (
												<Link to="/register">
													<span style={{ fontSize: 12 }}>
														REGISTER
                                                </span>
												</Link>
											)}

										{this.token !== null ? (
											<a href="#" onClick={this.doLogout}>
												<span style={{ fontSize: 12 }}>
													LOGOUT
                                                </span>
											</a>
										) : (
												<Link to="/auth?page=article">
													<span style={{ fontSize: 12 }}>
														LOGIN
                                                </span>
												</Link>
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default TopHedaer

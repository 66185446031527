import { assetsUrl } from '../config';

export const scriptsMaster = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptsMaster.js`;
	script.id = 'AmanahScriptMaster';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};

export const scriptsSlide = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptsSlide.js`;
	script.id = 'AmanahScriptsSlide';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};

export const scriptsSticky = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptSticky.js`;
	script.id = 'AmanahScriptSticky';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};

export const scriptsHideAds = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptsHideAds.js`;
	script.id = 'AmanahScriptHideAds';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};

export const scriptsUnHideAds = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptsUnhideAds.js`;
	script.id = 'AmanahScriptUnhideAds';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};

export const scriptsShowAdsense = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptsShowAdsense.js`;
	script.id = 'AmanahScriptsShowAdsense';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};


export const scriptsSetupPdf = callback => {
	const script = document.createElement('script');
	script.src = `${assetsUrl}/js/scriptstsetupPdf.js`;
	script.id = 'AmanahScriptsSetupPdf';
	document.body.appendChild(script);

	script.onload = () => {
		if (callback) callback()
	}
};
import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import Reaptcha from 'reaptcha'
import { withRouter } from "react-router-dom";

import { sitekeyReaptcha, keyToken, keyUser } from '../../../config'

import Toast from '../../../components/Toast'

import { IsSSR } from "../../../helpers/isSSR";
import { removeStorageItem, getStorageItem } from '../../../helpers/storage'
import Api from "../../../services/api";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fPassword: '',
            fPasswordConfirm: '',
            submittedLogin: false,
            loadingSubmit: false,
            errorStatus: false,
            errorMessage: '',
            verified: false
        };
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);

        this.onSubmitResetPassword = this.onSubmitResetPassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0);
		if (!this.token) {
			if (!this.isSSR) removeStorageItem(keyToken).then(r => console.log('delete token'));
			if (!this.isSSR) removeStorageItem(keyUser).then(r => console.log('delete user'));
		} else {
			return this.props.history.push(`/admin/dashboard`);
		}
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    };

    onDismiss() {
        this.setState({ errorStatus: false })
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    onSubmitResetPassword(e) {
        e.preventDefault();
        this.setState({ submittedLogin: true });
        const { fPassword, fPasswordConfirm } = this.state;
        if (fPassword !== '' && fPasswordConfirm !== '') {
            if (fPassword === fPasswordConfirm) this.onSave();
            else Toast("Password & Confirm Password doesn't match.");
        }
    }

    onSave() {
        const { fPassword, fPasswordConfirm } = this.state;
        const { match } = this.props;
        const token = match.params.token;

        this.setState({ errorStatus: false });
        this.setState({ loadingSubmit: true }, () => {
            const data = {
                new_password: fPassword,
                confirm_password: fPasswordConfirm
            };

            Api.post(`/user/resetPassword/${token}`, data, null)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        Toast(response.message, 'success')
                        this.setState({ loadingSubmit: false });
                    } else {
                        this.checkError(response)
                        this.setState({ loadingSubmit: false });
                    }
                })
                .catch(err => {
                    console.log('err:', err);
                    this.setState({ loadingSubmit: false });
                })
        })
    }

    checkError(response) {
        if (response.status && response.status === 'failed') {
            if (response.error) {
                this.setState({ errorStatus: true });
                this.setState({ errorMessage: response.message })
            } else {
                this.setState({ errorStatus: true });
                this.setState({ errorMessage: response.message })
            }
        } else {
            this.setState({ errorStatus: true });
            this.setState({ errorMessage: response.message })
        }
    }

    refreshPage() {
        window.location.reload()
    }

    render() {
        const {
            fPassword,
            fPasswordConfirm,
            loadingSubmit,
            errorStatus,
            errorMessage
        } = this.state;
        return (
            <div
                className="main-container container pt-80 pb-80"
                id="main-container"
            >
                <div className="blog__content mb-72">
                    <div className="container login-box">
                        <Alert
                            color="danger"
                            isOpen={errorStatus}
                            toggle={this.onDismiss}
                        >
                            {errorMessage}
                        </Alert>
                        <h2 className="text-center">Reset Password</h2>
                        <div className="row justify-content-center mt-40">
                            <div className="col-md-6">
                                <form
                                    onSubmit={this.onSubmitResetPassword}
                                    name="formLogin"
                                    className="search-form relative"
                                >
                                    <div className="form-group">
                                        <label htmlFor="password">
                                            Password
										</label>
                                        <input
                                            name="fPassword"
                                            id="fPassword"
                                            type="password"
                                            value={fPassword}
                                            onChange={this.onChange}
                                            required="required"
                                            minLength={8}
                                            maxLength={12}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">
                                            Confirm Password
										</label>
                                        <input
                                            name="fPasswordConfirm"
                                            id="fPasswordConfirm"
                                            type="password"
                                            value={fPasswordConfirm}
                                            onChange={this.onChange}
                                            required="required"
                                            minLength={8}
                                            maxLength={12}
                                        />
                                    </div>
                                    <div style={{ marginBottom: 16 }}>
                                        <Reaptcha
                                            sitekey={sitekeyReaptcha}
                                            onVerify={this.onVerify}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button
                                            disabled={
                                                !this.state.verified ||
                                                loadingSubmit
                                            }
                                            type="submit"
                                            className="btn btn-lg btn-color btn-button"
                                        >
                                            {loadingSubmit
                                                ? 'Loading...'
                                                : 'Reset Password'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ResetPassword)

import React, { Component } from 'react'
import AsyncPaginate from 'react-select-async-paginate';
import DatePicker from "react-datepicker";
import moment from 'moment'

class SearchReportCareer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.onChangeDate = this.onChangeDate.bind(this)
        this.onDeleteDate = this.onDeleteDate.bind(this)
    }

    onDeleteDate() {
        this.props.onChangeInput("createdDate", "");
        let e = {
            target: {
                "name": "createdAtMonth",
                "value": ""
            }
        }
        this.props.onChangeSearch(e);
        e = {
            target: {
                "name": "createdAtYear",
                "value": ""
            }
        }
        this.props.onChangeSearch(e);
    }

    onChangeDate(createdDate) {
        this.props.onChangeInput("createdDate", createdDate);
        console.log(createdDate);
        let e = {
            target: {
                "name": "createdAtMonth",
                "value": moment(createdDate).format("M")
            }
        }
        this.props.onChangeSearch(e);
        e = {
            target: {
                "name": "createdAtYear",
                "value": moment(createdDate).format("YYYY")
            }
        }
        this.props.onChangeSearch(e);
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-3 col-12">
                    <div className="form-group">
                        <label htmlFor="title">
                            Keyword
                        </label>
                        <input id="title" type="text" placeholder="Keyword" name="title" value={this.props.searchData.title} onChange={this.props.onChangeSearch} />
                    </div>
                </div>
                <div className="col-lg-3 col-12">
                    <div className="form-group month-post-list">
                        <label htmlFor="createdDate">
                            All Dates
                        </label>
                        {this.props.createdDate && (
                            <i class="fa fa-remove" onClick={() => this.onDeleteDate()} style={{ cursor: "pointer", fontSize: "21px", color: "red", position: "absolute", zIndex: "5", top: "37px", right: "23px" }}></i>
                        )}
                        <DatePicker
                            id="createdDate"
                            selected={this.props.createdDate}
                            dateFormat="MMM yyyy"
                            onChange={this.onChangeDate}
                            showMonthYearPicker
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-12">
                    <div className="form-group">
                        <label htmlFor="limit">
                            Items per page
                        </label>
                        <select name="limit" id="limit" value={this.props.searchData.limit} onChange={this.props.onChangeSearch}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="1000">1000</option>
                            <option value="All">All</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-12 text-right">
                    <div className="form-group">
                        <button
                            style={{ marginTop: "27px" }}
                            className="btn btn-md btn-color btn-button"
                            onClick={() => this.props.refreshDatas()}
                        >
                            Filter{this.state.test}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchReportCareer

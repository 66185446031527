import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';

import { sitekeyReaptcha } from '../../../config';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam';
import Toast from '../../../components/Toast';

class modalDetailMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteMediaModal: false,
      loading: false,
      first_page: 1,
      current_page: 1,
      offset: 4,
      errors: {}
    };
    this.doUpdatemedia = this.doUpdatemedia.bind(this);
    this.setParameterErrors = this.setParameterErrors.bind(this);
  }

  deleteRowTable() {
    if (this.props.selectedMedia.id) {
      this.setState({ loading: true });
      const route = `/media/delete/` + this.props.selectedMedia.id;
      Api.delete(route, this.props.token)
        .then((response) => {
          if (response.status && response.status === 'success') {
            this.setState({ deleteMediaModal: false, loading: false });
            this.props.refreshMedias();
            this.props.toggleDetailModal();
            Toast(response.message, 'success');
          } else {
            CheckError(response);
          }
        })
        .catch((err) => {
          this.setState({ deleteMediaModal: false, loading: false });
          this.refreshMedias();
          CheckError(err);
        });
    } else {
      Toast('Media tidak terbaca atau sudah terhapus');
    }
  }

  toggleDeleteMediasModal() {
    this.setState({
      deleteMediaModal: !this.state.deleteMediaModal
    });
  }

  deleteTag() {
    this.setState({
      deleteMediaModal: !this.state.deleteMediaModal
    });
  }

  doUpdatemedia() {
    this.setState({ loading: true });
    const params = {
      meta_tag: this.props.selectedMedia.meta_tag,
      caption: this.props.selectedMedia.caption
    };
    const route = `/media/update/` + this.props.selectedMedia.id;
    Api.put(route, params, this.props.token)
      .then((response) => {
        this.setState({ loading: false });
        if (response.status && response.status === 'success') {
          this.props.toggleDetailModal();
          this.props.refreshMedias();
          Toast(response.message, 'success');
        } else {
          CheckErrorWithParam(response, this.setParam);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        CheckErrorWithParam(err, this.setParam);
      });
  }

  setParameterErrors(data) {
    this.setState({
      errors: data
    });
  }

  render() {
    let mediaContainer = '';
    if (this.props.selectedMedia.media_type === 'image') {
      mediaContainer = <img src={this.props.selectedMedia.url} />;
    } else if (this.props.selectedMedia.media_type === 'video') {
      mediaContainer = (
        <video width="100%" controls>
          <source
            src={this.props.selectedMedia.url}
            type={this.props.selectedMedia.file_type}
          />
          Your browser does not support the video tag.
        </video>
      );
    } else if (this.props.selectedMedia.media_type === 'audio') {
      mediaContainer = (
        <audio controls>
          <source
            src={this.props.selectedMedia.url}
            type={this.props.selectedMedia.file_type}
          />
          Your browser does not support the audio element.
        </audio>
      );
    } else {
      mediaContainer = (
        <a href={this.props.selectedMedia.url}>
          <div style={{ height: '128px', width: '100%', textAlign: 'center' }}>
            <i
              className="fa fa-file"
              aria-hidden="true"
              style={{ fontSize: '50px', marginTop: '15px', display: 'block' }}
            ></i>
            <span>{this.props.selectedMedia.file_type}</span>
          </div>
        </a>
      );
    }

    return (
      <div>
        <Modal
          isOpen={this.state.deleteMediaModal}
          toggle={this.toggleDeleteMediasModal.bind(this)}
        >
          <ModalHeader toggle={this.toggleDeleteMediasModal.bind(this)}>
            Konfirmasi
          </ModalHeader>
          <ModalBody>
            Apakah ingin menghapus media {this.props.selectedMedia.meta_tag}?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteRowTable.bind(this)}>
              Hapus
            </Button>{' '}
            <Button
              color="secondary"
              onClick={this.toggleDeleteMediasModal.bind(this)}
            >
              Batalkan
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          isOpen={this.props.statusDetailModal}
          toggle={this.props.toggleDetailModal}
          className="modal-image-custome"
        >
          <ModalHeader toggle={this.props.toggleDetailModal}>Media</ModalHeader>
          <ModalBody>
            <LoadingOverlay
              active={this.state.loading}
              spinner
              text="Please Wait..."
            >
              <div className="row ivoox-media">
                <div className="col-md-8">
                  <div>{mediaContainer}</div>
                  <div className="form-group mt-10" style={{ marginTop: 10 }}>
                    <label htmlFor="caption">Caption</label>
                    <textarea
                      id="caption"
                      name="caption"
                      rows="4"
                      cols="50"
                      onChange={this.props.onChangeCaptionUpdate}
                    >
                      {this.props.selectedMedia.caption}
                    </textarea>
                    {/* <input
                        type="text"
                        name="caption"
                        placeholder=""
                        onChange={this.props.onChangeCaptionUpdate}
                        value={this.props.selectedMedia.caption}
                      /> */}
                    {this.state.errors.caption &&
                      this.state.errors.caption.message && (
                        <div
                          className="help-block"
                          style={{
                            backgroundColor: 'red',
                            paddingLeft: 10,
                            paddingRight: 10
                          }}
                        >
                          <small style={{ color: 'white' }}>
                            {this.state.errors.caption.message}
                          </small>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div style={{ wordBreak: 'break-all' }}>
                    <div>
                      <b>File name:</b>{' '}
                      {this.props.selectedMedia.new_path_name
                        ? this.props.selectedMedia.new_path_name.replace(
                            'media_public/',
                            ''
                          )
                        : ''}
                    </div>
                    <div>
                      <b>File type:</b> {this.props.selectedMedia.file_type}
                    </div>
                    <div>
                      <b>Uploaded on:</b>{' '}
                      {moment(this.props.selectedMedia.createdAt).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                    </div>
                    <hr />
                  </div>
                  <div>
                    <div className="form-group">
                      <label htmlFor="title">URL</label>
                      <input
                        type="text"
                        disable="true"
                        readOnly={true}
                        name="url"
                        placeholder=""
                        style={{ backgroundColor: '#eeeeee' }}
                        value={this.props.selectedMedia.url}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input
                        type="text"
                        required
                        name="title"
                        placeholder=""
                        onChange={this.props.onChangeMetaTag}
                        value={this.props.selectedMedia.meta_tag}
                      />
                      {this.state.errors.meta_tag &&
                        this.state.errors.meta_tag.message && (
                          <div
                            className="help-block"
                            style={{
                              backgroundColor: 'red',
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                          >
                            <small style={{ color: 'white' }}>
                              {this.state.errors.meta_tag.message.replace(
                                'meta tag',
                                'title'
                              )}
                            </small>
                          </div>
                        )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="title">Uploaded By</label>
                      <input
                        type="text"
                        disable="true"
                        readOnly={true}
                        name="url"
                        placeholder=""
                        style={{ backgroundColor: '#eeeeee' }}
                        value={
                          this.props.selectedMedia.user
                            ? this.props.selectedMedia.user.name
                            : ''
                        }
                      />
                    </div>
                    <div className="form-group">
                      <span>
                        <a
                          href="javascript:void(0);"
                          onClick={() => this.doUpdatemedia()}
                          style={{ color: '#007bff', paddingRight: '5px' }}
                        >
                          Update
                        </a>
                        <span>|</span>
                        <a
                          href="javascript:void(0);"
                          onClick={() => this.toggleDeleteMediasModal()}
                          style={{ paddingLeft: '5px' }}
                        >
                          Delete
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default modalDetailMedia;

import React, { Component } from 'react'

import Toast from '../../components/Toast'
import PopularPosts from '../../components/PopularPosts'
import SocialMedia from '../../components/SocialMedia'

import Api from '../../services/api'

import { IsSSR } from '../../helpers/isSSR'
import AuthorBox from './authorBox'
import { Link } from 'react-router-dom'
import moment from 'moment'

class Author extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datasTopTen: [],
            loadingTopTen: true,
            subscribeEmail: '',
            submittedSubscribe: false,
            datasUser: null,
            loadingUser: true,
            dataAuthor: null,
            loadingAuthor: true,
            entities: {
                data: [],
                pagination: {
                    total_found: 1,
                    limit: 4,
                    current_page: 1,
                    total_page: 1
                }
            },
            first_page: 1,
            current_page: 1,
            offset: 4,
            limit: 4
        }
        this.isSSR = IsSSR()

        this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0)
        this.fetchTopTen()

        let searchParams = new URLSearchParams(this.props.location.search)
        let current_page =
            searchParams.get('page') && !isNaN(searchParams.get('page'))
                ? searchParams.get('page')
                : 1
        this.setState(
            // { current_page: this.state.entities.pagination.current_page },
            { current_page: current_page },
            () => {
                this.fetchUser()
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        let params = new URLSearchParams(this.props.location.search)
        let paramsPrev = new URLSearchParams(prevProps.location.search)
        if (
            prevProps.match.params.username !== this.props.match.params.username
        ) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: 1 },
                () => {
                    window.scrollTo(0, 0)
                    this.fetchUser()
                }
            )
        } else if (paramsPrev.get('page') !== params.get('page')) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: params.get('page') },
                () => {
                    window.scrollTo(0, 0)
                    this.fetchDetailAuthor()
                }
            )
        }
    }

    onChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onSubmitSubscribe(e) {
        e.preventDefault()
        this.setState({ submittedSubscribe: true })
        const { subscribeEmail } = this.state
        if (subscribeEmail) {
            const route = `/subcriber/create`
            let params = {}
            params.email = subscribeEmail
            Api.post(route, params, null)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        Toast(response.message, 'success')
                    }
                })
                .catch(error => {
                    if (error.status && error.status === 'failed') {
                        Toast(error.message)
                    } else {
                        Toast('Error internet connection')
                    }
                })
        }
    }

    fetchTopTen() {
        this.setState({ loadingTopTen: true }, () => {
            const params = `page=1&limit=4&keyword=&state_id=3&schedule=0`
            const route = `/post/populer?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ datasTopTen: response.data })
                    this.setState({ loadingTopTen: false })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingTopTen: false })
                })
        })
    }

    fetchUser() {
        this.setState({ loadingUser: true }, () => {
            const route = `/user/username/${this.props.match.params.username}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ datasUser: response.data })
                    this.setState({ loadingUser: false })

                    // this.setState(
                    //     { current_page: this.state.entities.pagination.current_page },
                    //     () => {
                    this.fetchDetailAuthor()
                    //     }
                    // )
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingUser: false })
                })
        })
    }

    fetchDetailAuthor() {
        this.setState({ loadingAuthor: true }, () => {
            const params = `page=${this.state.current_page}&limit=${this.state.entities.pagination.limit}&keyword&type&schedule=0`
            const route = `/post/get_by_user/${this.props.match.params.username}?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ entities: response })
                    this.setState({ dataAuthor: response.data })
                    this.setState({ loadingAuthor: false })
                })
                .catch(error => {
                    this.setState({ loadingAuthor: false })
                })
        })
    }

    changePage(pageNumber) {
        this.setState({ current_page: pageNumber }, () => {
            this.props.history.push(
                `/authorPost/${this.props.match.params.username}?page=${pageNumber}`
            )
            // window.scrollTo(0, 0);
            // this.fetchDetailAuthor()
        })
    }

    pagesNumbers() {
        let from =
            this.state.entities.pagination.current_page - this.state.offset
        if (from < 1) {
            from = 1
        }
        let to = from + this.state.offset * 2
        if (to >= this.state.entities.pagination.total_page) {
            to = this.state.entities.pagination.total_page
        }
        let pagesArray = []
        for (let page = from; page <= to; page++) {
            pagesArray.push(page)
        }
        return pagesArray
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return (
                <span
                    key={page}
                    className={`pagination__page ${
                        page === this.state.entities.pagination.current_page
                            ? 'pagination__page--current'
                            : null
                    }`}
                    onClick={() => this.changePage(page)}
                >
                    {page}
                </span>
            )
        })
    }

    render() {
        const {
            datasTopTen,
            loadingTopTen,
            subscribeEmail,
            submittedSubscribe,
            loadingUser,
            datasUser,
            dataAuthor,
            loadingAuthor
        } = this.state

        if (loadingAuthor) return null
        return (
            <div className="main-container container-ivoox" id="main-container">
                <div className="row">
                    <div className="col-lg-8 blog__content mb-72">
                        <AuthorBox
                            loadingUser={loadingUser}
                            datasUser={datasUser}
                            className="mt-0"
                        />
                        <div className="row card-row min-height-auto ">
                            {dataAuthor.length > 0 ? (
                                dataAuthor.map((da, daIndex) => (
                                    <div className="col-md-6" key={daIndex}>
                                        <article className="entry card">
                                            <div className="entry__img-holder card__img-holder">
                                                <Link
                                                    to={{
                                                        pathname: `/${da.url}`,
                                                        search: `?tag_from=${
                                                            da.tagables[0]
                                                                ? da.tagables[0]
                                                                      .tag.slug
                                                                : 'unknown'
                                                        }`
                                                    }}
                                                >
                                                    <div
                                                        className="thumb-container thumb-70"
                                                        style={{
                                                            backgroundImage:
                                                                da.mediaable !==
                                                                null
                                                                    ? `url(${`${da.mediaable.media.url}`})`
                                                                    : `url(/static/media/logo-dark-beta.f32370e6.png)`,
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            backgroundPosition:
                                                                'center'
                                                        }}
                                                    >
                                                        {/* <img
                                                            src={
                                                                da.mediaable
                                                                    ? da.mediaable.media.url
                                                                    : ''
                                                            }
                                                            className="entry__img lazyloaded"
                                                            alt=""
                                                        /> */}
                                                    </div>
                                                </Link>
                                                <Link
                                                    to={{
                                                        pathname: `/category/${
                                                            da.categoryables[0]
                                                                ? da
                                                                      .categoryables[0]
                                                                      .category
                                                                      .slug
                                                                : 'Unknown'
                                                        }`
                                                    }}
                                                    className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
                                                >
                                                    {da.categoryables[0]
                                                        ? da.categoryables[0]
                                                              .category.name
                                                        : 'Unknown'}
                                                </Link>
                                            </div>
                                            <div className="entry__body card__body">
                                                <div className="entry__header">
                                                    <Link
                                                        to={{
                                                            pathname: `/${da.url}`,
                                                            search: `?tag_from=${
                                                                da.tagables[0]
                                                                    ? da
                                                                          .tagables[0]
                                                                          .tag
                                                                          .slug
                                                                    : 'unknown'
                                                            }`
                                                        }}
                                                    >
                                                        <h2
                                                            className="entry__title"
                                                            dangerouslySetInnerHTML={{
                                                                __html: da.title
                                                            }}
                                                        />
                                                    </Link>
                                                    <ul className="entry__meta">
                                                        <li className="entry__meta-date">
                                                            {moment(
                                                                da.createdAt
                                                            ).format('LLLL')}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                ))
                            ) : (
                                <div>Empty</div>
                            )}

                            {this.state.entities.data &&
                                this.state.entities.data.length > 0 && (
                                    <nav className="pagination">
                                        <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                                1 ===
                                                this.state.entities.pagination
                                                    .current_page
                                                    ? null
                                                    : this.changePage(
                                                          this.state.entities
                                                              .pagination
                                                              .current_page - 1
                                                      )
                                            }
                                            className="pagination__page pagination__icon pagination__page--next"
                                        >
                                            <i className="ui-arrow-left" />
                                        </a>
                                        {this.pageList()}
                                        <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                                this.state.entities.pagination
                                                    .total_page ===
                                                this.state.entities.pagination
                                                    .current_page
                                                    ? null
                                                    : this.changePage(
                                                          this.state.entities
                                                              .pagination
                                                              .current_page + 1
                                                      )
                                            }
                                            className="pagination__page pagination__icon pagination__page--next"
                                        >
                                            <i className="ui-arrow-right" />
                                        </a>
                                    </nav>
                                )}
                        </div>
                    </div>

                    <aside className="col-lg-4 sidebar sidebar--right">
                        <PopularPosts
                            loading={loadingTopTen}
                            data={datasTopTen}
                        />
                        {submittedSubscribe && !subscribeEmail && (
                            <div
                                className="help-block"
                                style={{
                                    backgroundColor: 'red',
                                    padding: 10,
                                    color: 'white'
                                }}
                            >
                                Email is required
                            </div>
                        )}
                        <aside className="widget widget_mc4wp_form_widget">
                            <h4 className="widget-title">Newsletter</h4>
                            <p className="newsletter__text">
                                <i className="ui-email newsletter__icon" />
                                Subscribe for our daily news
                            </p>
                            <form
                                className="mc4wp-form"
                                name="formSubscribe"
                                onSubmit={this.onSubmitSubscribe}
                            >
                                <div className="mc4wp-form-fields">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="subscribeEmail"
                                            placeholder="Your email"
                                            value={subscribeEmail}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            className="btn btn-lg btn-color"
                                            value="Sign Up"
                                        />
                                    </div>
                                </div>
                            </form>
                        </aside>
                        <SocialMedia />
                    </aside>
                </div>
            </div>
        )
    }
}

export default Author

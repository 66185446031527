import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Api from '../services/api'

class TagsHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datasTags: [],
      loadingTags: true
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.setState({ loadingTags: true }, () => {
      const params = `page=1&limit=5&keyword=`
      const route = `/tag/index?${params}`
      Api.get(route, null)
        .then((response) => {
          this.setState({ datasTags: response.data })
          this.setState({ loadingTags: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ loadingTags: false })
        })
    })
  }

  render() {
    const { datasTags, loadingTags } = this.state

    if (loadingTags) return null
    return (
      <div className={window.outerWidth < 480 ? 'container' : ''}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            color: 'black',
            fontWeight: 'bold',
            marginBottom: '1rem',
            fontSize: '12px'
          }}
        >
          <div style={{ whiteSpace: 'nowrap' }}>TRENDS # :</div>
          <div
            className="tagscroll"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }}
          >
            {datasTags?.map((d) => (
              <Link to={`/tag/${d.slug}`} style={{ padding: '6px 8px', backgroundColor: 'rgba(12, 12, 12, 0.1)', color: 'black', whiteSpace: 'nowrap' }}>
                {d.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default TagsHeader

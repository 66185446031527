import React, { Component } from 'react'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'

import Toast from '../../components/Toast'
import PopularPosts from '../../components/PopularPosts'
import SocialMedia from '../../components/SocialMedia'

import Api from '../../services/api'

import { IsSSR } from '../../helpers/isSSR'

class Search extends Component {
    constructor(props) {
        super(props)
        let searchParams = new URLSearchParams(props.location.search)

        this.state = {
            datasSearch: [],
            loadingPostCategory: true,
            datasTopTen: [],
            loadingTopTen: true,
            subscribeEmail: '',
            submittedSubscribe: false,
            entities: {
                data: [],
                pagination: {
                    total_found: 1,
                    limit: 5,
                    current_page: 1,
                    total_page: 1
                }
            },
            first_page: 1,
            current_page: 1,
            offset: 4,
            limit: 5,
            formSearchBoxInput: searchParams.get('keyword')
        }
        this.isSSR = IsSSR()

        this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this)
        this.onSubmitFormSearchBox = this.onSubmitFormSearchBox.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0)
        this.fetchEntities()
        this.fetchTopTen()

        let searchParams = new URLSearchParams(this.props.location.search)
        let current_page =
            searchParams.get('page') && !isNaN(searchParams.get('page'))
                ? searchParams.get('page')
                : 1
        this.setState(
            // { current_page: this.state.entities.pagination.current_page },
            { current_page: current_page },
            () => {
                this.fetchEntities()
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        let params = new URLSearchParams(this.props.location.search)
        let paramsPrev = new URLSearchParams(prevProps.location.search)
        if (paramsPrev.get('keyword') !== params.get('keyword')) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: 1 },
                () => {
                    window.scrollTo(0, 0)
                    this.fetchEntities()
                }
            )
            this.fetchTopTen()
            this.setState({ formSearchBoxInput: params.get('keyword') })
        } else if (paramsPrev.get('page') !== params.get('page')) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: params.get('page') },
                () => {
                    window.scrollTo(0, 0)
                    this.fetchEntities()
                }
            )
        }
    }

    onSubmitFormSearchBox(e) {
        e.preventDefault()
        const { formSearchBoxInput } = this.state
        this.props.history.push(`/search?keyword=${formSearchBoxInput}`)
    }

    fetchEntities() {
        let searchParams = new URLSearchParams(this.props.location.search)
        this.setState({ loadingPostCategory: true }, () => {
            const params = `page=${this.state.current_page}&limit=${
                this.state.entities.pagination.limit
            }&keyword=${searchParams.get(
                'keyword'
            )}&state_id=3&schedule=0`
            const route = `/post/index?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ entities: response })
                    this.setState({ datasSearch: response.data })
                    this.setState({ loadingPostCategory: false })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingPostCategory: false })
                })
        })
    }

    changePage(pageNumber) {
        let searchParams = new URLSearchParams(this.props.location.search)
        this.setState({ current_page: pageNumber }, () => {
            this.props.history.push(
                `/search?keyword=${searchParams.get(
                    'keyword'
                )}&page=${pageNumber}`
            )
            // window.scrollTo(0, 0);
            // this.fetchEntities()
        })
    }

    pagesNumbers() {
        let from =
            this.state.entities.pagination.current_page - this.state.offset
        if (from < 1) {
            from = 1
        }
        let to = from + this.state.offset * 2
        if (to >= this.state.entities.pagination.total_page) {
            to = this.state.entities.pagination.total_page
        }
        let pagesArray = []
        for (let page = from; page <= to; page++) {
            pagesArray.push(page)
        }
        return pagesArray
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return (
                <span
                    key={page}
                    className={`pagination__page ${
                        page === this.state.entities.pagination.current_page
                            ? 'pagination__page--current'
                            : null
                    }`}
                    onClick={() => this.changePage(page)}
                >
                    {page}
                </span>
            )
        })
    }

    onChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onSubmitSubscribe(e) {
        e.preventDefault()
        this.setState({ submittedSubscribe: true })
        const { subscribeEmail } = this.state
        if (subscribeEmail) {
            const route = `/subcriber/create`
            let params = {}
            params.email = subscribeEmail
            Api.post(route, params, null)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        Toast(response.message, 'success')
                    }
                })
                .catch(error => {
                    if (error.status && error.status === 'failed') {
                        Toast(error.message)
                    } else {
                        Toast('Error internet connection')
                    }
                })
        }
    }

    fetchTopTen() {
        this.setState({ loadingTopTen: true }, () => {
            const params = `page=1&limit=4&keyword=&state_id=3&schedule=0`
            const route = `/post/populer?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ datasTopTen: response.data })
                    this.setState({ loadingTopTen: false })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingTopTen: false })
                })
        })
    }

    render() {
        const {
            datasSearch,
            loadingPostCategory,
            datasTopTen,
            loadingTopTen,
            subscribeEmail,
            submittedSubscribe,
            formSearchBoxInput
        } = this.state
        const { match } = this.props
        return (
            <div className="main-container pt-40 container-ivoox" id="main-container">
                <div className="row">
                    <div className="col-lg-8 blog__content mb-72">
                        <h1 className="page-title tt-capitalize">
                            Search results for
                        </h1>
                        <div className="col-md-12" style={{ padding: 0 }}>
                            <form
                                className="search-form relative"
                                name="formSearchBox"
                                onSubmit={this.onSubmitFormSearchBox}
                            >
                                <input
                                    name="formSearchBoxInput"
                                    type="search"
                                    className="widget-search-input mb-0"
                                    placeholder="Search an article"
                                    value={formSearchBoxInput}
                                    onChange={this.onChange}
                                />
                                <button
                                    type="submit"
                                    className="widget-search-button btn btn-color"
                                >
                                    <i className="ui-search widget-search-icon" />
                                </button>
                            </form>
                        </div>
                        {loadingPostCategory ? (
                            <div style={{ marginTop: 20 }}>Loading...</div>
                        ) : (
                            <div style={{ marginTop: 20 }}>
                                {datasSearch.length > 0 ? (
                                    <div>
                                        {datasSearch.map(
                                            (dataSearch, indexDataSearch) => (
                                                <article
                                                    className="entry card post-list"
                                                    key={indexDataSearch}
                                                >
                                                    <div
                                                        className="entry__img-holder post-list__img-holder card__img-holder"
                                                        style={{
                                                            backgroundImage:
                                                                dataSearch.mediaable !==
                                                                null
                                                                    ? `url(${`${dataSearch.mediaable.media.url}`})`
                                                                    : ''
                                                        }}
                                                    >
                                                        <Link
                                                            to={{
                                                                pathname: `/${dataSearch.url}`,
                                                                search: `?tag_from=${
                                                                    dataSearch.tagables
                                                                        ? dataSearch
                                                                              .tagables[0]
                                                                            ? dataSearch
                                                                                  .tagables[0]
                                                                                  .tag
                                                                                ? dataSearch
                                                                                      .tagables[0]
                                                                                      .tag
                                                                                      .slug
                                                                                : 'unknown'
                                                                            : 'unknown'
                                                                        : 'unknown'
                                                                }`
                                                            }}
                                                            className="thumb-url"
                                                        />
                                                        <img
                                                            src={
                                                                dataSearch.mediaable !==
                                                                null
                                                                    ? `${dataSearch.mediaable.media.url}`
                                                                    : ''
                                                            }
                                                            alt=""
                                                            className="entry__img d-none"
                                                        />
                                                        <Link
                                                            to={{
                                                                pathname: `/category/${
                                                                    dataSearch
                                                                        .categoryables
                                                                        .length >
                                                                    0
                                                                        ? dataSearch
                                                                              .categoryables[0]
                                                                              .category
                                                                              .slug
                                                                        : 'berita-utama'
                                                                }`
                                                            }}
                                                            className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
                                                        >
                                                            {dataSearch
                                                                .categoryables
                                                                .length > 0
                                                                ? dataSearch
                                                                      .categoryables[0]
                                                                      .category
                                                                      .name
                                                                : 'Unknown'}
                                                        </Link>
                                                    </div>
                                                    <div className="entry__body post-list__body card__body">
                                                        <div className="entry__header">
                                                            <h2 className="entry__title">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${dataSearch.url}`,
                                                                        search: `?tag_from=${
                                                                            dataSearch.tagables
                                                                                ? dataSearch
                                                                                      .tagables[0]
                                                                                    ? dataSearch
                                                                                          .tagables[0]
                                                                                          .tag
                                                                                        ? dataSearch
                                                                                              .tagables[0]
                                                                                              .tag
                                                                                              .slug
                                                                                        : 'unknown'
                                                                                    : 'unknown'
                                                                                : 'unknown'
                                                                        }`
                                                                    }}
                                                                >
                                                                    {
                                                                        dataSearch.title
                                                                    }
                                                                </Link>
                                                            </h2>
                                                            <ul className="entry__meta">
                                                                <li className="entry__meta-author">
                                                                    <span>
                                                                        by
                                                                    </span>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/authorPost/${
                                                                                dataSearch.user
                                                                                    ? dataSearch
                                                                                          .user
                                                                                          .username
                                                                                    : 'Unknown'
                                                                            }`
                                                                        }}
                                                                    >
                                                                        <a href="javascript:void(0)">
                                                                            {` `}{' '}
                                                                            {dataSearch.user
                                                                                ? dataSearch
                                                                                      .user
                                                                                      .name
                                                                                : 'Unknown'}
                                                                        </a>
                                                                    </Link>
                                                                </li>
                                                                <li className="entry__meta-date">
                                                                    {moment(
                                                                        dataSearch.createdAt
                                                                    ).format(
                                                                        'LLLL'
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </article>
                                            )
                                        )}
                                        {this.state.entities.data &&
                                            this.state.entities.data.length >
                                                0 && (
                                                <nav className="pagination">
                                                    <a
                                                        href="javascript:void(0);"
                                                        onClick={() =>
                                                            1 ===
                                                            this.state.entities
                                                                .pagination
                                                                .current_page
                                                                ? null
                                                                : this.changePage(
                                                                      this.state
                                                                          .entities
                                                                          .pagination
                                                                          .current_page -
                                                                          1
                                                                  )
                                                        }
                                                        className="pagination__page pagination__icon pagination__page--next"
                                                    >
                                                        <i className="ui-arrow-left" />
                                                    </a>
                                                    {this.pageList()}
                                                    <a
                                                        href="javascript:void(0);"
                                                        onClick={() =>
                                                            this.state.entities
                                                                .pagination
                                                                .total_page ===
                                                            this.state.entities
                                                                .pagination
                                                                .current_page
                                                                ? null
                                                                : this.changePage(
                                                                      this.state
                                                                          .entities
                                                                          .pagination
                                                                          .current_page +
                                                                          1
                                                                  )
                                                        }
                                                        className="pagination__page pagination__icon pagination__page--next"
                                                    >
                                                        <i className="ui-arrow-right" />
                                                    </a>
                                                </nav>
                                            )}
                                    </div>
                                ) : (
                                    <div style={{ marginTop: 10 }}>
                                        No results for your search
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <aside className="col-lg-4 sidebar sidebar--right">
                        <PopularPosts
                            loading={loadingTopTen}
                            data={datasTopTen}
                        />
                        {submittedSubscribe && !subscribeEmail && (
                            <div
                                className="help-block"
                                style={{
                                    backgroundColor: 'red',
                                    padding: 10,
                                    color: 'white'
                                }}
                            >
                                Email is required
                            </div>
                        )}
                        <aside className="widget widget_mc4wp_form_widget">
                            <h4 className="widget-title">Newsletter</h4>
                            <p className="newsletter__text">
                                <i className="ui-email newsletter__icon" />
                                Subscribe for our daily news
                            </p>
                            <form
                                className="mc4wp-form"
                                name="formSubscribe"
                                onSubmit={this.onSubmitSubscribe}
                            >
                                <div className="mc4wp-form-fields">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="subscribeEmail"
                                            placeholder="Your email"
                                            value={subscribeEmail}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            className="btn btn-lg btn-color"
                                            value="Sign Up"
                                        />
                                    </div>
                                </div>
                            </form>
                        </aside>
                        <SocialMedia />
                    </aside>
                </div>
            </div>
        )
    }
}

export default withRouter(Search)

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyUser, keyToken } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import Toast from '../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deleteUserModal: false,
            deleteUserData: {
                id: '',
                username: ''
            },
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true
        }

        this.refreshUsers = this.refreshUsers.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-user')
                    .DataTable()
                    .destroy()
                $('#datatable-user tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    fetchData() {
        $('#datatable-user').off( "click", ".detail-user-btn");
        $('#datatable-user').off( "click", ".delete-user-btn");
        const user = JSON.parse(this.props.localUser)
        const {
            isAs
        } = this.props

        var _ = this
        const route = `/user/index`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var tagDatatableDraw = 1
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    tagDatatableDraw = data.draw
                    var requestData = {}
                    if (data.order && data.order[0] && data.order[0].column === 4) {
                        requestData.sort_field = "role";
                    } else {
                        requestData.sort_field = "id";
                    }

                    if (data.order && data.order[0] && data.order[0].dir === "desc") {
                        requestData.sort_by = "DESC";
                    } else {
                        requestData.sort_by = "ASC";
                    }
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    requestData.state_id = 'all'
                    requestData.schedule = 0
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/users?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id' },
                {
                    data: null,
                    name: 'username',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        return (
                            '<a href="/admin/users/' + data.id + '" data-id="' +
                            data.id +
                            '" class="detail-user-btn">' +
                            data.username +
                            '</a>'
                        )
                    }
                },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    searchable: false,
                    render: function (data) {
                        return data.name
                    }
                },
                {
                    data: null,
                    name: 'email',
                    orderable: false,
                    render: function (data) {
                        return data.email
                    }
                },
                {
                    data: null,
                    name: 'role',
                    orderable: false,
                    render: function (data) {
                        var role = (data.roles && data.roles[0]) ? data.roles[0].name : "";
                        return role
                    }
                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
                            data.id +
                            '" class="detail-user-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
                            data.id + '"  data-username="' + data.username +
                            '" class="delete-User-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        })
        $('#datatable-user').on('click', '.detail-user-btn', function (e) {
            e.preventDefault();
            var id = $(this).data('id')
            _.showUserDetail(id)
        })
        $('#datatable-user').on('click', '.delete-User-btn', function () {
            var id = $(this).data('id')
            var username = $(this).data('username')
            _.deleteUser(id, username)
        })
    }

    showUserDetail(id) {
        this.props.history.push(`/admin/users/${id}`)
    }

    componentWillUnmount() { }

    refreshUsers() {
        $('#datatable-user')
            .DataTable()
            .ajax.reload()
    }

    deleteRowTable(id) {
        this.setState({ loading: true })
        const route = `/user/delete/` + id
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.refreshUsers()
                    Toast(response.message, 'success')
                    this.setState({ deleteUserModal: false })
                }
            })
            .catch(() => {
                this.refreshUsers()
                Toast('Error internet connection')
                this.setState({ deleteUserModal: false })
            })
    }

    toggleDeleteUsersModal() {
        this.setState({
            deleteUserModal: !this.state.deleteUserModal
        })
    }

    deleteUser(id, username) {
        this.setState({
            deleteUserData: {
                id: id,
                username: username
            },
            deleteUserModal: !this.state.deleteUserModal
        })
    }

    render() {
        return (
            <div className="entry card card-input">
                <Modal
                    isOpen={this.state.deleteUserModal}
                    toggle={this.toggleDeleteUsersModal.bind(this)}
                    className={this.props.className}
                >
                    <ModalHeader
                        toggle={this.toggleDeleteUsersModal.bind(this)}
                    >
                        Konfirmasi
                    </ModalHeader>
                    <ModalBody>
                        Apakah ingin menghapus user dengan username {this.state.deleteUserData.username}?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.deleteRowTable.bind(
                                this,
                                this.state.deleteUserData.id
                            )}
                        >
                            Hapus
                        </Button>{' '}
                        <Button
                            color="secondary"
                            onClick={this.toggleDeleteUsersModal.bind(this)}
                        >
                            Batalkan
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="row">
                    <div className="col-12">
                        <div className="mt-3">
                            <h4 className="heading-title">User List</h4>
                            <Link to="/admin/users/add">
                                <span className="btn btn-md btn-color mt-3 mb-3">
                                    Add New User
                                </span>
                            </Link>
                            <table
                                id="datatable-user"
                                className="display table table-sm table-striped table-bordered"
                                width="100%"
                                ref={el => (this.el = el)}
                            >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Username</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListUser

// API
const serverList = [
  { name: 'LOCAL', url: 'http://localhost:8080' },
  { name: 'DEV', url: 'http://18.136.39.59' },
  { name: 'PROD', url: 'https://api.ivoox.id' }
];

// WEBSITE
const webServerList = [
  { name: 'LOCAL', url: 'http://localhost:3006' },
  { name: 'DEV', url: 'http://18.136.39.59' },
  { name: 'PROD', url: 'https://ivoox.id' }
];
const activeServer = serverList[2];
const activeWebServer = webServerList[2];
export const serverName = activeServer.name;
const baseUrl = activeServer.url;
const baseUrlWeb = activeWebServer.url;
export const apiUrl = `${baseUrl}/api`;
export const imagesUrl = `${apiUrl}/media/get`;
export const assetsUrl = `${baseUrlWeb}/assets`;
export const baseUrlFront = `${baseUrlWeb}`;
export const keyToken = 'IvooxWebClient_token';
export const keyUser = 'IvooxWebClient_user';
export const sosialMedia = [
  {
    label: 'facebook',
    title: 'Facebook',
    link: 'https://www.facebook.com/ivooxidnews'
  },
  {
    label: 'twitter',
    title: 'Twitter',
    link: 'https://twitter.com/ivooxid'
  },
  {
    label: 'tiktok',
    title: 'tiktok',
    link: 'https://www.tiktok.com/@ivooxindonesia'
  },
  {
    label: 'instagram',
    title: 'Instagram',
    link: 'https://www.instagram.com/ivooxid/'
  }
];
const captchaList = [
  {
    name: 'LOCAL',
    key: '6LcU6rAUAAAAAEz2AubTq0i-D8W-1bSUGcPLS_dC'
  },
  {
    name: 'PROD',
    key: '6Lf36bAUAAAAAJfc00o3myQqoX8SXE5xmGvmUWfR'
  },
  {
    name: 'PROD_NEW',
    key: '6LeAeLoUAAAAABB3hHfvn_cpd22j6mmVJt27IRs9'
  }
];
const activeCaptcha = captchaList[1];
export const sitekeyReaptcha = activeCaptcha.key;

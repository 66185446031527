import React, { Component } from 'react'
import Reaptcha from 'reaptcha'

import Toast from '../../components/Toast'

import { apiUrl, sitekeyReaptcha } from '../../config'
import Api from '../../services/api'

class CareerInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingCareer: false,
			datasCareer: [],
			fCv: null,
			fName: '',
			fEmail: '',
			fPosition: '',
			submittedFormCareer: false,
			submitLoading: false,
			verified: false
		};

		this.onSubmitFormCareer = this.onSubmitFormCareer.bind(this);
		this.onChange = this.onChange.bind(this)
	}

	componentDidMount() {
		this.setState({ loadingCareer: true }, () => {
			const params = `page=1&limit=10&keyword=`;
			const route = `/career_position/getCareer?${params}`;
			Api.get(route, null)
				.then(response => {
					this.setState({ datasCareer: response.data });
					this.setState({ loadingCareer: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ loadingCareer: false })
				})
		})
	}

	onVerify = recaptchaResponse => {
		this.setState({
			verified: true
		})
	};

	onChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value })
	}

	onSubmitFormCareer(e) {
		e.preventDefault();

		const { fCv, fName, fEmail, fPosition } = this.state;
		this.setState({ submittedFormCareer: true });

		if (fCv === null) Toast('Select Your CV is required');
		if (fCv !== null) this.onSubmit()
	}

	onSubmit() {
		const { fCv, fName, fEmail, fPosition } = this.state;
		this.setState({ submitLoading: false }, () => {
			const data = new FormData();
			data.append('email', fEmail);
			data.append('name', fName);
			data.append('career_position_id', fPosition);
			data.append('cv', fCv);
			fetch(`${apiUrl}/career/create`, {
				method: 'POST',
				body: data
			})
				.then(function(response) {
					return response.json()
				})
				.then(response => {
					if (response.status === 'success') {
						Toast('Thanks', 'success');
						setTimeout(function() {
							window.location.reload()
						}, 600)
					} else this.checkError(response);
					this.setState({ submitLoading: false })
				})
		})
	}

	handleUploadFile = event => {
		this.setState({ fCv: event.target.files[0] })
	};

	checkError(response) {
		console.log(response);
		if (response.status && response.status === 'failed') {
			if (response.error) {
				this.setState({ errors: response.error });
				Toast(response.message)
			} else {
				Toast(response.message)
			}
		} else {
			Toast('Error internet connection')
		}
	}

	render() {
		const {
			datasCareer,
			fCv,
			fEmail,
			fName,
			fPosition
		} = this.state;

		return (
			<div className="col-md-6">
				<div className="content-box">
					<form name="formCareer" onSubmit={this.onSubmitFormCareer}>
						<div className="form-group">
							<label htmlFor="fName">Your name (required)</label>
							<input
								type="text"
								className="form-control"
								id="fName"
								name="fName"
								required="required"
								value={fName}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="fEmail">
								Email address (required)
							</label>
							<input
								type="email"
								className="form-control"
								id="fEmail"
								name="fEmail"
								required="required"
								value={fEmail}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="Position">
								Position (required)
							</label>
							<select
								name="fPosition"
								required="required"
								value={fPosition}
								onChange={this.onChange}
							>
								<option value="">-- Choose --</option>
								{datasCareer.map(
									(dataCareer, dataCareerIndex) => (
										<option
											value={dataCareer.id}
											key={dataCareerIndex}
										>
											{dataCareer.name}
										</option>
									)
								)}
							</select>
						</div>
						<div className="form-group">
							<label htmlFor="Position">
								Upload your CV here (required)
							</label>
							<br />
							<label className="btn btn btn-md btn-color btn-file">
								Browse
								<input
									type="file"
									onChange={this.handleUploadFile}
								/>
								{fCv !== null && (
									<p style={{ color: 'white', fontSize: 11 }}>
										File selected
									</p>
								)}
							</label>
							<p>The cv must be a file of type: pdf,doc,docx.</p>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Reaptcha
								sitekey={sitekeyReaptcha}
								onVerify={this.onVerify}
							/>
						</div>
						<button
							type="submit"
							disabled={!this.state.verified}
							className="btn btn-md btn-color btn-button mt-5"
						>
							{this.state.submitLoading ? 'Loading...' : 'Submit'}
						</button>
					</form>
				</div>
			</div>
		)
	}
}

export default CareerInput

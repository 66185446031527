import LoadingOverlay from 'react-loading-overlay';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyToken, keyUser } from '../../../config'

import DashboardSideBar from '../../../components/dashboard/dashboardSideBar'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'

import { scriptsHideAds, scriptsUnHideAds } from '../../../utils/scripts'

class DetailActivityLog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detailData  : {},
            isAs        : '',
            loadingRole : true,
            loading     : false,
        }
        this.setParameterErrors = this.setParameterErrors.bind(this);
        this.isSSR = IsSSR();
        this.token = null;
        this.user = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
        if (!this.isSSR) this.user = getStorageItem(keyUser);
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0);
        this.getRoles().then(r => console.log('getRoles reload'));
        if (!this.token) return this.props.history.push(`/auth`);
        setTimeout(() => this.getDetail())
        setTimeout(() => {
            scriptsHideAds(() => {
                console.log('[scriptsHideAds.js]')
            })
        }, 600)
    }

    componentWillUnmount() {
        scriptsUnHideAds(() => {
            console.log('[scriptsUnhideAds.js]')
        })
    }

    async getRoles() {
        this.setState({ loadingRole: true })

        const user = JSON.parse(this.user)
        var isAdmin = await this.getIndexOf(user.roles, 'admin')
        this.setState({ isAdmin: isAdmin })

        var isEditor = await this.getIndexOf(user.roles, 'editor')
        this.setState({ isEditor: isEditor })

        var isAuthor = await this.getIndexOf(user.roles, 'author')
        this.setState({ isAuthor: isAuthor })

        var isContributor = await this.getIndexOf(user.roles, 'contributor')
        this.setState({ isContributor: isContributor })

        if (isAdmin) this.setState({ isAs: 'admin' })
        else if (isEditor) this.setState({ isAs: 'editor' })
        else if (isAuthor) this.setState({ isAs: 'author' })
        else if (isContributor) this.setState({ isAs: 'contributor' })

        this.setState({ loadingRole: false })
    }

    getIndexOf(a, v) {
        var l = a.length
        for (var k = 0; k < l; k++) {
            if (a[k].name == v) {
                return true
            }
        }
        return false
    }

    getDetail() {
        const { match } = this.props
        var id          = match.params.id
        let route       = `/activity_log/${id}`;
        this.setState({ loading: true });
        Api.get(route, this.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    this.setState({
                        detailData  : response.data,
                        loading     : false,
                    });
                } else {
                    this.setState({ loading: false })
                    CheckError(response);
                    this.props.history.push(`/admin/activityLogs`);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                CheckError(error);
                this.props.history.push(`/admin/activityLogs`);
            })
    }

    setParameterErrors(data) {
        this.setState({ errors: data })
    }

    render() {
        const {
            isAs,
            loadingRole
        } = this.state;

        if (loadingRole) return null;
        return (
            <div>
                <div className="container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link to="/admin/dashboard" className="breadcrumbs__url">
                                Home
                            </Link>
                        </li>
                        <li className="breadcrumbs__item">
                            <Link to="/admin/activityLogs" className="breadcrumbs__url">
                                Activity Log
                            </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Detail
                        </li>
                    </ul>
                </div>
                <div className="main-container container" id="main-container">
                    <div className="blog__content mb-72">
                        <h1 className="page-title">Activity Log</h1>
                        <div className="row card-row">
                            <DashboardSideBar isAs={isAs} localUser={this.user} {...this.props} />
                            <div className="col-lg-9 col-12">
                                <div className="entry card card-input">
                                    <LoadingOverlay
                                        active={this.state.loading}
                                        spinner
                                        text='Please Wait...'
                                    >
                                        <div className="form-group">
                                            <label htmlFor="id">ID</label>
                                            <input type="text"
                                                id="id" name="id" value={this.state.detailData.id ? this.state.detailData.id : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="activityable_type">Tipe</label>
                                            <input activityable_type="text"
                                                id="activityable_type" name="activityable_type" 
                                                value={this.state.detailData.activityable_type ? this.state.detailData.activityable_type.replace("App/", "") : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="activityable_id">ID Tipe</label>
                                            <input type="text"
                                                id="activityable_id" name="activityable_id" value={this.state.detailData.activityable_id ? this.state.detailData.activityable_id : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="action">Action</label>
                                            <input type="text"
                                                id="action" name="action" value={this.state.detailData.action ? this.state.detailData.action : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="username">Username (User ID)</label>
                                            <input type="text"
                                                id="username" name="username" 
                                                value={this.state.detailData.user && this.state.detailData.user.username ? this.state.detailData.user.username + '(' + this.state.detailData.user.id + ')' : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="ip_address">IP Address</label>
                                            <input type="text"
                                                id="ip_address" name="ip_address" 
                                                value={this.state.detailData.device_details && JSON.parse(this.state.detailData.device_details).ip_address ? JSON.parse(this.state.detailData.device_details).ip_address : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="createdAt">Date</label>
                                            <input type="text"
                                                id="createdAt" name="createdAt" 
                                                value={this.state.detailData.createdAt ? moment(this.state.detailData.createdAt).format('DD/MM/YYYY HH:mm') : ''}
                                                style={{ marginBottom: "0px" }} disabled={true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="old_value">Old Value </label>
                                            <textarea id="old_value" name="old_value" rows="7" 
                                                value={this.state.detailData.old_value}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="new_value">New Value </label>
                                            <textarea id="new_value" name="new_value" rows="7" 
                                                value={this.state.detailData.new_value}
                                            />
                                        </div>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailActivityLog;

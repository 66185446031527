import React, { Component } from "react";
import LoadingOverlay from 'react-loading-overlay';
import Reaptcha from 'reaptcha'

import { apiUrl, sitekeyReaptcha } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam'
import ModalMedia from "../../../components/modal/ModalMedia";
import Toast from '../../../components/Toast'

class FormProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            profileData: {
                name: "",
                email: "",
                biografi: "",
                no_hp: "",
                alamat: "",
                npwp: "",
                ktp: "",
                twitter: "",
                instagram: "",
                facebook: "",
                password: "",
                is_verified: "0",
            },
            selectedImagesModal: [],
            images: [],
            imagePagination: {
                total_found: 1,
                limit: 9,
                current_page: 1,
                total_page: 1
            },
            selectedImages: [],
            errors: {},
            isAs: '',
            loadingRole: true,
            verified: false,
            keyword: "",
            caption: "",
        }

        this.onChangeProfile = this.onChangeProfile.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this);
        this.getImagePosts = this.getImagePosts.bind(this);
        this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.addImagePost = this.addImagePost.bind(this)
        this.onChangeKeyword = this.onChangeKeyword.bind(this);
        this.setParameterErrors = this.setParameterErrors.bind(this)
        this.onChangeCaption = this.onChangeCaption.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.getProfile())
        if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
            this.setState({verified: true});
        }
    }

    onChangeKeyword(e) {
        const { name, value } = e.target
        this.setState({ keyword: value })
    }

    onChangeCaption(e) {
        const { name, value } = e.target
        this.setState({ caption: value })
    }

    onChangeProfile(e) {
        const { name, value } = e.target
        let { profileData } = this.state
        profileData[name] = value
        this.setState({ profileData })
    }

    getProfile() {
        this.setState({ loading: true });
        let route = `/user_profile`;
        Api.get(route, this.props.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    const profileData = response.data;
                    profileData.password = "";
                    delete profileData.roles;
                    this.setState({
                        profileData,
                        loading: false,
                    });
                    if (response.data.media) {
                        let images = []
                        images[0] = response.data.media.id
                        this.addImagePost(images, response.data.media.url)
                    }
                } else {
                    CheckError(response);
                    this.setState({ loading: false })
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                CheckError(error);
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        let route = "/user_profile";
        this.setState({ loading: false });
        let data = this.state.profileData;
        data.media_id = this.state.selectedImages[0] ? this.state.selectedImages[0] : null;
        Api.put(route, data, this.props.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    Toast(response.message, "success")
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false });
                    CheckErrorWithParam(response, this.setParameterErrors);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                CheckErrorWithParam(error, this.setParameterErrors);
            })
    }

    toggleAddImagePostModal() {
        if (!this.state.addImagePostModal) {
            this.getImagePosts();
        }
        let selectedImages = this.state.selectedImages;
        this.setState({
            addImagePostModal: !this.state.addImagePostModal,
            selectedImagesModal: selectedImages
        });
    }

    getImagePosts(page = 1, limit = 9) {
        this.setState({ loadingImage: true });
        const route = `/media/index?type=image&page=` + page + `&limit=` + limit + `&keyword=` + this.state.keyword;
        Api.get(route, this.props.token)
            .then(response => {
                if (response.status && response.status === "success") {
                    this.setState({ images: response.data, imagePagination: response.pagination, loadingImage: false });
                } else {
                    this.setState({ loadingImage: false })
                    CheckError(response)
                }
            })
            .catch((error) => {
                this.setState({ loadingImage: false });
                CheckError(error)
            })
    }

    setSelectedImagesModal(selectedImagesModal) {
        this.setState({ selectedImagesModal });
    }

    handleFileUpload(e) {
        const file = e.target.files[0];
        if (file) {
            this.setState({ loading: true });
            const formData = new FormData();
            let arrayName = file.name.split('.');
            arrayName.pop();
            let nameImage = arrayName.join(' ');

            formData.append("media", file)
            formData.append("meta_tag", nameImage.substring(0, 80))
            formData.append("media_type", "image")
            formData.append("caption", this.state.caption)
            const route = `/media/create`;
            fetch(`${apiUrl}` + route, {
                method: 'POST',
                headers: new Headers({
                    'x-access-token': this.props.token
                }),
                body: formData
            })
                .then(res => res.json()).then(response => {
                    this.setState({ loading: false });
                    if (response.status && response.status === "success") {
                        let images = [];
                        images[0] = response.data.id;
                        this.addImagePost(images, response.data.url);
                    } else {
                        this.setState({ loading: false });
                        CheckErrorWithParam(response, this.setParameterErrors);
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    CheckErrorWithParam(err, this.setParameterErrors);
                });
        }
    }

    addImagePost(images, text, caption = "") {
        this.setState({ selectedImages: images })
        this.setState({ imageText: text })
        this.setState({ caption: caption });
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    render() {
        let imageContainer;
        if (this.state.selectedImages.length > 1) {
            imageContainer = (
                <div style={{ paddingBottom: "10px" }}>
                    {this.state.selectedImages.length} Images
        </div>
            );
        } else if (this.state.selectedImages.length === 1) {
            imageContainer = (
                <div
                    style={{
                        height: "150px", width: "150px", display: "inline-block", backgroundImage: `url(${this.state.imageText}`,
                        backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }}
                ></div>
            );
        } else {
            imageContainer = (
                <img
                    src="/assets/img/blank-profile-picture-973460__340.png"
                    alt="Foto profil"
                />
            );
        }
        return (
            <div className="col-lg-9 col-12">
                <div className="entry card card-input">
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        text='Please Wait...'
                    >
                        <ModalMedia
                            type={"post"}
                            images={this.state.images}
                            selectedImagesModal={this.state.selectedImagesModal}
                            selectedImages={this.state.selectedImages}
                            pagination={this.state.imagePagination}
                            addImagePostModal={this.state.addImagePostModal}
                            toggleAddImagePostModal={this.toggleAddImagePostModal}
                            loadingImage={this.state.loadingImage}
                            addImagePost={this.addImagePost}
                            refreshData={this.getImagePosts}
                            setSelectedImagesModal={this.setSelectedImagesModal}
                            keyword={this.state.keyword}
                            onChangeKeyword={this.onChangeKeyword}
                        />
                        <div className="col-lg-12 col-12 blog__content mt-3">
                            <div className="row card-row">
                                <div className="col-lg-6 offset-lg-3 col-12">
                                    <div className="foto-profile p-4" style={{ background: "#efeded61" }}>
                                        <div className="text-center">
                                            {imageContainer}
                                        </div>
                                        <div className="text-center mt-3">
                                            {(['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1 || this.state.profileData.is_verified == "1") && (
                                                <div>
                                                    <div className="form-group">
                                                        <label htmlFor="caption">Caption(Untuk Upload)</label>
                                                        <input type="text" placeholder="Caption" name="caption" value={this.state.caption} onChange={this.onChangeCaption} 
                                                            style={{marginBottom : "0px"}}
                                                        />
                                                        {this.state.errors.caption && this.state.errors.caption.message && (
                                                            <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                                <small style={{ color: 'white' }}>{this.state.errors.caption.message}</small>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <label className="btn btn btn-md btn-color btn-file" onClick={this.toggleAddImagePostModal} style={{ marginRight: "10px" }}>
                                                        Browse
                                                    </label>
                                                    <label className="btn btn btn-md btn-color btn-file">
                                                        Upload
                                                        <input
                                                            type="file"
                                                            onChange={this.handleFileUpload}
                                                        />
                                                    </label>
                                                    <p>
                                                        The image must be a file of type: jpg,
                                                        jpeg, png, ico
                                                    </p>
                                                </div>
                                            )}
                                            {this.state.errors.media_id &&
                                                this.state.errors.media_id.message && (
                                                    <div
                                                        className="help-block"
                                                        style={{
                                                            backgroundColor: 'red',
                                                            paddingLeft: 10,
                                                            paddingRight: 10
                                                        }}
                                                    >
                                                        <small style={{ color: 'white' }}>
                                                            {
                                                                this.state.errors.media_id
                                                                    .message
                                                            }
                                                        </small>
                                                    </div>
                                                )}
                                        </div>
                                        {/* <div className="text-center mt-3">
                      <button className="btn btn-sm btn-color btn-button">Set Foto profile</button>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row card-row">
                                <div className="col-lg-12 col-12 mt-3">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="ASD">Name <span className="text-red">*</span></label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input name="name" id="name" type="text" onChange={this.onChangeProfile} value={this.state.profileData.name} />
                                            </div>
                                            {this.state.errors.name && this.state.errors.name.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.name.message}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="email">Email <span className="text-red">*</span></label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input disable="true" readOnly={true}
                                                    name="email" placeholder=""
                                                    value={this.state.profileData.email}
                                                    style={{ backgroundColor: "#eeeeee" }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="no_hp">No Telepon <span className="text-red">*</span></label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input name="no_hp" type="number" value={this.state.profileData.no_hp} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.no_hp && this.state.errors.no_hp.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.no_hp.message.replace("no hp", "No Telepon")}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="alamat">Alamat </label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <textarea name="alamat" rows="7" value={this.state.profileData.alamat} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.alamat && this.state.errors.alamat.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.alamat.message}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="biografi">Biografi Singkat</label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <textarea name="biografi" rows="7" value={this.state.profileData.biografi} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.biografi && this.state.errors.biografi.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.biografi.message}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="facebook">Facebook</label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input name="facebook" type="text" value={this.state.profileData.facebook} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.facebook && this.state.errors.facebook.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.facebook.message}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="instagram">Instgram</label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input name="instagram" type="text" value={this.state.profileData.instagram} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.instagram && this.state.errors.instagram.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.instagram.message}</small>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-lg-3 col-12">
                                                <label htmlFor="twitter">Twitter</label>
                                            </div>
                                            <div className="col-lg-9 col-12">
                                                <input name="twitter" type="text" value={this.state.profileData.twitter} onChange={this.onChangeProfile} />
                                            </div>
                                            {this.state.errors.twitter && this.state.errors.twitter.message && (
                                                <div className="help-block col-12" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                                    <small style={{ color: 'white' }}>{this.state.errors.twitter.message}</small>
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: 16 }}>
                                            { (['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                                <Reaptcha
                                                    sitekey={sitekeyReaptcha}
                                                    onVerify={this.onVerify}
                                                />
                                            }
                                        </div>
                                        <button disabled={!this.state.verified || this.state.loading} id="button-update" type="submit" className="btn btn-md btn-color btn-button">Update</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        );
    }
}

export default FormProfile;

import AsyncPaginate from 'react-select-async-paginate';
import LoadingOverlay from 'react-loading-overlay';
import React, { Component } from 'react';
import Reaptcha from 'reaptcha';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import Select from 'react-select';
import { async } from 'q';
import { Link } from 'react-router-dom';

import { getStorageItem } from '../../../helpers/storage';
import { IsSSR } from '../../../helpers/isSSR';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import {
  apiUrl,
  assetsUrl,
  keyToken,
  keyUser,
  sitekeyReaptcha
} from '../../../config';

import DashboardSideBar from '../../../components/dashboard/dashboardSideBar';
import ReactQuill from '../../../components/textarea/ReactQuillCustom';
import ReactQuillWithImage from '../../../components/textarea/ReactQuillWithMedia';
import ModalMedia from '../../../components/modal/ModalMedia';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam';
import Toast from '../../../components/Toast';

import { scriptsHideAds, scriptsUnHideAds } from '../../../utils/scripts';
import SEOContentAnalyzer from 'seo-content-analyzer/dist/client';

class CreatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      subtitle: '',
      text: '',
      loading: false,
      loadingImage: false,
      checkbox: false,
      checkbox2: false,
      isEbook: false,
      publish_now: true,
      published_date: new Date(),
      categories: [],
      categoriesSelected: [],
      selectedImages: [],
      selectedMedia: [],
      selectedImagesModal: [],
      images: [],
      imagePagination: {
        total_found: 1,
        limit: 9,
        current_page: 1,
        total_page: 1
      },
      tags: [],
      newPostData: {
        title: '',
        type: 'article',
        upperdeck: '',
        lowerdeck: '',
        content: '',
        description: '',
        keyword: '',
        robot: 'ivoox.id',
        url: '',
        state_id: '',
        is_top_ten: 0,
        is_headline: 0,
        commentable: 1,
        medias: '',
        categories: '',
        sheduled_post: 0,

        // tags        : props.match.params.for === 'Nitizen Jurnalism' ? '20' : '',
        tags: '',
        is_featured: 0,
        subtitle: '',
        auto_post_twitter: 1,
        //Ebooks
        writer: '',
        publisher: '',
        published_date: new Date(),
        total_page: '',
        audio_status: 0,
        downloadable: 0,
        show_download_count: 0
      },
      errors: {},
      tagErrors: {},
      addImagePostModal: false,
      isAdmin: false,
      isEditor: false,
      isAuthor: false,
      isContributor: false,
      isAs: '',
      loadingRole: true,
      verified: false,
      caption: '',
      searchTag: '',
      nameTag: '',
      pdfEbook: null,
      audioEbook: null,
      result: SEOContentAnalyzer(
        {
          content: '',
          keyword: '',
          descriptionMeta: '',
          title: ''
        },
        'id'
      ),
      accordionActive: false
    };
    this.onChangePost = this.onChangePost.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSubtitle = this.handleChangeSubtitle.bind(this);
    this.onChangeDisclimer = this.onChangeDisclimer.bind(this);
    this.getCategoryPosts = this.getCategoryPosts.bind(this);
    this.getTagPosts = this.getTagPosts.bind(this);
    this.toggleAddImagePostModal = this.toggleAddImagePostModal.bind(this);
    this.getImagePosts = this.getImagePosts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeCheckboxPost = this.onChangeCheckboxPost.bind(this);
    this.addImagePost = this.addImagePost.bind(this);
    this.setSelectedImagesModal = this.setSelectedImagesModal.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileUploadPdf = this.handleFileUploadPdf.bind(this);
    this.handleFileUploadAudio = this.handleFileUploadAudio.bind(this);
    this.setValueTag = this.setValueTag.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.onChangeKeyword = this.onChangeKeyword.bind(this);
    this.onChangeCaption = this.onChangeCaption.bind(this);
    this.doCreateTag = this.doCreateTag.bind(this);
    this.onChangeCreateTag = this.onChangeCreateTag.bind(this);
    this.setParameterErrors = this.setParameterErrors.bind(this);
    this.setParameterTagErrors = this.setParameterTagErrors.bind(this);
    this.isSSR = IsSSR();
    this.token = null;
    this.user = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);
    if (!this.isSSR) this.user = getStorageItem(keyUser);
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
    if (!this.token) return this.props.history.push(`/auth`);
    this.getRoles().then((r) => console.log('getRoles reload'));
    setTimeout(() => this.getCategoryPosts());
    setTimeout(() => {
      scriptsHideAds(() => {
        console.log('[scriptsHideAds.js]');
      });
    }, 600);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.newPostData !== this.state.newPostData ||
      prevState.text !== this.state.text ||
      prevState.subtitle !== this.state.subtitle ||
      prevState.selectedImages !== this.state.selectedImages ||
      prevState.images !== this.state.images ||
      prevState.imageText !== this.state.imageText
    ) {
      this.updateSEOResult();
    }
  }

  componentWillUnmount() {
    scriptsUnHideAds(() => {
      console.log('[scriptsUnhideAds.js]');
    });
  }

  updateSEOResult() {
    const img =
      this.state.selectedImages.length > 0
        ? this.state.images.find((d) => d.id === this.state.selectedImages[0])
        : null;

    const param = {
      content:
        `<h1>${this.state.newPostData.title}</h1>` +
        ' ' +
        this.state.subtitle +
        ' ' +
        this.state.text +
        ' ' +
        (img
          ? `<img src="" alt="${img.caption}" title="${img.meta_tag}" />`
          : ''),
      keyword: this.state.newPostData.keyword,
      descriptionMeta: this.state.newPostData.description,
      title: this.state.newPostData.title
    };
    const result = SEOContentAnalyzer(param, 'id');
    this.setState({
      result: result
    });
  }

  getIndexOf(a, v) {
    var l = a.length;
    for (var k = 0; k < l; k++) {
      if (a[k].name == v) {
        return true;
      }
    }
    return false;
  }

  async getRoles() {
    this.setState({ loadingRole: true });

    const user = JSON.parse(this.user);
    var isAdmin = await this.getIndexOf(user.roles, 'admin');
    this.setState({ isAdmin: isAdmin });

    var isEditor = await this.getIndexOf(user.roles, 'editor');
    this.setState({ isEditor: isEditor });

    var isAuthor = await this.getIndexOf(user.roles, 'author');
    this.setState({ isAuthor: isAuthor });

    var isContributor = await this.getIndexOf(user.roles, 'contributor');
    this.setState({ isContributor: isContributor });

    if (isAdmin) this.setState({ isAs: 'admin' });
    else if (isEditor) this.setState({ isAs: 'editor' });
    else if (isAuthor) this.setState({ isAs: 'author' });
    else if (isContributor) this.setState({ isAs: 'contributor' });
    if (['admin', 'editor', 'author'].indexOf(this.state.isAs) > -1) {
      this.setState({ verified: true });
    }

    this.setState({ loadingRole: false });
  }

  onChangeKeyword(e) {
    const { name, value } = e.target;
    this.setState({ keyword: value });
  }

  onChangeCaption(e) {
    const { name, value } = e.target;
    this.setState({ caption: value });
  }

  onChangeCreateTag(e) {
    const { name, value } = e.target;
    this.setState({ nameTag: value });
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  handleChangeSubtitle(value) {
    this.setState({ subtitle: value });
  }

  handleChangePublishNow(e) {
    const { name, value, checked } = e.target;
    let { newPostData } = this.state;

    if (checked) {
      newPostData[name] = 1;
    } else {
      newPostData[name] = 0;
    }
    this.setState({ newPostData });
  }

  handleFileUpload(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', 'image');
      formData.append('caption', this.state.caption);
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            let images = [];
            images[0] = response.data.id;
            this.getImagePosts();
            this.addImagePost(images, response.data.url);
            this.toggleAddImagePostModal();
          } else {
            CheckErrorWithParam(response, this.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.setParameterErrors);
        });
    }
  }

  handleFileUploadPdf(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', 'document');
      formData.append('caption', 'Pdf ebook');
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            this.setState({ pdfEbook: response.data });
          } else {
            CheckErrorWithParam(response, this.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.setParameterErrors);
        });
    }
  }

  handleFileUploadAudio(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', 'audio');
      formData.append('caption', 'Audio Ebook');
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            this.setState({ audioEbook: response.data });
          } else {
            CheckErrorWithParam(response, this.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.setParameterErrors);
        });
    }
  }

  setSelectedImagesModal(selectedImagesModal) {
    this.setState({ selectedImagesModal });
  }

  onChangeCheckboxPost(e) {
    const { name, value, checked } = e.target;
    let { newPostData } = this.state;

    if (checked) {
      newPostData[name] = 1;
    } else {
      newPostData[name] = 0;
    }
    this.setState({ newPostData });
  }

  onChangeDisclimer(e) {
    const { name, value, checked } = e.target;
    if (checked) {
      this.setState({ [name]: 1 });
    } else {
      this.setState({ [name]: 0 });
    }
  }

  toggleAddImagePostModal() {
    if (this.state.newPostData.type) {
      if (!this.state.addImagePostModal) {
        this.getImagePosts();
      }
      let selectedImages = this.state.selectedImages;
      this.setState({
        addImagePostModal: !this.state.addImagePostModal,
        selectedImagesModal: selectedImages
      });
    } else {
      Toast('Silahkan pilih tipe post terlebih dahulu');
    }
  }

  addImagePost(images, text, caption = '') {
    this.setState({ selectedImages: images });
    this.setState({ imageText: text });
    this.setState({ caption: caption });
  }

  onChangePost(e) {
    const { name, value } = e.target;
    let { newPostData } = this.state;

    newPostData[name] = value;
    if (name === 'type') {
      if (value === 'ebook') {
        this.setState({
          isEbook: true
        });
      } else {
        this.setState({
          isEbook: false
        });
      }
      this.setState({
        newPostData: { ...newPostData, [name]: value },
        selectedImages: [],
        selectedImagesModal: [],
        imageText: ''
      });
    } else {
      this.setState({ newPostData: { ...newPostData, [name]: value } });
    }
  }

  async handleSubmit(state_id) {
    const { match } = this.props;
    var forTitle = match.params.for;

    if (!this.state.checkbox || !this.state.checkbox2) {
      Toast('Anda Belum Mencentang Checkbox.');
    } else {
      let newPostData = this.state.newPostData;
      newPostData.content = this.state.text;
      newPostData.subtitle = this.state.subtitle;
      newPostData.robot = 'ivoox.id';
      newPostData.state_id = state_id;
      newPostData.medias = this.state.selectedImages.join(',');
      newPostData.seo_score = this.state.result.seoScore;
      let categoriesSelected = await this.state.categoriesSelected.map(
        (category) => {
          return category.value;
        }
      );

      if (newPostData.sheduled_post === 1) {
        newPostData.state_id = 7;
      }

      if (newPostData.type === 'ebook') {
        newPostData.published_date = moment(this.state.published_date).format(
          'YYYY-MM-DD'
        );
        if (newPostData.medias === '') {
          this.setState({
            errors: {
              medias: { message: 'Media required' }
            }
          });
          return;
        }

        if (this.state.pdfEbook === null) {
          this.setState({
            errors: {
              pdfEbook: { message: 'File Pdf required' }
            }
          });
          return;
        } else {
          newPostData.medias += ',' + this.state.pdfEbook.id;
        }
        if (newPostData.audio_status === 1) {
          if (this.state.audioEbook === null) {
            this.setState({
              errors: {
                audioEbook: { message: 'File Audio required' }
              }
            });
            return;
          } else {
            newPostData.medias += ',' + this.state.audioEbook.id;
          }
        }
      } else {
        newPostData.published_date = moment(this.state.published_date).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }
      if (
        forTitle === 'Nitizen Jurnalism' &&
        categoriesSelected.indexOf('63') === -1
      ) {
        categoriesSelected.push('63');
      }
      let tagsSelected = await this.state.tags.map((tag) => {
        return tag.value;
      });
      let categoriesText = categoriesSelected.join(',');
      newPostData.categories = categoriesText;
      newPostData.tags = tagsSelected.join(',');
      this.setParameterTagErrors({});
      this.setState(
        {
          loading: true,
          newPostData
        },
        () => {
          const params = newPostData;
          const route = `/post/create`;
          Api.post(route, params, this.token)
            .then((response) => {
              if (response.status && response.status === 'success') {
                Toast(response.message, 'success');
                this.props.history.push(
                  `/admin/${
                    forTitle === 'Nitizen Jurnalism'
                      ? 'nitizenJurnalism'
                      : 'post'
                  }`
                );
              } else {
                this.setState({ loading: false });
                CheckErrorWithParam(response, this.setParameterErrors);
              }
            })
            .catch((error) => {
              this.setState({ loading: false });
              CheckErrorWithParam(error, this.setParameterErrors);
            });
        }
      );
    }
  }

  getImagePosts(page = 1, limit = 9) {
    this.setState({ loadingImage: true });
    const route =
      `/media/index?type=image&page=` +
      page +
      `&limit=` +
      limit +
      `&keyword=` +
      this.state.keyword;
    Api.get(route, this.token)
      .then((response) => {
        if (response.status && response.status === 'success') {
          this.setState({
            images: response.data,
            imagePagination: response.pagination,
            loadingImage: false
          });
        } else {
          this.setState({ loadingImage: false });
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loadingImage: false });
        CheckError(err);
      });
  }

  getCategoryPosts() {
    this.setState({ loading: true });
    const route = `/category/all`;
    Api.get(route, this.token)
      .then(async (response) => {
        if (response.status && response.status === 'success') {
          let options = await response.data.map((value) => {
            let item = {
              value: value.id,
              label: value.name
            };
            return item;
          });
          this.setState({ categories: options, loading: false });
        } else {
          this.setState({ loadingImage: false });
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        CheckError(err);
      });
  }

  handleChangeCategory(value) {
    let categoriesSelected = value;
    this.setState({ categoriesSelected });
  }

  onChangeDate(date) {
    // console.log(this.state);
    // let { newPostData } = this.state;
    // newPostData['published_date'] = date;
    // console.log(date);
    console.log(date);
    this.setState({ published_date: date });
  }

  async getTagPosts(search, loadedOptions, { page }) {
    let { pageTag, keywordTag } = this.state;
    this.setState({ searchTag: search });
    const route = `/tag/index?page=${page}&limit=10&keyword=${search}&sort_field=name&sort_by=ASC`;
    const response = await fetch(`${apiUrl}` + route);
    const responseJSON = await response.json();
    let options = [];
    let hasMore = false;
    if (responseJSON.status && responseJSON.status === 'success') {
      options = await responseJSON.data.map((tag) => {
        let tagResponse = {
          value: tag.id,
          label: tag.name
        };
        return tagResponse;
      });
      if (
        responseJSON.pagination.total_page >
        responseJSON.pagination.current_page
      ) {
        hasMore = true;
        this.setState({ pageTag: this.state.pageTag + 1 });
      } else {
        hasMore = false;
      }
    }
    return {
      options: options,
      hasMore: hasMore,
      additional: {
        page: page + 1
      }
    };
  }

  setValueTag(value) {
    let tags = value;
    this.setState({ tags });
  }

  imageHandler() {
    console.log('imageHandler');
  }

  onVerify = (recaptchaResponse) => {
    this.setState({
      verified: true
    });
  };

  doCreateTag(e) {
    e.preventDefault();
    this.setParameterTagErrors({});
    this.setState({ loading: true });
    const params = {
      name: this.state.nameTag
    };
    const route = `/tag/create`;
    Api.post(route, params, this.token)
      .then((response) => {
        this.setState({ loading: false });
        if (response.status && response.status === 'success') {
          Toast(response.message, 'success');
          let tagsArr = this.state.tags;
          let item = {
            value: response.data.id,
            label: response.data.name
          };
          tagsArr.push(item);
          this.setState({ tags: tagsArr, searchTag: '1010101' });
          this.setState({ searchTag: '' });
        } else {
          CheckErrorWithParam(response, this.setParameterTagErrors);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        CheckErrorWithParam(err, this.setParameterTagErrors);
      });
  }

  setParameterErrors(data) {
    this.setState({
      errors: data
    });
  }

  setParameterTagErrors(data) {
    this.setState({
      tagErrors: data
    });
  }

  render() {
    const { isAs, loadingRole, isEbook, result } = this.state;
    const { match } = this.props;
    const percentage = result.seoScore;
    var forTitle = match.params.for;
    console.log(this.state);

    let imageContainer;
    if (this.state.selectedImages.length > 1) {
      imageContainer = (
        <div style={{ paddingBottom: '10px' }}>
          {this.state.selectedImages.length} Images
        </div>
      );
    } else if (this.state.selectedImages.length === 1) {
      imageContainer = (
        <div style={{ paddingBottom: '10px' }}>
          <div
            style={{
              height: '150px',
              width: '100%',
              backgroundImage: `url(${this.state.imageText}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          ></div>
        </div>
      );
    }

    if (loadingRole) return null;
    return (
      <div>
        <div className="main-container">
          <ul className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link to="/admin/dashboard" className="breadcrumbs__url">
                Home
              </Link>
            </li>
            <li className="breadcrumbs__item">
              <Link
                to={`/admin/${
                  forTitle === 'Nitizen Jurnalism' ? 'nitizenJurnalism' : 'post'
                }`}
                className="breadcrumbs__url"
              >
                {forTitle}
              </Link>
            </li>
            <li className="breadcrumbs__item breadcrumbs__item--current">
              Add
            </li>
          </ul>
        </div>
        <div className="main-container" id="main-container">
          <div className="blog__content mb-72">
            <h1 className="page-title">{forTitle}</h1>
            <div className="row card-row">
              <DashboardSideBar
                isAs={isAs}
                localUser={this.user}
                {...this.props}
              />
              <div className="col-lg-9 col-12">
                <div className="entry card card-input card-new-post">
                  <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text="Please Wait..."
                  >
                    <ModalMedia
                      type={this.state.newPostData.type}
                      images={this.state.images}
                      selectedImagesModal={this.state.selectedImagesModal}
                      selectedImages={this.state.selectedImages}
                      pagination={this.state.imagePagination}
                      addImagePostModal={this.state.addImagePostModal}
                      toggleAddImagePostModal={this.toggleAddImagePostModal}
                      loadingImage={this.state.loadingImage}
                      addImagePost={this.addImagePost}
                      refreshData={this.getImagePosts}
                      setSelectedImagesModal={this.setSelectedImagesModal}
                      keyword={this.state.keyword}
                      onChangeKeyword={this.onChangeKeyword}
                    />
                    <div className="row">
                      <div className="col-lg-7 col-12 border-right-editor">
                        <div
                          className={`seo-reader ${
                            this.state.accordionActive ? 'active' : ''
                          }`}
                        >
                          <div
                            className="score"
                            onClick={() => {
                              this.setState({
                                accordionActive: !this.state.accordionActive
                              });
                            }}
                          >
                            <h3 className={`seo-title`}>Analisa SEO</h3>
                            <div style={{ width: 50 }}>
                              <CircularProgressbar
                                value={percentage}
                                text={`${percentage}%`}
                                styles={buildStyles({
                                  textSize: '22px',
                                  pathTransitionDuration: 0.5,
                                  pathColor:
                                    percentage >= 70
                                      ? '#a9ff7a'
                                      : percentage >= 40
                                      ? '#ffef7a'
                                      : '#ff907a',
                                  textColor: '#000000'
                                })}
                              />
                            </div>
                          </div>
                          {this.state.result.sectionScore.map((ss, i) => (
                            <div key={i} className="sso-section">
                              <h3 className="sso-section-title">{ss.name}</h3>

                              {ss.messages
                                .filter(
                                  (m) =>
                                    !['H1_EXIST', 'H1_USE_KEYWORD'].includes(
                                      m.code
                                    )
                                )
                                .map((m, i) => (
                                  <div className="message">
                                    <div className={`box ${m.status}`}></div>
                                    <div>
                                      <p>{m.text}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ))}
                        </div>

                        <div className="form-group">
                          <label htmlFor="title">
                            Judul <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Tulis Judul di Sini"
                            name="title"
                            required
                            value={this.state.newPostData.title}
                            onChange={this.onChangePost}
                          />
                          {this.state.errors.title &&
                            this.state.errors.title.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.title.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="subtitle">Subtitle</label>
                          <ReactQuill
                            name="subtitle"
                            value={this.state.subtitle}
                            handleChange={this.handleChangeSubtitle}
                          />
                          {this.state.errors.subtitle &&
                            this.state.errors.subtitle.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.subtitle.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="upperdeck">Upperdeck</label>
                          <input
                            type="text"
                            placeholder="Upperdeck"
                            name="upperdeck"
                            value={this.state.newPostData.upperdeck}
                            onChange={this.onChangePost}
                          />
                          {this.state.errors.upperdeck &&
                            this.state.errors.upperdeck.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.upperdeck.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="lowerdeck">Lowerdeck</label>
                          <input
                            type="text"
                            placeholder="Lowerdeck"
                            name="lowerdeck"
                            value={this.state.newPostData.lowerdeck}
                            onChange={this.onChangePost}
                          />
                          {this.state.errors.lowerdeck &&
                            this.state.errors.lowerdeck.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.lowerdeck.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="lowerdeck">Keyword</label>
                          <input
                            type="text"
                            placeholder="Keyword"
                            name="keyword"
                            value={this.state.newPostData.keyword}
                            onChange={this.onChangePost}
                          />
                          {this.state.errors.keyword &&
                            this.state.errors.keyword.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.keyword.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="description">Description</label>
                          <input
                            type="text"
                            placeholder="Description Meta"
                            name="description"
                            required
                            value={this.state.newPostData.description}
                            onChange={this.onChangePost}
                          />
                          {this.state.errors.description &&
                            this.state.errors.description.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.description.message}
                                </small>
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="subtitle">
                            Teks <span className="text-red">*</span>
                          </label>
                          <ReactQuillWithImage
                            name="content"
                            value={this.state.text}
                            handleChange={this.handleChange}
                            token={this.token}
                          />
                          {this.state.errors.content &&
                            this.state.errors.content.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.content.message}
                                </small>
                              </div>
                            )}
                        </div>
                        {isEbook && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="published_date">
                                Tanggal Terbit
                              </label>
                              {/* <input
                                type='text'
                                placeholder='Tanggal Terbit'
                                name='published_date'
                                value={this.state.newPostData.published_date}
                                onChange={this.onChangePost}
                              /> */}
                              <div>
                                <DatePicker
                                  selected={this.state.published_date}
                                  name="published_date"
                                  onChange={(newDate) => {
                                    this.setState({ published_date: newDate });
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                              {this.state.errors.published_date &&
                                this.state.errors.published_date.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.published_date.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="writer">Penulis</label>
                              <input
                                type="text"
                                placeholder="Penulis"
                                name="writer"
                                value={this.state.newPostData.writer}
                                onChange={this.onChangePost}
                              />
                              {this.state.errors.writer &&
                                this.state.errors.writer.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.writer.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="publisher">Penerbit</label>
                              <input
                                type="text"
                                placeholder="Penerbit"
                                name="publisher"
                                value={this.state.newPostData.publisher}
                                onChange={this.onChangePost}
                              />
                              {this.state.errors.publisher &&
                                this.state.errors.publisher.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.publisher.message}
                                    </small>
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <label htmlFor="published_date">
                                Jumlah Halaman
                              </label>
                              <input
                                type="text"
                                placeholder="Jumlah Halaman"
                                name="total_page"
                                value={this.state.newPostData.total_page}
                                onChange={this.onChangePost}
                              />
                              {this.state.errors.total_page &&
                                this.state.errors.total_page.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.total_page.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        {/* <div style={{ marginBottom: 16 }}>
                                                    <Reaptcha
                                                    sitekey={sitekeyReaptcha}
                                                    onVerify={this.onVerify}
                                                    />
                                                </div> */}
                      </div>

                      <div className="col-lg-5 col-12">
                        {/* <div className="form-group">
                                                    <h4 className="widget-status">Status: <span>Draft</span></h4>
                                                    <hr/>
                                                </div> */}
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Kategori
                          </h4>
                          <Select
                            isMulti
                            name="colors"
                            defaultValue={this.state.categoriesSelected}
                            options={this.state.categories}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChangeCategory}
                            placeholder="-- Pilih Kategori --"
                          />
                          {this.state.errors.categories &&
                            this.state.errors.categories.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.categories.message}
                                </small>
                              </div>
                            )}
                          <hr />
                        </div>
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Tag
                          </h4>
                          <AsyncPaginate
                            isMulti
                            debounceTimeout={200}
                            cacheUniq={this.state.searchTag}
                            value={this.state.tags}
                            loadOptions={this.getTagPosts}
                            onChange={this.setValueTag}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="-- Pilih Tag --"
                            additional={{ page: 1 }}
                          />
                          <form
                            className="mc4wp-form"
                            name="formSubscribe"
                            style={{
                              marginBottom: '0px',
                              marginTop: '15px'
                            }}
                            onSubmit={this.doCreateTag}
                          >
                            <label htmlFor="new-tag">Buat tag baru.</label>
                            <div className="mc4wp-form-fields">
                              <div className="form-group">
                                <input
                                  type="text"
                                  style={{
                                    marginBottom: '0px'
                                  }}
                                  id="new-tag"
                                  name="name"
                                  placeholder="Name Tag"
                                  value={this.state.nameTag}
                                  onChange={this.onChangeCreateTag}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="submit"
                                  style={{
                                    marginBottom: '0px',
                                    zIndex: '0'
                                  }}
                                  className="btn btn-md btn-color"
                                  value="Create"
                                />
                              </div>
                            </div>
                          </form>
                          {this.state.tagErrors.name &&
                            this.state.tagErrors.name.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.tagErrors.name.message}
                                </small>
                              </div>
                            )}
                          {this.state.tagErrors.slug &&
                            this.state.tagErrors.slug.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.tagErrors.slug.message}
                                </small>
                              </div>
                            )}
                          {this.state.errors.tags &&
                            this.state.errors.tags.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.tags.message}
                                </small>
                              </div>
                            )}
                          <hr />
                        </div>
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Tipe Post
                          </h4>
                          <select
                            className="nomargin"
                            name="type"
                            value={this.state.newPostData.type}
                            onChange={this.onChangePost}
                          >
                            <option value="" disabled>
                              -- Pilih Tipe --
                            </option>
                            <option value="article">Post</option>
                            <option value="podcast">Podcast</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                            <option value="ebook">Ebook</option>
                          </select>
                          {this.state.errors.type &&
                            this.state.errors.type.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.type.message}
                                </small>
                              </div>
                            )}
                          <hr />
                        </div>
                        {['admin', 'editor'].indexOf(isAs) > -1 && (
                          <div className="form-group">
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="is_top_ten"
                                  id="is_top_ten"
                                  value="1"
                                  checked={
                                    this.state.newPostData.is_top_ten === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="is_top_ten"
                                >
                                  Is Top Ten
                                </label>
                              </div>
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="is_headline"
                                  id="is_headline"
                                  value="1"
                                  checked={
                                    this.state.newPostData.is_headline === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="is_headline"
                                >
                                  Is Headline
                                </label>
                              </div>
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="is_featured"
                                  id="is_featured"
                                  value="1"
                                  checked={
                                    this.state.newPostData.is_featured === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="is_featured"
                                >
                                  Is Featured
                                </label>
                              </div>
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="commentable"
                                  id="commentable"
                                  value="1"
                                  checked={
                                    this.state.newPostData.commentable === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="commentable"
                                >
                                  Comments
                                </label>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )}
                        {['admin', 'editor', 'author'].indexOf(isAs) > -1 && (
                          <div className="form-group">
                            <h4 className="widget-title widget-title-form">
                              Auto Post
                            </h4>
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="auto_post_twitter"
                                  id="auto_post_twitter"
                                  value="1"
                                  checked={
                                    this.state.newPostData.auto_post_twitter ===
                                    1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="auto_post_twitter"
                                >
                                  Twitter
                                </label>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )}
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Featured Image
                          </h4>
                          {imageContainer}
                          <div>
                            <div className="form-group">
                              <label htmlFor="caption">
                                Caption(Untuk Upload)
                              </label>
                              <input
                                type="text"
                                placeholder="Caption"
                                name="caption"
                                value={this.state.caption}
                                onChange={this.onChangeCaption}
                                style={{
                                  marginBottom: '0px'
                                }}
                              />
                              {this.state.errors.caption &&
                                this.state.errors.caption.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.caption.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                            <label
                              className="btn btn btn-md btn-color btn-file"
                              onClick={this.toggleAddImagePostModal}
                              style={{
                                marginRight: '10px'
                              }}
                            >
                              Browse
                            </label>
                            <label className="btn btn btn-md btn-color btn-file">
                              Upload
                              <input
                                type="file"
                                onChange={this.handleFileUpload}
                              />
                            </label>
                          </div>
                          <p>
                            The image must be a file of type: jpg, jpeg, png,
                            ico
                          </p>
                          {this.state.errors.medias &&
                            this.state.errors.medias.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small
                                  style={{
                                    color: 'white'
                                  }}
                                >
                                  {this.state.errors.medias.message}
                                </small>
                              </div>
                            )}
                          <hr />
                        </div>

                        {isEbook && (
                          <div className="form-group">
                            <h4 className="widget-title widget-title-form">
                              Ebook
                            </h4>
                            {this.state.pdfEbook && (
                              <div class="col-md-12">
                                <div>
                                  <a
                                    target="blank"
                                    href={this.state.pdfEbook.url}
                                  >
                                    <div
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <i
                                        class="fa fa-file"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: '50px',
                                          marginTop: '15px',
                                          display: 'block'
                                        }}
                                      ></i>
                                      <span>application/pdf</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            )}
                            <div>
                              <label className="btn btn btn-md btn-color btn-file">
                                Upload PDF
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  onChange={this.handleFileUploadPdf}
                                />
                              </label>
                            </div>
                            <p>Please upload ebook with .pdf format.</p>
                            <div className="form-group">
                              {this.state.errors.pdfEbook &&
                                this.state.errors.pdfEbook.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.pdfEbook.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                            <div>
                              {this.state.audioEbook && (
                                <div class="col-md-12">
                                  <div>
                                    <a
                                      target="blank"
                                      href={this.state.audioEbook.url}
                                    >
                                      <div
                                        style={{
                                          height: '100%',
                                          width: '100%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <i
                                          class="fa fa-file"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: '50px',
                                            marginTop: '15px',
                                            display: 'block'
                                          }}
                                        ></i>
                                        <span>audio</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              )}

                              <label className="btn btn btn-md btn-color btn-file">
                                Upload Audio
                                <input
                                  type="file"
                                  accept="audio/mpeg"
                                  onChange={this.handleFileUploadAudio}
                                />
                              </label>
                            </div>
                            <p>Please upload audio with .mp3 format.</p>
                            <div className="form-group">
                              {this.state.errors.audioEbook &&
                                this.state.errors.audioEbook.message && (
                                  <div
                                    className="help-block"
                                    style={{
                                      backgroundColor: 'red',
                                      paddingLeft: 10,
                                      paddingRight: 10
                                    }}
                                  >
                                    <small
                                      style={{
                                        color: 'white'
                                      }}
                                    >
                                      {this.state.errors.audioEbook.message}
                                    </small>
                                  </div>
                                )}
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="downloadable"
                                  id="downloadable"
                                  value="1"
                                  checked={
                                    this.state.newPostData.downloadable === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="downloadable"
                                >
                                  Downloadable
                                </label>
                              </div>
                              <div className="col-6">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="audio_status"
                                  id="audio_status"
                                  value="1"
                                  checked={
                                    this.state.newPostData.audio_status === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="audio_status"
                                >
                                  Audio Status
                                </label>
                              </div>
                              <div className="col-12">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  onChange={this.onChangeCheckboxPost}
                                  name="show_download_count"
                                  id="show_download_count"
                                  value="1"
                                  checked={
                                    this.state.newPostData
                                      .show_download_count === 1
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="checkbox-input"
                                  htmlFor="show_download_count"
                                >
                                  Show Download Counter
                                </label>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )}
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Publish Date
                          </h4>

                          <div className="checkbox checkbox-disclaimer">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              onChange={this.onChangeCheckboxPost}
                              name="sheduled_post"
                              id="sheduled_post"
                              value="1"
                              checked={
                                this.state.newPostData.sheduled_post === 1
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="checkbox-input"
                              htmlFor="sheduled_post"
                            >
                              Publish Terjadwal{' '}
                            </label>
                          </div>
                          <br></br>
                          {this.state.newPostData.sheduled_post === 1 && (
                            <div>
                              <DatePicker
                                selected={this.state.published_date}
                                name="published_date"
                                showTimeSelect
                                timeFormat="HH:mm"
                                minDate={new Date()}
                                onChange={(newDate) => {
                                  this.setState({ published_date: newDate });
                                }}
                                dateFormat="dd/MM/yyyy HH:mm"
                              />
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <h4 className="widget-title widget-title-form">
                            Disclaimer
                          </h4>
                          <div className="checkbox checkbox-disclaimer">
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              onChange={this.onChangeDisclimer}
                              name="checkbox"
                              id="checkbox1"
                              value=" Saya setuju jika artikel saya disunting oleh Editor Community"
                            />
                            <label
                              className="checkbox-input"
                              htmlFor="checkbox1"
                            >
                              Saya setuju jika artikel saya disunting oleh
                              Editor Community.
                            </label>
                            <input
                              type="checkbox"
                              className="checkbox-input"
                              onChange={this.onChangeDisclimer}
                              name="checkbox2"
                              id="checkbox2"
                              value=" Saya bertanggung jawab sepenuhnya terhadap tulisan saya"
                            />
                            <label
                              className="checkbox-input"
                              htmlFor="checkbox2"
                            >
                              Saya bertanggung jawab sepenuhnya terhadap tulisan
                              saya.
                            </label>
                          </div>
                          <hr />
                        </div>
                        <div
                          style={{
                            marginBottom: 16,
                            transform: 'scale(0.9)',
                            transformOrigin: '0 0'
                          }}
                        >
                          {['admin', 'editor', 'author'].indexOf(
                            this.state.isAs
                          ) <= -1 && (
                            <Reaptcha
                              sitekey={sitekeyReaptcha}
                              onVerify={this.onVerify}
                            />
                          )}
                        </div>
                        <div className="form-group">
                          {forTitle === 'Nitizen Jurnalism' ? (
                            <button
                              disabled={
                                !this.state.verified || this.state.loading
                              }
                              type="button"
                              onClick={() =>
                                this.handleSubmit(
                                  ['admin', 'editor'].indexOf(isAs) > -1 ? 3 : 2
                                )
                              }
                              className="btn btn-md btn-color btn-button w-100 mb-3"
                            >
                              {['admin', 'editor'].indexOf(isAs) > -1
                                ? 'Publish'
                                : 'Submit To Review'}
                            </button>
                          ) : (
                            <div>
                              <button
                                disabled={
                                  !this.state.verified || this.state.loading
                                }
                                type="button"
                                onClick={() => this.handleSubmit(1)}
                                className="btn btn-md btn-dark btn-button w-100 mb-3"
                              >
                                Simpan Konsep
                              </button>
                              <button
                                disabled={
                                  !this.state.verified || this.state.loading
                                }
                                type="button"
                                onClick={() => this.handleSubmit(3)}
                                className="btn btn-md btn-color btn-button w-100 mb-3"
                              >
                                Publish
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePost;

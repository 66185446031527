// export const Storage = () => {
// 	if (!typeof window.localStorage === "undefined") return window.localStorage;
// 	else if (!typeof localStorage === "undefined") return localStorage;
// 	else return false;
// };

export const getStorageItem = (key) => {
	try {
		return localStorage.getItem(key)
	} catch (e) {
		return null
	}
};

export const setStorageItem = async (key, value) => {
	try {
		await localStorage.setItem(key, value);
		return getStorageItem(key)
	} catch (e) {
		return false
	}
};

export const removeStorageItem = async (key) => {
	try {
		return localStorage.removeItem(key)
	} catch (e) {
		return false
	}
};
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import ModalDetailMedia from './modal.js';
import Reaptcha from 'reaptcha';

import { apiUrl, sitekeyReaptcha } from '../../../config';

import Api from '../../../services/api';
import CheckError from '../../../utils/admin/checkError';
import CheckErrorWithParam from '../../../utils/admin/checkErrorWithParam';
import NotificationVerify from '../../../utils/admin/notificationVerify';
import Toast from '../../../components/Toast';

const unselectedMedia = {
  display: 'block',
  width: '100%',
  height: '100%'
};

class Media extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusDetailModal: false,
      selectedMedia: {},
      loadingContainer: false,
      loading: false,
      keyword: '',
      type: '',
      date: '',
      dimension: 'post',
      errors: {},
      entities: {
        data: [],
        pagination: {
          total_found: 1,
          limit: 18,
          current_page: 1,
          total_page: 1
        }
      },
      first_page: 1,
      current_page: 1,
      offset: 4,
      limit: 18,
      isAdmin: false,
      isEditor: false,
      isAuthor: false,
      isContributor: false,
      isAs: '',
      loadingRole: true,
      verified: false,
      file: null,
      caption: '',
      mediaType: ''
    };

    this.onChangeMetaTag = this.onChangeMetaTag.bind(this);
    this.refreshMedias = this.refreshMedias.bind(this);
    this.onChange = this.onChange.bind(this);
    this.refreshMedias = this.refreshMedias.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.detailMedia = this.detailMedia.bind(this);
    this.toggleDetailModal = this.toggleDetailModal.bind(this);
    this.setParameterErrors = this.setParameterErrors.bind(this);
    this.onChangeCaption = this.onChangeCaption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeCaptionUpdate = this.onChangeCaptionUpdate.bind(this);
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    this.checkValue(params);
    if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
      this.setState({ verified: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let params = new URLSearchParams(this.props.location.search);
    let paramsPrev = new URLSearchParams(prevProps.location.search);
    if (
      paramsPrev.get('page') !== params.get('page') ||
      paramsPrev.get('type') !== params.get('type') ||
      paramsPrev.get('date') !== params.get('date') ||
      paramsPrev.get('keyword') !== params.get('keyword')
    ) {
      this.checkValue(params);
    }
  }

  checkValue(params) {
    this.setState({
      type: params.get('type') ? params.get('type') : '',
      date: params.get('date') ? params.get('date') : '',
      keyword: params.get('keyword') ? params.get('keyword') : '',
      current_page:
        params.get('page') && !isNaN(params.get('page'))
          ? params.get('page')
          : ''
    });
    setTimeout(() => this.refreshMedias());
  }

  onChangeCaption(e) {
    const { name, value } = e.target;
    this.setState({ caption: value });
  }

  onChangeMetaTag(e) {
    const { name, value } = e.target;
    let { selectedMedia } = this.state;
    selectedMedia['meta_tag'] = value;
    this.setState({ selectedMedia });
  }

  onChangeCaptionUpdate(e) {
    const { name, value } = e.target;
    let { selectedMedia } = this.state;
    selectedMedia['caption'] = value;
    this.setState({ selectedMedia });
  }

  getFileExtension3(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  }

  onChangeImage(e) {
    const file = e.target.files;
    let mediaType = '';

    if (file && file[0]) {
      let fileExt = this.getFileExtension3(file[0].name);
      if (
        ['avi', 'mp4', 'mpg', '3gp', 'webm', 'mkv', 'gifv', 'wmv'].includes(
          fileExt
        )
      ) {
        mediaType = 'video';
      } else if (['wav', 'aif', 'mp3', 'mid'].includes(fileExt)) {
        mediaType = 'audio';
      } else if (
        ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'].includes(fileExt)
      ) {
        mediaType = 'document';
      } else {
        mediaType = 'image';
      }
      this.setState({ mediaType: mediaType, file: file[0] });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const file = this.state.file;
    this.setParameterErrors({});
    if (file) {
      this.setState({ loading: true });
      const formData = new FormData();
      let arrayName = file.name.split('.');
      arrayName.pop();
      let nameImage = arrayName.join(' ');

      formData.append('media', file);
      formData.append('meta_tag', nameImage.substring(0, 80));
      formData.append('media_type', this.state.mediaType);
      formData.append('caption', this.state.caption);
      formData.append('dimension', this.state.dimension);
      const route = `/media/create`;
      fetch(`${apiUrl}` + route, {
        method: 'POST',
        headers: new Headers({
          'x-access-token': this.props.token
        }),
        body: formData
      })
        .then((res) => res.json())
        .then((response) => {
          this.setState({ loading: false });
          if (response.status && response.status === 'success') {
            this.setState({ file: null, caption: '', mediaType: '' });
            this.changePage(1);
            Toast(response.message, 'success');
          } else {
            CheckErrorWithParam(response, this.setParameterErrors);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          CheckErrorWithParam(err, this.setParameterErrors);
        });
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  pagesNumbers() {
    let from = this.state.entities.pagination.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + this.state.offset * 2;
    if (to >= this.state.entities.pagination.total_page) {
      to = this.state.entities.pagination.total_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  pageList() {
    return this.pagesNumbers().map((page) => {
      return (
        <span
          key={page}
          style={{ cursor: 'pointer' }}
          className={`pagination__page ${
            page === this.state.entities.pagination.current_page
              ? 'pagination__page--current'
              : null
          }`}
          onClick={() => this.changePage(page)}
        >
          {page}
        </span>
      );
    });
  }

  refreshMedias(page = 1, limit = 18) {
    const user = JSON.parse(this.props.localUser);
    let current_page =
      this.state.current_page &&
      !isNaN(this.state.current_page) &&
      this.state.current_page >= 1
        ? this.state.current_page
        : 1;
    var route =
      `/media/index?page=` +
      current_page +
      `&limit=` +
      limit +
      `&keyword=` +
      this.state.keyword +
      `&type=` +
      this.state.type +
      `&date=` +
      this.state.date;
    if (['admin', 'editor'].indexOf(this.state.isAs) > -1) {
    } else {
      route =
        `/media/index?page=` +
        current_page +
        `&limit=` +
        limit +
        `&keyword=` +
        this.state.keyword +
        `&type=` +
        this.state.type +
        `&date=` +
        this.state.date +
        `&user_id=` +
        user.id;
    }
    Api.get(route, this.props.token)
      .then((response) => {
        if (response.status && response.status === 'success') {
          let { entities } = this.state;
          entities['data'] = response.data;
          entities['pagination'] = response.pagination;
          this.setState({ entities: entities, loading: false });
        } else {
          CheckError(response);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        CheckError(err);
      });
  }

  changePage(pageNumber) {
    this.setState({ current_page: pageNumber }, () => {
      this.props.history.push(
        `/admin/media?page=${pageNumber}&type=${this.state.type}&date=${this.state.date}&keyword=${this.state.keyword}`
      );
      // window.scrollTo(0, 0);
      // this.refreshMedias()
    });
  }

  detailMedia(index) {
    this.setState({
      selectedMedia: this.state.entities.data[index],
      statusDetailModal: !this.state.statusDetailModal,
      errors: {}
    });
  }

  toggleDetailModal() {
    this.setState({
      statusDetailModal: !this.state.statusDetailModal,
      errors: {}
    });
  }

  setParameterErrors(data) {
    this.setState({
      errors: data
    });
  }

  onVerify = (recaptchaResponse) => {
    this.setState({
      verified: true
    });
  };

  render() {
    let medias =
      this.state.entities.data.length > 0 &&
      this.state.entities.data.map((media, index) => {
        if (media.media_type === 'image') {
          return (
            <div
              key={'media-' + media.id}
              className="col-lg-4 col-sm-6 ivoox-media-img"
              style={{ height: '128px' }}
              title={media.meta_tag}
            >
              <a
                onClick={() => this.detailMedia(index)}
                href="javascript:void(0);"
                style={unselectedMedia}
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(${media.url}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                ></div>
              </a>
            </div>
          );
        } else {
          return (
            <div
              key={'media-' + media.id}
              className="col-md-2 col-4  ivoox-media-img"
              style={{ height: '128px' }}
              title={media.meta_tag}
            >
              <a
                onClick={() => this.detailMedia(index)}
                href="javascript:void(0);"
                style={unselectedMedia}
              >
                <div
                  style={{ height: '100%', width: '100%', textAlign: 'center' }}
                >
                  <i
                    className="fa fa-file"
                    aria-hidden="true"
                    style={{
                      fontSize: '50px',
                      marginTop: '15px',
                      display: 'block'
                    }}
                  ></i>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      height: '68px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {media.file_type}
                  </span>
                </div>
              </a>
            </div>
          );
        }
      });
    return (
      <div className="col-lg-9 col-12">
        <NotificationVerify
          isAs={this.props.isAs}
          localUser={this.props.localUser}
        >
          <div className="entry card card-input">
            <LoadingOverlay
              active={this.state.loading}
              spinner
              text="Please Wait..."
            >
              <ModalDetailMedia
                statusDetailModal={this.state.statusDetailModal}
                selectedMedia={this.state.selectedMedia}
                errors={this.state.errors}
                onChangeMetaTag={this.onChangeMetaTag}
                toggleDetailModal={this.toggleDetailModal}
                refreshMedias={this.refreshMedias}
                setParameterErrors={this.setParameterErrors}
                onChangeCaptionUpdate={this.onChangeCaptionUpdate}
                token={this.props.token}
              />
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h4 className="heading-title">Add New Media</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="caption">Caption</label>
                          <input
                            name="caption"
                            placeholder=""
                            id="caption"
                            type="text"
                            class="mb-0"
                            onChange={this.onChangeCaption}
                            value={this.state.caption}
                          />
                          {this.state.errors.caption &&
                            this.state.errors.caption.message && (
                              <div
                                className="help-block"
                                style={{
                                  backgroundColor: 'red',
                                  paddingLeft: 10,
                                  paddingRight: 10
                                }}
                              >
                                <small style={{ color: 'white' }}>
                                  {this.state.errors.caption.message}
                                </small>
                              </div>
                            )}
                        </div>
                        {['admin', 'editor'].indexOf(this.props.isAs) > -1 && (
                          <div className="form-group">
                            <label htmlFor="dimension">Dimension</label>
                            <select
                              name="dimension"
                              id="dimension"
                              onChange={this.onChange}
                              value={this.state.dimension}
                              id="dimension"
                            >
                              <option value="all">All</option>
                              <option value="post">
                                Post/Polling(600X600)
                              </option>
                            </select>
                          </div>
                        )}
                        <div className="form-group">
                          <label htmlFor="media_id">Media</label>
                          <div>
                            {this.state.file !== null &&
                              this.state.file.name && (
                                <p
                                  style={{
                                    color: 'black',
                                    fontSize: 11
                                  }}
                                >
                                  {this.state.file.name}
                                </p>
                              )}
                          </div>
                          <div>
                            <label className="btn btn btn-md btn-color btn-file">
                              Browse
                              <input
                                name="media"
                                id="media"
                                type="file"
                                onChange={this.onChangeImage}
                              />
                            </label>
                            {this.state.errors.media &&
                              this.state.errors.media.message && (
                                <div
                                  className="help-block"
                                  style={{
                                    backgroundColor: 'red',
                                    paddingLeft: 10,
                                    paddingRight: 10
                                  }}
                                >
                                  <small style={{ color: 'white' }}>
                                    {this.state.errors.media.message}
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          {['admin', 'editor', 'author'].indexOf(
                            this.props.isAs
                          ) <= -1 && (
                            <Reaptcha
                              sitekey={sitekeyReaptcha}
                              onVerify={this.onVerify}
                            />
                          )}
                        </div>
                        <div className="form-group">
                          <button
                            disabled={
                              !this.state.verified || this.state.loading
                            }
                            key="category-btn-add"
                            className={
                              !this.state.verified || this.state.loading
                                ? 'btn btn-md btn-color btn-file disabled'
                                : 'btn btn-md btn-color btn-button'
                            }
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <h4 className="heading-title">Media Library</h4>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <select
                        name="type"
                        id="type"
                        onChange={this.onChange}
                        value={this.state.type}
                      >
                        <option value="">All media items</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                        <option value="document">Document</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-12">
                      <select
                        name="date"
                        id="date"
                        onChange={this.onChange}
                        value={this.state.date}
                      >
                        <option value="">All dates</option>
                        <option value="1">Januari</option>
                        <option value="2">Februari</option>
                        <option value="3">Maret</option>
                        <option value="4">April</option>
                        <option value="5">Mei</option>
                        <option value="6">Juni</option>
                        <option value="7">Juli</option>
                        <option value="8">Agustus</option>
                        <option value="9">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Desember</option>
                      </select>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <button
                        className="btn btn-md btn-color btn-button"
                        onClick={() => this.changePage(1)}
                      >
                        Apply
                      </button>
                      <hr className="divider" />
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="col-lg-12 col-12 p-0">
                        <input
                          id="keyword"
                          name="keyword"
                          type="text"
                          placeholder="Search media"
                          className="nav__search-input"
                          value={this.state.keyword}
                          onChange={this.onChange}
                        />
                        <button
                          type="button"
                          className="search-button btn btn-md btn-color btn-button"
                          onClick={() => this.changePage(1)}
                        >
                          <i className="ui-search nav__search-icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="row ivoox-media">{medias}</div>
                  {this.state.entities.data &&
                    this.state.entities.data.length > 0 && (
                      <nav className="pagination">
                        <a
                          href="javascript:void(0);"
                          onClick={() =>
                            1 === this.state.entities.pagination.current_page
                              ? null
                              : this.changePage(
                                  this.state.entities.pagination.current_page -
                                    1
                                )
                          }
                          style={{
                            cursor:
                              1 === this.state.entities.pagination.current_page
                                ? 'not-allowed'
                                : 'pointer'
                          }}
                          className="pagination__page pagination__icon pagination__page--next"
                        >
                          <i className="ui-arrow-left" />
                        </a>
                        {this.pageList()}
                        <a
                          href="javascript:void(0);"
                          onClick={() =>
                            this.state.entities.pagination.total_page ===
                            this.state.entities.pagination.current_page
                              ? null
                              : this.changePage(
                                  this.state.entities.pagination.current_page +
                                    1
                                )
                          }
                          style={{
                            cursor:
                              this.state.entities.pagination.total_page ===
                              this.state.entities.pagination.current_page
                                ? 'not-allowed'
                                : 'pointer'
                          }}
                          className="pagination__page pagination__icon pagination__page--next"
                        >
                          <i className="ui-arrow-right" />
                        </a>
                      </nav>
                    )}
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </NotificationVerify>
      </div>
    );
  }
}

export default Media;

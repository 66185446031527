import Toast from '../../components/Toast'

import { keyToken, keyUser } from '../../config'
import { removeStorageItem } from '../../helpers/storage'

const checkErrorWithParam = async (response, setParameterErrors) => {
    if (response.status && response.status === "failed") {
        if (response.message == "Fail to Authentication." || response.message == "No token provided.") {
            let msg = 'Token Expired, Please login again!';
            Toast(msg);
            await removeStorageItem(keyToken);
            await removeStorageItem(keyUser);
            setTimeout(() => {
                window.location.reload()
            }, 600);
        } else if (response.error) {
            setParameterErrors(response.error);
            let msg = response.message;
            Toast(msg);
        } else {
            Toast(response.message);
        }
    } else {
        Toast('Error internet connection');
    }
};

export default checkErrorWithParam
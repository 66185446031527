import React, { Component } from "react";
import DatePicker from "react-datepicker";
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'
import Reaptcha from 'reaptcha'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { keyToken, keyUser, sitekeyReaptcha } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkErrorWithParam'
import ReactQuill from '../../../components/textarea/ReactQuillCustom';
import Toast from '../../../components/Toast'

class FormCareerPosition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            verified: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() { 
        if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
            this.setState({verified: true});
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.setLoadingContainer(true);
        this.props.setParameterErrors({});
        if (this.props.editCareerPositionStatus) {
            this.doUpdateCareerPosition();
        } else {
            this.doCreateCareerPosition();
        }
    }

    doCreateCareerPosition() {
        const params = {
            name: this.props.careerPositionData.name,
            details: this.props.text,
            expired_at: moment(this.props.careerPositionData.expired_at).format('YYYY-MM-DD') + " 23:59:59",
        }
        const route = `/career_position/create`;
        Api.post(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false);
                if (response.status && response.status === "success") {
                    this.props.refreshCareerPositions();
                    this.props.refreshEditData();
                    Toast(response.message, "success");
                } else {
                    CheckError(response, this.props.setParameterErrors).then(r => console.log(''));
                }
            })
            .catch(err => {
                console.log(err);
                this.props.setLoadingContainer(false);
                CheckError(err, this.props.setParameterErrors).then(r => console.log(''))
            })
    }

    doUpdateCareerPosition() {
        const params = {
            name: this.props.careerPositionData.name,
            details: this.props.text,
            expired_at: moment(this.props.careerPositionData.expired_at).format('YYYY-MM-DD') + " 23:59:59",
        }
        const route = `/career_position/update/` + this.props.editCareerPositionId;
        Api.put(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false);
                if (response.status && response.status === "success") {
                    this.props.refreshCareerPositions();
                    this.props.refreshEditData();
                    Toast(response.message, "success");
                } else {
                    CheckError(response, this.props.setParameterErrors).then(r => console.log(''));
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false);
                CheckError(err, this.props.setParameterErrors).then(r => console.log(''))
            })
    }

    cancel(e) {
        e.preventDefault();
        this.props.refreshEditData();
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    render() {
        let headerContent;
        let buttonContent;
        if (this.props.editCareerPositionStatus) {
            headerContent = (
                <h4 className="heading-title">Edit Career Position Id {this.props.editCareerPositionId}</h4>
            );
            buttonContent = (
                <div className="form-group">
                    <button key="career_position-btn-cancel" type="button" onClick={this.cancel} className="btn btn-md btn-button mr-3">Cancel</button>
                    <button disabled={!this.state.verified || this.state.loading} key="career_position-btn-edit" className="btn btn-md btn-color btn-button">Save</button>
                </div>
            );
        } else {
            headerContent = (
                <h4 className="heading-title">Add New Career Position</h4>
            );
            buttonContent = (
                <div className="form-group">
                    <button disabled={!this.state.verified || this.state.loading} key="career_position-btn-add" className="btn btn-md btn-color btn-button">Add Career Position</button>
                </div>
            );
        }
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Please Wait...'
            >
                <div className="row">
                    <div className="col-12">
                        {headerContent}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name <span className="text-red">*</span></label>
                                <input name="name" placeholder="" id="name" type="text" onChange={this.props.onChangeCareerPosition}
                                    value={this.props.careerPositionData.name}
                                />
                                {this.props.errors.name && this.props.errors.name.message && (
                                    <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                        <small style={{ color: 'white' }}>{this.props.errors.name.message}</small>
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="details">Details</label>
                                <ReactQuill
                                    id="content"
                                    name="content"
                                    value={this.props.text}
                                    handleChange={this.props.handleChange}
                                />
                                {this.props.errors.details && this.props.errors.details.message && (
                                    <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                        <small style={{ color: 'white' }}>{this.props.errors.details.message}</small>
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="expired_at">Expired Date</label>
                                <div>
                                    <DatePicker
                                        selected={this.props.careerPositionData.expired_at}
                                        onChange={this.props.onChangeDate}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                {this.props.errors.expired_at && this.props.errors.expired_at.message && (
                                    <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                        <small style={{ color: 'white' }}>{this.props.errors.expired_at.message}</small>
                                    </div>
                                )}
                            </div>
                            <div style={{ marginBottom: 16 }}>
                                { (['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                    <Reaptcha
                                        sitekey={sitekeyReaptcha}
                                        onVerify={this.onVerify}
                                    />
                                }
                            </div>
                            {buttonContent}
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}

export default FormCareerPosition;

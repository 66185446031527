import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { keyToken, keyUser } from '../../../config'

import DashboardSideBar from '../../../components/dashboard/dashboardSideBar'
import ListTag from './list'

import { scriptsHideAds, scriptsUnHideAds } from '../../../utils/scripts'

class Tag extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true
        }
        this.isSSR = IsSSR()
        this.token = null
        this.user = null
        if (!this.isSSR) this.token = getStorageItem(keyToken)
        if (!this.isSSR) this.user = getStorageItem(keyUser)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0)
        if (!this.token) return this.props.history.push(`/auth`)
        this.getRoles().then(r => console.log('getRoles reload'))
        setTimeout(() => {
            scriptsHideAds(() => {
                console.log('[scriptsHideAds.js]')
            })
        }, 600)
    }

    componentWillUnmount() {
        scriptsUnHideAds(() => {
            console.log('[scriptsUnhideAds.js]')
        })
    }

    getIndexOf(a, v) {
        let l = a.length
        for (let k = 0; k < l; k++) {
            if (a[k].name === v) {
                return true
            }
        }
        return false
    }

    async getRoles() {
        this.setState({ loadingRole: true })

        const user = JSON.parse(this.user)
        let isAdmin = await this.getIndexOf(user.roles, 'admin')
        this.setState({ isAdmin: isAdmin })

        let isEditor = await this.getIndexOf(user.roles, 'editor')
        this.setState({ isEditor: isEditor })

        let isAuthor = await this.getIndexOf(user.roles, 'author')
        this.setState({ isAuthor: isAuthor })

        let isContributor = await this.getIndexOf(user.roles, 'contributor')
        this.setState({ isContributor: isContributor })

        if (isAdmin) this.setState({ isAs: 'admin' })
        else if (isEditor) this.setState({ isAs: 'editor' })
        else if (isAuthor) this.setState({ isAs: 'author' })
        else if (isContributor) this.setState({ isAs: 'contributor' })

        this.setState({ loadingRole: false })
    }

    render() {
        const { isAs, loadingRole } = this.state

        if (loadingRole) return null
        return (
            <div>
                <div className="main-container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link
                                to="/admin/dashboard"
                                className="breadcrumbs__url"
                            >
                                Home
                            </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Tag
                        </li>
                    </ul>
                </div>
                <div className="main-container" id="main-container">
                    <div className="blog__content mb-72">
                        <h1 className="page-title">Tag</h1>
                        <div className="row card-row ">
                            <DashboardSideBar
                                isAs={isAs}
                                localUser={this.user}
                                {...this.props}
                            />
                            <ListTag
                                isAs={isAs}
                                localUser={this.user}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tag

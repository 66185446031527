import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { apiUrl } from '../../../config'

import SearchPost from './search'
import CheckError from '../../../utils/admin/checkError'
import NotificationVerify from '../../../utils/admin/notificationVerify'
import Toast from '../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListPost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isAdmin: false,
      isEditor: false,
      isAuthor: false,
      isContributor: false,
      isAs: '',
      loadingRole: true,
      searchData: {
        title: '',
        user_id: '',
        user_name: '',
        category_id: '',
        category_name: '',
        typeList: 'all',
        createdAtMonth: '',
        createdAtYear: '',
        limit: 5
      },
      createdDate: '',
      author: null,
      category: null
    }
    this.refreshPosts = this.refreshPosts.bind(this)
    this.getQueryVariableState = this.getQueryVariableState.bind(this)
    this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
    this.onChangeSearch = this.onChangeSearch.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.checkValue = this.checkValue.bind(this)
  }

  handleClickMonthBox(e) {
    this.refs.pickAMonth.show()
  }

  componentDidMount() {
    setTimeout(() => this.getQueryVariableState())
    const user = JSON.parse(this.props.localUser)
    let params = new URLSearchParams(this.props.location.search)
    let searchData = {
      title: params.get('keyword') ? params.get('keyword') : '',
      user_id: params.get('user_id') ? params.get('user_id') : '',
      user_name: params.get('user_name') ? params.get('user_name') : '',
      category_id: params.get('category_id') ? params.get('category_id') : '',
      category_name: params.get('category_name')
        ? params.get('category_name')
        : '',
      createdAtMonth: params.get('month') ? params.get('month') : '',
      createdAtYear: params.get('year') ? params.get('year') : '',
      limit: params.get('limit') ? params.get('limit') : 5
    }
    this.checkValue(params)
    this.setState({ searchData })
    setTimeout(() => this.loadDatas(user.id))
  }

  componentDidUpdate(prevProps, prevState) {
    window.onpopstate = (e) => {
      let params = new URLSearchParams(this.props.location.search)
      let paramsPrev = new URLSearchParams(prevProps.location.search)
      if (
        paramsPrev.get('page') !== params.get('page') ||
        paramsPrev.get('limit') !== params.get('limit') ||
        paramsPrev.get('keyword') !== params.get('keyword') ||
        paramsPrev.get('state') !== params.get('state') ||
        paramsPrev.get('user_id') !== params.get('user_id') ||
        paramsPrev.get('category_id') !== params.get('category_id') ||
        paramsPrev.get('month') !== params.get('month') ||
        paramsPrev.get('year') !== params.get('year') ||
        paramsPrev.get('sort_field') !== params.get('sort_field') ||
        paramsPrev.get('sort_by') !== params.get('sort_by')
      ) {
        $('#datatable-post').off('click', '.detail-post-btn')
        let searchData = {
          title: params.get('keyword') ? params.get('keyword') : '',
          user_id: params.get('user_id') ? params.get('user_id') : '',
          user_name: params.get('user_name') ? params.get('user_name') : '',
          category_id: params.get('category_id')
            ? params.get('category_id')
            : '',
          category_name: params.get('category_name')
            ? params.get('category_name')
            : '',
          createdAtMonth: params.get('month') ? params.get('month') : '',
          createdAtYear: params.get('year') ? params.get('year') : '',
          limit: params.get('limit') ? params.get('limit') : 5
        }
        this.setState({ searchData })
        this.checkValue(params)
        $('#datatable-post').DataTable().destroy()
        $('#datatable-post tbody').empty()
        setTimeout(() => this.getQueryVariableState())
        const user = JSON.parse(this.props.localUser)
        setTimeout(() => this.loadDatas(user.id))
      }
    }
  }

  componentDidUnmount() {
    window.onpopstate = () => {}
  }

  checkValue(params) {
    if (params.get('category_id') && params.get('category_name')) {
      let category = {}
      category.value = params.get('category_id')
      category.label = params.get('category_name')
      this.setState({ category })
    } else {
      this.setState({ category: null })
    }
    if (params.get('user_id') && params.get('user_name')) {
      let author = {}
      author.value = params.get('user_id')
      author.label = params.get('user_name')
      this.setState({ author })
    } else {
      this.setState({ author: null })
    }
    if (params.get('month') && params.get('year')) {
      let createdDate = moment(
        params.get('year') + '-' + params.get('month') + '-' + '01',
        'YYYY-M-DD'
      )
      console.log(createdDate)
      if (createdDate.isValid()) {
        let test = createdDate.toDate()
        console.log(test)
        this.setState({ createdDate: test })
      }
    } else {
      this.setState({ createdDate: '' })
    }
  }

  onChangeInput(name, value) {
    this.setState({ [name]: value })
  }

  onChangeSearch(e) {
    const { name, value } = e.target
    let { searchData } = this.state
    searchData[name] = value
    if (name === 'limit') {
      this.setState({ searchData })
      $('#datatable-post').DataTable().page.len(value)
    } else {
      this.setState({ searchData })
    }
  }

  async getQueryVariableState() {
    let params = new URLSearchParams(this.props.location.search)
    var pair = params.get('state')
    if (pair && pair && (pair == 1 || pair == 2 || pair == 3)) {
      let { searchData } = this.state
      searchData['typeList'] = pair
      this.setState({ searchData })
    } else {
      let { searchData } = this.state
      searchData['typeList'] = 'all'
      this.setState({ searchData })
    }
  }

  loadDatas(user_id) {
    const { isAs } = this.props

    var _ = this
    var forTitle = this.props.title
    const route = `${
      forTitle === 'Nitizen Jurnalism' ? '/post/get_by_category' : '/post/index'
    }`
    const url = `${apiUrl}${route}`
    this.$el = $(this.el)
    var postDatatableDraw = 1
    let searchParams = new URLSearchParams(_.props.location.search)
    var page =
      searchParams.get('page') && !isNaN(searchParams.get('page'))
        ? Number(searchParams.get('page'))
        : 1
    var limit =
      searchParams.get('limit') && !isNaN(searchParams.get('limit'))
        ? Number(searchParams.get('limit'))
        : 5
    var start = (page - 1) * limit ? (page - 1) * limit : 0
    var sort_field = 0
    var sort_by =
      searchParams.get('sort_by') && searchParams.get('sort_by') == 'ASC'
        ? 'asc'
        : 'desc'

    if (
      searchParams.get('sort_field') &&
      searchParams.get('sort_field') == 'state_id'
    ) {
      sort_field = 4
    } else if (
      searchParams.get('sort_field') &&
      searchParams.get('sort_field') == 'createdAt'
    ) {
      sort_field = 5
    }

    this.$el.DataTable({
      processing: true,
      scrollX: true,
      serverSide: true,
      order: [[sort_field, sort_by]],
      pageLength: limit,
      displayStart: start,
      lengthChange: false,
      searching: false,
      searchDelay: 1000,
      processing: true,
      ajax: {
        url: url,
        headers: {
          'x-access-token': _.props.token
        },
        data: function (data) {
          var requestData = {}
          postDatatableDraw = data.draw

          if (data.order && data.order[0] && data.order[0].column === 4) {
            requestData.sort_field = 'state_id'
          } else if (
            data.order &&
            data.order[0] &&
            data.order[0].column === 5
          ) {
            requestData.sort_field = 'createdAt'
          } else {
            requestData.sort_field = 'id'
          }

          if (data.order && data.order[0] && data.order[0].dir === 'desc') {
            requestData.sort_by = 'DESC'
          } else {
            requestData.sort_by = 'ASC'
          }

          requestData.page = data.start / data.length + 1
          requestData.limit = data.length
          requestData.keyword = _.state.searchData.title
          requestData.state_id = _.state.searchData.typeList
          requestData.category_id = _.state.searchData.category_id
          requestData.createdAtMonth = _.state.searchData.createdAtMonth
          requestData.createdAtYear = _.state.searchData.createdAtYear
          requestData.schedule = 0
          if (['admin', 'editor'].indexOf(isAs) > -1) {
            requestData.user_id = _.state.searchData.user_id
          } else {
            requestData.user_id = user_id
          }
          if (forTitle == 'Nitizen Jurnalism') {
            requestData.category = 'netizen-journalist'
            if (data.draw !== 1) {
              _.props.history
                .push(`/admin/nitizenJurnalism?keyword=${requestData.keyword}
                            &limit=${requestData.limit}
                            &page=${requestData.page}
                            &state=${requestData.state_id}
                            &user_id=${requestData.user_id}
                            &user_name=${_.state.searchData.user_name}
                            &category_id=${requestData.category_id}
                            &category_name=${_.state.searchData.category_name}
                            &month=${requestData.createdAtMonth}
                            &year=${requestData.createdAtYear}
                            &sort_field=${requestData.sort_field}
                            &sort_by=${requestData.sort_by}`)
            }
          } else {
            if (data.draw !== 1) {
              _.props.history.push(
                `/admin/post?keyword=${requestData.keyword}` +
                  `&limit=${requestData.limit}` +
                  `&page=${requestData.page}` +
                  `&state=${requestData.state_id}` +
                  `&user_id=${requestData.user_id}` +
                  `&user_name=${_.state.searchData.user_name}` +
                  `&category_id=${requestData.category_id}` +
                  `&category_name=${_.state.searchData.category_name}` +
                  `&month=${requestData.createdAtMonth}` +
                  `&year=${requestData.createdAtYear}` +
                  `&sort_field=${requestData.sort_field}` +
                  `&sort_by=${requestData.sort_by}`
              )
            }
          }

          return requestData
        },
        error: function (jqXHR, textStatus, errorThrown) {
          // Do something here
          if (jqXHR && jqXHR.responseJSON) {
            CheckError(jqXHR.responseJSON)
          } else {
            Toast('Error internet connection')
          }
        }
      },
      columns: [
        {
          data: null,
          name: 'id',
          render: function (data) {
            var id = data.id
            if (forTitle == 'Nitizen Jurnalism') {
              id = data.post.id
            }
            return (
              '<a href="/admin/post/' +
              id +
              '/' +
              _.props.title +
              '" data-id="' +
              id +
              '" class="detail-post-btn">' +
              id +
              '</a>'
            )
          }
        },
        {
          data: null,
          name: 'title',
          orderable: false,
          width: '250px',
          render: function (data) {
            var id = data.id
            var title = data.title
            if (forTitle == 'Nitizen Jurnalism') {
              id = data.post.id
              title = data.post.title
            }
            return (
              '<a href="/admin/post/' +
              id +
              '/' +
              _.props.title +
              '" data-id="' +
              id +
              '" class="detail-post-btn">' +
              title +
              '</a>'
            )
          }
        },
        {
          data: null,
          name: 'seo_score',
          orderable: false,
          searchable: false,
          render: function (data) {
            return data.seo_score
          }
        },
        {
          data: null,
          name: 'name',
          orderable: false,
          searchable: false,
          render: function (data) {
            return forTitle == 'Nitizen Jurnalism'
              ? data.post.user.name
              : data.user.name
          }
        },
        {
          data: null,
          name: 'categories',
          orderable: false,
          searchable: false,
          render: function (data) {
            if (forTitle == 'Nitizen Jurnalism') {
              var categories = ''
              var total = data.post.categoryables.length
              data.post.categoryables.map(function (item, key) {
                categories += item.category.name
                categories = key + 1 < total ? categories + ', ' : categories
              })
              return categories
            } else {
              var categories = ''
              var total = data.categoryables.length
              data.categoryables.map(function (item, key) {
                categories += item.category.name
                categories = key + 1 < total ? categories + ', ' : categories
              })
              return categories
            }
          }
        },
        {
          data: null,
          name: 'status',
          render: function (data) {
            return forTitle == 'Nitizen Jurnalism'
              ? data.post.state.name
              : data.state.name
          }
        },
        {
          data: null,
          name: 'createdAt',
          render: function (data) {
            var createdDate = moment(
              forTitle == 'Nitizen Jurnalism'
                ? data.post.createdAt
                : data.createdAt
            ).format('DD/MM/YYYY HH:mm')
            return createdDate
          }
        }
      ]
    })
    $('#datatable-post').on('click', '.detail-post-btn', function (e) {
      e.preventDefault()
      var id = $(this).data('id')
      _.showEditPost(id)
    })
  }

  showEditPost(id) {
    this.props.history.push(`/admin/post/${id}/${this.props.title}`)
  }

  refreshPosts() {
    $('#datatable-post').DataTable().ajax.reload()
  }

  render() {
    return (
      <div className="col-lg-9 col-12">
        <NotificationVerify
          isAs={this.props.isAs}
          localUser={this.props.localUser}
        >
          <div className="entry card card-input">
            <div className="row">
              <div className="col-12">
                <div className="mt-3">
                  <h4 className="heading-title">{this.props.title} List</h4>
                  <Link to={`/admin/post/add/${this.props.title}`}>
                    <span className="btn btn-md btn-color mt-3 mb-3">
                      Add New {this.props.title}
                    </span>
                  </Link>
                  <div>
                    <SearchPost
                      searchData={this.state.searchData}
                      createdDate={this.state.createdDate}
                      author={this.state.author}
                      category={this.state.category}
                      isAs={this.props.isAs}
                      token={this.props.token}
                      title={this.props.title}
                      location={this.props.location}
                      onChangeSearch={this.onChangeSearch}
                      refreshPosts={this.refreshPosts}
                      onChangeInput={this.onChangeInput}
                    />
                  </div>
                  <table
                    id="datatable-post"
                    className="display table table-sm table-striped table-bordered"
                    width="100%"
                    ref={(el) => (this.el = el)}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>SEO</th>
                        <th>Author</th>
                        <th>Categories</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </NotificationVerify>
      </div>
    )
  }
}

export default ListPost

import React, { Component } from 'react'

class AuthorBox extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { loadingUser, datasUser } = this.props;
        if (loadingUser) return null;
        return (
            <div className="entry-author clearfix">
                <img
                    alt=""
                    src={
                        datasUser.mediaable
                            ? datasUser.mediaable.media.url
                            : '/assets/img/person-icon.png'
                    }
                    className="avatar lazyload"
                    style={{ width: 100, height: 100 }}
                />
                <div className="entry-author__info">
                    <h6 className="entry-author__name">
                        <a href="#">{datasUser.name}</a>
                    </h6>
                    <p className="mb-0">{datasUser.biografi}</p>
                </div>
            </div>
        )
    }
}

export default AuthorBox

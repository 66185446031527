import React, { Component } from 'react'
import Reaptcha from 'reaptcha'

import { apiUrl, sitekeyReaptcha } from '../../config'

import Toast from '../../components/Toast'

class ContactForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			fCUName: '',
			fCUPhone: '',
			fCUEmail: '',
			fCUMessage: '',
			fCUSubject: '',
			submittedSubject: false,
			loadingSubmit: false,
			verified: false
		};

		this.onSubmitContactUs = this.onSubmitContactUs.bind(this);
		this.onChange = this.onChange.bind(this)
	}

	componentDidMount() {}

	onVerify = recaptchaResponse => {
		this.setState({
			verified: true
		})
	};

	onChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value })
	}

	onSubmitContactUs(e) {
		e.preventDefault();

		this.setState({ submittedSubject: true });
		const { fCUName, fCUPhone, fCUEmail } = this.state;
		if (fCUName !== '' && fCUPhone !== '' && fCUEmail !== '') this.onSave()
	}

	onSave() {
		const {
			fCUName,
			fCUPhone,
			fCUEmail,
			fCUMessage,
			fCUSubject
		} = this.state;

		this.setState({ loadingSubmit: false }, () => {
			const data = {
				email: fCUEmail,
				phone: fCUPhone,
				name: fCUName,
				subject: fCUSubject,
				message: fCUMessage
			};
			fetch(`${apiUrl}/contact/create`, {
				method: 'POST',
				headers: [
					['Content-Type', 'application/json;text/plain'],
				],
				body: JSON.stringify(data)
			})
				.then(function(response) {
					return response.json()
				})
				.then(response => {
					if (response.status === 'success')
						Toast('Thanks', 'success');
					else this.checkError(response);
					this.setState({
						loadingSubmit: false,
						submittedSubject: false,
						fCUEmail: '',
						fCUPhone: '',
						fCUName: '',
						fCUSubject: '',
						fCUMessage: ''
					})
				})
		})
	}

	checkError(response) {
		console.log(response);
		if (response.status && response.status === 'failed') {
			if (response.error) {
				this.setState({ errors: response.error });
				Toast(response.message)
			} else {
				Toast(response.message)
			}
		} else {
			Toast('Error internet connection')
		}
	}

	render() {
		const {
			fCUName,
			fCUPhone,
			fCUEmail,
			fCUMessage,
			fCUSubject
		} = this.state;
		return (
			<div className="col-lg-8">
				<div className="content-box">
					<form
						className="contact-form mt-30 mb-30"
						onSubmit={this.onSubmitContactUs}
						name="formContactUs"
					>
						<div className="form-group">
							<label htmlFor="fCUName">
								Name <span className="text-red">*</span>
							</label>
							<input
								name="fCUName"
								id="fCUName"
								type="text"
								required="required"
								value={fCUName}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="fCUPhone">
								Phone <span className="text-red">*</span>
							</label>
							<input
								name="fCUPhone"
								id="fCUPhone"
								type="number"
								required="required"
								value={fCUPhone}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="fCUEmail">
								Email <span className="text-red">*</span>
							</label>
							<input
								name="fCUEmail"
								id="fCUEmail"
								type="email"
								required="required"
								value={fCUEmail}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="fCUSubject">Subject</label>
							<input
								name="fCUSubject"
								id="fCUSubject"
								type="text"
								value={fCUSubject}
								onChange={this.onChange}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="fCUMessage">Message</label>
							<textarea
								id="fCUMessage"
								name="fCUMessage"
								rows="7"
								value={fCUMessage}
								onChange={this.onChange}
							/>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Reaptcha
								sitekey={sitekeyReaptcha}
								onVerify={this.onVerify}
							/>
						</div>
						<input
							type="submit"
							disabled={!this.state.verified}
							className="btn btn-lg btn-color btn-button"
							value="Send Message"
						/>
						<div id="msg" className="message" />
					</form>
				</div>
			</div>
		)
	}
}

export default ContactForm

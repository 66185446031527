import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Reaptcha from 'reaptcha';

import { apiUrl, sitekeyReaptcha, keyToken, keyUser } from '../../../config';

import Toast from '../../../components/Toast';
import {
  removeStorageItem,
  getStorageItem,
  setStorageItem
} from '../../../helpers/storage';
import { IsSSR } from '../../../helpers/isSSR';
import { auth, provider } from '../firebase.utils';

import { signInWithPopup } from 'firebase/auth';
import Api from '../../../services/api';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fIdentifier: '',
      fName: '',
      fEmail: '',
      fPassword: '',
      fConfirmPassword: '',
      submittedLogin: false,
      loadingSubmit: false,
      errorStatus: false,
      errorMessage: '',
      errorMessagePasswordRule: '',
      verified: false
    };
    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);

    this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.logGoogleUser = this.logGoogleUser.bind(this);
  }

  componentDidMount() {
    if (!this.isSSR) window.scrollTo(0, 0);
    if (!this.token) {
      if (!this.isSSR)
        removeStorageItem(keyToken).then((r) => console.log('delete token'));
      if (!this.isSSR)
        removeStorageItem(keyUser).then((r) => console.log('delete user'));
    } else {
      return this.props.history.push(`/admin/dashboard`);
    }
  }

  onVerify = (recaptchaResponse) => {
    this.setState({
      verified: true
    });
  };

  onDismiss() {
    this.setState({ errorStatus: false });
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSubmitLogin(e) {
    e.preventDefault();

    this.setState({ submittedLogin: true });
    const { fIdentifier, fPassword, fName, fEmail, fConfirmPassword } =
      this.state;
    if (
      fIdentifier !== '' &&
      fPassword !== '' &&
      fName !== '' &&
      fEmail !== '' &&
      fConfirmPassword !== ''
    )
      this.onSave();
  }

  onSave() {
    const { fIdentifier, fPassword, fName, fEmail, fConfirmPassword } =
      this.state;

    this.setState({ errorStatus: false, errorMessagePasswordRule: '' });

    if (fPassword !== fConfirmPassword) {
      this.setState({ errorStatus: true });
      this.setState({
        errorMessage: "Password and Confirm Password doesn't match"
      });
      return false;
    }

    this.setState({ loadingSubmit: false }, () => {
      const data = {
        username: fIdentifier,
        password: fPassword,
        name: fName,
        email: fEmail,
        confirm_password: fConfirmPassword,
        roles: 'contributor'
      };
      fetch(`${apiUrl}/auth/signup`, {
        method: 'POST',
        headers: [['Content-Type', 'application/json;text/plain']],
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 'success') {
            Toast('Register Success Please Login', 'success');
            this.props.history.push(`/auth`);
          } else {
            this.checkError(response);
          }
          this.setState({
            loadingSubmit: false,
            submittedSubject: false
          });
        });
    });
  }

  logGoogleUser() {
    this.setState({ submittedLogin: true });
    signInWithPopup(auth, provider)
      .then((d) => {
        this.onSaveGoogle(d.user.uid);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveProfile(data) {
    let searchParams = new URLSearchParams(this.props.location.search);
    const route = `/user_profile`;
    Api.get(route, data.accessToken)
      .then(async (response) => {
        this.setState({ loading: false });
        if (response.status && response.status === 'success') {
          await setStorageItem(keyToken, data.accessToken);
          await setStorageItem(keyUser, JSON.stringify(response.data));
          Toast('Login Successfully', 'success');
          let queryPage = searchParams.get('page');
          if (queryPage && queryPage == 'article') {
            this.props.history.goBack();
          } else {
            this.props.history.push(`/admin/dashboard`);
          }
          setTimeout(function () {
            window.location.reload();
          }, 600);
        } else {
          this.checkError(response);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.checkError(error);
      });
  }
  onSaveGoogle(uid) {
    this.setState({ errorStatus: false });
    this.setState({ loadingSubmit: false }, () => {
      const data = {
        uid
      };
      fetch(`${apiUrl}/auth/google`, {
        method: 'POST',
        headers: [['Content-Type', 'application/json;text/plain']],
        body: JSON.stringify(data)
      })
        .then(function (response) {
          return response.json();
        })
        .then((response) => {
          if (response.status === 'success') {
            this.saveProfile(response.data);
          } else {
            this.checkError(response);
          }
          this.setState({
            loadingSubmit: false,
            submittedSubject: false
          });
        });
    });
  }

  checkError(response) {
    console.log(response);
    if (response.status && response.status === 'failed') {
      if (response.error) {
        this.setState({ errorStatus: true });
        this.setState({ errorMessage: response.message });
        if (response.message == 'Password structure is not valid!')
          this.setState({
            errorMessagePasswordRule: response.passwordRules
          });
      } else {
        this.setState({ errorStatus: true });
        this.setState({ errorMessage: response.message });
        if (response.message == 'Password structure is not valid!')
          this.setState({
            errorMessagePasswordRule: response.passwordRules
          });
      }
    } else {
      this.setState({ errorStatus: true });
      this.setState({ errorMessage: response.message });
      if (response.message == 'Password structure is not valid!')
        this.setState({
          errorMessagePasswordRule: response.passwordRules
        });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const {
      fIdentifier,
      fPassword,
      fName,
      fEmail,
      fConfirmPassword,
      loadingSubmit,
      errorStatus,
      errorMessage,
      errorMessagePasswordRule
    } = this.state;
    return (
      <div className="main-container container pt-80 pb-80" id="main-container">
        <div className="blog__content mb-72">
          <div className="container login-box">
            <Alert color="danger" isOpen={errorStatus} toggle={this.onDismiss}>
              {errorMessage}
              {errorMessagePasswordRule !== '' && (
                <div>{errorMessagePasswordRule}</div>
              )}
            </Alert>
            <h2 className="text-center">Register</h2>
            <div className="row justify-content-center mt-40">
              <div className="col-md-6">
                <form
                  onSubmit={this.onSubmitLogin}
                  name="formLogin"
                  className="search-form relative"
                >
                  <div className="form-group">
                    <label htmlFor="fIdentifier">Username</label>
                    <input
                      name="fIdentifier"
                      id="fIdentifier"
                      type="text"
                      value={fIdentifier}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fName">Name</label>
                    <input
                      name="fName"
                      id="fName"
                      type="text"
                      value={fName}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fEmail">Email</label>
                    <input
                      name="fEmail"
                      id="fEmail"
                      type="text"
                      value={fEmail}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fPassword">Password</label>
                    <input
                      name="fPassword"
                      id="fPassword"
                      type="password"
                      value={fPassword}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="fConfirmPassword">Confirm Password</label>
                    <input
                      name="fConfirmPassword"
                      id="fConfirmPassword"
                      type="password"
                      value={fConfirmPassword}
                      onChange={this.onChange}
                      required="required"
                    />
                  </div>
                  <div style={{ marginBottom: 16 }}>
                    <Reaptcha
                      sitekey={sitekeyReaptcha}
                      onVerify={this.onVerify}
                    />
                  </div>
                  <div className="form-group text-center">
                    <button
                      disabled={!this.state.verified || loadingSubmit}
                      type="submit"
                      className="btn btn-lg btn-color btn-button"
                    >
                      {loadingSubmit ? 'Loading...' : 'Register'}
                    </button>
                  </div>
                </form>
                <div className="d-flex my-2 justify-content-center w-100">
                  <button
                    onClick={this.logGoogleUser}
                    className="btn btn-lg btn-color btn-button "
                  >
                    Sign In With Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { sosialMedia, keyToken } from '../config';

import { getStorageItem, removeStorageItem } from '../helpers/storage';
import { IsSSR } from '../helpers/isSSR';

// const menusManual = [
//   {
//     id: 1,
//     title: 'Berita Utama',
//     slug: 'berita-utama',
//     parent: null,
//   },
//   {
//     id: 2,
//     title: 'Perbankan',
//     slug: 'perbankan',
//     parent: null,
//   },
//   {
//     id: 3,
//     title: 'Pasar Modal',
//     slug: 'pasar-modal',
//     parent: null,
//   },
//   {
//     id: 4,
//     title: 'NonBank',
//     slug: 'non-bank',
//     parent: null,
//   },
//   {
//     id: 5,
//     title: 'Literasi',
//     slug: 'literasi',
//     parent: null,
//   },
//   {
//     id: 6,
//     title: 'Ekonomi',
//     slug: 'ekonomi',
//     parent: null,
//   },
//   {
//     id: 7,
//     title: 'Makro',
//     slug: 'makro',
//     parent: null,
//   },
//   {
//     id: 8,
//     title: 'Investasi',
//     slug: 'investasi',
//     parent: null,
//   },
//   {
//     id: 10,
//     title: 'Ebook',
//     slug: 'ebook',
//     parent: null,
//   },
//   {
//     id: 12,
//     title: 'Media',
//     slug: 'media',
//     parent: [
//       {
//         id: 13,
//         title: 'Foto',
//         slug: 'foto',
//       },
//       {
//         id: 14,
//         title: 'Infografik',
//         slug: 'infografik',
//       },
//       {
//         id: 15,
//         title: 'Video',
//         slug: 'video',
//       },
//     ],
//   },
// ];

class Header extends Component {
  constructor(props) {
    super(props);
    this.isSSR = IsSSR();
    this.token = null;
    if (!this.isSSR) this.token = getStorageItem(keyToken);
    this.doLogout = this.doLogout.bind(this);
  }

  doLogout() {
    if (!this.isSSR)
      removeStorageItem(keyToken).then((r) => console.log('remove token'));
    this.refreshPage();
  }

  refreshPage() {
    if (!this.isSSR) window.location.reload();
  }

  checkPageHome() {
    if (window.location.pathname == '/') {
      window.location.reload();
    }
  }

  render() {
    const menusManual = this.props.menusManual;
    return (
      <header className="sidenav" id="sidenav">
        <div className="sidenav__close">
          <button
            className="sidenav__close-button"
            id="sidenav__close-button"
            aria-label="close sidenav"
          >
            <i className="ui-close sidenav__close-icon" />
          </button>
        </div>
        <nav className="sidenav__menu-container">
          <ul className="sidenav__menu" role="menubar" style={{ width: '80%' }}>
            <li>
              <Link
                onClick={this.checkPageHome}
                to="/"
                className="sidenav__menu-url"
              >
                Home
              </Link>
            </li>

            {menusManual.map((nav, i) => (
              <li key={i}>
                {nav.parent?.length > 0 ? (
                  <>
                    <a href="javascript:void(0);" className="sidenav__menu-url">
                      {nav.name}
                    </a>
                    <button
                      className="sidenav__menu-toggle"
                      aria-haspopup="true"
                      aria-label="Open dropdown"
                    >
                      <i className="ui-arrow-down" />
                    </button>
                    <ul className="sidenav__menu-dropdown">
                      {nav.parent.map((navParent, iParent) => (
                        <li key={iParent}>
                          <Link
                            to={`/category/${navParent.slug}`}
                            className="sidenav__menu-url"
                          >
                            {navParent.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Link
                    to={`/category/${nav.slug}`}
                    className="sidenav__menu-url"
                  >
                    {nav.name}
                  </Link>
                )}
              </li>
            ))}

            {this.token !== null && (
              <li>
                <Link to="/admin/dashboard" className="sidenav__menu-url">
                  Dashboard
                </Link>
              </li>
            )}
            {this.token !== null && (
              <li>
                <a
                  href="#"
                  onClick={this.doLogout}
                  className="sidenav__menu-url"
                >
                  Logout
                </a>
              </li>
            )}
            {this.token == null && (
              <li>
                <Link to="/auth?page=article" className="sidenav__menu-url">
                  Login
                </Link>
              </li>
            )}
            {this.token == null && (
              <li>
                <Link to="/register" className="sidenav__menu-url">
                  Register
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <div className="socials sidenav__socials">
          <a
            className="social social-facebook"
            href={`${sosialMedia[0].link}`}
            target="_blank"
            aria-label="facebook"
          >
            <i className="ui-facebook" />
          </a>
          <a
            className="social social-twitter"
            href={`${sosialMedia[1].link}`}
            target="_blank"
            aria-label="twitter"
          >
            <i className="ui-twitter" />
          </a>
          <a
            className="social social-google-plus"
            href={`${sosialMedia[2].link}`}
            target="_blank"
            aria-label="google"
          >
            <img src="/assets/img/tiktok.png" style={{ width: 20 }} />
          </a>
          <a
            className="social social-instagram"
            href={`${sosialMedia[3].link}`}
            target="_blank"
            aria-label="instagram"
          >
            <i className="ui-instagram" />
          </a>
        </div>
      </header>
    );
  }
}

export default Header;

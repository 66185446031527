import React, { Component } from 'react'
import ContactForm from './contactForm'
import ContactInfo from './contactInfo'

import { IsSSR } from "../../helpers/isSSR";

class ContactUs extends Component {
	constructor(props) {
		super(props);
		this.isSSR = IsSSR();
	}

	componentDidMount() {
		if (!this.isSSR) window.scrollTo(0, 0)
	}

	render() {
		return (
			<div className="main-container container" id="main-container">
				<div className="blog__content mb-72">
					<h1 className="page-title">Contact Us</h1>
					<div className="row">
						<ContactForm />
						<ContactInfo />
					</div>
				</div>
			</div>
		)
	}
}

export default ContactUs
import React, { Component } from 'react'

class ContactInfo extends Component {
	render() {
		return (
			<div className="col-lg-4">
				<div className="content-box">
					<p>Untuk informasi lebih lanjut hubungi:</p>
					<ul className="contact-items">
						<li className="contact-item">
							<address>PT Media Nusantara Sakti</address>
						</li>
						<li className="contact-item">
							<a href="mailto:info@ivoox.id" className="text-red">
								Email: info@ivoox.id
							</a>
						</li>

						<li className="contact-item">
							<address>Ivoox Indonesia</address>
						</li>
						<li className="contact-item">
							<address>Jl. Batu Ceper IV, No.6C
Gambir, Jakarta Pusat, Jakarta 10120</address>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default ContactInfo

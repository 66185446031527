import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'

import Api from '../../services/api'
import { IsSSR } from "../../helpers/isSSR";
import { getStorageItem } from "../../helpers/storage";
import { keyToken } from "../../config";

class DashboardThreeColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingPublished: false,
			loadingPending: false,
			loadingDraft: false,
			totalPublished: 0,
			totalPending: 0,
			totalDraft: 0,
			loadingPublishedPolling: false,
			loadingPendingPolling: false,
			loadingDraftPolling: false,
			totalPublishedPolling: 0,
			totalPendingPolling: 0,
			totalDraftPolling: 0,
		};
		this.isSSR = IsSSR();
		this.token = null;
		if (!this.isSSR) this.token = getStorageItem(keyToken);
		this.getTotalPost = this.getTotalPost.bind(this);
	}

	componentDidMount() {
		this.fetch()
	}

	fetch() {
		const { localUser } = this.props;
		const user = JSON.parse(localUser);
		this.getTotalPost(3, 'totalPublished', 'loadingPublished', 'post', user.id);
		this.getTotalPost(2, 'totalPending', 'loadingPending', 'post', user.id);
		this.getTotalPost(1, 'totalDraft', 'loadingDraft', 'post', user.id);
		this.getTotalPost(
			3,
			'totalPublishedPolling',
			'loadingPublishedPolling',
			'polling',
			user.id
		);
		this.getTotalPost(
			2,
			'totalPendingPolling',
			'loadingPendingPolling',
			'polling',
			user.id
		);
		this.getTotalPost(
			1,
			'totalDraftPolling',
			'loadingDraftPolling',
			'polling',
			user.id
		)
	}

	getTotalPost(type, state, stateLoading, router, userId) {
		const { isAs } = this.props;
		this.setState({ [stateLoading]: true });
		let searchUser = "";
		if (['admin', 'editor'].indexOf(isAs) > -1) {
		} else {
			searchUser = `&user_id=${userId}`;
		}
		const route = `/${router}/index?page=1&limit=1&schedule=0&state_id=` + type + searchUser;
		Api.get(route, this.token)
			.then(response => {
				this.setState({ [stateLoading]: false });
				if (response.status && response.status === 'success') {
					this.setState({ [state]: response.pagination.total_found })
				}
			})
			.catch(() => {
				this.setState({ [stateLoading]: false })
			})
	}

	render() {
		const {
			loadingPublished,
			loadingPending,
			loadingDraft,
			totalPublished,
			totalPending,
			totalDraft,
			loadingPublishedPolling,
			loadingPendingPolling,
			loadingDraftPolling,
			totalPublishedPolling,
			totalPendingPolling,
			totalDraftPolling
		} = this.state;
		const { isAs } = this.props;

		return (
			<div>
				<h4 className="heading-title-dashboard">
					Post
				</h4>
				<div className="row">
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingPublished}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Published Post
										</h4>
										<h1>{totalPublished}</h1>
										{['admin', 'editor', 'author'].indexOf(isAs) > -1 ? (
											<Link to="/admin/post?state=3">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										) :  (
											<Link to="/admin/nitizenJurnalism?state=3">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										)}
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingPending}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Pending Post
										</h4>
										<h1>{totalPending}</h1>
										{['admin', 'editor', 'author'].indexOf(isAs) > -1 ? (
											<Link to="/admin/post?state=2">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										) :  (
											<Link to="/admin/nitizenJurnalism?state=2">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										)}
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingDraft}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Draft Post
										</h4>
										<h1>{totalDraft}</h1>
										{['admin', 'editor', 'author'].indexOf(isAs) > -1 ? (
											<Link to="/admin/post?state=1">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										) :  (
											<Link to="/admin/nitizenJurnalism?state=1">
												<a
													href="javascript:void(0)"
													className="btn btn-sm btn-color w-100"
												>
													View All
												</a>
											</Link>
										)}
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
				</div>
				<hr />
				<h4 className="heading-title-dashboard">
					Polling
				</h4>
				<div className="row">
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingPublishedPolling}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Published Polling
										</h4>
										<h1>{totalPublishedPolling}</h1>
										<Link to="/admin/polling?state=3">
											<a
												href="javascript:void(0)"
												className="btn btn-sm btn-color w-100"
											>
												View All
											</a>
										</Link>
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingPendingPolling}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Pending Polling
										</h4>
										<h1>{totalPendingPolling}</h1>
										<Link to="/admin/polling?state=2">
											<a
												href="javascript:void(0)"
												className="btn btn-sm btn-color w-100"
											>
												View All
											</a>
										</Link>
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
					<div id="three-box" className="col-lg-4 col-12">
						<div className="border-dashboard">
							<LoadingOverlay
								active={loadingDraftPolling}
								spinner
								text="Loading your content..."
							>
								<div className="entry__body card__body card-dashboard">
									<div className="entry__header text-center">
										<h4 className="heading-title-dashboard">
											Draft Polling
										</h4>
										<h1>{totalDraftPolling}</h1>
										<Link to="/admin/polling?state=1">
											<a
												href="javascript:void(0)"
												className="btn btn-sm btn-color w-100"
											>
												View All
											</a>
										</Link>
									</div>
								</div>
							</LoadingOverlay>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DashboardThreeColumn

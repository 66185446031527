import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Api from '../../services/api'

import Toast from '../../components/Toast';
import PopularPosts from '../../components/PopularPosts'
import SocialMedia from '../../components/SocialMedia'

import { IsSSR } from '../../helpers/isSSR'

class ArticlesLatest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datasPostCategory: [],
            loadingPostCategory: true,
            datasTopTen: [],
            loadingTopTen: true,
            subscribeEmail: '',
            submittedSubscribe: false,
            entities: {
                data: [],
                pagination: {
                    total_found: 1,
                    limit: 9,
                    current_page: 1,
                    total_page: 1
                }
            },
            first_page: 1,
            current_page: 1,
            offset: 4,
            limit: 9
        }
        this.isSSR = IsSSR();

        this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0)
        this.fetchTopTen()
		let searchParams = new URLSearchParams(this.props.location.search);
        let current_page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? searchParams.get('page') : 1; 
        this.setState(
            // { current_page: this.state.entities.pagination.current_page },
            { current_page: current_page },
            () => {
                this.fetchEntities()
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
		let params = new URLSearchParams(this.props.location.search);
		let paramsPrev = new URLSearchParams(prevProps.location.search);
        if (paramsPrev.get('page') !== params.get('page')) {
            this.setState(
                // { current_page: this.state.entities.pagination.current_page },
                { current_page: params.get('page') },
                () => {
                    window.scrollTo(0, 0);
                    this.fetchEntities()
                }
            )
        }
    }

    fetchEntities() {
        this.setState({ datasPostCategory: [] })
        this.setState({ loadingPostCategory: true }, () => {
            const params = `page=${this.state.current_page}&limit=${
                this.state.entities.pagination.limit
                }&keyword=&schedule=0`
            const route = `/post/index?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ entities: response })
                    this.setState({ datasPostCategory: response.data })
                    if (response.data.length > 0) this.renderCountLikeComment(response.data[0].id)
                    this.setState({ loadingPostCategory: false })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingPostCategory: false })
                })
        })
    }

    renderCountLikeComment(ids) {
        var obj = this.state.datasPostCategory
        const route = `/post/get_total_like_and_comment/${ids}`
        Api.get(route, null)
            .then(response => {
                if (obj && obj[0]) obj[0]['manualCountLikeComment'] = response.data
                this.setState({ 'datasPostCategory': obj })
            })
            .catch(error => {
                console.log('renderCountLikeComment error:', obj, error)
            })
    }

    changePage(pageNumber) {
        this.setState({ current_page: pageNumber }, () => {
            this.props.history.push(`/latestNews?page=${pageNumber}`);
            // window.scrollTo(0, 0);
            // this.fetchEntities()
        })
    }

    pagesNumbers() {
        let from =
            this.state.entities.pagination.current_page - this.state.offset
        if (from < 1) {
            from = 1
        }
        let to = from + this.state.offset * 2
        if (to >= this.state.entities.pagination.total_page) {
            to = this.state.entities.pagination.total_page
        }
        let pagesArray = []
        for (let page = from; page <= to; page++) {
            pagesArray.push(page)
        }
        return pagesArray
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return (
                <span
                    key={page}
                    className={`pagination__page ${
                        page === this.state.entities.pagination.current_page
                            ? 'pagination__page--current'
                            : null
                        }`}
                    onClick={() => this.changePage(page)}
                >
                    {page}
                </span>
            )
        })
    }

    onChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onSubmitSubscribe(e) {
        e.preventDefault()
        this.setState({ submittedSubscribe: true })
        const { subscribeEmail } = this.state
        if (subscribeEmail) {
            const route     = `/subcriber/create`
            let params      = {}; 
            params.email    = subscribeEmail; 
            Api.post(route, params, null)
            .then(response => {
                if (response.status && response.status === "success") {
                    Toast(response.message, "success");
                } 
            })
            .catch(error => {
                if (error.status && error.status === "failed") {
                    Toast(error.message);
                } else {
                    Toast('Error internet connection');
                }
            })
        }
    }

    fetchTopTen() {
        this.setState({ loadingTopTen: true }, () => {
            const params = `page=1&limit=4&keyword=&schedule=0`
            const route = `/post/populer?${params}`
            Api.get(route, null)
                .then(response => {
                    this.setState({ datasTopTen: response.data })
                    this.setState({ loadingTopTen: false })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ loadingTopTen: false })
                })
        })
    }

    render() {
        const {
            datasPostCategory,
            loadingPostCategory,
            datasTopTen,
            loadingTopTen,
            subscribeEmail,
            submittedSubscribe
        } = this.state
        const { match } = this.props

        return (
            <div>
                <div className="container">
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs__item">
                            <Link to="/" className="breadcrumbs__url">
                                Home
                            </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            Latest News
                        </li>
                    </ul>
                </div>

                <div className="main-container container" id="main-container">
                    <div className="row">
                        <div className="col-lg-8 blog__content mb-72">
                            <h1 className="page-title tt-capitalize">Latest News</h1>
                            {loadingPostCategory ? <div>Loading...</div> :
                                (
                                    <div>
                                        {datasPostCategory.length > 0 ?
                                            (
                                                <div className="row card-row">
                                                    <div className="col-md-12">
                                                        <article className="entry thumb thumb--size-3 thumb--mb-20">
                                                            <div
                                                                className="entry__img-holder thumb__img-holder"
                                                                style={{
                                                                    backgroundImage: datasPostCategory[0].mediaable !== null ? `url(${`${datasPostCategory[0].mediaable.media.url}`})` : ''
                                                                }}
                                                            >
                                                                <div className="bottom-gradient"></div>
                                                                <div className="thumb-text-holder thumb-text-holder--2">
                                                                    <ul className="entry__meta">
                                                                        <li>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/category/${
                                                                                        datasPostCategory[0].categoryables
                                                                                            ? datasPostCategory[0]
                                                                                                .categoryables[0]
                                                                                                ? datasPostCategory[0]
                                                                                                    .categoryables[0]
                                                                                                    .category
                                                                                                    ? datasPostCategory[0]
                                                                                                        .categoryables[0]
                                                                                                        .category
                                                                                                        .slug
                                                                                                    : 'unknown'
                                                                                                : 'unknown'
                                                                                            : 'unknown'
                                                                                        }`
                                                                                }}
                                                                                className="entry__meta-category--label"
                                                                            >
                                                                                {datasPostCategory[0].categoryables
                                                                                    ? datasPostCategory[0]
                                                                                        .categoryables[0]
                                                                                        ? datasPostCategory[0]
                                                                                            .categoryables[0]
                                                                                            .category
                                                                                            ? datasPostCategory[0]
                                                                                                .categoryables[0]
                                                                                                .category.name
                                                                                            : 'unknown'
                                                                                        : 'unknown'
                                                                                    : 'unknown'}
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                    <h2 className="thumb-entry-title">
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/${
                                                                                    datasPostCategory[0]
                                                                                        .url
                                                                                    }`, search: `?tag_from=${datasPostCategory[0].tagables[0] ? datasPostCategory[0].tagables[0].tag.slug : 'unknown'}`
                                                                            }}
                                                                        >
                                                                            {datasPostCategory[0].title}
                                                                        </Link>
                                                                    </h2>
                                                                    {/* <ul className="entry__meta">
                                                                        <li className="entry__meta-views">
                                                                            <i className="ui-eye"></i>
                                                                            <span>{datasPostCategory[0]['manualCountLikeComment'] ? datasPostCategory[0]['manualCountLikeComment']['total_view'] : 0}</span>
                                                                        </li>
                                                                        <li className="entry__meta-comments">
                                                                            <a href="#">
                                                                                <i className="ui-chat-empty"></i> {datasPostCategory[0]['manualCountLikeComment'] ? datasPostCategory[0]['manualCountLikeComment']['total_comment'] : 0}
                                                                            </a>
                                                                        </li>
                                                                    </ul> */}
                                                                </div>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${
                                                                            datasPostCategory[0]
                                                                                .url
                                                                            }`, search: `?tag_from=${datasPostCategory[0].tagables[0] ? datasPostCategory[0].tagables[0].tag.slug : 'unknown'}`
                                                                    }} className="thumb-url"
                                                                ></Link>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            )
                                            : null}

                                        {datasPostCategory.length > 0 ? (
                                            <div className="row card-row">
                                                {datasPostCategory.map((dataPostCategory, dataPostCategoryIndex) => dataPostCategoryIndex === 0 ? null : (
                                                    <div className="col-md-6 min-height-auto " key={dataPostCategoryIndex}>
                                                        <article className="entry card">
                                                            <div className="entry__img-holder card__img-holder">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${
                                                                            dataPostCategory
                                                                                .url
                                                                            }`, search: `?tag_from=${dataPostCategory.tagables[0] ? dataPostCategory.tagables[0].tag.slug : 'unknown'}`
                                                                    }}
                                                                >
                                                                    <div className="thumb-container thumb-70">
                                                                        <img
                                                                            src={dataPostCategory.mediaable !== null ? `${dataPostCategory.mediaable.media.url}` : ''}
                                                                            className="entry__img lazyload"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </Link>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/category/${dataPostCategory.categoryables[0] ? dataPostCategory.categoryables[0].category.slug : 'unknown'}`
                                                                    }}
                                                                    className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner entry__meta-category--label"
                                                                >
                                                                    {dataPostCategory.categoryables[0] ? dataPostCategory.categoryables[0].category.name : 'unknown'}
                                                                </Link>
                                                            </div>
                                                            <div className="entry__body card__body">
                                                                <div className="entry__header">
                                                                    <h2 className="entry__title">
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/${
                                                                                    dataPostCategory
                                                                                        .url
                                                                                    }`, search: `?tag_from=${dataPostCategory.tagables[0] ? dataPostCategory.tagables[0].tag.slug : 'unknown'}`
                                                                            }}
                                                                        >
                                                                            {dataPostCategory.title}
                                                                        </Link>
                                                                    </h2>
                                                                    <ul className="entry__meta">
                                                                        <li className="entry__meta-author">
                                                                            <span>by</span>
                                                                            <Link to={{
                                                                                pathname: `/authorPost/${dataPostCategory
                                                                                    .user
                                                                                    ? dataPostCategory

                                                                                        .user
                                                                                        .username
                                                                                    : 'Unknown'}`
                                                                            }}>
                                                                                {` `}{' '}
                                                                                {dataPostCategory
                                                                                    .user
                                                                                    ? dataPostCategory

                                                                                        .user
                                                                                        .name
                                                                                    : 'Unknown'}
                                                                            </Link>
                                                                        </li>
                                                                        <li className="entry__meta-date">
                                                                            {moment(
                                                                                dataPostCategory

                                                                                    .createdAt
                                                                            ).format(
                                                                                'LLLL'
                                                                            )}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                {/* <div className="entry__excerpt">
                                                                    <p>{dataPostCategory.description.length > 100 ? `${dataPostCategory.description.substring(0, 100)}...` : `${dataPostCategory.description}`}</p>
                                                                </div> */}
                                                            </div>
                                                        </article>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : <div>No results for your search.</div>}

                                        {this.state.entities.data &&
                                            this.state.entities.data.length > 0 && (
                                                <nav className="pagination">
                                                    <a
                                                        href="javascript:void(0);"
                                                        onClick={() =>
                                                            1 ===
                                                                this.state.entities
                                                                    .pagination.current_page
                                                                ? null
                                                                : this.changePage(
                                                                    this.state
                                                                        .entities
                                                                        .pagination
                                                                        .current_page -
                                                                    1
                                                                )
                                                        }
                                                        className="pagination__page pagination__icon pagination__page--next"
                                                    >
                                                        <i className="ui-arrow-left" />
                                                    </a>
                                                    {this.pageList()}
                                                    <a
                                                        href="javascript:void(0);"
                                                        onClick={() =>
                                                            this.state.entities
                                                                .pagination
                                                                .total_page ===
                                                                this.state.entities
                                                                    .pagination.current_page
                                                                ? null
                                                                : this.changePage(
                                                                    this.state
                                                                        .entities
                                                                        .pagination
                                                                        .current_page +
                                                                    1
                                                                )
                                                        }
                                                        className="pagination__page pagination__icon pagination__page--next"
                                                    >
                                                        <i className="ui-arrow-right" />
                                                    </a>
                                                </nav>
                                            )}
                                    </div>
                                )
                            }
                        </div>
                        <aside className="col-lg-4 sidebar sidebar--right">
                            <PopularPosts loading={loadingTopTen} data={datasTopTen} />
                            {submittedSubscribe && !subscribeEmail && (
                                <div
                                    className="help-block"
                                    style={{
                                        backgroundColor: 'red',
                                        padding: 10,
                                        color: 'white'
                                    }}
                                >
                                    Email is required
                                </div>
                            )}
                            <aside className="widget widget_mc4wp_form_widget">
                                <h4 className="widget-title">Newsletter</h4>
                                <p className="newsletter__text">
                                    <i className="ui-email newsletter__icon" />
                                    Subscribe for our daily news
                                </p>
                                <form
                                    className="mc4wp-form"
                                    name="formSubscribe"
                                    onSubmit={this.onSubmitSubscribe}
                                >
                                    <div className="mc4wp-form-fields">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="subscribeEmail"
                                                placeholder="Your email"
                                                value={subscribeEmail}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="submit"
                                                className="btn btn-lg btn-color"
                                                value="Sign Up"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </aside>
                            <SocialMedia />
                        </aside>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArticlesLatest

import React, { Component } from 'react'
import {
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { imagesUrl } from '../config'
import Api from '../services/api'

class Foto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			datasBeritaFoto: [],
			loadingBeritaFoto: true
		};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this)
	}

	componentDidMount() {
		this.fetchBeritaByCategory('foto')
	}

	fetchBeritaByCategory(cat) {
		var varLoadingName = null;
		var varDataName = null;
		var limit = 3;

		if (cat === 'foto') {
			varLoadingName = 'loadingBeritaFoto';
			varDataName = 'datasBeritaFoto'
		}

		this.setState({ [varLoadingName]: true }, () => {
			const params = `page=1&limit=${limit}&keyword=&type=image&schedule=0&category=${cat}`;
			const route = `/post/get_by_category?${params}`;
			Api.get(route, null)
				.then(response => {
					if (response.data.length > 0) {
						var lists = [];
						var no = 1;
						response.data.map(dt => {
							var item = {};
							item.src = dt.post.mediaable.media.url;
							item.url = dt.post.url;
							item.altText = dt.post.title;
							item.caption = 'Slide Foto' + no;
							item.header = dt.post.title;
							no += 1;
							lists.push(item)
						});
						this.setState({ [varDataName]: lists })
					}

					this.setState({ [varLoadingName]: false })
				})
				.catch(error => {
					console.error(error);
					this.setState({ [varLoadingName]: false })
				})
		})
	}

	onExiting() {
		this.animating = true
	}

	onExited() {
		this.animating = false
	}

	next() {
		const { datasBeritaFoto } = this.state;
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === datasBeritaFoto.length - 1
				? 0
				: this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex })
	}

	previous() {
		const { datasBeritaFoto } = this.state;
		if (this.animating) return;
		const nextIndex =
			this.state.activeIndex === 0
				? datasBeritaFoto.length - 1
				: this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex })
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex })
	}

	render() {
		const { datasBeritaFoto, loadingBeritaFoto, activeIndex } = this.state;
		const slides = datasBeritaFoto.map(item => {
			return (
				<CarouselItem
					onExiting={this.onExiting}
					onExited={this.onExited}
					key={item.caption}
					ride="carousel"
				>
					<div className="containerImg" style={{ 
							marginBottom: 16,
							backgroundImage: item && item.src ? `url(${item.src})` : 'url(/static/media/logo-dark-beta.f32370e6.png)',
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain",
							backgroundPosition: "center"
						}}>
						{/* <img
							src={item.src}
							alt={item.altText}
							style={{ width: '100%', height:"auto" }}
							onError={e => {
								e.target.src =
									'https://tpc.googlesyndication.com/simgad/4354010893698296582?w=400&h=209'
							}}
						/> */}
						<div className="contentImg">
							<Link
								to={{
									pathname: `/${item.url}`,
									search: `?tag_from=${item.tag}`
								}}
							>
								<h2
									style={{
										color: 'white',
										// margin: 0,
										// padding: 0
									}}
								>
									{item.header}
								</h2>
							</Link>
						</div>
					</div>
				</CarouselItem>
			)
		});

		if (loadingBeritaFoto) return null;
		return (
			<div>
				<React.Fragment>
					<section className="section mb-24">
						<div className="title-wrap title-wrap--line">
							<Link to={{ pathname: `/category/foto` }}>
								<h3 className="section-title">FOTO</h3>
							</Link>
						</div>
						{datasBeritaFoto.length > 0 ? (
							<Carousel
								activeIndex={activeIndex}
								next={this.next}
								previous={this.previous}
								autoPlay={true}
							>
								<CarouselIndicators
									items={datasBeritaFoto}
									activeIndex={activeIndex}
									onClickHandler={this.goToIndex}
								/>
								{slides}
								<CarouselControl
									direction="prev"
									directionText="Previous"
									onClickHandler={this.previous}
								/>
								<CarouselControl
									direction="next"
									directionText="Next"
									onClickHandler={this.next}
								/>
							</Carousel>
						) : (
							<p>Belum ada foto.</p>
						)}
					</section>
				</React.Fragment>
			</div>
		)
	}
}

export default Foto

import 'isomorphic-fetch';
import { apiUrl } from '../config'

export default (routerName, slug, token = "") => {
	if (['news'].indexOf(routerName) > -1) {
		return fetch(`${apiUrl}/post/by_url/${slug}`,{
				headers: [
					["Content-Type", "application/json;text/plain"],
					["x-access-token", token]
				],
			})
			.then(res => {
				return res.json();
			})
			.then(data => {
				return data;
			});
	} else {
		return null
	}

};
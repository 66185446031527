import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Slider from 'react-slick'
import moment from "moment";

import { IsSSR } from '../helpers/isSSR'

class Polling extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.isSSR = IsSSR();
	}

	renderImage(dataPolling) {
		var imageUrl = '/assets/img/favicon-ivoox.png';
		if (dataPolling.mediaables.length > 0)
			imageUrl = dataPolling.mediaables[0].media ? dataPolling.mediaables[0].media.url : '/assets/img/favicon-ivoox.png';
		return <img src={imageUrl} className="entry__img lazyloaded" alt="" />
	}

	render() {
		const { datas, loading } = this.props;

		let settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
			slidesToScroll: !this.isSSR && window.outerWidth < 480 ? 1 : 3
		};

		if (loading) return null;
		return (
			<Slider {...settings}>
				{datas
					.reverse()
					.slice(0, 6)
					.map((dataPolling, dataPollingIndex) => (
						<div
							className="slick-entry-card"
							key={dataPollingIndex}
						>
							<article className="entry card thumb--size-3">
								<div className="entry__img-holder card__img-holder">
									<Link
										to={{
											pathname: `/polling/${
												dataPolling.id
												}/${dataPolling.url}`,
											search: '?tag_from=yahoo'
										}}
									>
										<div className="thumb-container thumb-70">
											{this.renderImage(
												dataPolling
											)}
										</div>
									</Link>
									{/* {dataPolling.categoryables.length >
										0 ? (
											dataPolling.categoryables
												.slice(0, 1)
												.map(
													data_polling_category => {
														return (
															<Link
																key={
																	data_polling_category
																		.category
																		.id
																}
																to={{
																	pathname: `/category/${
																		data_polling_category
																			.category
																			.slug
																		}`,
																	search:
																		'?tag_from=ivoox'
																}}
																className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
															>
																<span>
																	{
																		data_polling_category
																			.category
																			.name
																	}
																</span>
															</Link>
														)
													}
												)
										) : (
											<a
												href="javascript:void(0);"
												className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
											>
												<span>Unknown</span>
											</a>
										)} */}
								</div>
								<div
									className="entry__body card__body"
									style={{ minHeight: 120 }}
								>
									<div className="entry__header">
										<h2 className="entry__title">
											<Link
												to={{
													pathname: `/polling/${
														dataPolling.id
														}/${
														dataPolling.url
														}`
												}}
											>
												{dataPolling.title}
											</Link>
										</h2>
										<ul className="entry__meta">
											<li className="entry__meta-date">
												{moment(dataPolling.createdAt).format('LLLL')}
											</li>
										</ul>
									</div>
									<div className="entry__excerpt" style={{ marginBottom: 10 }}>
										<Link
											to={{
												pathname: `/polling/${
													dataPolling.id
													}/${dataPolling.url}`
											}}
											className="btn btn-color"
										>
											Vote Now
										</Link>
									</div>
								</div>
							</article>
						</div>
					))}
			</Slider>
		);
	}
}

export default Polling

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'

import { IsSSR } from '../helpers/isSSR'

class V2Vooxline extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.isSSR = IsSSR()
    this.sliderRef = React.createRef()
  }

  renderImage(dataFoto) {
    const imageUrl = dataFoto.post?.mediaable?.media?.url || '/assets/img/favicon-ivoox.png'
    return <img src={imageUrl} className="entry__img lazyloaded" alt="" style={{ aspectRatio: '4/3', objectFit: 'cover', objectPosition: 'center' }} />
  }

  handleNext = () => {
    this.sliderRef.current.slickNext()
  }
  handlePrev = () => {
    this.sliderRef.current.slickPrev()
  }

  render() {
    const { datas, loading } = this.props
    console.log('V2Vooxline datas:', datas)

    if (loading) return null
    return (
      // <section className="section mb-24">
      //   <div style={{ paddingLeft: 0, paddingRight: 0 }} className="infografik">
      //     <div className="title-wrap title-wrap--line">
      //       <div className="row">
      //         <div className="col-md-6" style={{ width: '50%' }}>
      //           <Link
      //             to={{
      //               pathname: `/category/vooxline`
      //             }}
      //           >
      //             <h3 className="section-title">VOOXLINE</h3>
      //           </Link>
      //         </div>
      //         {/* <input onChange={this.handleInputChange} type="range" min={0} max={3} /> */}
      //         <div className="col-md-6 d-flex align-items-center justify-content-end " style={{ gap: '1rem' }}>
      //           <Link
      //             to={{
      //               pathname: `/category/vooxline`
      //             }}
      //             style={{ fontSize: '18px', textDecoration: 'underline', fontWeight: 'bold', color: 'black' }}
      //           >
      //             See All
      //           </Link>
      //           <div className="d-flex justify-content-center">
      //             <button
      //               onClick={this.handlePrev}
      //               className="px-3 py-2 "
      //               style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '2px 0 0 2px', background: 'none' }}
      //             >
      //               <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      //                 <path d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z" fill="#0C0C0C" fill-opacity="0.4" />
      //               </svg>
      //             </button>
      //             <button
      //               onClick={this.handleNext}
      //               className="px-3 py-2 "
      //               style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '0 2px 2px 0', background: 'none' }}
      //             >
      //               <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      //                 <path d="M4.6 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6Z" fill="#0C0C0C" fill-opacity="0.4" />
      //               </svg>
      //             </button>
      //           </div>
      //         </div>
      //       </div>
      //     </div>

      //     <Slider
      //       ref={this.sliderRef}
      //       {...{
      //         dots: false,
      //         infinite: true,
      //         speed: 500,
      //         slidesToShow: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
      //         slidesToScroll: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
      //         arrows: false
      //       }}
      //     >
      //       {datas.map((data, dataIndex) => (
      //         <div className="infografik-card" key={dataIndex}>
      //           <Link
      //             to={{
      //               pathname: `/${data.post.url}`,
      //               search: `?tag_from=${data.post.tagables[0].tag.name || 'yahoo'}`
      //             }}
      //             style={{ position: 'relative' }}
      //           >
      //             {this.renderImage(data)}
      //             <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.3)', color: 'white', bottom: 0, padding: '1rem', width: '100%' }}>
      //               <h5 style={{ color: 'white' }}>{data.post.title}</h5>
      //               <span style={{ fontSize: '11px' }}> {moment(data.post.createdAt).format('LLLL')}</span>
      //               <div>
      //                 <span>by</span>
      //                 <Link
      //                   to={{
      //                     pathname: `/authorPost/${data.post.user.username}`
      //                   }}
      //                   className="text-white"
      //                 >
      //                   {` `}
      //                   {data.post.user.name}
      //                 </Link>
      //               </div>
      //             </div>
      //           </Link>
      //         </div>
      //       ))}
      //     </Slider>
      //   </div>
      // </section>
      <>
        <section className="section mb-24 category-section-post">
          <div className="title-wrap title-wrap--line" style={{ marginBottom: 16 }}>
            <div className="row">
              <div className="col-md-6" style={{ width: '50%' }}>
                <Link
                  to={{
                    pathname: `/category/vooxline`
                  }}
                >
                  <h3 className="section-title">VOOXLINE</h3>
                </Link>
              </div>
              <div
                className="col-md-6"
                style={{
                  textAlign: 'right',
                  width: '50%'
                }}
              >
                <Link
                  to={{
                    pathname: `/category/vooxline`
                  }}
                >
                  <h5>
                    <u>See All</u>
                  </h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="row row-20 card-row">
            <div className="col-md-6">
              {datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                dataByCategoriesParentIndex === 0 ? (
                  <article className="entry thumb thumb--size-3-cus thumb--mb-20" key={dataByCategoriesParentIndex} style={{ marginBottom: '0px' }}>
                    <div
                      className="entry__img-holder thumb__img-holder"
                      style={{
                        backgroundImage: dataByCategoriesParent.post.mediaable !== null ? `url(${dataByCategoriesParent.post.mediaable.media.url})` : ''
                      }}
                    >
                      <div className="bottom-gradient" />
                      <div className="thumb-text-holder thumb-text-holder--2">
                        <ul className="entry__meta">
                          <li>
                            <Link
                              to={{
                                pathname: `/category/vooxline`
                              }}
                              className="entry__meta-category--label"
                            >
                              VOOXLINE
                            </Link>
                          </li>
                        </ul>
                        <Link
                          to={{
                            pathname: `/${dataByCategoriesParent.post.url}`,
                            search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                          }}
                        >
                          <h2
                            className="thumb-entry-title"
                            dangerouslySetInnerHTML={{
                              __html: dataByCategoriesParent.post.title
                            }}
                          />
                        </Link>
                      </div>
                      <Link
                        to={{
                          pathname: `/${dataByCategoriesParent.post.url}`,
                          search: `?tag_from=${dataByCategoriesParent.post.tagables[0] ? dataByCategoriesParent.post.tagables[0].tag.slug : ''}`
                        }}
                        className="thumb-url"
                      />
                    </div>
                  </article>
                ) : null
              )}
            </div>
            <div className="col-md-6">
              <div className="row card-row">
                {datas.map((dataByCategoriesParent, dataByCategoriesParentIndex) =>
                  dataByCategoriesParentIndex === 0 ? null : (
                    <div className="col-md-6" key={dataByCategoriesParentIndex}>
                      <article
                        className="entry card thumb--size-3 card-hard-auto"
                        style={{
                          height: '100%'
                        }}
                      >
                        <div className="entry__img-holder card__img-holder">
                          <Link
                            to={{
                              pathname: `/${dataByCategoriesParent.post.url}`,
                              search: `?tag_from=${
                                dataByCategoriesParent.post.tagables
                                  ? dataByCategoriesParent.post.tagables[0]
                                    ? dataByCategoriesParent.post.tagables[0].tag
                                      ? dataByCategoriesParent.post.tagables[0].tag.slug
                                      : 'unknown'
                                    : 'unknown'
                                  : 'unknown'
                              }`
                            }}
                          >
                            {/* <div className="thumb-container thumb-70"> */}
                            <div className="">
                              <img
                                // className="logo__img entry__img lazyload"
                                className=""
                                src={dataByCategoriesParent.post.mediaable !== null ? `${dataByCategoriesParent.post.mediaable.media.url}` : ''}
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            to={{
                              pathname: `/category/vooxline`
                            }}
                            className="entry__meta-category entry__meta-category--label entry__meta-category--align-in-corner"
                          >
                            VOOXLINE
                          </Link>
                        </div>

                        <div
                          className="entry__body card__body"
                          style={{
                            minHeight: 'auto'
                          }}
                        >
                          <div className="entry__header">
                            <Link
                              to={{
                                pathname: `/${dataByCategoriesParent.post.url}`,
                                search: `?tag_from=${
                                  dataByCategoriesParent.post.tagables
                                    ? dataByCategoriesParent.post.tagables[0]
                                      ? dataByCategoriesParent.post.tagables[0].tag
                                        ? dataByCategoriesParent.post.tagables[0].tag.slug
                                        : 'unknown'
                                      : 'unknown'
                                    : 'unknown'
                                }`
                              }}
                            >
                              <h2
                                className="entry__title"
                                dangerouslySetInnerHTML={{
                                  __html: dataByCategoriesParent.post.title
                                }}
                              />
                            </Link>
                            <ul className="entry__meta">
                              <li className="entry__meta-author">
                                <span>by</span>
                                <Link
                                  to={{
                                    pathname: `/authorPost/${dataByCategoriesParent.post.user.username}`
                                  }}
                                >
                                  {` `}
                                  {dataByCategoriesParent.post.user.name}
                                </Link>
                              </li>
                              <li className="entry__meta-date">{moment(dataByCategoriesParent.post.createdAt).format('LLLL')}</li>
                            </ul>
                          </div>
                        </div>
                      </article>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    )
  }
}

export default V2Vooxline

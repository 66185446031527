import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import FormCareerPosition from './form'
import Toast from '../../../components/Toast'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import { apiUrl, keyToken, keyUser } from '../../../config'

const $ = require('jquery');
$.DataTable = require('datatables.net');

class ListCareerPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            loadingContainer: false,
            loading: false,
            editCareerPositionStatus: false,
            editCareerPositionId: 0,
            careerPositionData: {
                name: '',
                expired_at: ''
            },
            deleteCareerPositionModal: false,
            deleteCareerPositionData: {
                id: '',
                name: ''
            },
            errors: {},
            loadingRole: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeCareerPosition = this.onChangeCareerPosition.bind(this);
        this.setLoadingContainer = this.setLoadingContainer.bind(this);
        this.refreshCareerPositions = this.refreshCareerPositions.bind(this);
        this.showCareerPositionDetail = this.showCareerPositionDetail.bind(this);
        this.deleteCareerPosition = this.deleteCareerPosition.bind(this);
        this.refreshEditData = this.refreshEditData.bind(this);
        this.setParameterErrors = this.setParameterErrors.bind(this);
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-career_position').off( "click", ".detail-career_position-btn");
                $('#datatable-career_position').off( "click", ".delete-career_position-btn");
                $('#datatable-career_position')
                    .DataTable()
                    .destroy()
                $('#datatable-career_position tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    handleChange(value) {
        this.setState({ text: value })
    }

    onChangeDate(date) {
        let { careerPositionData } = this.state;
        careerPositionData["expired_at"] = date;
        console.log(date);
        this.setState({ careerPositionData })
    }

    onChangeCareerPosition(e) {
        const { name, value } = e.target;
        let { careerPositionData } = this.state;
        careerPositionData[name] = value;
        this.setState({ careerPositionData })
    }

    setParameterErrors(data) {
        this.setState({
            errors: data
        })
    }

    refreshEditData() {
        this.setState({
            editCareerPositionStatus: false,
            editCareerPositionId: 0,
            text: "",
            careerPositionData: {
                name: '',
                details: '',
                expired_at: ''
            },
            errors: {}
        })
    }

    refreshCareerPositions() {
        $('#datatable-career_position')
            .DataTable()
            .ajax.reload()
    }

    fetchData() {
        const user = JSON.parse(this.props.localUser);
        const {
            isAs
        } = this.props;

        var _ = this;
        const route = `/career_position/index`;
        const url = `${apiUrl}${route}`;
        this.$el = $(this.el);
        var careerPositionDatatableDraw = 1;
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    careerPositionDatatableDraw = data.draw;
                    var requestData = {};
                    requestData.page = data.start / data.length + 1;
                    requestData.limit = data.length;
                    requestData.keyword = data.search.value;
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/careerPosition?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id', orderable: false },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    render: function (data) {
                        return (
                            '<a href="javascript:void(0);" data-id="' +
                            data.id +
                            '" class="detail-career_position-btn">' +
                            data.name +
                            '</a>'
                        )
                    }
                },
                {
                    data: 'expired_at',
                    name: 'expired_at',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY')
                    }
                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><button data-id="' +
                            data.id +
                            '" class="detail-career_position-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></button><button  data-id="' +
                            data.id +
                            '" data-name="' +
                            data.name +
                            '" class="delete-career_position-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        });
        $('#datatable-career_position').on(
            'click',
            '.detail-career_position-btn',
            function () {
                var id = $(this).data('id');
                _.showCareerPositionDetail(id)
            }
        );
        $('#datatable-career_position').on(
            'click',
            '.delete-career_position-btn',
            function () {
                var id = $(this).data('id');
                var name = $(this).data('name');
                _.deleteCareerPosition(id, name)
            }
        )
    }

    showCareerPositionDetail(id) {
        if (['admin', 'editor'].indexOf(this.props.isAs) > -1) {
            this.setLoadingContainer(true);
            this.setParameterErrors({});
            const route = `/career_position/` + id;
            Api.get(route, this.token)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        let careerPositionData = {};
                        careerPositionData.id = response.data.id;
                        careerPositionData.name = response.data.name;
                        if (response.data && response.data.expired_at) {
                            let date = moment(response.data.expired_at);
                            if (date.isValid()) {
                                careerPositionData.expired_at = date.toDate();
                            } else {
                                careerPositionData.expired_at = "";
                            }
                        }
                        this.setState({
                            editCareerPositionStatus: true,
                            editCareerPositionId: response.data.id,
                            careerPositionData: careerPositionData,
                            text: response.data.details
                        });
                        this.setLoadingContainer(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                    Toast('Error internet connection');
                    this.setLoadingContainer(false)
                })
        } else {
            Toast('You do not have access to this action')
        }
    }

    deleteRowTable(id) {
        if (['admin', 'editor'].indexOf(this.props.isAs) > -1) {
            this.setState({ loading: true });
            const route = `/career_position/delete/` + id;
            Api.delete(route, this.token)
                .then(response => {
                    if (response.status && response.status === 'success') {
                        this.refreshCareerPositions();
                        Toast(response.message, 'success');
                        this.setState({ deleteCareerPositionModal: false })
                    }
                })
                .catch(() => {
                    this.refreshCareerPositions();
                    Toast('Error internet connection');
                    this.setState({ deleteCareerPositionModal: false })
                })
        } else {
            Toast('You do not have access to this action')
        }
    }

    toggleDeleteCareerPositionsModal() {
        this.setState({
            deleteCareerPositionModal: !this.state.deleteCareerPositionModal
        })
    }

    deleteCareerPosition(id, name) {
        this.setState({
            deleteCareerPositionData: {
                id: id,
                name: name
            },
            deleteCareerPositionModal: !this.state.deleteCareerPositionModal
        })
    }

    setLoadingContainer(status) {
        this.setState({ loadingContainer: status })
    }

    render() {
        const { loadingContainer } = this.state;
        const { isAs } = this.props;
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <Modal
                            isOpen={this.state.deleteCareerPositionModal}
                            toggle={this.toggleDeleteCareerPositionsModal.bind(this)}
                            className={this.props.className}
                        >
                            <ModalHeader
                                toggle={this.toggleDeleteCareerPositionsModal.bind(
                                    this
                                )}
                            >
                                Konfirmasi
                            </ModalHeader>
                            <ModalBody>
                                Apakah ingin menghapus posisi karir{' '}
                                {this.state.deleteCareerPositionData.name}?
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.deleteRowTable.bind(
                                        this,
                                        this.state.deleteCareerPositionData.id
                                    )}
                                >
                                    Hapus
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDeleteCareerPositionsModal.bind(
                                        this
                                    )}
                                >
                                    Batalkan
                                </Button>
                            </ModalFooter>
                        </Modal>
                        {['admin', 'editor'].indexOf(isAs) > -1 && (
                            <FormCareerPosition
                                setLoadingContainer={this.setLoadingContainer}
                                onChangeCareerPosition={this.onChangeCareerPosition}
                                refreshCareerPositions={this.refreshCareerPositions}
                                setParameterErrors={this.setParameterErrors}
                                onChangeDate={this.onChangeDate}
                                handleChange={this.handleChange}
                                refreshEditData={this.refreshEditData}
                                editCareerPositionStatus={
                                    this.state.editCareerPositionStatus
                                }
                                editCareerPositionId={this.state.editCareerPositionId}
                                careerPositionData={this.state.careerPositionData}
                                errors={this.state.errors}
                                text={this.state.text}
                                isAs={this.props.isAs} 
                            />
                        )}

                        <div className="divider" />
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">
                                        Career Position List
                                    </h4>
                                    <table
                                        id="datatable-career_position"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Position Name</th>
                                                <th>Expired Date</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ListCareerPosition

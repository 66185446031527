import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

import { IsSSR } from '../../helpers/isSSR'

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);
		this.isSSR = IsSSR();
	}

	componentDidMount() {
		if (!this.isSSR) window.scrollTo(0, 0)
	}

	render() {
		return (
			<div>
				<div className="container">
					<ul className="breadcrumbs">
						<li className="breadcrumbs__item">
							<Link to="/" className="breadcrumbs__url">
								Home
							</Link>
						</li>
						<li className="breadcrumbs__item breadcrumbs__item--current">
							Privacy Policy
						</li>
					</ul>
				</div>

				<div className="main-container container" id="main-container">
					<h1 className="page-title">Privacy Policy</h1>
					<div className="row">
						<div className="col-lg-8 mb-72">
							<div className="content-box">
								<p>
									Kebijakan Privasi (Privacy Policy) untuk
									ivoox.id.
								</p>
								<p>
									Jika Anda memerlukan informasi lebih lanjut
									atau memiliki pertanyaan lain tentang
									kebijakan privasi pada ivoox.id, silahkan
									menghubungi kami via e-mail di
									developer@ptmns.id.
								</p>
								<p>
									Pada ivoox.id, privasi para pengunjung blog
									ini sangat penting. Dokumen kebijakan
									privasi ini mengurai jenis informasi pribadi
									yang diterima dan dikumpulkan oleh ivoox.id
									dan bagaimana informasi pribadi tersebut
									digunakan.
								</p>
								<p>Log Files</p>
								<p>
									Seperti kebanyakan situs Web lain, ivoox.id
									juga menggunakan log files. Informasi dalam
									log files meliputi alamat internet protocol
									(IP), jenis browser, Internet Service
									Provider (ISP), jejak tanggal / waktu,
									refferering atau exit pages dan klik-klik
									yang dilakukan pengunjung untuk menganalisis
									kecendrungan, mengelola situs, melacak
									aktifitas pengguna pada situs, dan untuk
									mengumpulkan informasi demografis. Alamat IP
									dan informasi lainnya tersebut tidak terkait
									dengan informasi yang bersifat pribadi.
								</p>
								<p>Cookies dan Web Beacons</p>
								<p>
									ivoox.id menggunakan cookies untuk menyimpan
									informasi tentang preferensi pengunjung,
									merekam informasi pengguna tertentu pada
									halaman yang dikunjungi, menyesuaikan
									halaman Web content based berdasarkan tipe
									browser yang digunakan pengunjung atau
									informasi lainnya yang pengunjung kirimkan
									melalui browser yang digunakan.
								</p>
								<p>
									ivoox.id tidak memiliki akses atau kontrol
									terhadap cookies yang digunakan oleh
									pengiklan pihak ketiga.
								</p>
								<p>
									Jika Anda ingin menonaktifkan cookies, Anda
									dapat melakukannya melalui pilihan-pilihan
									di browser Anda. Informasi lebih lanjut
									tentang manajemen cookie dengan browser web
									tertentu dapat ditemukan di situs web
									masing-masing browser.
								</p>

								<p>
									ivoox.id tunduk dan patuh pada aturan
									dibawah google. Untuk membaca aturan Google,
									silahkan kunjungi :
									http://www.google.com/policies/privacy/
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PrivacyPolicy

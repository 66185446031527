import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import Reaptcha from 'reaptcha'
import { Link, withRouter } from "react-router-dom";

import {
    apiUrl,
    sitekeyReaptcha, 
    keyToken, 
    keyUser
} from '../../../config'

import Toast from '../../../components/Toast'

import { IsSSR } from "../../../helpers/isSSR";
import { removeStorageItem, getStorageItem } from '../../../helpers/storage'

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fIdentifier: '',
            submittedLogin: false,
            loadingSubmit: false,
            errorStatus: false,
            errorMessage: '',
            verified: false
        };
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);

        this.onSubmitForgotPassword = this.onSubmitForgotPassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this)
    }

    componentDidMount() {
        if (!this.isSSR) window.scrollTo(0, 0);
		if (!this.token) {
			if (!this.isSSR) removeStorageItem(keyToken).then(r => console.log('delete token'));
			if (!this.isSSR) removeStorageItem(keyUser).then(r => console.log('delete user'));
		} else {
			return this.props.history.push(`/admin/dashboard`);
		}
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    };

    onDismiss() {
        this.setState({ errorStatus: false })
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value })
    }

    onSubmitForgotPassword(e) {
        e.preventDefault();

        this.setState({ submittedLogin: true });
        const { fIdentifier } = this.state;
        if (fIdentifier !== '') this.onSave()
    }

    onSave() {
        const { fIdentifier } = this.state;

        this.setState({ errorStatus: false });
        this.setState({ loadingSubmit: true }, () => {
            const data = {
                email: fIdentifier
            };
            fetch(`${apiUrl}/user/forgotpassword`, {
                method: 'POST',
                headers: [
                    ['Content-Type', 'application/json;text/plain']
                ],
                body: JSON.stringify(data)
            })
                .then(function (response) {
                    return response.json()
                })
                .then(response => {
                    if (response.status === 'success') {
                        Toast('Forgot Password Success, Please Check Your Email And Click Link For Reset Password.', 'success')
                    } else {
                        this.checkError(response)
                    }
                    this.setState({
                        loadingSubmit: false,
                        submittedSubject: false
                    })
                })
        })
    }

    checkError(response) {
        if (response.status && response.status === 'failed') {
            if (response.error) {
                this.setState({ errorStatus: true });
                this.setState({ errorMessage: response.message })
            } else {
                this.setState({ errorStatus: true });
                this.setState({ errorMessage: response.message })
            }
        } else {
            this.setState({ errorStatus: true });
            this.setState({ errorMessage: response.message })
        }
    }

    refreshPage() {
        window.location.reload()
    }

    render() {
        const {
            fIdentifier,
            loadingSubmit,
            errorStatus,
            errorMessage
        } = this.state;

        return (
            <div
                className="main-container container pt-80 pb-80"
                id="main-container"
            >
                <div className="blog__content mb-72">
                    <div className="container login-box">
                        <Alert
                            color="danger"
                            isOpen={errorStatus}
                            toggle={this.onDismiss}
                        >
                            {errorMessage}
                        </Alert>
                        <h2 className="text-center">Forgot Password</h2>
                        <div className="row justify-content-center mt-40">
                            <div className="col-md-6">
                                <form
                                    onSubmit={this.onSubmitForgotPassword}
                                    name="formLogin"
                                    className="search-form relative"
                                >
                                    <div className="form-group">
                                        <label htmlFor="name">Email</label>
                                        <input
                                            name="fIdentifier"
                                            id="fIdentifier"
                                            type="email"
                                            value={fIdentifier}
                                            onChange={this.onChange}
                                            required="required"
                                        />
                                    </div>
                                    <div style={{ marginBottom: 16 }}>
                                        <Reaptcha
                                            sitekey={sitekeyReaptcha}
                                            onVerify={this.onVerify}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button
                                            disabled={
                                                !this.state.verified ||
                                                loadingSubmit
                                            }
                                            type="submit"
                                            className="btn btn-lg btn-color btn-button"
                                        >
                                            {loadingSubmit
                                                ? 'Loading...'
                                                : 'Forgot Password'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <p className="text-center"><Link to="/auth" >Login</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ForgotPassword)

import React from 'react';
import { Link } from 'react-router-dom'

export default ({ staticContext = {} }) => {
	staticContext.status = 404;
	return (
		<div
			className="main-container container pt-80 pb-80"
			id="main-container"
		>
			<div className="blog__content mb-72">
				<div className="container text-center">
					<h1 className="page-404-number">404</h1>
					<h2>Page not found</h2>
					<div className="row justify-content-center mt-40">
						<div className="col-md-6">
							<Link
								to="/"
								className="btn btn-lg btn-light mt-40"
							>
								<span>Back to Home</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};
import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import Reaptcha from 'reaptcha'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { keyToken, keyUser, sitekeyReaptcha } from '../../../config'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkErrorWithParam'
import Toast from '../../../components/Toast'

class FormTag extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            verified: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() { 
        if (['admin', 'editor', 'author'].indexOf(this.props.isAs) > -1) {
            this.setState({verified: true});
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.setLoadingContainer(true)
        this.props.setParameterErrors({})
        if (this.props.editTagStatus) {
            this.doUpdateTag()
        } else {
            this.doCreateTag()
        }
    }

    doCreateTag() {
        const params = {
            name: this.props.tagData.name,
            slug: this.props.tagData.slug,
            description: this.props.tagData.description
        }
        const route = `/tag/create`
        Api.post(route, params, this.token)
            .then(response => {
                this.props.setLoadingContainer(false)
                if (response.status && response.status === 'success') {
                    this.props.refreshTags()
                    this.props.refreshEditData()
                    Toast(response.message, 'success')
                } else {
                    CheckError(response, this.props.setParameterErrors)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false)
                CheckError(err, this.props.setParameterErrors)
            })
    }

    doUpdateTag() {
        const params = {
            name: this.props.tagData.name,
            slug: this.props.tagData.slug,
            description: this.props.tagData.description
        }
        const route = `/tag/update/` + this.props.editTagId
        Api.put(route, params, this.token)
            .then(response => {
                console.log()
                this.props.setLoadingContainer(false)
                if (response.status && response.status === 'success') {
                    this.props.refreshTags()
                    this.props.refreshEditData()
                    Toast(response.message, 'success')
                } else {
                    CheckError(response, this.props.setParameterErrors)
                }
            })
            .catch(err => {
                this.props.setLoadingContainer(false)
                CheckError(err, this.props.setParameterErrors)
            })
    }

    cancel(e) {
        e.preventDefault()
        this.props.refreshEditData()
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        })
    }

    render() {
        let headerContent
        let buttonContent
        if (this.props.editTagStatus) {
            headerContent = (
                <h4 className="heading-title">
                    Edit Tag Id {this.props.editTagId}
                </h4>
            )
            buttonContent = (
                <div className="form-group">
                    <button
                        key="tag-btn-cancel"
                        type="button"
                        onClick={this.cancel}
                        className="btn btn-md btn-button mr-3"
                    >
                        Cancel
                    </button>
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="tag-btn-edit"
                        className="btn btn-md btn-color btn-button"
                    >
                        Save
                    </button>
                </div>
            )
        } else {
            headerContent = <h4 className="heading-title">Add New Tag</h4>
            buttonContent = (
                <div className="form-group">
                    <button
                        disabled={!this.state.verified || this.state.loading}
                        key="tag-btn-add"
                        className="btn btn-md btn-color btn-button"
                    >
                        Add Tag
                    </button>
                </div>
            )
        }
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text="Please Wait..."
            >
                <div className="row">
                    <div className="col-lg-12 col-12">
                        {headerContent}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">
                                    Name <span className="text-red">*</span>
                                </label>
                                <input
                                    name="name"
                                    placeholder=""
                                    id="name"
                                    type="text"
                                    onChange={this.props.onChangeTag}
                                    value={this.props.tagData.name}
                                />
                                {this.props.errors.name &&
                                    this.props.errors.name.message && (
                                        <div
                                            className="help-block"
                                            style={{
                                                backgroundColor: 'red',
                                                paddingLeft: 10,
                                                paddingRight: 10
                                            }}
                                        >
                                            <small style={{ color: 'white' }}>
                                                {this.props.errors.name.message}
                                            </small>
                                        </div>
                                    )}
                                {this.props.errors.slug && this.props.errors.slug.message && (
                                    <div className="help-block" style={{ backgroundColor: 'red', paddingLeft: 10, paddingRight: 10 }}>
                                        <small style={{ color: 'white' }}>{this.props.errors.slug.message}</small>
                                    </div>
                                )}
                            </div>
                            <div style={{ marginBottom: 16 }}>
                                { (['admin', 'editor', 'author'].indexOf(this.props.isAs) <= -1) &&
                                    <Reaptcha
                                        sitekey={sitekeyReaptcha}
                                        onVerify={this.onVerify}
                                    />
                                }
                            </div>
                            {buttonContent}
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }
}

export default FormTag

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'

import { IsSSR } from '../helpers/isSSR'
import Api from '../services/api'

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datasBeritaVideo: [],
      loadingBeritaVideo: true
    }
    this.sliderRef = React.createRef()
  }

  componentDidMount() {
    this.fetchBeritaByCategory('video')
  }

  fetchBeritaByCategory(cat) {
    var varLoadingName = null
    var varDataName = null
    var limit = 9

    if (cat === 'video') {
      varLoadingName = 'loadingBeritaVideo'
      varDataName = 'datasBeritaVideo'
    } else if (cat === 'foto') {
      varLoadingName = 'loadingBeritaFoto'
      varDataName = 'datasBeritaFoto'
    }

    this.setState({ [varLoadingName]: true }, () => {
      const params = `page=1&limit=${limit}&keyword=&type=video&schedule=0&category=${cat}`
      const route = `/post/get_by_category?${params}`
      Api.get(route, null)
        .then((response) => {
          if (response.data.length > 0) {
            var lists = []
            var no = 1
            console.log(response.data, 'slepet')
            response.data.map((dt) => {
              var item = {}
              item.src = dt.post.mediaable.media.url
              item.url = dt.post.url
              item.altText = dt.post.title
              item.caption = 'Slide Video' + no
              item.header = dt.post.title
              item.createdAt = dt.post.createdAt

              item.user = dt.post.user
              no += 1
              lists.push(item)
            })

            this.setState({ [varDataName]: lists })
          }

          this.setState({ [varLoadingName]: false })
        })
        .catch((error) => {
          console.error(error)
          this.setState({ [varLoadingName]: false })
        })
    })
  }

  renderImage(dataVid) {
    const imageUrl = dataVid?.src || '/assets/img/favicon-ivoox.png'
    return (
      <img
        src={imageUrl}
        alt={dataVid.altText}
        style={{ width: '100%', height: 'auto' }}
        onError={(e) => {
          e.target.src = 'https://tpc.googlesyndication.com/simgad/4354010893698296582?w=400&h=209'
        }}
      />
    )
  }

  handleNext = () => {
    this.sliderRef.current.slickNext()
  }
  handlePrev = () => {
    this.sliderRef.current.slickPrev()
  }

  render() {
    const { datasBeritaVideo, loadingBeritaVideo } = this.state

    if (loadingBeritaVideo || !datasBeritaVideo?.length) return null
    return (
      <section className="section mb-24">
        <div style={{ paddingLeft: 0, paddingRight: 0 }} className="infografik">
          <div className="title-wrap title-wrap--line">
            <div className="row">
              <div className="col-md-6" style={{ width: '50%' }}>
                <Link
                  to={{
                    pathname: `/category/video`
                  }}
                >
                  <h3 className="section-title">VIDEO</h3>
                </Link>
              </div>
              {/* <input onChange={this.handleInputChange} type="range" min={0} max={3} /> */}
              <div className="col-md-6 d-flex align-items-center justify-content-end " style={{ gap: '1rem' }}>
                <Link
                  to={{
                    pathname: `/category/video`
                  }}
                  style={{ fontSize: '18px', textDecoration: 'underline', fontWeight: 'bold', color: 'black' }}
                >
                  See All
                </Link>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={this.handlePrev}
                    className="px-3 py-2 "
                    style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '2px 0 0 2px', background: 'none' }}
                  >
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z" fill="#0C0C0C" fill-opacity="0.4" />
                    </svg>
                  </button>
                  <button
                    onClick={this.handleNext}
                    className="px-3 py-2 "
                    style={{ border: '1px solid rgba(12, 12, 12, 0.4)', borderRadius: '0 2px 2px 0', background: 'none' }}
                  >
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.6 6L0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6Z" fill="#0C0C0C" fill-opacity="0.4" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Slider
            ref={this.sliderRef}
            {...{
              dots: true,
              infinite: true,
              speed: 500,
              slidesToShow: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
              slidesToScroll: !this.isSSR && window.outerWidth < 480 ? 1 : 3,
              arrows: false
            }}
          >
            {datasBeritaVideo.map((data, dataIndex) => (
              <div className="infografik-card" key={dataIndex}>
                <div>{this.renderImage(data)}</div>
                <div className="infografik-footer">
                  <h2 className="entry__title">
                    <Link
                      to={{
                        pathname: `/${data.url}`,
                        search: `?tag_from=${data.tag}`
                      }}
                    >
                      {data.header}
                    </Link>
                  </h2>
                  <div style={{ display: 'inline-block' }}>
                    <span style={{ fontWeight: 'bold', color: 'black', marginRight: '4px', display: 'inline-block' }}>BY</span>
                    <Link
                      to={{
                        pathname: `/authorPost/${data.user?.username}`
                      }}
                    >
                      {` `} {data.user?.name}
                    </Link>
                    <div>{moment(data.createdAt).format('LLLL')}</div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  }
}

export default Video

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Jumbotron } from 'reactstrap';

import Api from '../../services/api'
import { IsSSR } from "../../helpers/isSSR";
import { getStorageItem } from "../../helpers/storage";
import { keyToken } from "../../config";

class DashboardTwoRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalAllPost: 1
		};
		this.isSSR = IsSSR();
		this.token = null;
		if (!this.isSSR) this.token = getStorageItem(keyToken);
		this.getTotalAllPost = this.getTotalAllPost.bind(this);
	}

	componentDidMount() {
		this.interval = setInterval(this.getTotalAllPost, 1500);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getTotalAllPost() {
		const route = `/post/index?page=1&limit=1&schedule=0&state_id=all`;
		Api.get(route, this.token)
			.then(response => {
				if(response.status && response.status === "success") {
					this.setState({ totalAllPost: response.pagination.total_found });
				}
				clearInterval(this.interval);
			})
			.catch((err) => { clearInterval(this.interval);})
	}

	render() {
		const { totalAllPost } = this.state;
		return (
			<React.Fragment>
				<Jumbotron style={{ display: totalAllPost > 0 ? 'none' : 'block'}} className="mt-3 mb-3">
					<h1 className="display-3">No Posts Published!</h1>
					<p className="lead">You can make your first post here</p>
					<Link to="/admin/post/add">
						<span href="" className="btn btn-md btn-color btn-button">Add New Post</span>
					</Link>
				</Jumbotron>
				<div style={{ display: totalAllPost > 0 ? 'block' : 'none'}} className="mt-3">&nbsp;</div>
			</React.Fragment>
		)
	}
}

export default DashboardTwoRow

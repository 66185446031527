import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import { apiUrl, keyUser, keyToken } from '../../../config'
import CheckError from '../../../utils/admin/checkError'
import Toast from '../../../components/Toast'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListActivityLog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingContainer: false,
            loading: false,
            loadingActivityLog: true,

        }

        this.refreshActivityLogs = this.refreshActivityLogs.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        this.user = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
        if (!this.isSSR) this.user = getStorageItem(keyUser);
    }

    componentDidMount() {
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword')
            ) {
                $('#datatable-activity_log').off( "click", ".detail-activity_log-btn");
                $('#datatable-activity_log')
                    .DataTable()
                    .destroy()
                $('#datatable-activity_log tbody').empty();
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    onChangeActivityLog(e) {
        const { name, value } = e.target
        let { activityLogData } = this.state
        activityLogData[name] = value
        this.setState({ activityLogData })
    }

    fetchData() {
        var _ = this;
        const route = `/activity_log/index`;
        const url = `${apiUrl}${route}`;
        this.$el = $(this.el);
        var activityLogDatatableDraw = 1;
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            searching: true,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    activityLogDatatableDraw = data.draw
                    var requestData = {}
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/activityLogs?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                {
                    data: null,
                    name: 'id',
                    render: function (data) {
                        var id = data.id
                        return (
                            '<a href="/admin/activityLogs/' + id + '" data-id="' +
                            id +
                            '" class="detail-activity_log-btn">' +
                            id +
                            '</a>'
                        )
                    }

                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    name: 'username',
                    orderable: false,
                    render: function (data) {
                        var username = (data.user && data.user.username) ? data.user.username : "";
                        return username;
                    }
                },
                {
                    data: null,
                    name: 'ip',
                    orderable: false,
                    render: function (data) {
                        var ip_address = (data.device_details) && JSON.parse(data.device_details).ip_address ? JSON.parse(data.device_details).ip_address : "";
                        return ip_address;
                    }
                },
                {
                    data: null,
                    name: 'activityable_type',
                    orderable: false,
                    render: function (data) {
                        var type = data.activityable_type.replace("App/", "");
                        return type;
                    }
                },
                {
                    data: null,
                    name: 'action',
                    orderable: false,
                    render: function (data) {
                        return data.action;
                    }
                },
            ]
        })
        $('#datatable-activity_log').on('click', '.detail-activity_log-btn', function (e) {
            e.preventDefault();
            var id = $(this).data('id')
            _.showDetail(id)
        })
    }

    showDetail(id) {
        this.props.history.push(`/admin/activityLogs/${id}`)
    }

    refreshActivityLogs() {
        $('#datatable-activity_log')
            .DataTable()
            .ajax.reload()
    }

    render() {
        const { loadingContainer } = this.state
        return (
            <div className="col-lg-9 col-12">
                <LoadingOverlay
                    active={loadingContainer}
                    spinner
                    text="Please Wait..."
                >
                    <div className="entry card card-input">
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Activity Log List</h4>
                                    <table
                                        id="datatable-activity_log"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Date</th>
                                                <th>Username</th>
                                                <th>IP</th>
                                                <th>Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ListActivityLog

import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { getStorageItem } from '../../../helpers/storage'
import { IsSSR } from '../../../helpers/isSSR'

import Api from '../../../services/api'
import CheckError from '../../../utils/admin/checkError'
import NotificationVerify from '../../../utils/admin/notificationVerify'
import Toast from '../../../components/Toast'

import { apiUrl, keyUser, keyToken } from '../../../config'

const $ = require('jquery')
$.DataTable = require('datatables.net')

class ListPolling extends Component {
    constructor(props) {
        super(props)

        this.state = {
            typeList: "all",
            deletePollingModal: false,
            deletePollingData: {
                id: ''
            },
            isAdmin: false,
            isEditor: false,
            isAuthor: false,
            isContributor: false,
            isAs: '',
            loadingRole: true,
            requestData: {}
        }

        this.refreshPollings = this.refreshPollings.bind(this)
        this.deletePolling = this.deletePolling.bind(this)
        this.onChangeType = this.onChangeType.bind(this)
        this.getQueryVariableState = this.getQueryVariableState.bind(this)
        this.isSSR = IsSSR();
        this.token = null;
        if (!this.isSSR) this.token = getStorageItem(keyToken);
    }

    componentDidMount() {
        setTimeout(() => this.getQueryVariableState())
        setTimeout(() => this.fetchData())
    }

    componentDidUpdate(prevProps, prevState) {
        window.onpopstate  = (e) => {
            let params = new URLSearchParams(this.props.location.search);
            let paramsPrev = new URLSearchParams(prevProps.location.search);
            if (
                paramsPrev.get('page') !== params.get('page') ||
                paramsPrev.get('limit') !== params.get('limit') ||
                paramsPrev.get('keyword') !== params.get('keyword') ||
                paramsPrev.get('state') !== params.get('state')
            ) {
                $('#datatable-polling').off( "click", ".detail-polling-btn");
                $('#datatable-polling').off( "click", ".delete-polling-btn");
                $('#datatable-polling')
                    .DataTable()
                    .destroy()
                $('#datatable-polling tbody').empty();
                setTimeout(() => this.getQueryVariableState())
                setTimeout(() => this.fetchData())
            }
        }
    }

    componentDidUnmount() {
        window.onpopstate = () => {}
    }

    onChangeType(e) {
        const { name, value } = e.target
        const { requestData } = this.state
        this.setState({ typeList: value });
        this.props.history.push(`/admin/polling?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}&state=${requestData.state_id ? requestData.state_id: 'all'}`);
    }

    async getQueryVariableState() {
        let params = new URLSearchParams(this.props.location.search);
        var pair = params.get('state');
        if (pair && pair && (pair == 1 || pair == 2 || pair == 3)) {
            this.setState({ typeList: pair });
        } else {
            this.setState({ typeList: 'all' });
        }
    }

    fetchData() {
        const user = JSON.parse(this.props.localUser)
        const {
            isAs
        } = this.props

        var _ = this
        const route = `/polling/index`
        const url = `${apiUrl}${route}`
        this.$el = $(this.el)
        var tagDatatableDraw = 1
        let searchParams = new URLSearchParams(_.props.location.search);
        var page = searchParams.get('page') && !isNaN(searchParams.get('page')) ? Number(searchParams.get('page')) : 1;
        var limit = searchParams.get('limit') && !isNaN(searchParams.get('limit')) ? Number(searchParams.get('limit')) : 5;
        var start = (page - 1) * limit ? (page - 1) * limit : 0;
        this.$el.DataTable({
            processing: true,
            scrollX: true,
            serverSide: true,
            order: [[0, 'desc']],
            pageLength: limit,
            displayStart: start,
            search: {"search": searchParams.get('keyword') ? searchParams.get('keyword') : ""},
            lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
            searching: true,
            searchDelay: 1000,
            processing: true,
            ajax: {
                url: url,
                headers: {
                    'x-access-token': _.token
                },
                data: function (data) {
                    tagDatatableDraw = data.draw
                    var requestData = {}
                    requestData.page = data.start / data.length + 1
                    requestData.limit = data.length
                    requestData.keyword = data.search.value
                    requestData.state_id = _.state.typeList
                    requestData.schedule = 0
                    _.setState({requestData : requestData});
                    if (['admin', 'editor'].indexOf(isAs) > -1) {
                    } else {
                        requestData.user_id = user.id
                    }
                    if(data.draw !== 1) {
                        _.props.history.push(`/admin/polling?keyword=${requestData.keyword}&limit=${requestData.limit}&page=${requestData.page}&state=${requestData.state_id}`);
                    }
                    return requestData
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // Do something here
                    if(jqXHR && jqXHR.responseJSON) { CheckError(jqXHR.responseJSON) }
                    else { Toast('Error internet connection') }
                },
            },
            columns: [
                { data: 'id', name: 'id', orderable: false },
                {
                    data: null,
                    name: 'name',
                    orderable: false,
                    searchable: false,
                    render: function (data) {
                        return data.user.name
                    }
                },
                {
                    data: null,
                    name: 'title',
                    orderable: false,
                    width: '250px',
                    render: function (data) {
                        return (
                            '<a href="/admin/polling/' + data.id + '" data-id="' +
                            data.id +
                            '" class="detail-polling-btn">' +
                            data.title +
                            '</a>'
                        )
                    }
                },
                {
                    data: null,
                    name: 'status',
                    orderable: false,
                    render: function (data) {
                        return data.state.name
                    }
                },
                {
                    data: 'createdAt',
                    name: 'CreatedAt',
                    orderable: false,
                    render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm')
                    }
                },
                {
                    data: null,
                    className: 'center',
                    searchable: false,
                    orderable: false,
                    render: function (data) {
                        return (
                            '<div class="btn-group" role="group" aria-label="Basic example"><a  href="/admin/polling/' + data.id + '"  data-id="' +
                            data.id +
                            '" class="detail-polling-btn btn btn-sm btn-color btn-button w-100" style="background-color: orange; color: rgb(254, 254, 254);"><i class="fa fa-pencil" aria-hidden="true"></i></a><button  data-id="' +
                            data.id +
                            '" class="delete-polling-btn btn btn-sm btn-color btn-button w-100" style="background-color: red; color: rgb(254, 254, 254);"><i class="fa fa-trash" aria-hidden="true"></i></button></div>'
                        )
                    }
                }
            ]
        })
        $('#datatable-polling').on('click', '.detail-polling-btn', function (e) {
            e.preventDefault();
            var id = $(this).data('id')
            _.showPollingDetail(id)
        })
        $('#datatable-polling').on('click', '.delete-polling-btn', function () {
            var id = $(this).data('id')
            _.deletePolling(id)
        })
    }

    showPollingDetail(id) {
        this.props.history.push(`/admin/polling/${id}`)
    }

    componentWillUnmount() { }

    refreshPollings() {
        $('#datatable-polling')
            .DataTable()
            .ajax.reload()
    }

    deleteRowTable(id) {
        this.setState({ loading: true })
        const route = `/polling/delete/` + id
        Api.delete(route, this.token)
            .then(response => {
                if (response.status && response.status === 'success') {
                    this.refreshPollings()
                    Toast(response.message, 'success')
                    this.setState({ deletePollingModal: false })
                } else { CheckError(response) }
            })
            .catch((err) => {
                this.refreshPollings()
                this.setState({ deletePollingModal: false })
                CheckError(err)
            })
    }

    toggleDeletePollingsModal() {
        this.setState({
            deletePollingModal: !this.state.deletePollingModal
        })
    }

    deletePolling(id) {
        this.setState({
            deletePollingData: {
                id: id
            },
            deletePollingModal: !this.state.deletePollingModal
        })
    }

    render() {
        return (
            <div className="col-lg-9 col-12">
                <NotificationVerify isAs={this.props.isAs} localUser={this.props.localUser}>
                    <div className="entry card card-input">
                        <Modal
                            isOpen={this.state.deletePollingModal}
                            toggle={this.toggleDeletePollingsModal.bind(this)}
                            className={this.props.className}
                        >
                            <ModalHeader
                                toggle={this.toggleDeletePollingsModal.bind(this)}
                            >
                                Konfirmasi
                            </ModalHeader>
                            <ModalBody>
                                Apakah ingin menghapus polling ini? id :{' '}
                                {this.state.deletePollingData.id}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={this.deleteRowTable.bind(
                                        this,
                                        this.state.deletePollingData.id
                                    )}
                                >
                                    Hapus
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDeletePollingsModal.bind(this)}
                                >
                                    Batalkan
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <div className="row">
                            <div className="col-12">
                                <div className="mt-3">
                                    <h4 className="heading-title">Polling List</h4>
                                    <Link to="/admin/polling/add">
                                        <span className="btn btn-md btn-color mt-3 mb-3">
                                            Add New Polling
                                        </span>
                                    </Link>
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-9 col-12">
                                                <select name="type" id="type" onChange={this.onChangeType} value={this.state.typeList}>
                                                    <option value="all">All</option>
                                                    <option value="3">Published</option>
                                                    <option value="2">Pending</option>
                                                    <option value="1">Draft</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-12 text-right">
                                                <button className="btn btn-md btn-color btn-button" onClick={() => this.refreshPollings()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                    <table
                                        id="datatable-polling"
                                        className="display table table-sm table-striped table-bordered"
                                        width="100%"
                                        ref={el => (this.el = el)}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Author</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </NotificationVerify>
            </div>
        )
    }
}

export default ListPolling
